import React, { ChangeEvent, useState } from 'react';
import Image from 'next/legacy/image';
import { Neighborhood } from '../../../apolloClient/types';
import { City } from '../../../apolloClient/types/City';
import useDebounce from '../../../hooks/useDebounce';
import { NeighborhoodsResponse } from '../../../apolloClient/types/Neighborhood';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from '../../../lib/flattenStrapiBulkDataItems';
import BasicAccordion from './BasicAccordion';
import ResultAccordion from './ResultAccordion';
import iconClose from 'public/images/close.svg';

const NeighborhoodMenuBody: React.FC<{
  neighborhoods?: NeighborhoodsResponse;
  fetchMoreNhds: (search: string) => void;
}> = ({ fetchMoreNhds, neighborhoods }) => {
  const [value, setValue] = useState('');

  useDebounce({
    callback: () => fetchMoreNhds(value),
    changingValue: value,
    timeout: 500,
    callOnInitial: false,
  });

  const changeInputValue = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  function groupNeighborhoods(neighborhoods: Neighborhood[]) {
    const groups: {
      [key: string]: {
        [key: string]: { slug: string; neighborhoods: Neighborhood[] };
      };
    } = {};

    const updateCountyGroup = (
      countyName: string,
      city: City,
      neighborhood: Neighborhood
    ) => {
      const countyGroup = groups[countyName] || {};
      const cityName = city.name || 'Other';
      const citySlug = city.slug || 'other';
      const cityGroup = countyGroup[cityName] || {
        slug: citySlug,
        neighborhoods: [],
      };
      groups[countyName] = {
        ...countyGroup,
        [cityName]: {
          ...cityGroup,
          neighborhoods: [...cityGroup.neighborhoods, neighborhood],
        },
      };
    };

    neighborhoods.forEach((neighborhood) => {
      const city = flattenStrapiDataItem(neighborhood.city.data);
      const county = flattenStrapiDataItem(city.county?.data);
      updateCountyGroup(county.name || 'Other', city, neighborhood);
    });

    const modifiedCounties = Object.keys(groups).map((key) => ({
      countyName: key,
      cities: Object.keys(groups[key]).map((cityName) => ({
        cityName,
        slug: groups[key][cityName].slug,
        neighborhoods: groups[key][cityName].neighborhoods,
      })),
    }));

    modifiedCounties.sort((a, b) => a.countyName.localeCompare(b.countyName));

    // Apply sorting to each county's cities
    modifiedCounties.forEach((c) => {
      // Separate cities with neighbors and cities without neighbors
      const citiesWithNeighbors = c.cities.filter((city) => {
        const neighbors = city.neighborhoods.map(
          (neighborhood) => neighborhood.name
        );

        return (
          neighbors.length !== 1 ||
          (neighbors.length === 1 && neighbors[0] !== city.cityName)
        );
      });

      const citiesWithoutNeighbors = c.cities.filter((city) => {
        const neighbors = city.neighborhoods.map(
          (neighborhood) => neighborhood.name
        );

        return !(
          neighbors.length !== 1 ||
          (neighbors.length === 1 && neighbors[0] !== city.cityName)
        );
      });

      // Sort cities with neighbors
      citiesWithNeighbors.sort((a, b) => {
        // Check if 'Miami' should come first
        if (a.cityName === 'Miami') return -1;
        if (b.cityName === 'Miami') return 1;

        // Check if 'Miami Beach' should come next
        if (a.cityName === 'Miami Beach') return -1;
        if (b.cityName === 'Miami Beach') return 1;

        // Sort by city name if no special conditions apply
        return a.cityName.localeCompare(b.cityName);
      });

      // Concatenate the sorted arrays
      c.cities = citiesWithNeighbors.concat(citiesWithoutNeighbors);
    });

    return modifiedCounties;
  }

  return (
    <React.Fragment>
      <div className="relative w-full pl-[1px] xl:px-[18px] xl:pb-[4px] xl:pt-[2px] pr-[5px] xl:border-b border-beige-dark">
        <input
          className="w-full p-[8px] pl-[16px] mt-[21px] mb-[15px] text-16-26-0.3 bg-white border text-black border-gray"
          type="text"
          placeholder="Search Neighborhoods"
          value={value}
          onChange={changeInputValue}
        />
        <button
          onClick={() => setValue('')}
          className="absolute top-[29px] right-[19px] xl:top-[39px] xl:right-[29px] md:top-[30px] lg:top-[30px] md:right-[20px] lg:right-[20px]"
        >
          <Image src={iconClose} alt="close popup" width={15} height={15} />
        </button>
      </div>
      <div className="grid text-16-34-0.3 pt-[5px] xl:pt-0">
        {groupNeighborhoods(
          flattenStrapiBulkDataItems(neighborhoods?.data)
        ).map(({ countyName, cities }) =>
          !value ? (
            <BasicAccordion
              key={countyName}
              countyName={countyName}
              cities={cities}
            />
          ) : (
            <ResultAccordion
              key={countyName}
              countyName={countyName}
              cities={cities}
            />
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default NeighborhoodMenuBody;
