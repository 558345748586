import React, { useContext } from 'react';
import Image from 'next/legacy/image';
import { format } from 'date-fns';

import arrowLeft from '../../../public/images/arrow-left.svg';
import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import { KeyValueList } from '../../KeyValueList';

interface SalesHistory {
  [key: string]: number;
}

type BuildingStatsProps = {
  href: string;
  showButton?: string;
};

const SalesHistory: React.FC<BuildingStatsProps> = ({ href, showButton }) => {
  const {
    building: { buildingStats },
  } = useContext(BuildingPageContext) as BuildingPageContextInterface;
  const saleHistoryState = buildingStats?.saleHistory;

  let salesHistory: SalesHistory | null = null;
  if (typeof saleHistoryState === 'string') {
    try {
      const parsedData = JSON.parse(saleHistoryState);
      if (typeof parsedData === 'object' && parsedData !== null) {
        salesHistory = parsedData as SalesHistory;
      } else {
        console.error('Parsed data is not an object:', parsedData);
      }
    } catch (error) {
      console.error('Error parsing JSON in SalesHistory', error);
    }
  } else if (
    typeof saleHistoryState === 'object' &&
    saleHistoryState !== null
  ) {
    salesHistory = saleHistoryState as SalesHistory;
  } else {
    console.error(
      'saleHistory is neither a string nor an object:',
      saleHistoryState
    );
  }

  const salesHistoryList = Array.from(Array(6).keys()).map((index) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const indexMonthAgoDate = new Date(currentYear, currentMonth - index);

    const i = format(indexMonthAgoDate, 'yyyy-MM');
    return {
      key: format(indexMonthAgoDate, 'MMMM yyyy'),
      value: salesHistory ? salesHistory[i] || 0 : 0,
    };
  });

  const showSeeRecentButton =
    !!salesHistoryList[0].value ||
    !!salesHistoryList[1].value ||
    !!salesHistoryList[2].value ||
    !!salesHistoryList[3].value ||
    !!salesHistoryList[4].value;
  return (
    <>
      <div className="block xl:hidden w-fill mt-12 h-0.5 bg-beige" />
      <div className="flex flex-col pt-[26px] md:w-2/3 xl:w-1/3 xl:border-l xl:border-beige-dark">
        <div className="flex flex-col xl:mt-[88px] xl:ml-8">
          <h2 className="text-18-28-0.3 md:text-21-28-0.3 uppercase font-sohneKraftig">
            Sales history
          </h2>
          <p className="text-14-26-0.3 md:text-16-26-0.3">
            Number of sold condos by month
          </p>
        </div>
        <div></div>
        <div className="mt-[15px] md:mt-6 xl:ml-8 lg:mt-[17px]">
          <KeyValueList
            rowsClassName="lg:text-16-26-0.3"
            colRightClassName="font-sohneKraftig flex justify-end py-[6px] md:py-[4px] text-14-22-0.3 md:text-21-28-0.3"
            colLeftClassName="py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3"
            rows={salesHistoryList}
          />
        </div>
        {showSeeRecentButton && (
          <div className={`${showButton} xl:pl-8`}>
            <a
              href={href}
              className="flex items-center uppercase border-b-2 cursor-pointer border-gold-dark md:mb-10 xl:mb-6 w-max"
            >
              <div className="w-5 h-4">
                <Image src={arrowLeft} alt="See recent sales" width={15} />
              </div>
              <span className="whitespace-nowrap">See recent sales</span>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default SalesHistory;
