import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import Image from 'next/legacy/image';

import arrowLeft from 'public/images/arrow-left.svg';
import FlagLabel from 'components/FlagLabel';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';

import MarkdownParser from '../MarkdownParser';

const BuildingDescription: React.FC = () => {
  const { building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const { name, description } = building;

  return (
    <div className="flex flex-col pt-[24px] mx-0 md:pt-[33px] md:w-1/2 lg:pr-[13px]">
      <div className="relative flex flex-col justify-center mb-[37px] lg:mb-[40px] w-max">
        <FlagLabel name="About" />
      </div>
      <h2 className="mb-[10px] text-18-23-0.3 md:text-21-28-0.3 font-sohneKraftig">
        {name}
      </h2>
      {description && (
        <>
          <div
            className={classnames(
              'relative md:pr-1 text-14-21-0.3 md:text-16-26--0.3 lg:text-16-26-0.3',
              {
                'h-44 md:h-112 lg:h-[444px]': !descriptionOpen,
                'overflow-hidden': !descriptionOpen,
              }
            )}
          >
            <MarkdownParser
              markdown={description}
              classNames="text-14-21-0.3 lg:text-16-26-0.3"
            />
            {description.length > 1040 && (
              <div
                className={classnames('absolute bottom-0 h-10 w-full', {
                  hidden: descriptionOpen,
                })}
                style={{
                  background: 'linear-gradient(rgba(255,255,255,0),#fff)',
                }}
              />
            )}
          </div>
          {description.length > 1040 && (
            <button
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              className="min-w-[57px] flex items-center mt-4 text-16-26-0.3 uppercase border-b-[3px] cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
            >
              <div className="w-[8px] h-[14px] mr-[7px]">
                <Image
                  className={classnames({
                    'transform -rotate-90': descriptionOpen,
                  })}
                  src={arrowLeft}
                  alt={`${descriptionOpen ? 'Hide' : 'Read more'}`}
                  width={8}
                  height={14}
                />
              </div>
              <span className="pt-[6px] whitespace-nowrap">
                {`${descriptionOpen ? 'Hide' : 'Read more'}`}
              </span>
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default BuildingDescription;
