import React from 'react';
import Image from 'next/legacy/image';

import iconPrint from '../public/images/print.svg';

const Print: React.FC = () => {
  return (
    <button
      onClick={() => document.execCommand('print')}
      className="items-center hidden uppercase h-7 md:flex"
    >
      <Image src={iconPrint} alt="print" width={25} height={25} />
      <span className="px-2 pt-px hover:text-gold-hover">Print</span>
    </button>
  );
};

export default Print;
