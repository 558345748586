import { StrapiMediaInfo } from 'apolloClient/types';

export function getSmallImageUrlFromImage(image: StrapiMediaInfo) {
  return image?.formats?.small?.url || image?.url;
}

export function getMediumImageUrlFromImage(image: StrapiMediaInfo) {
  return image?.formats?.medium?.url || image?.url;
}

export function getThumbnailImageUrlFromImage(image: StrapiMediaInfo) {
  return image?.formats?.thumbnail?.url || image?.url;
}
