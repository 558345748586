import React from 'react';
import { formatPriceValue } from '../utils';

interface CalculatedValueProps {
  calculatedValue: number;
}
const CalculatedValue: React.FC<CalculatedValueProps> = ({
  calculatedValue,
}) => {
  return (
    <div className="flex flex-col lg:w-[534px]">
      <div className="py-[8px] text-center border lg:w-[534px] border-beige-dark lg:py-[22px]">
        <div className="text-14-21-0.3 lg:text-16-26-0.3 lg:uppercase">
          Estimated monthly cost
        </div>
        <div className="mt-[4px] mb-[10px] lg:mb-[26px] border border-gold-dark bg-gold-dark lg:mx-[37px]" />
        <div className="text-40-40-0.3 text-bold font-sohneKraftig">
          {formatPriceValue(calculatedValue)}
        </div>
      </div>
      <span className="pt-6 pb-[32px] lg:pt-[35px] text-12-21-0.3 lg:w-3/4 text-gray">
        All calculations are estimates and provided for informational purposes
        only. Actual amounts may vary.
      </span>
    </div>
  );
};

export default CalculatedValue;
