import { useRouter } from 'next/router';
import React from 'react';

import { ANY } from '../../../../constants/building/availableCondos';

import CbbDropdown from '../../../CbbDropdown';

import { SetFiltersValues } from '../../types';

interface Props {
  setValues: SetFiltersValues;
  initialValue: string;
  isBuildingPage?: boolean;
}

const DaysOnBlackbook: React.FC<Props> = ({
  setValues,
  initialValue,
  isBuildingPage = false,
}) => {
  const { query } = useRouter();
  const { forSale, forRent, sold } = query;
  return (
    <>
      {sold && !forRent && !forSale ? (
        ''
      ) : (
        <div className="md:w-1/2">
          <label htmlFor="" className="md:text-14-26-0.3">
            Days on Blackbook
          </label>
          <div className="flex align-center leading-[21px]">
            <CbbDropdown
              rootClassName={`w-full md:w-[298px] pt-[8px] pb-[9px] h-[44px] md:pl-[16px] text-black`}
              selectClassName="w-full md:w-[298px] h-[44px] left-[0px] md:left-[-1px] border-0 border-b"
              nameSelected="Any Time"
              initialSelectedOption={initialValue}
              onChange={(value) =>
                setValues((values) => ({ ...values, daysOnCbb: value }))
              }
              options={[
                { label: 'Any Time', value: ANY },
                { label: '1 day', value: '1' },
                { label: '7 days', value: '7' },
                { label: '14 days', value: '14' },
                { label: '30 days', value: '30' },
                { label: '90 days', value: '90' },
                { label: '6 months', value: '182' },
                { label: '12 months', value: '365' },
                { label: '24 months', value: '730' },
                { label: '36 months', value: '1095' },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DaysOnBlackbook;
