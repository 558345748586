import React from 'react';
import classnames from 'classnames';

import { NO_DATA_PLACEHOLDER } from '../../constants/labels';
import { Building } from '../../apolloClient/types';

import {
  getBuildingFloorplans,
  NormalizedBuildingFloorplan,
} from '../../src/utils/buildingUtils';

interface BuildingFloorplanBodyProps {
  activeRowIndex: number | null;
  building: Building;
  openImage: (rowIndex: number) => void;
  openSitePlan: () => void;
}

const BuildingFloorplanBody: React.FC<BuildingFloorplanBodyProps> = ({
  building,
  activeRowIndex,
  openImage,
  openSitePlan,
}) => {
  const { floorplans } = building;
  const floorplansData = getBuildingFloorplans(floorplans);

  function cellValue(value: any) {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    return value;
  }

  function renderTable() {
    return [
      {
        key: 'aptNo',
        heading: 'Apt#',
        headingMobile: 'Apt#',
      },
      {
        key: 'bedBath',
        heading: 'Bed/Baths',
        headingMobile: 'Bed/Bath',
      },
      {
        key: 'floors',
        heading: 'Floors',
      },
      {
        key: 'interiorExt',
        heading: 'Interior + Ext (Sq Ft)',
        widthRatio: 2,
      },
    ].map((column) => (
      <div
        key={column.key}
        className={`pb-2`}
        style={{ flexGrow: column.widthRatio || 1, flexBasis: 0 }}
      >
        <div
          className={`pr-[3px] md:pr-2 md:mb-8 text-14-26-0.3 font-sohneKraftig whitespace-normal h-12 mb-0`}
        >
          {column.headingMobile
            ? column.headingMobile
            : column.heading || column.key}
        </div>
        {floorplansData.map((row, i) => {
          return (
            <React.Fragment key={i}>
              <div>
                <div
                  className={classnames({
                    'border-dashed-floorplans-popup': i > 0,
                  })}
                />
                <div className="pr-6 my-2 text-14-26-0.3 md:text-16-26-0.3 tracking-[-0.5px] cursor-pointer">
                  <div
                    className={classnames({
                      'text-gold': i === activeRowIndex,
                    })}
                    onClick={() => openImage(i)}
                  >
                    {cellValue(row[column.key])}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    ));
  }

  return (
    <React.Fragment>
      <div className="flex flex-row no-scroll whitespace-nowrap h-112">
        {renderTable()}
      </div>
      <div className="flex justify-center w-full my-5 xl:pr-8 xl:mt-12 xl:justify-end">
        <button
          onClick={() => openSitePlan()}
          className="h-8 px-2 uppercase bg-white border w-36 border-gold hover:bg-gold hover:border-gold"
        >
          View site plan
        </button>
      </div>
    </React.Fragment>
  );
};

export default BuildingFloorplanBody;
