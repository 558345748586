import React from 'react';
import Image from 'next/legacy/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StrapiBulkResponseData } from 'apolloClient/types';

import unitRightArrowBig from '../../public/images/unit_right_arrow.svg';
import unitLeftArrowBig from '../../public/images/unit_left_arrow.svg';
import arrowDown from '../../public/images/arrow-down.svg';

import NeighborhoodBlock from '../Neighborhood/NeighborhoodBlock';
import LineBetweenSections from 'components/LineBetweenSections';
import { NeighborhoodWithoutId } from 'apolloClient/types/Neighborhood';
import { defaultImage } from 'src/constants/constants';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';

const NearbyNeighborhoods: React.FC<{
  neighborhoods: StrapiBulkResponseData<NeighborhoodWithoutId>;
}> = ({ neighborhoods }) => {
  const imagesCarousel = Boolean(neighborhoods && neighborhoods.length > 1);
  function NearbyNeighborhoodsPrevArrow({
    currentSlide,
    slideCount,
    ...props
  }: any) {
    return (
      <button
        {...props}
        className="transform md:hidden slick-arrow slick-prev slick-prev-nearby-neighborhoods mt-[5px] md:mt-0 !left-[23px]"
      >
        <Image src={unitLeftArrowBig} alt="previous" width="28" height="48" />
      </button>
    );
  }
  function NearbyNeighborhoodsNextArrow({
    currentSlide,
    slideCount,
    ...props
  }: any) {
    return (
      <button
        {...props}
        className="z-0 transform md:hidden slick-arrow slick-next slick-next-nearby-neighborhoods mt-[5px] md:mt-0"
      >
        <Image src={unitRightArrowBig} alt="next" width="28" height="48" />
      </button>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: neighborhoods.slice(0, 3).length,
    className:
      neighborhoods.slice(0, 3).length === 1
        ? 'w-1/3'
        : neighborhoods.slice(0, 3).length === 2
        ? 'w-2/3'
        : 'w-full',
    slidesToScroll: 1,
    prevArrow: <NearbyNeighborhoodsPrevArrow />,
    nextArrow: <NearbyNeighborhoodsNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: neighborhoods.slice(0, 3).length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: 'w-full',
        },
      },
    ],
  };
  return (
    <>
      {neighborhoods && neighborhoods.length > 0 && (
        <>
          <LineBetweenSections hideLine={'container'} />
          <section className="container lg:pl-[50px] lg:pr-[14px] pt-3 md:pt-[36px] lg:pt-[30px]">
            <div className="flex ">
              <div className="items-center justify-center hidden w-10 mx-2 md:flex lg:w-16">
                <div className="w-10 lg:w-16 h-0.5 bg-black" />
              </div>
              <div className="justify-center text-23-29-0.77  uppercase whitespace-pre-line w-max lg:text-30-36-1 md:mx-4 font-sohneBreitBuch">
                <button className="flex items-start text-left uppercase">
                  <div className="block w-8 mr-1 md:hidden">
                    <Image src={arrowDown} alt="Nearby Neighborhoods" />
                  </div>
                  <h2 className="whitespace-pre-line">Nearby Neighborhoods</h2>
                </button>
              </div>
            </div>
            <div className="relative mb-2 md:my-4 lg:mb-14">
              <Slider {...settings}>
                {neighborhoods
                  // .slice(0, 3)
                  .map((neighbors) => (
                    <NeighborhoodBlock
                      key={`nearby-${neighbors.attributes?.slug}`}
                      href={`/${neighbors?.attributes?.slug}`}
                      imageUrl={getSmallImageUrlFromImage(
                        flattenStrapiDataItem(
                          neighbors?.attributes?.image?.data
                        )
                      )}
                      blurredImageUrl={getThumbnailImageUrlFromImage(
                        flattenStrapiDataItem(
                          neighbors?.attributes?.image?.data
                        )
                      )}
                      imageAlt={`${neighbors?.attributes?.name} photo`}
                      nameNeighbor={neighbors.attributes?.name}
                      minSalePrice={
                        neighbors?.attributes?.neighborhoodStats
                          ?.minSalePrice || 0
                      }
                      maxSalePrice={
                        neighbors?.attributes?.neighborhoodStats
                          ?.maxSalePrice || 0
                      }
                      description={`${
                        neighbors?.attributes?.neighborhoodStats?.saleCount || 0
                      } Condos for Sale`}
                    />
                  ))}
              </Slider>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default NearbyNeighborhoods;
