import React, { useRef } from 'react';
import Image from 'next/legacy/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';

import { getVideoUrl } from 'lib/getVideoUrl';
import unitLeftArrow from 'public/images/unit_left_arrow.svg';
import unitRightArrow from 'public/images/unit_right_arrow.svg';

const PopUpTour: React.FC<{ tours?: string[] | string | null }> = ({
  tours,
}) => {
  const more426 = useMediaQuery('(min-width: 426px)');
  const more600 = useMediaQuery('(min-width: 600px)');
  const more639 = useMediaQuery('(min-width: 639px)');
  const more1680 = useMediaQuery('(min-width: 1680px)');

  const currentIframeRef = useRef(null);
  function TourPrevArrow({ onClick, ...props }: any) {
    return (
      <button
        {...props}
        onClick={onClick}
        className={classNames(
          'z-50 transform -translate-y-1/2 slick-arrow slick-prev slick-prev-tour md:!top-[184px] lg:!top-[259px]',
          {
            '!top-[83px]': !more426,
            '!top-[169px]': !!more426,
            '!top-[142px]': !!more600,
            'xl:!top-[292px]': !!more1680,
          }
        )}
      >
        <Image src={unitLeftArrow} alt="previous item" width="28" height="48" />
      </button>
    );
  }
  function TourNextArrow({ onClick, ...props }: any) {
    return (
      <button
        {...props}
        onClick={onClick}
        className={classNames(
          'z-50 transform -translate-y-1/2 slick-arrow slick-next slick-next-tour md:!top-[184px] lg:!top-[259px]',
          {
            '!top-[83px]': !more426,
            '!top-[169px]': !!more426,
            '!top-[142px]': !!more600,
            'xl:!top-[292px]': !!more1680,
          }
        )}
      >
        <Image src={unitRightArrow} alt="next item" width="28" height="48" />
      </button>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // afterChange: (e: any) =>
    //   (e.target as HTMLElement).childNodes[0].childNodes.forEach(
    //     (block) => {
    //       if ((block as HTMLElement).classList.contains('active')) {
    //         return (
    //           block.childNodes[0] as HTMLIFrameElement
    //         ).contentWindow?.postMessage(
    //           '{"event":"command","func":"playVideo","args":""}',
    //           '*'
    //         );
    //       }
    //       (
    //         block.childNodes[0] as HTMLIFrameElement
    //       ).contentWindow?.postMessage(
    //         '{"event":"command","func":"stopVideo","args":""}',
    //         '*'
    //       );
    //     }
    //   ),
    prevArrow: <TourPrevArrow />,
    nextArrow: <TourNextArrow />,
  };

  return (
    <div
      className={classNames(
        'h-screen md:pr-[35px] md:pl-[36px] overflow-y-auto pb-60 md:pb-60 lg:pb-0 flex justify-center',
        {
          'pl-[11px] pr-[12px]': !more639,
          'pl-[24px] pr-[25px]': !!more639,
        }
      )}
    >
      <div className="relative video-popup">
        {Array.isArray(tours) ? (
          <>
            <Slider {...settings}>
              {tours.map((url) => {
                let videoUrl = getVideoUrl(url);
                videoUrl.searchParams.append('enablejsapi', '1');
                return (
                  <div
                    className="flex justify-center w-full h-screen-tour-video"
                    key={url}
                  >
                    <iframe
                      className="w-full h-full"
                      src={videoUrl.toString()}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                );
              })}
            </Slider>
          </>
        ) : tours ? (
          <>
            <iframe
              src={
                tours.includes('watch')
                  ? tours.replace('watch?v=', 'embed/')
                  : tours.includes('youtu.be')
                  ? tours.replace('youtu.be', 'www.youtube.com/embed/')
                  : tours.includes('dropbox')
                  ? tours.replace('dl=0', 'raw=1')
                  : tours
              }
              className="w-full h-full"
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PopUpTour;
