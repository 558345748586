import React, { useContext, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';

import FlagLabel from '../../FlagLabel';
import TransitionWrapper from '../../TransitionWrapper';
import ContactUsDialog from '../../ContactUs/ContactUsDialog';

import CondoEstimatesDisclosurePanel from './CondoEstimatesDisclosurePanel';
import LineBetweenSections from '../../LineBetweenSections';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import ErrorBoundary from 'components/ErrorBoundary';
import { Agent } from 'apolloClient/types/Agents';

type CondoValueEstimatesProps = {
  experts: Agent[];
};
const CondoValueEstimates: React.FC<CondoValueEstimatesProps> = ({
  experts,
}) => {
  const [open, setOpen] = useState(false);
  const { listing } = useContext(UnitPageContext) as UnitPageContextInterface;
  const { propertyTypeCode } = listing;

  const handlePopUpClose = () => setOpen(false);
  const handlePopUpOpen = () => setOpen(true);

  if (propertyTypeCode === 'R') return null;

  return (
    <ErrorBoundary>
      <section className="container relative pt-0 pb-3 md:pt-[22px] md:pb-10 text-m">
        <div
          id="condo-value-estimates"
          className="absolute -top-20 md:-top-12"
        />
        <ContactUsDialog
          experts={experts}
          open={open}
          onClose={handlePopUpClose}
        />
        <Disclosure defaultOpen={true} as="div">
          {({ open }) => {
            return (
              <>
                <Disclosure.Button
                  as="div"
                  className="flex mt-4 mb-10 cursor-pointer md:hidden"
                >
                  <div className="relative flex flex-col justify-center w-max">
                    <FlagLabel
                      imageClassName={classnames({
                        'transform rotate-180': open,
                      })}
                      iconFlagLabel={true}
                      name="Condo Value Estimates"
                    />
                  </div>
                </Disclosure.Button>
                <div className="hidden mb-10 md:mb-[50px] md:flex">
                  <div className="relative flex flex-col justify-center w-max">
                    <FlagLabel
                      imageClassName={classnames({
                        'transform rotate-180': !open,
                      })}
                      iconFlagLabel={true}
                      name="Condo Value Estimates"
                    />
                  </div>
                </div>
                <TransitionWrapper>
                  <Disclosure.Panel>
                    <CondoEstimatesDisclosurePanel
                      openContactUsPopup={handlePopUpOpen}
                    />
                  </Disclosure.Panel>
                </TransitionWrapper>
              </>
            );
          }}
        </Disclosure>
      </section>
      <LineBetweenSections hideLine="container block" />
    </ErrorBoundary>
  );
};

export default CondoValueEstimates;
