import React, { useContext } from 'react';

import { Table } from '../../../components/Table';
import { NO_DATA_PLACEHOLDER } from '../../../constants/labels';
import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import TableSection from '../TableSection';

type PreconstructionTableSectionProps = {
  href: string;
  setAvailableCondosPropertyType: (type: 'rent' | 'sale') => void;
  availableCondosCoordinates?: number;
};

const PreconstructionTableSection: React.FC<PreconstructionTableSectionProps> =
  ({ href, setAvailableCondosPropertyType, availableCondosCoordinates }) => {
    const { building } = useContext(
      BuildingPageContext
    ) as BuildingPageContextInterface;
    const { floorsCount, unitsCount, yearBuilt, buildingStats } = building;
    return (
      <>
        {buildingStats?.maxSaleAreaPrice ||
        buildingStats?.maxSalePrice ||
        buildingStats?.minSaleAreaPrice ||
        buildingStats?.minSalePrice ||
        buildingStats?.rentCount ||
        buildingStats?.saleCount ? (
          <TableSection
            setAvailableCondosPropertyType={setAvailableCondosPropertyType}
            availableCondosCoordinates={availableCondosCoordinates}
          />
        ) : (
          <section className="container mt-2 md:mt-0 md:px-12 md:pt-5 md:pb-2 lg:py-2 text-m">
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2">
                <Table
                  rootClassName="mb-6 md:border-r border-dashed border-gray pr-1 md:pr-4"
                  rowClasses="font-sohneHalbfett text-16-26-0.3 md:text-20-28-0.3 lg:text-30-36-1 mt-4"
                  columns={[
                    {
                      key: 'forSale',
                      widthRatio: 2,
                      heading: 'FOR SALE',
                      headingMobile: 'FOR SALE',
                      cellRender: (row) => (
                        <a href={href} className="flex justify-center w-full">
                          <button className="flex items-center justify-center w-full pt-4 pb-3 pl-6 pr-3 text-14-26-0.3 uppercase hover-animation lg:w-3/4 bg-gold lg:pl-6 lg:pr-6 lg:pt-[7px] lg:pb-[7px] md:max-w-full md:hover:bg-gold-hover font-sohneBreitHalbfett lg:text-16-26-0.3">
                            <div className="text-14-26-0.3 lg:text-16-26-0.3 font-sohneBreitHalbfett">
                              pricing and availability
                            </div>
                          </button>
                        </a>
                      ),
                    },
                  ]}
                  data={[
                    {
                      id: 1,
                      forSale: '',
                    },
                  ]}
                />
              </div>
              <div className="md:w-1/2">
                <Table
                  rootClassName="grid grid-cols-3 mb-6 pr-1 ml-1 md:pr-4 md:ml-4 last:pr-0"
                  rowClasses="font-sohneHalbfett text-16-26-0.3 md:text-20-28-0.3 lg:text-30-36-1 mt-4"
                  columns={[
                    {
                      key: 'units',
                      heading: 'NO. OF UNITS',
                      headingMobile: 'UNITS',
                    },
                    {
                      key: 'stories',
                      heading: 'NO. OF STORIES',
                      headingMobile: 'STORIES',
                    },
                    {
                      key: 'year',
                      heading: 'YEAR BUILT ',
                      headingMobile: 'BUILT ',
                    },
                  ]}
                  data={[
                    {
                      id: 1,
                      units: unitsCount || NO_DATA_PLACEHOLDER,
                      stories: floorsCount || NO_DATA_PLACEHOLDER,
                      year: yearBuilt || NO_DATA_PLACEHOLDER,
                    },
                  ]}
                />
              </div>
            </div>
          </section>
        )}
      </>
    );
  };

export default PreconstructionTableSection;
