import React, { useState, useEffect } from 'react';

import { timeToHM } from 'lib/timeFormat';
import CbbDropdown from 'components/CbbDropdown';
import GoogleMapsSearch from 'components/GoogleMapsSearch';

import { CommuteParams, Transport } from './types';

const errorMesssages = {
  noTransit:
    'Unable to calculate commute by transit. Please select a different mode of transport.',
};
type CommuteFormErrors = 'noTransit' | '';

type CommuteFormProps = {
  CancelButton: React.ElementType;
  onCalculate: (res: CommuteParams) => Promise<string>;
  dataToEdit?: CommuteParams;
  onDelete?: () => void;
  mapInstance?: google.maps.Map;
};

const CommuteForm: React.FC<CommuteFormProps> = ({
  CancelButton,
  onCalculate,
  dataToEdit,
  onDelete,
  mapInstance,
}) => {
  const { time, destination, transport, name } = dataToEdit || {};
  const now = new Date();

  const [error, setError] = useState<CommuteFormErrors>('');
  const [selectedTransport, setSelectedTransport] = useState<Transport>('Car');
  const [selectedTime, setSelectedTime] = useState(timeToHM(now));
  const [curretnName, setName] = useState('');
  const [currentDestination, setDestination] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    time && setSelectedTime(timeToHM(time));
    destination && setDestination(destination);
    transport && setSelectedTransport(transport);
    setName(name || '');
  }, [time, destination, transport, name]);

  const calculate: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    setError('');
    if (currentDestination) {
      const hm = selectedTime.split(':');
      const time = new Date();
      time.setHours(Number(hm[0]));
      time.setMinutes(Number(hm[1]));
      const status = await onCalculate({
        destination: currentDestination,
        time,
        transport: selectedTransport,
        name: curretnName,
      });
      if (status === google.maps.DirectionsStatus.ZERO_RESULTS) {
        setError('noTransit');
      }
    }
  };

  return (
    <form className="mb-14 xl:p-5 xl:border xl:border-beige-dark">
      <div className="flex flex-col flex-grow">
        <div className="relative mb-2 font-sohneKraftig">Destination</div>
        <GoogleMapsSearch mapInstance={mapInstance} onChange={setDestination}>
          <input
            className="w-full p-2 text-16-26-0.3 border no-focus border-beige-dark"
            type="text"
            placeholder="Location or Address"
            defaultValue={destination?.formatted_address || ''}
          />
        </GoogleMapsSearch>
      </div>
      <div className="flex flex-col flex-wrap gap-3 md:flex-row">
        <div className="flex justify-between gap-3">
          <div className="flex flex-col w-32 md:w-48 xl:w-32">
            <div className="mt-4 mb-2 font-sohneKraftig">Depart At</div>
            <input
              value={selectedTime}
              type="time"
              className="w-full h-10.5 bg-white p-2 border no-focus border-beige-dark text-16-26-0.3"
              onInput={(e) =>
                setSelectedTime((e.target as HTMLInputElement).value)
              }
            />
          </div>
          <div className="flex flex-col w-32 md:w-48 xl:w-32">
            <div className="mt-4 mb-2 font-sohneKraftig ">Transport</div>
            <CbbDropdown
              selectClassName="w-32 md:w-48 xl:w-32 -left-0.1 mt-0"
              options={['Car', 'Walking', 'Bicycle', 'Transit']}
              nameSelected={selectedTransport}
              onChange={(res) => setSelectedTransport(res as Transport)}
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow ">
          <div className="mt-4 mb-2 font-sohneKraftig">Commute name</div>
          <input
            defaultValue={curretnName}
            onInput={(e) => setName((e.target as HTMLInputElement).value || '')}
            className="p-2 text-16-26-0.3 border no-focus border-beige-dark"
            type="text"
            placeholder="e.g. Greg’s Office"
          />
        </div>
      </div>
      {error && <div className="text-red">{errorMesssages[error]}</div>}
      <div className="flex justify-between md:justify-end pb-[60px]">
        {dataToEdit && onDelete && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
            className="flex items-center justify-center w-32 mt-3 mr-3 uppercase border outline-none bg-red-light border-red-light"
          >
            <span className="p-2 text-14-26-0.3 md:py-2.5 md:text-16-26-0.3">
              Delete
            </span>
          </button>
        )}
        {CancelButton && (
          <CancelButton className="flex items-center justify-center w-32 mt-3 mr-3 uppercase bg-white border outline-none border-gold">
            <span className="p-2 text-14-26-0.3 md:py-2.5 md:text-16-26-0.3">
              Cancel
            </span>
          </CancelButton>
        )}
        <button
          onClick={calculate}
          className="flex items-center justify-center w-32 mt-3 uppercase border border-gold bg-gold"
          disabled={!currentDestination}
        >
          <span className="p-2 text-14-26-0.3 md:py-2.5 md:text-16-26-0.3">
            Calculate
          </span>
        </button>
      </div>
    </form>
  );
};

export default CommuteForm;
