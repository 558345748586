import React, { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';

import { usePrevious } from '../../hooks/usePrevious';

import {
  FiltersValues,
  PropertyType,
  SetFiltersValues,
} from '../UnitsFilters/types';
import { defaultFilterValues } from '../UnitsFilters/constants';

import PriceRange from '../UnitsFilters/components/moreFiltersFields/PriceRange';
import BedroomRange from '../UnitsFilters/components/moreFiltersFields/BedroomRange';
import Baths from '../UnitsFilters/components/moreFiltersFields/Baths';
import Parking from '../UnitsFilters/components/moreFiltersFields/Parking';
import SquareFeet from '../UnitsFilters/components/moreFiltersFields/SquareFeet';
import DaysOnBlackbook from '../UnitsFilters/components/moreFiltersFields/DaysOnBlackbook';
import IOnlyWant from '../UnitsFilters/components/moreFiltersFields/IOnlyWant';
import SpecialTerms, {
  PageType,
} from '../UnitsFilters/components/moreFiltersFields/SpecialTerms';
import Keywords from '../UnitsFilters/components/moreFiltersFields/Keywords';
import PopoverSearchPage from '../UnitsFilters/components/PopoverSearchPage';

import useDebounce from 'hooks/useDebounce';
import { getMoreFiltersLabel } from '../Search/utils/listingsFiltersUtils';
import { useSearchCount } from '../../hooks/useSearchCount';
import { useDebounced } from 'lib/debounce';
import { isEqualValues } from 'src/utils/utils';
import { CircularProgress } from '@mui/material';

interface Props {
  values: FiltersValues;
  setValues: SetFiltersValues;
  buildingId: number;
  propertyType: PropertyType;
}

const BuildingPageMoreFilters: React.FC<Props> = ({
  values,
  setValues,
  propertyType,
  buildingId,
}) => {
  const { count, loadListingsCount, loading } = useSearchCount();
  const [isOpen, setIsOpen] = useState(false);
  const [moreFiltersValues, setMoreFiltersValues] = useState(values);
  const [lastCalledType, setLastCalledType] = useState<PropertyType>();

  const prevInitialValues = usePrevious(values);
  useEffect(() => {
    if (JSON.stringify(prevInitialValues) !== JSON.stringify(values)) {
      setMoreFiltersValues(values);
    }
  }, [values]);

  const [prevMoreFilterValues, setPrevMoreFilterValues] = useState({});

  const debouncedLoadListings = useDebounced<{
    filters: FiltersValues;
    buildingId: number;
    propertyType: PropertyType;
  }>(
    (val) => {
      loadListingsCount(val.filters, val.buildingId, val.propertyType);
      setLastCalledType(propertyType);
      setPrevMoreFilterValues(val.filters);
    },
    300,
    []
  );

  useEffect(() => {
    if (
      (!isEqualValues(prevMoreFilterValues, moreFiltersValues) ||
        propertyType !== lastCalledType) &&
      isOpen
    ) {
      debouncedLoadListings({
        filters: moreFiltersValues,
        buildingId,
        propertyType,
      });
    }
  }, [moreFiltersValues, isOpen, propertyType]);

  const setOpenStatus = (status: boolean) => {
    setIsOpen(status);
    return null;
  };

  return (
    <PopoverSearchPage
      buttonClassName="border mb-4 md:mb-0 md:py-2 lg:py-2.5 border-beige-dark outline-none"
      name={getMoreFiltersLabel(moreFiltersValues, defaultFilterValues)}
      render={() => (
        <Popover.Panel
          className="absolute transform -translate-x-1/2 outline-none z-90"
          ref={(ref: any) => ref === null && setValues(moreFiltersValues)}
        >
          {({ close, open }) => (
            <div className="absolute mt-6 text-16-26-0.3 bg-white border z-90 -left-6 md:-left-[76] lg:-left-40 xl:left-0 border-gray outline-none">
              {setOpenStatus(open)}
              <div className="relative w-screen outline-none lg:w-[676px]">
                <div className="absolute z-90 w-6 h-6 transform rotate-45 bg-white -translate-y-1/2 left-20 md:left-88 lg:left-56 xl:left-12 -top-0.1 border-t border-l border-gray" />
                <div className="sticky top-0 h-4" />
                <div className="px-8 pt-4 pb-8 overflow-y-auto h-screen-filters h-auto">
                  <PriceRange
                    min={moreFiltersValues.anyPrice.min}
                    max={moreFiltersValues.anyPrice.max}
                    setValues={setMoreFiltersValues}
                    page="building"
                    isBuildingPage
                  />
                  <div className="flex flex-col mb-6">
                    <div className="flex flex-col md:flex-row gap-[25px]">
                      <BedroomRange
                        min={moreFiltersValues.anyBeds.min}
                        max={moreFiltersValues.anyBeds.max}
                        setValues={setMoreFiltersValues}
                      />
                      <div className="flex md:w-1/2">
                        <Baths
                          initialValue={moreFiltersValues.baths}
                          setValues={setMoreFiltersValues}
                        />
                        <div className="self-end md:mb-[21px] md:w-[8px] px-1.5 md:px-0 mx-[7px] bg-beige border-none" />
                        <Parking
                          initialValue={moreFiltersValues.parkingPlaces}
                          setValues={setMoreFiltersValues}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="flex flex-col md:flex-row md:gap-[24px]">
                      <SquareFeet
                        min={moreFiltersValues.sqft.min}
                        max={moreFiltersValues.sqft.max}
                        setValues={setMoreFiltersValues}
                      />
                      <DaysOnBlackbook
                        setValues={setMoreFiltersValues}
                        initialValue={moreFiltersValues.daysOnCbb}
                        isBuildingPage
                      />
                    </div>
                  </div>
                  <IOnlyWant
                    values={moreFiltersValues}
                    setValues={setMoreFiltersValues}
                    page="building"
                  />
                  <SpecialTerms
                    values={moreFiltersValues}
                    setValues={setMoreFiltersValues}
                    page={PageType.building}
                  />
                  <Keywords
                    value={moreFiltersValues.keyWords}
                    setValues={setMoreFiltersValues}
                  />
                </div>
              </div>
              <div className="flex justify-center uppercase border-t border-beige-dark">
                <button
                  disabled={loading}
                  className="items-center px-8 py-2 my-4 text-12-18-0.3 hover-animation bg-gold hover:bg-gold-hover md:text-16-26-0.3"
                  onClick={() => close()}
                >
                  VIEW{' '}
                  {loading ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : (
                    count
                  )}{' '}
                  RESULTS
                </button>
              </div>
            </div>
          )}
        </Popover.Panel>
      )}
    />
  );
};

export default BuildingPageMoreFilters;
