import React, { useContext } from 'react';

import { BuildingPageContext } from '../pages/[urlParam]/[urlBuildParam]';

import ContactForm from './ContactUs/ContactForm';
import Agents from './Agents';
import { Agent } from 'apolloClient/types/Agents';

type RequestInfoProps = {
  experts: Agent[];
};
const RequestInfo: React.FC<RequestInfoProps> = ({ experts }) => {
  const { building } = useContext(BuildingPageContext) || {};

  return (
    <section className="relative px-0 pt-[5px] md:pt-[26px] lg:pt-6 pb-[20px] md:pb-[29.5px] xl:px-[67px] md:min-h-[513px] lg:min-h-[521px] bg-beige-light">
      <div id="request-info" className="absolute -top-12"></div>
      <div className="container">
        {building?.isPreconstruction && (
          <p className="pb-6 text-24-32-0.3 pt-9 font-sohneKraftig">
            Contact us for exclusive developer availability and pricing
          </p>
        )}
        <h2 className="pb-[27px] md:pb-[46px] lg:pb-[38px] uppercase text-23-29-0.77 md:text-23-29-0.77 xl:text-30-47-1 font-sohneBreitBuch">
          Request info
        </h2>
        <div className="flex flex-col-reverse md:flex-row md:justify-between md:gap-[31px] lg:gap-[114px]">
          <ContactForm experts={experts} isHeaderForm={false} />
          <Agents
            experts={experts}
            containerClassName="md:w-[84px] mr-[23px]"
            textClassName="w-[156px]"
            containerExpert="md:!mt-0 md:!mb-[40px] "
            showSecondAgent
          />
        </div>
      </div>
    </section>
  );
};

export default RequestInfo;
