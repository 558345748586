import {
  ListingFloorplan,
  ListingFloorplanKeys,
} from '../../apolloClient/types/Units';
import { sqftFormatterM2 } from './utils';

export function getBathsCount({
  bathsFull,
  bathsHalf,
}: {
  bathsFull: number;
  bathsHalf: number;
}) {
  return bathsFull + (bathsHalf ? 0.5 : 0);
}

export function getRowsForUnitFloorplanDetails(floorplan: ListingFloorplan) {
  if (!floorplan) return;
  return Object.keys(floorplan).reduce((acc, _) => {
    const rowsKeys: { [key in ListingFloorplanKeys]?: string } = {
      model: 'Model',
      floors: 'Floors',
      bedBath: 'Bed/Bath',
      apartmentNumbers: 'Apt No',
      area: 'Interior Ft2 (m2)',
    };
    const key = _ as ListingFloorplanKeys;
    const value = floorplan[key];
    const rowKey = rowsKeys[key];
    if (rowKey) {
      if (key === 'model') {
        acc[0] = { key: rowKey, value: value };
        return acc;
      }
      if (key === 'apartmentNumbers') {
        acc[1] = { key: rowKey, value: value };
        return acc;
      }
      if (key === 'bedBath') {
        acc[2] = { key: rowKey, value: value };
        return acc;
      }
      if (key === 'floors') {
        acc[3] = {
          key: rowKey,
          value: !!value.length
            ? value?.[0]?.from +
              (value?.[0]?.from !== value?.[0]?.to && '-' + value?.[0]?.to)
            : 'All',
        };
        return acc;
      }
      if (key === 'area') {
        const f = value;
        const m = f * 0.09290304;
        acc[4] = { key: rowKey, value: `${f} (${sqftFormatterM2(m)})` };
        return acc;
      }
    }
    return acc;
  }, [] as { key: string; value: string }[]);
}
