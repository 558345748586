import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { Dialog, styled } from '@mui/material';

import { UnitFromQuickViewType } from 'types';
import iconClose from '../../public/images/close.svg';
import {
  defaultEmail,
  defaultPhoneNumberWithCode,
} from '../../src/constants/constants';
import phone from '../../public/images/phone.svg';
import email from '../../public/images/email.svg';
import whatsapp from '../../public/images/whatsapp.svg';

import FlagLabel from '../FlagLabel';
import ContactForm from './ContactForm';
import { Agent } from 'apolloClient/types/Agents';

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-container': {
    minWidth: '100%',

    '.MuiPaper-root': {
      maxHeight: 'none',
      margin: '0',
      borderRadius: '0',
    },
    '@media (max-width:767px)': {
      '.MuiPaper-root': {
        width: '100%',
      },
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  isRequestInfoBuilding?: boolean;
  isRequestInfoBuildingOwner?: boolean;
  isRequestInfoUnitOwner?: boolean;
  unitFromQuickView?: UnitFromQuickViewType;
  experts?: Agent[];
  unitPrice?: number;
  phoneEnding?: string;
  areaCode?: string;
  dialogPhoneEnding?: string;
  dialogAreaCode?: string;
}

const ContactUsDialog: React.FC<Props> = ({
  onClose,
  open,
  isRequestInfoBuilding,
  isRequestInfoBuildingOwner,
  isRequestInfoUnitOwner,
  unitFromQuickView,
  experts,
  unitPrice,
  phoneEnding,
  areaCode,
  dialogPhoneEnding,
  dialogAreaCode,
}) => {
  const eventClickPhone = () => {
    window.gtag('event', 'click_phone', {
      event_category: 'click_phone',
      event_label: 'Success',
      value: 1,
    });
  };
  return (
    <StyledDialog open={open} onClose={onClose}>
      <div className="pb-10 md:py-[35px] bg-white border border-gray w-full h-screen md:h-auto md:w-[504px]">
        <div className="flex flex-row items-start justify-between pl-[30px] md:pl-10 pr-6 mb-6 pt-10 md:pt-0">
          <div className="relative flex flex-col justify-center w-max">
            <FlagLabel
              name="Contact us"
              textSize="text-18-23-0.3 md:text-20-28-0.3 xl:text-24-31-0.8 bg-beige pt-[10px] pb-[10px]"
            />
          </div>
          <button className="outline-none" onClick={() => onClose()}>
            <Image
              id="Contact_Us_Close_Header"
              src={iconClose}
              alt="close popup"
              width={15}
              height={15}
            />
          </button>
        </div>
        <a
          className="flex px-[30px] md:px-10 my-2"
          onClick={eventClickPhone}
          href={`tel:${defaultPhoneNumberWithCode}`}
        >
          <Image src={phone} alt="phone" width={24} height={24} />
          <div className="ml-4">
            <span className="font-LucidaGrandeBold">(</span>
            <span>
              {unitPrice && unitPrice > 1000000 ? areaCode : dialogAreaCode}
            </span>
            <span className="font-LucidaGrandeBold">)</span>{' '}
            <span>
              {unitPrice && unitPrice > 1000000
                ? phoneEnding
                : dialogPhoneEnding}
            </span>
          </div>
        </a>
        <a
          className="flex px-[30px] md:px-10 my-2"
          href="https://api.whatsapp.com/send?phone=17863217233"
          target="_blanc"
        >
          <Image src={whatsapp} alt="whatsapp" width={24} height={24} />
          <div className="flex items-center gap-1 ml-4">
            <div>(786) 321-7233</div>
            <div className="text-12-18-0.3">(text only)</div>
          </div>
        </a>
        <a
          className="flex px-[30px] md:px-10 my-2"
          href={`mailto:${defaultEmail}`}
        >
          <Image src={email} alt="email" width={28} height={18} />
          <div className="ml-4">{defaultEmail}</div>
        </a>
        <div className="px-[30px] md:px-10">
          <ContactForm
            isRequestInfoBuilding={isRequestInfoBuilding}
            isRequestInfoBuildingOwner={isRequestInfoBuildingOwner}
            isRequestInfoUnitOwner={isRequestInfoUnitOwner}
            isHeaderForm={true}
            unitFromQuickView={unitFromQuickView}
            experts={experts}
          />
        </div>
      </div>
    </StyledDialog>
  );
};

export default ContactUsDialog;
