import { Autocomplete } from '@react-google-maps/api';
import React, { useState, useEffect, useRef } from 'react';

type Props = {
  onChange: (res: google.maps.places.PlaceResult) => void;
  children: React.ReactChild;
  mapInstance?: google.maps.Map;
};

const GoogleMapsSearch: React.FC<Props> = ({
  onChange,
  children,
  mapInstance,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  const ref = useRef<null | HTMLDivElement>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  useEffect(() => {
    if (mapInstance && autocomplete) {
      autocomplete.bindTo('bounds', mapInstance);
    }
    return () => autocomplete?.unbindAll();
  }, [mapInstance, autocomplete]);

  const onPlaceChanged = () => {
    if (autocomplete && autocomplete.getPlace) {
      onChange(autocomplete.getPlace());
    }
  };

  function fixPopupPosition() {
    const popups: NodeListOf<HTMLDivElement> =
      document.querySelectorAll('.pac-container'); //pac-container is class of the google popup
    popups.forEach((popup) => {
      ref.current?.appendChild(popup);
    });
  }

  return (
    <Autocomplete
      options={{ bounds: mapInstance?.getBounds() }}
      restrictions={{ country: 'us' }}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
    >
      <div
        onFocus={fixPopupPosition}
        ref={ref}
        className="commute-autocomplete-wrapper relative"
      >
        {children}
      </div>
    </Autocomplete>
  );
};

export default GoogleMapsSearch;
