import React, { ReactNode, useContext, useState } from 'react';
import Image from 'next/legacy/image';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ribbonBeige from 'public/images/ribbonBeige.svg';
import ribbonGolden from 'public/images/ribbonGolden.svg';
import ribbonBiancone from 'public/images/ribbonBiancone.svg';

import unitLeftArrow from 'public/images/unit_left_arrow.svg';
import unitRightArrow from 'public/images/unit_right_arrow.svg';
import videoTour from 'public/images/video-tour.svg';
import floorplan from 'public/images/floorplan.svg';
import location from 'public/images/location.svg';

import { Position } from 'types';
import { translateUnitStatus } from 'lib/translateUnitStatus';
import { BuildingPageContext } from 'pages/[urlParam]/[urlBuildParam]';
import { UnitPageContext } from 'pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { getBuildingFloorplans } from 'src/utils/buildingUtils';
import { Building, StrapiMediaInfo } from 'apolloClient/types';
import { Listing } from 'apolloClient/types/Units';

import PopUpUnitBuilding from '../PopUpUnitBuilding';
import UnitBuildingLocation from '../UnitBuildingLocation';
import BlurredBgImage from './BlurredBgImage';
import PopUpViewLargePhoto from './PopUpViewLargePhoto';

import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import {
  getMediumImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';

type BuildingCarouselProps = {
  images?: StrapiMediaInfo[];
  position?: Position;
  renderHeader: JSX.Element;
  tours?: string[] | string;
  nameButton: string;
};

const getTourLabel = (tourUrl?: string | string[] | null) => {
  if (!tourUrl) return null;
  if (Array.isArray(tourUrl)) return 'video';
  if (
    tourUrl?.includes('youtube') ||
    tourUrl?.includes('youtu.be') ||
    tourUrl?.includes('vimeo')
  )
    return 'video tour';
  if (tourUrl?.includes('matterport')) return '3D Walkthrough';

  return 'virtual tour';
};

const initialPhotosModalState = {
  isOpen: false,
  initialShowedIndex: 0,
};

type SliderArrowProps = Partial<
  JSX.Element & {
    currentSlide: number;
    slideCount: number;
  }
>;

const ArrowLeft = ({
  currentSlide,
  slideCount,
  ...props
}: SliderArrowProps) => (
  <button
    aria-label="Previous"
    {...props}
    className="slick-prev slick-prev-image !top-[95px] md:!top-[162px] lg:!top-[209px] !left-[4px] md:!left-[22px] lg:!left-[22px] !w-[28px] md:!w-[36px]"
  >
    <Image src={unitLeftArrow} alt="previous item" width="31" height="48" />
  </button>
);

const ArrowRight = ({
  currentSlide,
  slideCount,
  ...props
}: SliderArrowProps) => (
  <button
    aria-label="Next"
    {...props}
    className="slick-next slick-next-image !top-[95px] md:!top-[162px] lg:!top-[209px] !right-[6px] md:!right-[8px] lg:!right-[7px] !w-[28px] md:!w-[36px]"
  >
    <Image src={unitRightArrow} alt="next item" width="31" height="48" />
  </button>
);

const BuildingCarousel: React.FC<BuildingCarouselProps> = ({
  images,
  position,
  renderHeader,
  tours,
  nameButton,
}) => {
  const [photosModalState, setPhotosModalState] = useState(
    initialPhotosModalState
  );
  const [isBuildingDialogOpen, setBuildingDialogOpen] = useState(false);
  const [selectedDialogTab, setSelectedDialogTab] = useState<number>(0);

  const { building: buildingData } = useContext(BuildingPageContext) || {};
  const { building, listing } = useContext(UnitPageContext) || {};
  const {
    statusCode,
    propertyTypeCode,
    closeDate,
    yearBuilt,
    isShortSale,
    isReo,
  } = listing || {};

  const isPreconstruction = buildingData?.isPreconstruction;
  const floorplansFromBuilding = getBuildingFloorplans(
    buildingData?.floorplans
  );
  const floorplanFromListing = listing?.floorplan;
  const floorPlanExists =
    Boolean(floorplansFromBuilding && floorplansFromBuilding.length > 0) ||
    Boolean(floorplanFromListing);
  const imagesExists = Boolean(images && images.length > 0);
  const imagesCarousel = Boolean(images && images.length > 1);
  const isTourExists = Boolean((tours?.length || 0) > 0);
  const isPropertypanoramaTour = tours?.includes('propertypanorama');

  const unitLabels =
    propertyTypeCode &&
    statusCode &&
    translateUnitStatus(propertyTypeCode, statusCode, closeDate);

  const isUnitPreconstruction =
    building?.isPreconstruction || Number(yearBuilt) > new Date().getFullYear();

  const extraUnitLabels = (() => {
    if (!building?.isPreconstruction && isReo) {
      return `Foreclosure`;
    } else if (!building?.isPreconstruction && isShortSale) {
      return `Short sale`;
    } else if (isUnitPreconstruction) {
      return `Pre-construction`;
    } else {
      return null;
    }
  })();

  const underContractLabels = (() => {
    if (statusCode === 'PS') {
      return `Under Contract`;
    } else {
      return null;
    }
  })();

  const isUnitLabelLongName = statusCode === 'R' || statusCode === 'CS';

  function closePhotosModal() {
    setPhotosModalState(initialPhotosModalState);
  }

  const openBuildingDialog = (tab: number): void => {
    closePhotosModal();
    setBuildingDialogOpen(true);
    setSelectedDialogTab(tab);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const isMobile = useMediaQuery('(max-width: 767px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
  const isDesctop = useMediaQuery('(min-width: 1024px)');

  let sliderContainerHeight: number;
  if (isMobile) {
    sliderContainerHeight = 220;
  } else if (isTablet) {
    sliderContainerHeight = 352;
  } else if (isDesctop) {
    sliderContainerHeight = 444;
  }

  return (
    <>
      <section className="px-0 mt-[21px] md:mt-5 lg:mt-[10px] md:-ml-4 text-14-26-0.3 xxxl:container lg:pt-1 lg:pb-2">
        <div className="relative h-[220px] md:h-88 lg:h-[444px]">
          {isPreconstruction && (
            <div
              className={classnames(
                'absolute uppercase left-0 md:left-3 xl:left-4 z-40',
                {
                  'top-8': imagesExists,
                  'top-13': !imagesExists,
                }
              )}
            >
              <div className={`relative h-8 md:h-10 w-48 md:w-64 xl:w-72`}>
                <Image
                  layout="fill"
                  className="object-cover object-left transform -rotate-180"
                  src={ribbonGolden}
                  alt="ribbonGolden"
                  unoptimized={true}
                />
                <div className="absolute text-12-18-0.3 top-[8px] left-4 font-sohneBreitHalbfett md:text-16-26-0.3">
                  Pre-construction
                </div>
              </div>
            </div>
          )}
          <div
            className={`absolute  uppercase left-0 md:left-3 xl:left-4 z-40 ${classnames(
              {
                hidden: !unitLabels,
                'top-2 sm:top-[30px]': underContractLabels,
                'top-[14px] md:top-[30px]': !underContractLabels,
              }
            )} `}
          >
            <div
              className={`relative h-8 md:h-10 ${
                statusCode === 'R'
                  ? 'w-48 md:w-60'
                  : statusCode === 'CS'
                  ? 'w-44 md:w-60'
                  : 'w-40 md:w-44'
              }`}
            >
              <Image
                layout="fill"
                className="object-cover object-left transform -rotate-180"
                src={ribbonGolden}
                alt="ribbonGolden"
                unoptimized={true}
              />
              <div className="absolute text-12-18-0.3 top-[8px] left-4 font-sohneBreitHalbfett md:text-16-26-0.3">
                {unitLabels}
              </div>
            </div>
          </div>
          <div
            className={`absolute font-sohneBreitHalbfett uppercase left-48 md:left-56 z-40
              ${classnames({
                hidden: extraUnitLabels === null,
                'ml-2 sm:ml-8 md:ml-10 xl:ml-12':
                  isUnitPreconstruction && isUnitLabelLongName,
                'ml-8 md:ml-10 xl:ml-12':
                  isUnitLabelLongName && !isUnitPreconstruction,
                'top-2 sm:top-[30px]': underContractLabels,
                'top-[30px]': !underContractLabels && !isMobile,
                'top-[14px]': isMobile
              })}`}
          >
            <div
              className={`relative h-8 md:h-10 ${
                isUnitPreconstruction ? 'w-48 md:w-64' : 'w-40 md:w-48'
              }`}
            >
              <Image
                layout="fill"
                className="object-cover object-right"
                src={ribbonBiancone}
                alt="ribbonBiancone"
                unoptimized={true}
              />
              <div className="absolute text-12-18-0.3 top-[7px] left-4  md:text-16-26-0.3">
                {extraUnitLabels}
              </div>
            </div>
          </div>
          <div
            className={`absolute font-sohneBreitHalbfett uppercase top-[9px] sm:top-[30px] z-40 left-[140px]
              ${classnames({
                hidden: underContractLabels === null,
                'sm:ml-10 md:ml-24':
                  isUnitLabelLongName && !isUnitPreconstruction,
                'sm:ml-2 md:ml-28':
                  isUnitPreconstruction && !isUnitLabelLongName,
                'sm:left-96 md:ml-16': extraUnitLabels,
                'sm:ml-9 md:ml-36':
                  isUnitPreconstruction && isUnitLabelLongName,
                'sm:left-48 md:left-56': !extraUnitLabels,
              })}`}
          >
            <div
              className={`relative h-[31px] md:h-10 font-sohneBreitHalbfett w-[177px] md:w-[230px]`}
            >
              <Image
                layout="fill"
                className="object-cover object-right"
                src={ribbonBeige}
                alt="ribbonBeige"
                unoptimized={true}
              />
              <div className="absolute text-12-18-0.3 top-[7px] left-4  md:text-16-26-0.3">
                {underContractLabels}
              </div>
            </div>
          </div>
          {imagesExists ? (
            <div className="slider-container">
              <Slider {...settings}>
                {images?.reduce<Array<ReactNode>>((acc, image, index) => {
                  if (getMediumImageUrlFromImage(image)) {
                    acc.push(
                      <div key={index}>
                        <div
                          className="relative flex lg:ml-4 h-[220px] md:h-[352px] lg:h-[444px]"
                          onClick={() => {
                            setPhotosModalState({
                              isOpen: true,
                              initialShowedIndex: index,
                            });
                          }}
                        >
                          <BlurredBgImage
                            src={getMediumImageUrlFromImage(image)}
                            alt={`${
                              building?.name || buildingData?.name
                            } image #${index + 1}`}
                            blurDataURL={getThumbnailImageUrlFromImage(image)}
                          />
                        </div>
                      </div>
                    );
                  }

                  return acc;
                }, [])}
              </Slider>
            </div>
          ) : (
            <div className="z-50 h-[220px] md:h-88 lg:h-112">
              <UnitBuildingLocation position={position} />
            </div>
          )}
          <div className="absolute w-full -translate-x-1/2 md:left-auto md:w-96 transform-none bottom-[-43px] md:bottom-4 lg:bottom-auto md:right-20 lg:top-8">
            <div className="container z-40 flex justify-center w-auto px-6 pt-0 space-x-2 outline-none md:pl-10 md:space-x-0">
              {isTourExists && !isPropertypanoramaTour && (
                <button
                  onClick={() => openBuildingDialog(3)}
                  className={`${
                    (floorPlanExists || position) && 'md:border-r-0'
                  } z-30 flex items-center justify-center min-w-[120px] w-auto md:min-w-[150px] h-[28px] xl:h-[34px] px-2 py-1 uppercase bg-white border-2 md:w-40 hover-animation group border-gold-dark md:px-3 md:space-x-2 xl:pb-[7px] xl:pt-[5px]`}
                >
                  <span className="hidden hover-animation group-hover:opacity-70 md:inline-block pt-[5px]">
                    <Image src={videoTour} alt="videoTour" />
                  </span>
                  <span className="hover-animation whitespace-nowrap group-hover:opacity-70 text-12-21-0.3 md:text-14-21-0.3">
                    {getTourLabel(tours)}
                  </span>
                </button>
              )}
              {floorPlanExists && (
                <button
                  onClick={() => openBuildingDialog(1)}
                  className={classnames(
                    'flex items-center justify-center min-w-[111px] md:min-w-[153px] px-2 py-1 h-[28px] xl:h-[34px] xl:pb-[7px] xl:pt-[5px] uppercase bg-white border-2 md:w-36 hover-animation group border-gold-dark md:px-3 md:space-x-2',
                    {
                      'md:border-r-0': position,
                    }
                  )}
                >
                  <div className="hidden pt-[5px] hover-animation group-hover:opacity-70 md:inline-block">
                    <Image src={floorplan} alt="floorplans" />
                  </div>
                  <span className="hover-animation group-hover:opacity-70 text-12-21-0.3 md:text-14-14-0.3">
                    {nameButton}
                  </span>
                </button>
              )}
              {position && (
                <button
                  onClick={() => openBuildingDialog(2)}
                  className="flex items-center justify-center min-w-[91px] md:min-w-[125px] lg:min-w-[135px] px-2 py-1 h-[28px] xl:h-[34px] xl:pb-[7px] xl:pt-[5px] uppercase bg-white border-2 md:w-36 hover-animation group border-gold-dark md:px-0 md:space-x-2"
                >
                  <div className="hidden pt-[5px] h-[28px] w-[18px] hover-animation group-hover:opacity-70 md:inline-block">
                    <Image
                      src={location}
                      alt="location"
                      height={20}
                      width={20}
                    />
                  </div>
                  <div className="hover-animation group-hover:opacity-70 text-12-21-0.3 md:text-14-14-0.3 lg:!ml-[6px]">
                    Directions
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="md:container mt-[58px] md:mt-20 text-m md:hidden">
        <div className="w-fill h-[1px] bg-beige" />
      </section>
      <PopUpViewLargePhoto
        open={photosModalState.isOpen}
        onClose={closePhotosModal}
        images={images}
        actionHandle={() => openBuildingDialog(0)}
        renderHeader={renderHeader}
        initialActiveIndex={photosModalState.initialShowedIndex}
      />
      <PopUpUnitBuilding
        entityType={listing ? 'listing' : 'building'}
        entity={(buildingData as Building) || (listing as Listing)}
        toursButtonLabel={getTourLabel(tours)}
        floorplanButtonLabel={listing ? 'Floor plan' : 'Floor plans'}
        open={isBuildingDialogOpen}
        onClose={() => setBuildingDialogOpen(false)}
        openPopupLarge={setPhotosModalState}
        activeTab={selectedDialogTab}
        images={images}
        tours={tours}
        position={position}
        tabsRenderExclude={{
          photos: !imagesExists,
          floorPlan: !floorPlanExists,
          tour: !isTourExists,
          directions: !position,
        }}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default BuildingCarousel;
