import React, { useContext, useMemo } from 'react';
import Image from 'next/legacy/image';

import renderAmenitiesRows from 'components/Building/BuildingAmenities/RenderAmenitiesRows';
import {
  getAmenitiesData,
  PARKING,
  WATERFRONT,
} from 'components/Building/BuildingAmenities/amenitiesLabels';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import { normilizeUnitDescription } from 'src/utils/utils';

import stories from '../../public/images/stories.svg';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { ConvenienceType } from '../../apolloClient/types/Amenities';

import { KeyValueList } from '../KeyValueList';
import FlagLabel from '../FlagLabel';
import ErrorBoundary from 'components/ErrorBoundary';

const UnitFeatures: React.FC = () => {
  const { building, listing } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;

  const {
    amenity,
    windowsTreatment,
    frontExposure,
    floorDescription,
    exteriorFeatures,
    unitFloorLocation,
    parkingDescription,
    parkingRestrictions,
  } = listing;
  const buildingAmenities = building?.amenity;

  function getFurnishedLabel(convenience: ConvenienceType) {
    const { furnished, unfurnished, partiallyFurnished } = convenience;

    if (furnished) return 'Yes';
    if (partiallyFurnished) return 'Partially Furnished';
    if (unfurnished) return 'No';

    return NO_DATA_PLACEHOLDER;
  }

  function getAmenityLabel(amenityGroup: { [index: string]: boolean }) {
    const amenityLabel = Object.keys(amenityGroup)
      .reduce((acc, key) => {
        const value = amenityGroup[key];
        if (key === 'canalWidth121Plus' && value) {
          acc.push('Canal Width 121+');
        }
        if (key === 'canalWidth1To80' && value) {
          acc.push('Canal Width 1-80');
        }
        if (key === 'canalWidth81To120' && value) {
          acc.push('Canal Width 81-120');
        }
        if (
          typeof value === 'boolean' &&
          key !== 'any' &&
          value &&
          key !== 'canalWidth121Plus' &&
          key !== 'canalWidth1To80' &&
          key !== 'canalWidth81To120'
        ) {
          acc.push(key.charAt(0).toUpperCase() + key.slice(1));
        }
        return acc;
      }, [] as string[])
      .join(', ');
    return amenityLabel.replace(/([A-Z])/g, ' $1');
  }
  const unitDetails = [
    ...(getFurnishedLabel(amenity.convenience)
      ? [
          {
            key: 'Furnished',
            value: getFurnishedLabel(amenity.convenience),
          },
        ]
      : []),
    ...(getAmenityLabel(amenity.view)
      ? [
          {
            key: 'View',
            value: getAmenityLabel(amenity.view),
          },
        ]
      : []),
    ...(getAmenityLabel(amenity.waterfront)
      ? [
          {
            key: 'Waterfront',
            value: getAmenityLabel(amenity.waterfront),
          },
        ]
      : [
          {
            key: 'Waterfront',
            value: 'No',
          },
        ]),
    {
      key: 'Water Access',
      value: getAmenityLabel(amenity.waterAccess) || NO_DATA_PLACEHOLDER,
    },
    ...(windowsTreatment
      ? [
          {
            key: 'Windows/Treatment',
            value: normilizeUnitDescription(windowsTreatment),
          },
        ]
      : []),
    {
      key: 'Front Exposure',
      value: frontExposure || NO_DATA_PLACEHOLDER,
    },
    ...(amenity.convenience.washerDryer
      ? [
          {
            key: 'Washer/Dryer in Unit',
            value: 'Yes',
          },
        ]
      : []),
    ...(floorDescription
      ? [
          {
            key: 'Floor',
            value: normilizeUnitDescription(floorDescription),
          },
        ]
      : []),
    ...(exteriorFeatures
      ? [
          {
            key: 'Exterior Features',
            value: normilizeUnitDescription(exteriorFeatures),
          },
        ]
      : []),
    ...(unitFloorLocation
      ? [
          {
            key: 'Unit Floor #',
            value: unitFloorLocation,
          },
        ]
      : []),
  ];

  const BuildingAmenities = useMemo(() => {
    const data = getAmenitiesData(buildingAmenities)
      ?.filter((_) => Boolean(_.items.length) && _.name !== WATERFRONT)
      .map((_) => {
        let items = _.items;
        return { ..._, items: items };
      });
    return data ? renderAmenitiesRows(data) : null;
  }, []);

  return (
    <ErrorBoundary>
      <section className="container">
        <div className="flex flex-col md:border-b md:border-t lg:flex-row text-m border-beige-dark">
          <div className="lg:w-1/2 mb-[40px]">
            <div className="flex mt-[7.5px] md:mt-[34px] lg:mt-[47px]">
              <div className="relative flex flex-col justify-center mb-6 w-max md:mb-[50px]">
                <FlagLabel name="Unit features" />
              </div>
            </div>
            <div>
              <KeyValueList fixedRatio rows={unitDetails} />
            </div>
          </div>
          <div className="px-0 text-m lg:hidden">
            <div className="w-fill h-0.5 bg-beige" />
          </div>
          <div className="border-l-0 lg:w-1/2 lg:ml-[50px] lg:pl-8 border-beige-dark lg:border-l">
            <div className="flex pt-[22.5px] md:pt-[34px] lg:pt-[47px]">
              <div className="relative flex flex-col justify-center mb-[33px] md:mb-[50px] w-max">
                <FlagLabel name="Building Amenities" />
              </div>
            </div>
            <div className="flex flex-col mb-[13px] md:mb-10">
              <div className="text-14-26-0.3">
                {BuildingAmenities}
                <div className="flex">
                  <div className="flex w-1/2 h-12 md:w-1/3">
                    <div className="w-8 mt-1.5">
                      <Image src={stories} alt="" />
                    </div>
                    <div className="pb-1 mt-1.5 ml-4 font-sohneKraftig text-14-26-0.3">
                      # of Stories
                    </div>
                  </div>
                  <div className="flex w-1/2 ml-6 border-l md:w-2/3 border-beige-dark ">
                    <div className="pb-1 mt-1.5 mb-2 ml-8">
                      {building?.floorsCount || 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default UnitFeatures;
