import classnames from 'classnames';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import Image from 'next/legacy/image';
import arrowLeft from 'public/images/arrow-left.svg';
export type TableColumn = {
  key: string;
  heading?: string;
  headingMobile?: string;
  widthRatio?: number;
  cellRender?: (
    dataRecord: any,
    columnKey: string
  ) => ReactElement | string | number;
};

/**
 * data records must have distintive id
 */
export type HasId = {
  id?: number | string;
  [k: string]: any;
};

const TableFloorplans: FunctionComponent<{
  columns: TableColumn[];
  data: HasId[];
  openFloorplanDialog: (rowIndex: number) => void;
}> = ({ columns, data, openFloorplanDialog }) => {
  function cellValue(value: any) {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    return value;
  }
  const [allFloorplans, setAllFloorplans] = useState(false);
  const floorplans = allFloorplans ? data : data.slice(0, 8);

  function renderTable() {
    return columns.map((column, index) => (
      <div
        key={index}
        className={`pb-2`}
        style={{ flexGrow: column.widthRatio || 1, flexBasis: 0 }}
      >
        <div className="pr-2 mb-2 text-16-26-0.3 md:mb-8 font-sohneKraftig whitespace-nowrap">
          {column.headingMobile
            ? column.headingMobile
            : column.heading || column.key}
        </div>
        {floorplans.map((row, i) => (
          <React.Fragment key={i}>
            <div>
              <div
                className={classnames({
                  'border-gold-dark border-dashed border-t-[1px]': i > 0,
                })}
              />
              <div className="pr-6 my-2 text-16-26-0.3 cursor-pointer bg-yellow">
                <div onClick={() => openFloorplanDialog(i)}>
                  {column.cellRender
                    ? column.cellRender(row, column.key)
                    : cellValue(row[column.key])}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    ));
  }

  return (
    <>
      <div className="flex flex-row no-scroll whitespace-nowrap">
        {renderTable()}
      </div>
      {data.length > 8 && (
        <button
          onClick={() => setAllFloorplans(!allFloorplans)}
          className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
        >
          <div className="w-5 h-4">
            <Image
              className={classnames({
                'transform -rotate-90': allFloorplans,
              })}
              src={arrowLeft}
              alt="floorplans"
              width={15}
            />
          </div>
          <span className="whitespace-nowrap">
            {`${allFloorplans ? 'Hide' : 'Show more'}`}
          </span>
        </button>
      )}
    </>
  );
};

export { TableFloorplans };
