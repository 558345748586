import React, { useContext } from 'react';
import Image from 'next/legacy/image';

import arrowLeft from '../../../public/images/arrow-left.svg';

import ButtonWithScroll from '../../ButtonWithScroll';
import FlagLabel from '../../FlagLabel';
import Expert from '../../Unit/Expert';

import BuildingDescription from '../BuildingDescription';
import { BuildingPageContext } from 'pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from 'apolloClient/types/Building';
import Agents from 'components/Agents';
import { Agent } from 'apolloClient/types/Agents';
import PopUpContactUs from 'components/ContactUs/PopUpContactUs';

interface InfoItemProps {
  header: string;
  url: string;
}
const InfoItem: React.FC<InfoItemProps> = ({ header, url }) => {
  return (
    <a href={url} className="flex flex-col-reverse md:ml-8">
      <div className="flex items-center mt-4 uppercase border-b cursor-pointer border-gold-dark lg:mt-2 lg:mb-0 w-max">
        <div className="w-5 h-4">
          <Image src={arrowLeft} alt={header} width={15} />
        </div>
        <span className="whitespace-nowrap">{header}</span>
      </div>
    </a>
  );
};

interface PreconstructionAboutSectionProps {
  href: string;
  experts: Agent[];
}
const PreconstructionAboutSection: React.FC<PreconstructionAboutSectionProps> =
  ({ href, experts }) => {
    const {
      building: { moreInfo },
    } = useContext(BuildingPageContext) as BuildingPageContextInterface;

    return (
      <section className="container pt-2 pb-6 mt-2 lg:pt-2 md:pb-0 text-m">
        <div className="flex flex-col justify-between border-b md:border-t sm:flex-row border-beige-dark">
          <BuildingDescription />
          <div className="block md:hidden w-fill mt-12 h-0.5 bg-beige" />
          <div className="flex flex-col pt-[23px] md:pt-[33px] md:w-1/2 lg:w-5/12 md:border-l md:border-beige-dark">
            <div className="relative flex flex-col justify-center md:ml-8 md:mb-4 w-max">
              <FlagLabel name="Get more info" />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col-reverse md:ml-8">
                <div className="flex items-center mt-4 text-black uppercase border-b cursor-pointer border-gold-dark lg:mt-2 lg:mb-0 w-max">
                  <div className="w-5 h-4">
                    <Image src={arrowLeft} alt="Price list" width={15} />
                  </div>
                  <PopUpContactUs
                    contactUsNotButtonClassName="text-black"
                    contactUsNotButton={'PRICE LIST'}
                  />
                </div>
              </div>
              {moreInfo &&
                moreInfo.map(({ header, url }) => (
                  <InfoItem key={header} header={header} url={url} />
                ))}
            </div>
            <div className="w-fill my-6 md:my-8 h-0.5 bg-beige" />
            <div className="relative flex flex-col justify-center mb-10 md:ml-8 w-max">
              <FlagLabel name="The team" />
            </div>
            <Agents experts={experts} containerExpert="md:ml-8" />
            <div className="mb-8 md:ml-8 xl:w-80">
              <ButtonWithScroll name="Request info" href={href} />
            </div>
          </div>
        </div>
      </section>
    );
  };

export default PreconstructionAboutSection;
