import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';

import { NeighborhoodsResponse } from 'apolloClient/types/Neighborhood';
import MobileNeighborhoodMenu from './MobileNeighborhoodMenu';
import DesktopNeighborhoodMenu from './DesktopNeighborhoodMenu';

const Neighborhoods: React.FC<{
  neighborhoods?: NeighborhoodsResponse;
  fetchMoreNhds: (search: string) => void;
}> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xl')
  );

  return isMobile ? (
    <MobileNeighborhoodMenu {...props} />
  ) : (
    <DesktopNeighborhoodMenu {...props} />
  );
};

export default Neighborhoods;
