import Image from 'next/legacy/image';
import React from 'react';

import whiteHeart from '../../public/images/white_heart.svg';
import yellowHeart from '../../public/images/yellow_heart.svg';

import whiteHeartFilled from '../../public/images/white_heart_filled.svg';
import yellowHeartFilled from '../../public/images/yelow_heart_filled.svg';

const Favorite: React.FC<{ hover?: boolean; white?: boolean; fill?: boolean }> =
  ({ hover, white, fill }) => {
    let image = '';

    if (white) {
      if (fill) {
        if (hover) {
          image = yellowHeartFilled;
        } else {
          image = whiteHeartFilled;
        }
      } else {
        if (hover) {
          image = yellowHeart;
        } else {
          image = whiteHeart;
        }
      }
    } else {
      if (fill) {
        image = yellowHeartFilled;
      } else {
        image = yellowHeart;
      }
    }

    return (
      <Image
        src={image}
        className="!min-w-[18px] !min-h-[16px] !w-[18px] !h-[16px] md:w-[22px] md:h-[19px]"
        alt="add to favorites"
      />
    );
  };

export default Favorite;
