import React, { useContext } from 'react';

import PopUpContactUs from 'components/ContactUs/PopUpContactUs';

import FlagLabel from '../../components/FlagLabel';
import ButtonWithScroll from '../../components/ButtonWithScroll';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

import UnitDescription from './UnitDescription';
import ErrorBoundary from 'components/ErrorBoundary';
import Agents from 'components/Agents';
import { Agent } from 'apolloClient/types/Agents';

type AboutUnitProps = {
  experts: Agent[];
};

const AboutUnit: React.FC<AboutUnitProps> = ({ experts }) => {
  const {
    building,
    listing: { unitNumber, statusCode },
  } = useContext(UnitPageContext) as UnitPageContextInterface;

  const doYouOwnSection = (() => {
    if (
      statusCode === 'C' ||
      statusCode === 'CS' ||
      statusCode === 'R' ||
      statusCode === 'W' ||
      statusCode === 'X' ||
      statusCode === 'Q'
    ) {
      return true;
    } else return null;
  })();
  return (
    <ErrorBoundary>
      <section className="container pt-[4px] pb-[11px] md:pb-6 mt-2 md:pt-2 lg:pb-0 text-m">
        <div className="flex flex-col justify-between md:border-b md:border-t md:flex-row border-beige-dark">
          <UnitDescription />
          <div className="block md:hidden w-fill mt-12 h-0.5 bg-beige" />
          <div className="flex flex-col pt-[22.5px] md:pt-[39px] lg:pt-[56px] md:w-1/2 lg:w-4/12 md:border-l md:border-beige-dark">
            {doYouOwnSection ? (
              <div className="sm:pl-8">
                <div className="mb-8 text-24-32-0.3 uppercase border-b-8 lg:text-30-36-1 border-gold font-sohneBreitHalbfett">
                  Do you own in {building.name} #{unitNumber}?
                </div>
                <div className="mb-6">
                  Our condo sales experts and premier marketing team can help
                  you sell your place for top dollar.
                </div>
                <div className="flex mb-14 xl:w-80">
                  <PopUpContactUs
                    experts={experts}
                    isRequestInfoUnitOwner={true}
                    contactUsName={'Contact us'}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="relative flex flex-col justify-center mb-10 lg:mb-[28px] md:ml-[43px] lg:ml-[38px] w-max">
                  <FlagLabel name="The team" />
                </div>
                <div className="md:pl-[43px] lg:pl-[38px] lg:mb-[20px]">
                  <Agents
                    experts={experts}
                    containerClassName="!mt-0 !min-w-[116px] !w-[116px] !h-[116px]"
                    showSecondAgent
                  />
                </div>
                <div className="mb-4 lg:mb-[56px] md:ml-[43px] lg:ml-8 xl:w-80 md:mt-[20px] lg:mt-[0px]">
                  <ButtonWithScroll
                    classNameWidth="max-w-full"
                    name="Request info"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default AboutUnit;
