import React, { FunctionComponent, ReactNode } from 'react';

export type KeyValue<T = string> = { key: string; value: T };

function processValue(obj: KeyValue<string | ReactNode>): ReactNode {
  if (
    obj.key === 'Monthly Avg Association Fee / Sq Ft' &&
    typeof obj.value === 'string'
  ) {
    if (obj.value === 'N/A') {
      return 'N/A';
    } else {
      const stringWithoutDollar = obj.value.replace('$', '');
      const floatValue = parseFloat(stringWithoutDollar);
      return '$' + floatValue.toFixed(2);
    }
  }
  return obj.value;
}

const KeyValueList: FunctionComponent<{
  rows: KeyValue<string | ReactNode>[];
  fixedRatio?: boolean;
  colLeftClassName?: string;
  colRightClassName?: string;
  rowsClassName?: string;
}> = ({
  rows,
  fixedRatio,
  colLeftClassName = 'font-sohneKraftig py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3',
  colRightClassName = 'py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3',
  rowsClassName = 'last:border-0 text-14-26-0.3 lg:text-16-36-0.3 md:text-16-36-0.3',
}) => {
  const [grid, colLeft, colRight] = getGridClasses(!!fixedRatio);
  return (
    <div>
      {rows.map((row) => {
        return (
          <div
            className={`grid ${grid} gap-3 border-dashed-small-down ${rowsClassName} border-dashed border-gold-dark`}
            key={row.key + row.value}
          >
            <p className={`${colLeftClassName} ${colLeft}`}>{row.key}</p>
            <p className={`pl-4 md:pl-0 ${colRightClassName} ${colRight}`}>
              {processValue(row)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export { KeyValueList };

function getGridClasses(fixedRatio: boolean) {
  if (fixedRatio) {
    return ['grid-cols-5', 'col-span-2', 'col-span-3'];
  } else {
    return ['grid-cols-2', '', 'col-span-1'];
  }
}
