import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { getHrefForBuildingLink } from '../../Search/utils/listingsFiltersUtils';

interface Props {
  openContactUsPopup: () => void;
}

const CondoEstimatesDisclosurePanel: React.FC<Props> = ({
  openContactUsPopup,
}) => {
  const [isError, setIsError] = useState(false);

  const contentRef: { current: HTMLElement | null } = useRef(null);
  const { listing, building, neighborhood } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;

  useEffect(() => {
    createScriptWithVariable();
    loadEstimateScript();
    setTimeout(() => {
      !contentRef?.current?.innerHTML && setIsError(true);
    }, 4000);
  }, []);

  function createScriptWithVariable() {
    const { unitNumber } = listing;
    const { primaryAddress } = building;
    const script = document.createElement('script');

    const [address, city, zipCode] = primaryAddress.split(',');

    script.innerHTML = `
      var rprAvmWidgetOptions = {
        Token: "ACD5B56B-DC7D-4D13-8AD0-BD9E898706DD",
        Query: "${address}, Apt ${unitNumber}, ${city}, ${zipCode}",
        CoBrandCode: "btshbroswellrealty",
        ShowRprLinks:  false,
        ContainerSelector: "#rpr-widget-container"
      } 
    `;
    document.head.appendChild(script);
  }

  function loadEstimateScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.narrpr.com/widgets/avm-widget/widget.ashx/script';
    document.head.appendChild(script);
  }

  return (
    <div
      className={classnames('flex flex-col text-14-21-0.3 md:text-16-26-0.3', {
        'lg:flex-row': !isError,
        'text-center': isError,
      })}
    >
      <div
        className={classnames('mb-[47px] ov', {
          'lg:w-2/5': !isError,
        })}
      >
        {
          'Buyers and Sellers can use this third-party automated valuation as a starting point for the potential condo sale value at '
        }
        <a
          rel="nofollow"
          className="text-gold-darker"
          href={getHrefForBuildingLink({ neighborhood, building })}
        >
          {building.name}
        </a>
        {
          '. The actual value of the condo is based on many factors that may not always be available in an automated valuation, so we suggest you '
        }
        <span
          className="cursor-pointer text-gold-darker"
          onClick={openContactUsPopup}
        >
          contact us
        </span>
        {' for an opinion from one of our real estate experts.'}
      </div>
      <div
        ref={(ref) => (contentRef.current = ref)}
        id="rpr-widget-container"
        className="relative w-full lg:w-3/5 lg:mx-10"
      />
      {isError ? (
        <p>
          Sorry, the automated estimation tool did not offer a condo sale value,
          but feel free to contact us for our opinion of value.
        </p>
      ) : null}
    </div>
  );
};

export default CondoEstimatesDisclosurePanel;
