import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import React from 'react';
import { NeighborhoodStats } from 'apolloClient/types';

import { Table } from '../../components/Table';
import {
  checkNan,
  sqftFormatter,
  sqftFormatterM2,
} from '../../src/utils/utils';

const TableNeighborhood: React.FC<{ stats: NeighborhoodStats }> = ({
  stats: neighborhoodStats,
}) => (
  <section className="container mt-10 md:mt-0 lg:my-10 md:px-12 md:pt-5 md:pb-2 lg:py-2 text-m">
    <div className="flex flex-col w-full md:flex-row">
      <div className="md:w-1/2">
        <Table
          whitespace="whitespace-normal h-[38px] md:h-10 lg:h-6 text-14-19-0 md:text-12-16-0 lg:text-16-26-0.3 mb-[11px] md:mb-0 lg:mb-[11px]"
          rootClassName="grid grid-cols-2 mb-[28px] md:border-r border-dashed border-gray pr-1 ml-1 md:pr-4 md:ml-4"
          rowClasses="font-sohneHalbfett text-20-26-0.67 tracking-[-0.21px] md:text-20-26-0.67 lg:text-30-38-1 my-0 mt-[5px] xl:mt-[17px]"
          colClasses="pb-0"
          columns={[
            {
              key: 'averageListPrice',
              heading: 'AVERAGE LIST PRICE',
              headingMobile: 'AVERAGE LIST PRICE',
              widthRatio: 2,
              cellRender: (row) => (
                <div className="flex justify-center">
                  <div className="font-SohneBuch">$</div>
                  <div className="font-sohneHalbfett">
                    {checkNan(row.averageListPrice)
                      ? sqftFormatter(row.averageListPrice)
                      : NO_DATA_PLACEHOLDER}
                  </div>
                </div>
              ),
            },
            {
              key: 'averageListing',
              heading: 'AVERAGE LISTING $/SQ FT',
              headingMobile: 'AVERAGE LISTING $/SQ FT',
              widthRatio: 2,
              cellRender: (row) => (
                <div className="flex justify-center">
                  <div className="font-SohneBuch">$</div>
                  <div className="font-sohneHalbfett">
                    {checkNan(row.averageListing)
                      ? sqftFormatter(row.averageListing)
                      : NO_DATA_PLACEHOLDER}
                  </div>
                </div>
              ),
            },
          ]}
          data={[
            {
              id: 1,
              averageListPrice:
                neighborhoodStats && neighborhoodStats.averageSalePrice,
              averageListing:
                neighborhoodStats && neighborhoodStats.averageSaleSqftPrice,
            },
          ]}
        />
      </div>
      <div className="md:w-1/2">
        <Table
          whitespace="whitespace-normal h-[38px] mb-[11px] md:h-10 lg:h-6 text-14-19-0 md:mb-0 lg:mb-[11px]"
          rootClassName="grid grid-cols-2 pr-1 ml-1 md:pr-4 md:ml-4"
          rowClasses="font-sohneHalbfett text-20-26-0.67 tracking-[-0.21px] md:text-20-26-0.3 lg:text-30-38-1 my-0 mt-[5px] xl:mt-[17px]"
          columns={[
            {
              key: 'rentalListPrice',
              heading: 'AVERAGE RENT LIST PRICE',
              headingMobile: 'AVERAGE RENT LIST PRICE',
              widthRatio: 2,
              cellRender: (row) => (
                <div className="flex justify-center">
                  <div className="font-SohneBuch">$</div>
                  <div className="font-sohneHalbfett">
                    {checkNan(row.rentalListPrice)
                      ? sqftFormatter(row.rentalListPrice)
                      : NO_DATA_PLACEHOLDER}
                  </div>
                </div>
              ),
            },
            {
              key: 'averageRentalListing',
              heading: 'AVERAGE RENTAL $/SQ FT',
              headingMobile: 'AVERAGE RENTAL $/SQ FT',
              widthRatio: 2,
              cellRender: (row) => (
                <div className="flex justify-center">
                  <div className="font-SohneBuch">$</div>
                  <div className="font-sohneHalbfett">
                    {checkNan(row.averageRentalListing)
                      ? sqftFormatterM2(row.averageRentalListing)
                      : NO_DATA_PLACEHOLDER}
                  </div>
                </div>
              ),
            },
          ]}
          data={[
            {
              id: 1,
              rentalListPrice:
                neighborhoodStats && neighborhoodStats.averageRentalPrice,
              averageRentalListing:
                neighborhoodStats && neighborhoodStats.averageRentalSqftPrice,
            },
          ]}
        />
      </div>
    </div>
  </section>
);

export default TableNeighborhood;
