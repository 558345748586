import React from 'react';

import { StylesType } from '../PageBuilderContentParser';

interface EditorTypes {
  styles: StylesType;
  text: string;
}

const Editor: React.FC<EditorTypes> = ({ styles, text }) => {
  return (
    <div
      className="page-builder-text"
      style={styles}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Editor;
