import {
  Amenities,
  AmenitiesCategoriesNames,
  ConvenienceType,
  MaintenanceType,
  StyleType,
  AssociationType,
} from 'apolloClient/types/Amenities';
import amenities from 'public/images/amenities.svg';
import boating from 'public/images/boating.svg';
import sport from 'public/images/sport.svg';
import pool from 'public/images/pool.svg';
import parking from 'public/images/parking.svg';
import waterfront from 'public/images/waterfront.svg';
import { AmenityToRender } from './RenderAmenitiesRows';

export const POOL_SPA = 'poolSpa';
export const BOATING = 'boating';
export const GENERAL = 'general';
export const WATERFRONT = 'waterfront';
export const SPORT = 'sport';
export const PARKING = 'parking';

const amenitiesCategories: {
  name: AmenitiesCategoriesNames;
  label: string;
  icon: any;
  items: Set<string>;
}[] = [
  {
    name: POOL_SPA,
    label: 'Pool / Spa',
    icon: pool,
    items: new Set<string>(),
  },
  {
    name: BOATING,
    label: 'Boating',
    icon: boating,
    items: new Set<string>(),
  },
  {
    name: SPORT,
    label: 'Sport / Fitness',
    icon: sport,
    items: new Set<string>(),
  },
  {
    name: PARKING,
    label: 'Parking',
    icon: parking,
    items: new Set<string>(),
  },
  {
    name: GENERAL,
    label: 'General Amenities',
    icon: amenities,
    items: new Set<string>(),
  },
  {
    name: WATERFRONT,
    label: 'Waterfront',
    icon: waterfront,
    items: new Set<string>(),
  },
];

const amenitiesList: {
  [index: string]: {
    [index: string]: {
      category:
        | 'poolSpa'
        | 'boating'
        | 'general'
        | 'waterfront'
        | 'sport'
        | 'parking'
        | null;
      label: string;
    };
  };
} = {
  waterfront: {
    bay: {
      category: WATERFRONT,
      label: 'Bay Front',
    },
    beach: {
      category: WATERFRONT,
      label: 'Deeded Beach Access',
    },
    canal: {
      category: WATERFRONT,
      label: 'Canal Front',
    },
    intracoastal: {
      category: WATERFRONT,
      label: 'Intracoastal',
    },
    lagoon: {
      category: WATERFRONT,
      label: 'Lagoon',
    },
    lake: {
      category: WATERFRONT,
      label: 'Waterfront Lake',
    },
    mangrove: {
      category: WATERFRONT,
      label: 'Mangrove',
    },
    ocean: {
      category: WATERFRONT,
      label: 'Waterfront Ocean',
    },
    pond: {
      category: WATERFRONT,
      label: 'Pond',
    },
    ripRap: {
      category: WATERFRONT,
      label: 'RipRap',
    },
    river: {
      category: WATERFRONT,
      label: 'River',
    },
    other: {
      category: WATERFRONT,
      label: 'Other',
    },
    bridge: {
      category: BOATING,
      label: 'Bridge',
    },
    seawall: {
      category: BOATING,
      label: 'Seawall',
    },
  },
  waterAccess: {
    boatHoistsDavits: {
      category: BOATING,
      label: 'Boat Hoists Davits',
    },
    boatlift: {
      category: BOATING,
      label: 'Boatlift',
    },
    boatlock: {
      category: BOATING,
      label: 'Boatlock',
    },
    communityBoatDock: {
      category: BOATING,
      label: 'Community Boat Dock',
    },
    communityBoatRamp: {
      category: BOATING,
      label: 'Community Boat Ramp',
    },
    deededDock: {
      category: BOATING,
      label: 'Deeded Dock',
    },
    dockAvailable: {
      category: BOATING,
      label: 'Dock Available',
    },
    privateDock: {
      category: BOATING,
      label: 'Private Dock',
    },
    restrictedSalt: {
      category: BOATING,
      label: 'Restricted Salt Water Access',
    },
    unrestrictedSalt: {
      category: BOATING,
      label: 'Unrestricted Salt Water Access',
    },
    canalWidth121Plus: {
      category: BOATING,
      label: 'Canal Width 121+',
    },
    canalWidth1To80: {
      category: BOATING,
      label: 'Canal Width 1-80',
    },
    canalWidth81To120: {
      category: BOATING,
      label: 'Canal Width 81-120',
    },
    intersectingCanals: {
      category: BOATING,
      label: 'Intersecting Canals',
    },
    navigable: {
      category: BOATING,
      label: 'Navigable',
    },
    noFixedBridges: {
      category: BOATING,
      label: 'No Fixed Bridges',
    },
    ocean: {
      category: WATERFRONT,
      label: 'Ocean Access',
    },
    fixedBridges: {
      category: BOATING,
      label: 'Fixed Bridges',
    },
    lake: {
      category: WATERFRONT,
      label: 'Lake Access',
    },
  },
  view: {
    directOcean: {
      category: null,
      label: 'Direct Ocean',
    },
    bay: {
      category: null,
      label: 'Bay View',
    },
    canal: {
      category: null,
      label: 'Canal',
    },
    city: {
      category: null,
      label: 'City',
    },
    clubArea: {
      category: null,
      label: 'Club Area',
    },
    ocean: {
      category: null,
      label: 'Ocean',
    },
    garden: {
      category: null,
      label: 'Garden',
    },
    golf: {
      category: null,
      label: 'Golf',
    },
    intracoastal: {
      category: null,
      label: 'Intracoastal',
    },
    lagoon: {
      category: null,
      label: 'Lagoon',
    },
    lake: {
      category: null,
      label: 'Lake',
    },
    poolArea: {
      category: null,
      label: 'Pool Area',
    },
    river: {
      category: null,
      label: 'River',
    },
    skyline: {
      category: null,
      label: 'Skyline',
    },
    tennis: {
      category: null,
      label: 'Tennis',
    },
    water: {
      category: null,
      label: 'Water',
    },
  },
  sport: {
    basketball: {
      category: SPORT,
      label: 'Basketball',
    },
    billiard: {
      category: SPORT,
      label: 'Billiard',
    },
    golf: {
      category: SPORT,
      label: 'Golf',
    },
    exerciseRoom: {
      category: SPORT,
      label: 'Exercise Room',
    },
    pool: {
      category: SPORT,
      label: 'Pool',
    },
    shuffleboard: {
      category: SPORT,
      label: 'Shuffleboard',
    },
    tennis: {
      category: SPORT,
      label: 'Tennis',
    },
    bikeStorage: {
      category: GENERAL,
      label: 'Bike Storage',
    },
    bikeJogPath: {
      category: GENERAL,
      label: 'Bike Jog Path',
    },
    fishingPier: {
      category: GENERAL,
      label: 'Fishing Pier',
    },
  },
  general: {
    bar: {
      category: GENERAL,
      label: 'Bar',
    },
    bbqPicnicArea: {
      category: GENERAL,
      label: 'Bbq Picnic Area',
    },
    boatDock: {
      category: BOATING,
      label: 'Boat Dock',
    },
    businessCenter: {
      category: GENERAL,
      label: 'Business Center',
    },
    cabana: {
      category: POOL_SPA,
      label: 'Cabana',
    },
    childPlayArea: {
      category: GENERAL,
      label: 'Child Play Area',
    },
    club: {
      category: GENERAL,
      label: 'Club',
    },
    commonLaundry: {
      category: GENERAL,
      label: 'Common Laundry',
    },
    communityRoom: {
      category: GENERAL,
      label: 'Community Room',
    },
    courtesyBus: {
      category: GENERAL,
      label: 'Courtesy Bus',
    },
    elevator: {
      category: GENERAL,
      label: 'Elevator',
    },
    exteriorLighting: {
      category: GENERAL,
      label: 'Exterior Lighting',
    },
    extraStorage: {
      category: GENERAL,
      label: 'Extra Storage',
    },
    heatedPool: {
      category: POOL_SPA,
      label: 'Heated Pool',
    },
    hobbyRoom: {
      category: GENERAL,
      label: 'Hobby Room',
    },
    kitchenFacilities: {
      category: GENERAL,
      label: 'Kitchen Facilities',
    },
    library: {
      category: GENERAL,
      label: 'Library',
    },
    marina: {
      category: BOATING,
      label: 'Marina',
    },
    privateBeachPavilion: {
      category: GENERAL,
      label: 'Private Beach Pavilion',
    },
    privatePool: {
      category: POOL_SPA,
      label: 'Private Pool',
    },
    puttingGreen: {
      category: GENERAL,
      label: 'Putting Green',
    },
    sauna: {
      category: POOL_SPA,
      label: 'Sauna',
    },
    spaHotTub: {
      category: POOL_SPA,
      label: 'Spa / Hot Tub',
    },
    trashChute: {
      category: GENERAL,
      label: 'Trash Chute',
    },
    vehicleWashArea: {
      category: GENERAL,
      label: 'Vehicle Wash Area',
    },
  },
  parking: {
    oneAssigned: {
      category: PARKING,
      label: 'One Assigned',
    },
    covered: {
      category: PARKING,
      label: 'Covered',
    },
    deeded: {
      category: PARKING,
      label: 'Deeded',
    },
    guest: {
      category: PARKING,
      label: 'Guest',
    },
    underBuilding: {
      category: PARKING,
      label: 'Under Building',
    },
    valet: {
      category: PARKING,
      label: 'Valet',
    },
  },
};

export function getAmenitiesData(
  amenities: Amenities | null
): AmenityToRender[] | null {
  if (!amenities) return null;
  const categories = Object.keys(amenities);
  const result = categories.reduce((acc, category) => {
    const newAcc = [...acc];
    const categoryItems = amenities[category];
    const itemsFromAmenitiesList = amenitiesList[category];

    if (itemsFromAmenitiesList && categoryItems) {
      Object.keys(categoryItems).forEach((amenityKey) => {
        if (categoryItems[amenityKey]) {
          const { label, category } = itemsFromAmenitiesList[amenityKey] || {};
          const { items } = newAcc.find((_) => _.name === category) || {};
          category && label && items && items.add(label);
        }
      });
    }

    return newAcc;
  }, amenitiesCategories);

  return result.map((_) => {
    return { ..._, items: Array.from(_.items).join(', ') };
  });
}

const conveniencesLabels: { [index: string]: string } = {
  centralCooling: 'Central Cooling',
  centralHeat: 'Central Heat',
  electricHeat: 'Electric Heat',
  electricCooling: 'Electric Cooling',
  dishwasher: 'Dishwasher',
  washerDryer: 'Washer/Dryer',
  walkInClosets: 'Walk In Closets',
  furnished: 'Furnished',
  unfurnished: 'Unfurnished',
  partiallyFurnished: 'Partially Furnished',
};

const conveniencesEquipmentAppliancesLabels: { [index: string]: string } = {
  washerDryer: 'Washer/Dryer',
  dishwasher: 'Dishwasher',
};

const conveniencesInteriorFeaturesLabels: { [index: string]: string } = {
  walkInClosets: 'Walk In Closets',
};

const conveniencesHeatingCoolingLabels: { [index: string]: string } = {
  centralCooling: 'Central Cooling',
  centralHeat: 'Central Heat',
  electricHeat: 'Electric Heat',
  electricCooling: 'Electric Cooling',
};

const associationLabels: { [index: string]: string } = {
  condo: 'Condo',
  homeowners: 'Homeowners',
  none: 'None',
  other: 'Other',
  voluntary: 'Voluntary',
  allowed: 'Yes',
};

const maintenanceLabels: { [index: string]: string } = {
  landscapingAndLawn: 'Landscaping/Lawn Maintenance',
  recreationFacilities: 'Recreation Facilities',
  security: 'Security',
  buildingExterior: 'Building Exterior',
  commonArea: 'Common Area',
  insurance: 'Insurance',
  outside: 'Outside Maintenance',
  poolService: 'Pool Service',
  roofRepairs: 'Roof Repairs',
  trashRemoval: 'Trash Removal',
  airConditioning: 'Air Conditioning Maintenance',
  all: 'All Amenities',
  cableTv: 'Cable TV',
  electric: 'Electric',
  elevator: 'Elevator',
  fidelityBond: 'Fidelity Bond',
  golf: 'Golf',
  hotWater: 'Hot Water',
  manager: 'Manager',
  parking: 'Parking',
  laundryFacilities: 'Laundry Facilities',
  legalAndAccounting: 'Legal/Accounting',
  sewer: 'Sewer',
  water: 'Water',
  other: 'Other Maintenance Includes',
  pestControlInterior: 'Pest Control Interior',
  reserveFund: 'Reserve Fund',
  masterAntenna: 'Master Antenna',
  none: 'No Maintenance Included',
  internetAndWiFi: 'Internet/WiFi',
};

const styleLabels: { [index: string]: string } = {
  anchoredCenter: 'Anchored Center',
  commercial: 'Commercial',
  professional: 'Professional',
  restaurant: 'Restaurant',
  shoppingCenter: 'Shopping Center',
  retail: 'Retail',
  freeStanding: 'Free Standing',
  warehouse: 'Warehouse',
  apartment: 'Apartment',
  apartmentsAnnual: 'Apartments-Annual',
  manufacturedAndMobileHome: 'Manufactured/Mobile Home',
  coOp: 'Co-Op',
  condo: 'Condo',
  duplex: 'Duplex',
  efficiency: 'Efficiency',
  house: 'House',
  lease: 'Lease',
  mobile: 'Mobile',
  multiFamily: 'Multi Family',
  other: 'Other',
  residential: 'Residential',
  townhouse: 'Townhouse',
  villa: 'Villa',
  apartmentsOffSeason: 'Apartments-Off Season',
  apartmentsSeasonal: 'Apartments-Seasonal',
  coOp1To4Stories: 'Co-Op 1-4 Stories',
  dockominium: 'Dockominium',
  coOp5PlusStories: 'Co-Op 5+ Stories',
  hotel: 'Hotel',
  officeAndRetail: 'Office/Retail',
  industrialAndManufacturing: 'Industrial/Manufacturing',
  officeAndWarehouse: 'Office/Warehouse Combination',
  office: 'Office',
  storeAndWarehouse: 'Store/Warehouse Combination',
  industrial: 'Industrial',
  condo1To4Stories: 'Condo 1-4 Stories',
  condo5PlusStories: 'Condo 5+ Stories',
  condoHotel: 'Condo-Hotel',
  timeshare: 'Timeshare',
  condoAndCoOpAnnual: 'Condo/Co-Op-Annual',
  condoAndCoOpOffSeason: 'Condo/Co-Op-Off Season',
  condoAndCoOpSeasonal: 'Condo/Co-Op-Seasonal',
  condoAndTimeshare: 'Condo/Timeshare',
  fourplex: 'Fourplex',
  triplex: 'Triplex',
  duplexAndTriplexAndQuadruplexAnnual: 'Duplex/Tri/Quad-Annual',
  condoAndCoOpAndAnnual: 'Condo/Co-Op/Annual',
  condoAndCoOpAndOffSeason: 'Condo/Co-Op/Off Season',
  condoAndCoOpAndSeasonal: 'Condo/Co-Op/Seasonal',
  duplexAndTriplexAndQuadruplexOffSeason: 'Duplex/Tri/Quad-Off Season',
  duplexAndTriplexAndQuadruplexSeasonal: 'Duplex/Tri/Quad-Seasonal',
  efficiencyAndStandartAndHotelRoomAnnual:
    'Efficiency/Standard/Hotel Room-Annual',
  efficiencyAndStandartAndHotelRoomOffSeason:
    'Efficiency/Standard/Hotel Room-Off Season',
  efficiencyAndStandartAndHotelRoomSeasonal:
    'Efficiency/Standard/Hotel Room-Seasonal',
  hotelAndMotel: 'Hotel/Motel',
  lounge: 'Lounge',
  recreationFacility: 'Recreation Facility',
  singleFamilyAnnual: 'Single Family-Annual',
  townhouseAndVillaAnnual: 'Townhouse/Villa-Annual',
  medicalOffice: 'Medical Office',
  noPoolAndNoWater: 'No Pool/No Water',
  pool: 'Pool Only',
  quadruplex: 'Quadruplex',
  residentialAnnual: 'Residential-Annual',
  residentialOffSeason: 'Residential-Off Season',
  residentialSeasonal: 'Residential-Seasonal',
  singleFamilyOffSeason: 'Single Family-Off Season',
  singleFamilySeasonal: 'Single Family-Seasonal',
  townhouseCondo: 'Townhouse Condo',
  townhouseFeeSimple: 'Townhouse Fee Simple',
  townhouseAndVillaOffSeason: 'Townhouse/Villa-Off Season',
  townhouseAndVillaSeasonal: 'Townhouse/Villa-Seasonal',
  unimprovedAgriculturalAndRecreationAndMobileHome:
    'Unimproved Agricultural/Recreation/Mobile Home',
  villaCondo: 'Villa Condo',
  villaFeeSimple: 'Villa Fee Simple',
  waterfrontAndNoOceanAccess: 'Waterfront/No Ocean Access',
  waterfrontAndOceanAccess: 'Waterfront/Ocean Access',
  waterfrontAndPoolAndNoOceanAccess: 'Waterfront/Pool/No Ocean Access',
  waterfrontAndPoolAndOceanAccess: 'Waterfront/Pool/Ocean Access',
};

export function filterConveniencesLabels(convenience: ConvenienceType): string {
  return Object.keys(convenience)
    .reduce<string[]>((acc, key) => {
      const label = conveniencesLabels[key];
      convenience[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterConveniencesEquipmentAppliancesLabels(
  convenience: ConvenienceType
): string {
  return Object.keys(convenience)
    .reduce<string[]>((acc, key) => {
      const label = conveniencesEquipmentAppliancesLabels[key];
      convenience[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterConveniencesHeatingCoolingLabels(
  convenience: ConvenienceType
): string {
  return Object.keys(convenience)
    .reduce<string[]>((acc, key) => {
      const label = conveniencesHeatingCoolingLabels[key];
      convenience[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterConvenienceInteriorFeaturesLabels(
  convenience: ConvenienceType
): string {
  return Object.keys(convenience)
    .reduce<string[]>((acc, key) => {
      const label = conveniencesInteriorFeaturesLabels[key];
      convenience[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterAssociationLabels(association: AssociationType): string {
  return Object.keys(association)
    .reduce<string[]>((acc, key) => {
      const label = associationLabels[key];
      association[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterMaintenanceLabels(maintenance: MaintenanceType): string {
  return Object.keys(maintenance)
    .reduce<string[]>((acc, key) => {
      const label = maintenanceLabels[key];
      maintenance[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}

export function filterStyleLabels(style: StyleType): string {
  return Object.keys(style)
    .reduce<string[]>((acc, key) => {
      const label = styleLabels[key];
      style[key] && label && acc.push(label);
      return acc;
    }, [])
    .join(', ');
}
