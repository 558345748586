import { gql } from '@apollo/client';

export const GET_PMMS = gql`
  query {
    pmms {
      data {
        id
        attributes {
          thirtyYearsRate
          fifteenYearsRate
          oneToFiveYearsRate
        }
      }
    }
  }
`;
