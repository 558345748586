import React, { useContext } from 'react';

import { priceFormatter } from 'src/utils/utils';
import { isListingOffMarket } from 'lib/checkListingStatus';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from 'pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

import PopUpContactUs from '../ContactUs/PopUpContactUs';

const UnitPopupHeader: React.FC = () => {
  const { building, unit, listing } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;

  const { bedsTotal, sqft, bathsFull, bathsHalf, price, statusCode } = listing;

  function renderBathrooms(): any {
    return (
      (bathsHalf && bathsHalf > 0 ? bathsFull + 0.5 : bathsFull) ||
      NO_DATA_PLACEHOLDER
    );
  }

  return (
    <div className="fixed w-full text-14-26-0.3 bg-white border-b cursor-auto z-60 border-beige-dark">
      <div className="container flex items-start justify-between px-0 py-4 pl-8 md:py-2 lg:py-0 md:items-center h-28 md:pl-14 md:h-14 xl:h-12">
        <div className="flex flex-col w-2/3 h-full xl:gap-6 xl:items-center xl:flex-row md:w-3/5 lg:w-1/2">
          <div className="relative flex items-center w-auto h-full border-dashed border-dashed-small-unit border-gray group">
            <span className="relative inline pr-6 mb-2 text-20-28-0.3 leading-5 md:mb-2 xl:mb-0 self-align-center hover:text-gold-hover font-sohneKraftig">
              {building?.name}&nbsp;#{unit?.unique}
            </span>
          </div>
          <div className="relative flex flex-wrap w-2/3 h-auto leading-5 md:w-auto group">
            {!isListingOffMarket(statusCode) && (
              <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
                {priceFormatter(price)}
              </span>
            )}
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {bedsTotal} bds
            </span>
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {renderBathrooms()} ba
            </span>
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {sqft} sqft
            </span>
          </div>
        </div>
        <PopUpContactUs />
      </div>
    </div>
  );
};

export default UnitPopupHeader;
