import React from 'react';
import Image from 'next/legacy/image';
import { Popover } from '@headlessui/react';
import classnames from 'classnames';

import rightArrow from '../../../public/images/arrow-right.svg';

type PopoverSearchPageProps = {
  name: string;
  buttonClassName?: string;
  classnamesFront?: string;
  arrowClasses?: string;
  render?: any;
};

const PopoverSearchPage: React.FC<PopoverSearchPageProps> = ({
  name,
  buttonClassName,
  render,
  classnamesFront,
  arrowClasses,
}) => {
  return (
    <Popover as="div" className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="outline-none">
            <div
              className={`flex text-black justify-between !pt-[10px] !pb-[12px] lg:!pt-[9px] lg:!pb-[13px] items-center px-3 xl:px-[17px] xxl:px-3 ${buttonClassName}`}
            >
              <div
                className={`flex mr-3 items-center align-middle xxl:pt-0 md:text-16-16-0.3 xl:text-14-20-0.3 ${classnamesFront}`}
              >
                <span>{name}</span>
              </div>
              <div className={`w-6 h-6 contents ${arrowClasses}`}>
                <Image
                  className={` transition duration-300 !pt-[2px] ${classnames({
                    'transform rotate-90': open,
                  })}`}
                  src={rightArrow}
                  width={20}
                  height={20}
                  alt="view"
                />
              </div>
            </div>
          </Popover.Button>
          {render({ close })}
        </>
      )}
    </Popover>
  );
};

export default PopoverSearchPage;
