import { ListingFiltersInput } from 'apolloClient/types/filters';
import {
  NeighborhoodsFilter,
  SearchBoxFilters,
} from 'components/Search/SearchInput/types';

export const generateSearchBoxFilters = ({
  cities,
  counties,
  zipCodes,
  neighborhoods,
  buildings,
  addresses,
}: SearchBoxFilters): ListingFiltersInput | undefined => {
  const cityIds = cities.map((it) => it.id);
  const countyIds = counties.map((it) => it.id);
  const buildingsIds = buildings
    .map((it) => it.id)
    .concat(addresses.map((it) => it.id));
  const neighborhoodsIds = neighborhoods.map((it) => it.id);

  const orResult = [
    ...(buildingsIds.length
      ? [
          {
            unit: {
              building: { id: { in: buildingsIds } },
            },
          },
        ]
      : []),
    ...(neighborhoodsIds.length
      ? [
          {
            unit: {
              building: {
                neighborhood: {
                  id: { in: neighborhoodsIds },
                },
              },
            },
          },
        ]
      : []),
    ...(cityIds.length
      ? [
          {
            unit: {
              building: {
                neighborhood: {
                  city: { id: { in: cityIds } },
                },
              },
            },
          },
        ]
      : []),
    ...(countyIds.length
      ? [
          {
            unit: {
              building: {
                neighborhood: {
                  city: {
                    county: { id: { in: countyIds } },
                  },
                },
              },
            },
          },
        ]
      : []),
    ...(zipCodes.length
      ? [
          {
            address: { zipCode: { in: zipCodes.map((it) => `'${it}'`) } },
          },
        ]
      : []),
  ];

  if (orResult.length !== 0) {
    return {
      or: orResult,
    };
  }
};
