import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/dist/client/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { defaultImage } from 'src/constants/constants';
import TagLinks from './Blog/TagLinks';

type ArticleProps = {
  imageUrl?: string;
  blurredImageUrl?: string;
  imageAlt: string;
  topics?: any;
  href?: any;
  title: string;
  description: string | null;
};

const Articles: React.FC<ArticleProps> = ({
  imageUrl,
  imageAlt,
  topics,
  title,
  description,
  blurredImageUrl,
  href = '#',
}) => {
  const { asPath } = useRouter();
  const isHomePage = asPath === '/';

  return (
    <Link
      href={href}
      passHref
      className="mt-4 md:mb-4 group text-ellipsis image-wrapper"
      title={title}
    >
      <div className="relative blog-recent-news-img">
        <Image
          src={imageUrl || defaultImage}
          alt={imageAlt}
          loading="eager"
          layout="fill"
          blurDataURL={blurredImageUrl || defaultImage}
          placeholder="blur"
          objectFit="cover"
        />
      </div>
      <TagLinks tags={topics} />
      <p className="mt-[12px] lg:mt-[7px] lg:w-full text-18-23-0.3 h-11 md:h-[43px] xl:h-14 ellipsis-mini font-sohneKraftig group-hover:text-gold-darker lg:text-21-28-0.3 lg:mr-10">
        {title}
      </p>
      <p className="mt-[10px] md:mt-[12px] text-14-21-0.3 min-h-14 lg:min-h-18 h-18 lg:text-16-26-0.3 ellipsis-small">
        {description}
      </p>
    </Link>
  );
};

export default Articles;
