import React, { useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Link from 'next/link';
import Tooltip from '@mui/material/Tooltip';

import rightArrow from '../../public/images/arrow-right.svg';

const AboutUs: React.FC = () => {
  const [listAboutUsOpen, setListAboutUsOpen] = useState(false);
  const [openAboutUs, setOpenAboutUs] = useState(false);
  const handleCloseAboutUs = () => {
    setOpenAboutUs(false);
  };
  const handleOpenAboutUs = () => {
    setOpenAboutUs(true);
  };

  return (
    <div className="relative w-full h-auto pb-3 border-b border-dashed xl:pb-0 border-gold-dark xl:w-auto xl:border-b-0 group">
      <Tooltip
        TransitionProps={{ timeout: 0 }}
        open={openAboutUs}
        onClose={handleCloseAboutUs}
        onOpen={handleOpenAboutUs}
        title={
          <div className="z-40 hidden text-black xl:block">
            <div className="relative bg-transparent">
              <div className="absolute hidden w-3 h-3 transform rotate-45 bg-white border-t border-l xl:block -top-1.7 left-7 border-gray" />
              <div className="flex">
                <div className="px-3 xl:pl-[17px] xl:pr-[42px] pb-2 pr-6 text-14-26-0.3 xl:text-16-34-0.3">
                  <div className="pt-3 hover:cursor-pointer hover:text-gold-hover">
                    <a href="/about" rel="nofollow">
                      About us
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href="https://joinblackbookproperties.com/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      Join the team
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <a className="hidden w-full pb-3 pr-6 text-black border-b border-dashed xl:flex xl:pb-0 border-gold-dark xl:border-b-0 xl:w-16 hover:cursor-pointer hover:text-gold-hover">
          About
        </a>
      </Tooltip>
      <div
        onClick={() => setListAboutUsOpen(!listAboutUsOpen)}
        className="relative flex pr-6 cursor-pointer hover:text-gold-hover xl:hidden close-building text-18-23-0.3 mt-[-4px]"
      >
        About
        <div className="pt-0.5 pl-2 xl:hidden">
          <Image
            className={classnames({
              'transform rotate-90': listAboutUsOpen,
            })}
            src={rightArrow}
            alt="about"
            height={15}
            width={12}
          />
        </div>
      </div>
      {listAboutUsOpen && (
        <div className="pt-3 w-80">
          <div className="z-40 bg-white xl:border top-4 w-80 border-gray ">
            <div className="relative">
              <div className="xl:absolute hidden xl:group-hover:block  w-3 h-3 bg-white -top-1.7 left-16 border-t border-l border-gray transform rotate-45" />
              <div className="z-40 block text-black xl:hidden">
                <div className="relative bg-transparent">
                  <div className="absolute hidden w-3 h-3 transform rotate-45 bg-white border-t border-l xl:block -top-1.7 left-10 border-gray" />
                  <div className="flex">
                    <div className="pb-2 pr-6 text-16-34-0.3">
                      <div className="pt-3 pb-1 mb-1 hover:cursor-pointer hover:text-gold-hover">
                        <a href="/about" rel="nofollow">
                          About us
                        </a>
                      </div>
                      <div className="pb-1 mb-1 hover:cursor-pointer hover:text-gold-hover">
                        <a
                          href="https://joinblackbookproperties.com/"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          Join the team
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
