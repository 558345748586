/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  LoadScriptNext,
} from '@react-google-maps/api';

import { Position } from '../types';
import mapPinHouse from '../public/images/map_pin_house.svg';
import { loadScriptProps } from '../constants/gapi/googleMapsConfig';

import { CommuteData, markerColorsMap } from './Commute';
import icon1 from './Commute/icons/1.svg';
import icon2 from './Commute/icons/2.svg';
import icon3 from './Commute/icons/3.svg';
import icon4 from './Commute/icons/4.svg';
import icon5 from './Commute/icons/5.svg';
import icon6 from './Commute/icons/6.svg';

const markerIconMap = [
  icon1.src,
  icon2.src,
  icon3.src,
  icon4.src,
  icon5.src,
  icon6.src,
];

type UnitBuildingLocationProps = {
  position?: Position;
  directions?: CommuteData[];
  onMapLoaded?: (map: google.maps.Map) => void;
  map?: google.maps.Map;
};

const UnitBuildingLocation: React.FC<UnitBuildingLocationProps> = React.memo(
  ({ position, directions, onMapLoaded, map }) => {
    useEffect(() => {
      if (directions && directions?.length > 0) {
        const filtered = directions?.filter(({ active }) => active) || [];
        if (filtered.length > 0) {
          const lats = [
            position?.lat as number,
            ...filtered.map(
              ({ destination }) =>
                destination.geometry?.location?.lat() as number
            ),
          ].filter((_) => _);
          const lngs = [
            position?.lng as number,
            ...filtered.map(
              ({ destination }) =>
                destination.geometry?.location?.lng() as number
            ),
          ].filter((_) => _);
          return map?.fitBounds(
            new google.maps.LatLngBounds(
              { lat: Math.min(...lats), lng: Math.min(...lngs) },
              { lat: Math.max(...lats), lng: Math.max(...lngs) }
            )
          );
        }
      }
      position && map?.panTo({ lat: position.lat, lng: position.lng });
    }, [position, directions, map]);

    if ((!position?.lat || !position?.lng) && !directions) return null;
    return (
      <LoadScriptNext {...loadScriptProps} loadingElement={undefined}>
        <GoogleMap
          mapContainerClassName="relative w-full  h-full"
          options={{
            zoom: 14,
            center: position,
          }}
          onLoad={(map) => onMapLoaded && onMapLoaded(map)}
        >
          {position && <Marker position={position} icon={mapPinHouse.src} />}
          {directions &&
            directions.map((direction, index) =>
              direction.active ? (
                <DirectionsRenderer
                  key={index}
                  directions={direction.result}
                  options={{
                    polylineOptions: { strokeColor: markerColorsMap[index] },
                    suppressMarkers: true,
                    preserveViewport: true,
                  }}
                />
              ) : null
            )}
          {directions &&
            directions.map((direction, index) => {
              const markerPosition = direction.destination?.geometry?.location;
              if (!direction.active || !markerPosition) return null;
              return (
                <Marker
                  key={Symbol(index).toString()}
                  position={markerPosition}
                  icon={markerIconMap[index]}
                />
              );
            })}
        </GoogleMap>
      </LoadScriptNext>
    );
  }
);

export default UnitBuildingLocation;
