import React from 'react';
import { StrapiMediaInfo } from '../apolloClient/types';
import { getSmallImageUrlFromImage } from 'lib/getImages';

//temporary variables
const imageSrcGallery = [
  'https://images.unsplash.com/photo-1589083130544-0d6a2926e519?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWlhbWklMjBiZWFjaHxlbnwwfHwwfHw%3D&w=1000&q=80',
  'https://images.unsplash.com/photo-1533106497176-45ae19e68ba2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bWlhbWl8ZW58MHx8MHx8&w=1000&q=80',
  'https://images.miamiandbeaches.com/getmedia/aecaea17-9d7b-41e7-be01-8b86fc18b1c2/Downtown-Miami-Brickell-night-1440x900.aspx?ext=.jpg',
  'https://alt-center.ru/blog/wp-content/uploads/2013/01/Miami.jpg',
  'https://upload.wikimedia.org/wikipedia/commons/9/9b/Downtown_Miami_from_north_20080408.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiygWrxp-sCptkXM7VNB53NkMW8Amv4sUTiA&usqp=CAU',
];
//===================

export type PopUpGalleryProps = {
  openPopupLarge?: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      initialShowedIndex: number;
    }>
  >;
  images?: StrapiMediaInfo[];
};

const PopUpGallery: React.FC<PopUpGalleryProps> = ({
  images,
  openPopupLarge,
}) => {
  return (
    <div className="flex flex-wrap justify-center mb-24 space-x-4 overflow-y-auto">
      {images && images.length > 0
        ? images.map((image, idx) => (
            <img
              className="mb-4 h-60 md:h-80 cursor-pointer"
              key={image.hash}
              alt={image.name}
              src={getSmallImageUrlFromImage(image)}
              onClick={() => {
                if (!!openPopupLarge) {
                  openPopupLarge({
                    isOpen: true,
                    initialShowedIndex: idx,
                  });
                }
              }}
            />
          ))
        : imageSrcGallery.map((src) => (
            <img
              key={src}
              className="mb-4 h-60 md:h-80"
              src={src}
              alt="default image"
            />
          ))}
    </div>
  );
};

export default PopUpGallery;
