import React from 'react';
import Image from 'next/legacy/image';

import arrowLeft from '../../public/images/arrow-left.svg';
import { ORIGIN } from '../../src/constants';

type GuideLinkprops = {
  title: string;
  slug: string;
};

const GuideLink: React.FC<GuideLinkprops> = ({ title, slug }) => {
  return (
    <a
      className="flex py-3 space-x-3 border-dashed-small-down-black border-dashed lg:w-1/2 text-16-26-0.3"
      href={`${ORIGIN}/blog/${slug}`}
      rel="nofollow"
    >
      <p className="max-w-[92%] text-14-21-0.3 md:16-26-0.3">{title}</p>
      <div className="flex">
        <Image src={arrowLeft} alt="view" />
      </div>
    </a>
  );
};

export default GuideLink;
