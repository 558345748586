import React, { createContext, useEffect, useState } from 'react';
import type { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useLazyQuery, useQuery } from '@apollo/client';

import { NeighborhoodWithoutId } from '../../../apolloClient/types/Neighborhood';
import BuildingPage from '../../../components/Building/BuildingPage';

import { Building, EntityWithId } from 'apolloClient/types';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from 'lib/flattenStrapiBulkDataItems';
import { BlogsResponse, BlogWithoutId } from 'apolloClient/types/Blog';
import { BuildingPageContextInterface } from 'apolloClient/types/Building';
import {
  BUILDING_AND_BLOGS_BY_SLUG,
  BUILDING_ITEMS_LIST,
  NEARBY_CONDOS,
  NEARBY_CONDOS_WITHOUT_RANGES,
} from 'apolloClient/queries/building';
import { client } from 'apolloClient/client';
import { ListingCollectionResponse } from 'apolloClient/types/Units';
import { getNearbyCondosParams } from 'lib/nearbyCondosParams';
import { notfoundRedirect } from 'constants/notFoundParams';
import { Entity, ResponseCollection } from 'apolloClient/types/common';
import { DEFAULT_REVALIDATE_INTERVAL, ORIGIN } from 'src/constants';
import { useAuth, useOnLoggedIn } from 'components/Auth/AuthProvider';
import { useFavorite } from 'components/SavedHouses/SavedFavoritesContext';
import { updateListingFavoritesQuery } from 'src/utils/updateListingFavoritesQuery';
import { UsefulGuideResponse } from '../../../apolloClient/types/UsefulGuide';
import { USEFUL_GUIDE } from '../../../apolloClient/queries/usefulGuide';
import BuildingProductSchema from 'components/Schema/BuildingProductSchema';
import BuildingAppartmentComplexSchema from 'components/Schema/BuildingAppartmentComplexSchema';
import { Agent, AgentsResponse } from 'apolloClient/types/Agents';
import { LOAD_AGENTS_FOR_EXPERTS_SECTION } from 'apolloClient/queries/agents';
import { getThreeUniquePageBlogs } from 'src/utils/blogs';

interface BuildingComponentTypes {
  building: Building;
  neighborhood: EntityWithId<NeighborhoodWithoutId>;
  blogs: EntityWithId<BlogWithoutId>[];
  saleCount: number;
  rentCount: number;
  usefulGuide: UsefulGuideResponse;
  experts: Agent[];
}

export const BuildingPageContext =
  createContext<BuildingPageContextInterface | null>(null);

const BuildingComponent: React.FC<BuildingComponentTypes> = (props) => {
  const {
    building,
    neighborhood,
    blogs,
    rentCount,
    saleCount,
    usefulGuide,
    experts,
  } = props;
  const { asPath } = useRouter();

  const buildingStats = building?.buildingStats;

  const [nearbyCondosParams, setNearbyCondosParams] = useState(
    getNearbyCondosParams({
      minAvgCoef: 0.5,
      maxAvgCoef: 1.5,
      buildingStats,
    })
  );

  const { getActualAuthData } = useAuth();
  const { latestAction } = useFavorite();

  const [
    fetchNearby,
    {
      data: nearbyCondosData,
      refetch: refetchNearbyCondos,
      updateQuery: updateNearbyQuery,
    },
  ] = useLazyQuery<{
    listings: ListingCollectionResponse;
  }>(NEARBY_CONDOS, {
    variables: {
      neighborhoodId: neighborhood.id,
      ...nearbyCondosParams,
      buildingId: building.id,
      propertyTypeList: ['C', 'RI', 'S', 'R'],
    },
    fetchPolicy: 'network-only',
  });

  const [
    fetchNearbyWithoutRanges,
    {
      data: nearbyCondosWithoutRangesData,
      refetch: refetchNearbyWithoutRanges,
      updateQuery: updateNearbyWithoutRangesQuery,
    },
  ] = useLazyQuery<{ listings: ListingCollectionResponse }>(
    NEARBY_CONDOS_WITHOUT_RANGES,
    {
      variables: {
        neighborhoodId: neighborhood.id,
        buildingId: building.id,
        propertyTypeList: ['C', 'RI', 'S', 'R'],
      },
    }
  );

  useEffect(() => {
    const authData = getActualAuthData();
    fetchNearby({
      variables: {
        neighborhoodId: neighborhood.id,
        ...nearbyCondosParams,
        buildingId: building.id,
        propertyTypeList: ['C', 'RI', 'S', 'R'],
        userId: authData.user.id || -1,
      },
    });
  }, []);

  useEffect(() => {
    if (!nearbyCondosData) return;
    if (nearbyCondosData && nearbyCondosData?.listings?.data?.length) return;
    const authData = getActualAuthData();
    fetchNearbyWithoutRanges({
      variables: {
        neighborhoodId: neighborhood.id,
        buildingId: building.id,
        propertyTypeList: ['C', 'RI', 'S', 'R'],
        userId: authData.user.id || -1,
      },
    });
    setNearbyCondosParams({ ...nearbyCondosParams, unused: true });
  }, [nearbyCondosData]);

  useOnLoggedIn(() => {
    const authData = getActualAuthData();
    if (nearbyCondosParams.unused) {
      return refetchNearbyWithoutRanges({
        userId: authData.user.id || -1,
      });
    }
    refetchNearbyCondos({
      userId: authData.user.id || -1,
    });
  });

  useEffect(() => {
    if (latestAction) {
      if (nearbyCondosParams.unused) {
        return updateNearbyWithoutRangesQuery(
          updateListingFavoritesQuery(latestAction, 'listings')
        );
      }
      updateNearbyQuery(updateListingFavoritesQuery(latestAction, 'listings'));
    }
  }, [latestAction]);

  const nearbyCondos = nearbyCondosData?.listings?.data?.length
    ? flattenStrapiBulkDataItems(nearbyCondosData.listings.data)
    : flattenStrapiBulkDataItems(nearbyCondosWithoutRangesData?.listings?.data);

  const showProductSchema =
    building?.buildingStats?.saleHistory || saleCount || rentCount;
  return (
    <BuildingPageContext.Provider
      value={{
        building,
        nearbyCondos,
        calculatedParams: nearbyCondosParams,
        blogs: blogs,
        neighborhood,
        rentCount,
        saleCount,
        usefulGuide,
        experts,
        url: `${ORIGIN}${asPath}`,
      }}
    >
      {!!showProductSchema && <BuildingProductSchema />}
      <BuildingAppartmentComplexSchema />
      <BuildingPage />
    </BuildingPageContext.Provider>
  );
};

export default BuildingComponent;

export const getStaticProps: GetStaticProps<
  BuildingComponentTypes,
  { urlBuildParam: string; urlParam: string; id: string }
> = async ({ params }) => {
  const urlParam = params?.urlParam as string;
  const urlBuildParam = params?.urlBuildParam as string;

  const buildingResponse = await client.query<
    { buildings: ResponseCollection<Building> } & { blogs: BlogsResponse } & {
      saleCount: ListingCollectionResponse;
    } & { rentCount: ListingCollectionResponse }
  >({
    query: BUILDING_AND_BLOGS_BY_SLUG,
    variables: {
      slug: urlBuildParam,
      nhSlug: urlParam,
      blogLimit: 3,
      neighborsLimit: 3,
    },
  });

  const {
    data: { buildings, blogs, rentCount, saleCount },
  } = buildingResponse;

  if (!buildings) return notfoundRedirect;

  const { data } = buildings;

  if (!data) return notfoundRedirect;

  const building = flattenStrapiDataItem(data[0]);
  const buildingId = building.id;

  if (!buildingId) return notfoundRedirect;
  const neighborhood = flattenStrapiDataItem(building?.neighborhood?.data);
  if (!neighborhood?.id) return notfoundRedirect;

  const urlIsCorrect =
    urlBuildParam === building?.slug && urlParam === neighborhood.slug;

  const buildingsBlogs = flattenStrapiBulkDataItems(building?.blogs?.data);
  const nhBlogs = flattenStrapiBulkDataItems(neighborhood?.blogs?.data);
  const otherBlogs = flattenStrapiBulkDataItems(blogs?.data);

  const pageBlogs = [...buildingsBlogs, ...nhBlogs, ...otherBlogs];
  const threeUniquePageBlogs = getThreeUniquePageBlogs(pageBlogs);

  const {
    data: { usefulGuide },
  } = await client.query<{ usefulGuide: UsefulGuideResponse }>({
    query: USEFUL_GUIDE,
  });
  // const usefulGuideData = flattenStrapiDataItem(usefulGuide.data)
  const expertsResponse = await client.query<{ agents: AgentsResponse }>({
    query: LOAD_AGENTS_FOR_EXPERTS_SECTION,
    fetchPolicy: 'network-only',
  });
  return urlIsCorrect
    ? {
        props: {
          blogs: threeUniquePageBlogs,
          building,
          neighborhood: neighborhood,
          rentCount: rentCount?.meta?.pagination?.total || 0,
          saleCount: saleCount?.meta?.pagination?.total || 0,
          usefulGuide: usefulGuide,
          experts: flattenStrapiBulkDataItems(
            expertsResponse.data.agents?.data
          ),
        },
        revalidate: DEFAULT_REVALIDATE_INTERVAL,
      }
    : notfoundRedirect;
};

export async function getStaticPaths() {
  const pageSize = 100;
  const query = client.watchQuery<{ buildings: ResponseCollection<Building> }>({
    query: BUILDING_ITEMS_LIST,
    fetchPolicy: 'network-only',
  });
  let page = 1;
  let finished = false;
  let result: Entity<Building>[] = [];

  while (!finished) {
    const response = await query.refetch({ pageSize, page });
    result = result.concat(response.data.buildings.data);
    page += 1;
    if (response.data.buildings.data.length < pageSize) {
      finished = true;
    }
  }
  const paths = result
    .filter(
      (building) =>
        building?.attributes?.neighborhood?.data?.attributes?.slug &&
        building?.attributes?.slug
    )
    .map((building) => ({
      params: {
        urlBuildParam: building.attributes.slug,
        urlParam: building.attributes.neighborhood.data.attributes.slug,
      },
    }));

  return { paths: [], fallback: 'blocking' };
}
