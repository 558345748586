import React, { useEffect, useState } from 'react';
import Image from 'next/legacy/image';

import {
  Building,
  Neighborhood,
  StrapiBulkResponseData,
  StrapiResponse,
} from 'apolloClient/types';
import Button from 'components/Button';
import CbbDropdown from 'components/CbbDropdown';
import arrowLeft from 'public/images/arrow-left.svg';
import LineBetweenSections from 'components/LineBetweenSections';
import { NEIGHBORHOOD_CONDOS } from 'apolloClient/queries/neighborhood';

import Condo from '../../components/Neighborhood/Condo';
import arrowDown from '../../public/images/arrow-down.svg';
import { useLazyQuery } from '@apollo/client';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from 'lib/flattenStrapiBulkDataItems';
import { BuildingWithoutId } from 'apolloClient/types/Building';
import { createSearchUrlByType } from 'components/Search/utils/listingsFiltersUtils';
import { defaultImage } from 'src/constants/constants';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';

export const loadingStep = 9;

const NeighborhoodCondos: React.FC<{
  neigborhood: Neighborhood;
  callbackBuildings: (data: boolean) => void;
}> = ({ neigborhood, callbackBuildings }) => {
  const { slug, name } = neigborhood;

  const [fetch, { data, fetchMore }] = useLazyQuery<{
    buildings: StrapiResponse<StrapiBulkResponseData<BuildingWithoutId>>;
  }>(NEIGHBORHOOD_CONDOS);

  const buildings = flattenStrapiBulkDataItems(data?.buildings.data);

  const [sortDropdownValue, setSortDropdownMethod] = useState(
    'Price Highest First'
  );

  const addMoreBuildings = () => {
    fetchMore({
      variables: { page: (data?.buildings.meta?.pagination.page || 0) + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        const { buildings = {} } = fetchMoreResult;

        const newData = previousQueryResult?.buildings?.data?.concat(
          fetchMoreResult.buildings.data
        );
        return {
          buildings: { ...buildings, data: newData },
        };
      },
    });
  };

  useEffect(() => {
    const sortsMethods: any = {
      'Price Highest First': 'buildingStats.minSalePrice:DESC',
      'Price Lowest First': 'buildingStats.minSalePrice:ASC',
      'Year Built': 'yearBuilt:ASC',
    };
    fetch({
      variables: {
        sorting: [sortsMethods[sortDropdownValue]],
        nhSlug: slug,
        page: 1,
        pageSize: loadingStep,
      },
    });
  }, [fetch, sortDropdownValue]);

  function renderBuildings(data: Building[]) {
    return data.map((building, index) => (
      <div key={building.id}>
        <Condo
          href={`/${slug}/${building.slug}`}
          imageUrl={
            getSmallImageUrlFromImage(
              flattenStrapiDataItem(building?.mainImage?.data)
            ) || ''
          }
          blurredImageUrl={
            getThumbnailImageUrlFromImage(
              flattenStrapiDataItem(building?.mainImage?.data)
            ) || ''
          }
          imageAlt={`${building?.name} condos`}
          name={building?.name || ''}
          buildingStatus={building?.isPreconstruction || false}
          address={building?.primaryAddress || ''}
          minSalePrice={Number(building?.buildingStats?.minSalePrice) || 0}
          maxSalePrice={Number(building?.buildingStats?.maxSalePrice) || 0}
          yearBuilt={+building?.yearBuilt || 0}
          minSaleBeds={Number(building?.buildingStats?.minSaleBeds) || 0}
          maxSaleBeds={Number(building?.buildingStats?.maxSaleBeds) || 0}
        />
      </div>
    ));
  }
  const Preloader = () => (
    <>
      {new Array(1).fill(null).map((_, i) => (
        <Box pt={3} key={i}>
          {new Array(10).fill(null).map((__, j) => (
            <Typography key={j} variant="h3">
              <Skeleton />
            </Typography>
          ))}
          <Divider sx={{ marginTop: 3 }} />
        </Box>
      ))}
    </>
  );
  if (buildings.length > 0) {
    callbackBuildings(true);
  }
  return (
    <>
      {buildings.length > 0 && (
        <>
          <section className="container pt-3 md:pt-[28px] lg:pt-[35px] text-m">
            <div className="flex flex-col justify-between md:items-center md:flex-row">
              <div className="flex items-center w-2/3">
                <div className="items-center justify-center hidden w-10 mx-2 md:flex lg:w-16">
                  <div className="w-10 lg:w-16 h-0.5 bg-black" />
                </div>
                <div className="justify-center text-23-29-0.77  uppercase lg:text-30-47-1 md:mx-4 font-sohneBreitBuch">
                  <button className="flex items-start text-left uppercase">
                    <div className="hidden w-8 mr-1">
                      <Image src={arrowDown} alt={`${name} Condos`} />
                    </div>
                    <h2>{name} Condos</h2>
                  </button>
                </div>
              </div>
              <CbbDropdown
                onChange={setSortDropdownMethod}
                rootClassName="h-12 w-60 md:w-[226px] mt-4 md:mt-0 text-14-18-0.3 md:pl-[16px] lg:pt-[7px] lg:pb-[9px] py-0 pb-[3px]"
                selectClassName="w-60 md:w-[226px] left-[-1px] top-[46px] mt-0"
                iconClasses="flex md:contents mt-[1px]"
                nameSelected="Price Highest First"
                textBeforeSelect="Sort by: "
                options={[
                  'Price Highest First',
                  'Price Lowest First',
                  'Year Built',
                ]}
              />
            </div>
            {buildings.length > 0 ? (
              <div className="grid grid-cols-1 gap-4 mt-6 mb-4 md:grid-cols-2 md:gap-[30px] lg:gap-[40px] xl:grid-cols-3">
                {renderBuildings(buildings)}
              </div>
            ) : (
              <Preloader />
            )}
            {data?.buildings.data.length &&
              data?.buildings.data.length >= 9 &&
              data?.buildings.meta?.pagination.total &&
              data?.buildings.data.length <
                data?.buildings.meta?.pagination.total && (
                <div className="flex justify-center">
                  <button
                    onClick={() => addMoreBuildings()}
                    className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
                  >
                    <div className="w-5 h-4">
                      <Image src={arrowLeft} alt="more" width={15} />
                    </div>
                    <span className="whitespace-nowrap">More</span>
                  </button>
                </div>
              )}
          </section>
          <div className="container pb-3 mt-10 md:mt-0 md:px-12 md:pt-5 md:pb-[70px] lg:py-2 lg:pb-[73px] text-m">
            <div className="flex flex-col items-center justify-center mx-auto mt-10 space-y-8 xl:flex-row xl:space-y-0 xl:space-x-[16px] lg:w-1/2">
              <Button
                fontClassName="font-sohneBreitHalbfett xl:pb-[1.5px] lg:pb-[1px]"
                styles={{ paddingTop: '15px' }}
                classNames="h-[48px]"
                name={`${name} Condos for sale`}
                href={createSearchUrlByType('sale', { neighborhoods: [slug] })}
                classNameWidth="lg:pl-[19px]"
              />
              <Button
                fontClassName="font-sohneBreitHalbfett xl:pb-[1.5px] lg:pb-[1px]"
                styles={{ paddingTop: '15px' }}
                classNames="h-[48px]"
                name={`${name} Condos for rent`}
                href={createSearchUrlByType('rent', { neighborhoods: [slug] })}
                classNameWidth="xl:pl-[19px]"
              />
            </div>
          </div>
          <LineBetweenSections hideLine={'container'} />
        </>
      )}
    </>
  );
};

export default NeighborhoodCondos;
