import { Tooltip } from '@mui/material';
import { FavoriteListingsResponse } from 'apolloClient/types/FavoriteListing';
import classNames from 'classnames';
import { useAuth } from 'components/Auth/AuthProvider';
import Favorite from 'components/icons/Favorite';
import { PropertyType } from 'components/UnitsFilters/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFavorite } from './SavedFavoritesContext';

type SaveToFavoritesButtonProps = {
  full?: boolean;
  unitId: number;
  type: PropertyType;
  userFavorites?: FavoriteListingsResponse;
  visibility?: string;
};

const LOCAL_STORAGE_FAVORITES_KEY = 'saveFavoritesTmp';

const SaveToFavoritesButton: React.FC<SaveToFavoritesButtonProps> = ({
  visibility = 'hidden',
  unitId,
  type,
  full,
  userFavorites,
}) => {
  const [hovered, setHovered] = useState(false);

  const { isLogged, openHeaderPopup } = useAuth();

  const { createFavorite, deleteFavorite } = useFavorite();

  const favorite = useMemo(
    () =>
      userFavorites?.data?.find(({ attributes }) => attributes?.type === type),
    [userFavorites, type]
  );

  const isSaved = !!favorite?.id;

  useEffect(() => {
    if (isLogged) {
      const favoriteData = localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY);
      if (favoriteData) {
        try {
          createFavorite(JSON.parse(favoriteData));
        } catch (error) {
          console.error('Data is not a JSON string:', favoriteData);
          console.error('Error parsing JSON createFavorite', error);
        }
        localStorage.removeItem(LOCAL_STORAGE_FAVORITES_KEY);
      }
    }
  }, [createFavorite, deleteFavorite, isLogged]);

  const clickHandler = () => {
    if (!isLogged) {
      localStorage.setItem(
        LOCAL_STORAGE_FAVORITES_KEY,
        JSON.stringify({ type, unit: unitId, alertFrequency: 'Daily' })
      );
      return openHeaderPopup();
    }

    isSaved
      ? deleteFavorite({ id: favorite?.id, type, unitId })
      : createFavorite({ type, unit: unitId, alertFrequency: 'Daily' });
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            padding: '5px 7px',
            fontSize: '16px',
            fontFamily: 'Sohne Buch, sans-serif',
          },
        },
      }}
      title={isSaved ? 'Delete from favorites' : 'Save to favorites'}
    >
      <button
        onClick={clickHandler}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={classNames(
          'cursor-pointer group block',
          full && 'flex items-center uppercase md:w-full h-7 xl:w-[100px]'
        )}
      >
        <Favorite fill={isSaved && isLogged} white={!full} hover={hovered} />
        {full && (
          <span
            className={classNames(
              `${visibility} px-2 pt-px hover-animation md:block text-14-18-0.3 md:text-16-21-0.3`,
              {
                'text-gold-hover': hovered,
              }
            )}
          >
            {isSaved ? 'Saved' : 'Save'}
          </span>
        )}
      </button>
    </Tooltip>
  );
};

export default SaveToFavoritesButton;
