import React, { useContext, useEffect } from 'react';
import Chart from 'chart.js/auto';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import { percentFormatterFractionZero } from 'src/utils/utils';
import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

const BuildingInventory: React.FC = () => {
  const {
    building: { buildingStats: buildingStat, unitsCount },
  } = useContext(BuildingPageContext) as BuildingPageContextInterface;

  const saleValues = [
    `${buildingStat?.salePercent}`,
    `${100 - Number(buildingStat?.salePercent)}`,
  ];
  const saleColors = ['#04A8BE', '#D4D4D4'];
  const rentValues = [
    `${buildingStat?.rentPercent}`,
    `${100 - Number(buildingStat?.rentPercent)}`,
  ];
  const rentColors = ['#E3972F', '#D4D4D4'];

  useEffect(() => {
    const chart = new Chart('salePercent', {
      type: 'doughnut',
      data: {
        datasets: [
          {
            backgroundColor: saleColors,
            data: saleValues,
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, []);

  useEffect(() => {
    const chart = new Chart('rentPercent', {
      type: 'doughnut',
      data: {
        datasets: [
          {
            backgroundColor: rentColors,
            data: rentValues,
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutout: '80%',
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <>
      <div className="flex flex-col items-center md:items-start md:mt-[60px] lg:mt-[44px] gap-[5px]">
        <div className="text-18-23-0.3 md:text-21-28-0.3 uppercase font-sohneKraftig">
          Inventory
        </div>
        <div className="text-14-21-0.3 lg:text-16-16-0.3">
          Condos for Sale and for Rent
        </div>
      </div>
      <div className="flex flex-col items-center justify-between my-[41px] md:my-8 xl:justify-evenly md:flex-row">
        <div className="flex flex-col justify-start w-[70%] lg:min-w-[260px] px-[8px] text-center border-l border-r border-dashed md:w-auto md:px-10 xl:px-8 border-gray">
          <div className="pb-1 text-59-57-1.57 lg:text-76-76-2.03 text-gold-dark font-sohneBreitHalbfett xl:pb-0">
            {unitsCount || NO_DATA_PLACEHOLDER}
          </div>
          <div className="text-14-26-0.3 lg:text-16-36-0.3 md:mb-9 xl:mb-0">
            Total Condos
          </div>
        </div>
        <div className="flex flex-col justify-center w-full px-2 lg:px-0 md:gap-2">
          <div className="flex ml-[35px] md:ml-[10px] lg:ml-0 mt-[45px] md:my-0 md:justify-center">
            <div className="relative h-[65px] w-[65px]">
              <canvas className="absolute -top-2" id="salePercent"></canvas>
            </div>
            <div className="text-16-16-0.3 md:text-14-14-0.3 lg:text-16-16-0.3 flex flex-col ml-[20px] md:ml-[15px] lg:ml-6 mt-[15px]">
              <div>{buildingStat?.saleCount || 0} Condos for Sale</div>
              <div className="mt-[3px]">
                {percentFormatterFractionZero(buildingStat?.salePercent || 0)}%
                of building
              </div>
            </div>
          </div>
          <div className="flex ml-[35px] md:ml-[10px] lg:ml-0 mt-[18px] md:my-0 md:justify-center">
            <div className="relative h-[65px] w-[65px]">
              <canvas className="absolute -top-2" id="rentPercent"></canvas>
            </div>
            <div className="text-16-16-0.3 md:text-14-14-0.3 lg:text-16-16-0.3 flex flex-col ml-[20px] md:ml-[15px] lg:ml-6 mt-[15px]">
              <div>{buildingStat?.rentCount || 0} Condos for Rent</div>
              <div className="mt-[3px]">
                {percentFormatterFractionZero(buildingStat?.rentPercent || 0)}%
                of building
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[55px] md:mt-0 justify-start w-[70%] lg:!min-w-[230px] px-[8px] text-center border-l border-r border-dashed md:w-auto md:px-10 xl:px-8 md:border-r-0 border-gray">
          <div className="pb-1 text-59-57-1.57 lg:text-76-76-2.03 text-gold-dark font-sohneBreitHalbfett xl:pb-0">
            {percentFormatterFractionZero(buildingStat?.unitsPercent || 0)}%
          </div>
          <div className="text-14-26-0.3 lg:text-16-36-0.3 md:mb-9 xl:mb-0">
            of building
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingInventory;
