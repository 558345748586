import React, { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Image from 'next/legacy/image';
import { useLazyQuery } from '@apollo/client';

import { defaultImage } from 'src/constants/constants';
import { ComponentBuildingFloorplan } from 'apolloClient/types/Building';
import { SEARCH_FLOORPLANS_BY_LISTING_ID } from 'apolloClient/queries/searchListings';
import {
  Building,
  EntityWithId,
  StrapiMediaInfo,
} from '../../apolloClient/types';
import { Listing } from '../../apolloClient/types/Units';
import { flattenStrapiDataItem } from '../../lib/flattenStrapiBulkDataItems';
import ZoomWrapper from '../ZoomWrapper';

import BuildingFloorplanBody from './BuildingFloorplanBody';
import UnitFloorplanBody from './UnitFloorplanBody';

interface FloorplanPopupBodyProps {
  initialSitePlan?: boolean;
  initialActiveRowIndex?: number;
  entity: Building | Listing;
  bodyType: 'listing' | 'building';
}

const FloorplanPopup: React.FC<FloorplanPopupBodyProps> = ({
  initialSitePlan = true,
  initialActiveRowIndex = null,
  entity,
  bodyType,
}) => {
  const [sitePlan, setSitePlan] = useState(initialSitePlan);
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(
    initialActiveRowIndex
  );

  const [fetchFloorplan, { data }] = useLazyQuery<{
    getFloorplanByListingId: ComponentBuildingFloorplan;
  }>(SEARCH_FLOORPLANS_BY_LISTING_ID);

  const floorplan = data?.getFloorplanByListingId;

  useEffect(() => {
    fetchFloorplan({
      variables: {
        id: Number((entity as EntityWithId<Listing>).id),
      },
    });
  }, [(entity as EntityWithId<Listing>).id]);

  const openSitePlan = () => {
    setSitePlan(true);
    setActiveRowIndex(null);
  };
  const openImage = (tab: number): void => {
    setSitePlan(false);
    setActiveRowIndex(tab);
  };

  function getFloorplanImage(): StrapiMediaInfo | undefined {
    if (bodyType === 'building') {
      const { floorplans, floorPlanImage } = entity as Building;
      const floorplanSitePlanImageUrl = floorPlanImage.data?.attributes;

      if (sitePlan) {
        return floorplanSitePlanImageUrl;
      }

      const currentFloorplan =
        floorplans[activeRowIndex === null ? -1 : activeRowIndex];

      return currentFloorplan.image?.data.attributes;
    }

    if (bodyType === 'listing') {
      const image = floorplan?.image?.data;
      return flattenStrapiDataItem(image);
    }
  }

  function getFloorplanImageSrc(): string {
    const image = getFloorplanImage();
    return image?.url || defaultImage;
  }

  function getFloorplanThumbnailImageSrc(): string {
    const image = getFloorplanImage();
    return image?.url || defaultImage;
  }

  return (
    <div className="flex flex-col mb-36 xl:flex-row xl:justify-between">
      <div className="relative border border-gold-dark xl:w-[65%] h-96 md:h-112 xl:h-[70vh]">
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
              <TransformComponent>
                <div className="relative flex items-center justify-center w-full h-full">
                  <div className="justify-center h-96 md:h-[400px] xl:h-[60vh]">
                    <Image
                      className="object-contain object-center w-full h-full"
                      src={getFloorplanImageSrc()}
                      blurDataURL={getFloorplanThumbnailImageSrc()}
                      placeholder="blur"
                      loading="eager"
                      layout="fill"
                      alt="image-floorplan"
                    />
                  </div>
                </div>
              </TransformComponent>
              <ZoomWrapper
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                resetTransform={resetTransform}
                initialActiveRowIndex={activeRowIndex}
              />
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
      <div className="mt-16 xl:mt-0 xl:ml-[40px] xl:w-[35%]">
        {bodyType === 'building' && (
          <BuildingFloorplanBody
            building={entity as Building}
            activeRowIndex={activeRowIndex}
            openImage={openImage}
            openSitePlan={openSitePlan}
          />
        )}
        {bodyType === 'listing' && (
          <UnitFloorplanBody
            floorplan={floorplan}
            listing={entity as EntityWithId<Listing>}
          />
        )}
      </div>
    </div>
  );
};

export default FloorplanPopup;
