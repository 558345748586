import React, { useEffect, useState } from 'react';

import MonthlyCostFormWrapper from '../components/MonthlyCostFormWrapper';
import { formatPriceValue, removeCurrencyFromValue } from '../utils';

interface PropertyTaxesFormProps {
  homePrice: number;
  onChange: (value: number) => void;
  taxAmount: number;
}

const PropertyTaxesForm: React.FC<PropertyTaxesFormProps> = ({
  homePrice,
  taxAmount,
  onChange,
}) => {
  const initialTaxRate = ((100 * taxAmount) / homePrice).toFixed(2);
  const [taxRateValue, setTaxRateValue] = useState(initialTaxRate);
  const [calculatedValue, setCalculatedValue] = useState(0);

  useEffect(() => {
    calculateValue(taxRateValue);
  }, [taxRateValue]);

  useEffect(() => {
    onChange(calculateTotalValue(calculatedValue));
  }, [calculatedValue]);

  function calculateValue(taxRateValue: string) {
    setCalculatedValue(Math.round(homePrice * (Number(taxRateValue) / 100)));
  }

  function calculateTotalValue(calculatedValue: number) {
    return Number((calculatedValue / 12).toFixed());
  }

  return (
    <MonthlyCostFormWrapper
      label="Property Taxes"
      value={calculateTotalValue(calculatedValue)}
    >
      <span className="text-14-26-0.3 text-gray">
        This estimate is based on the home value and an estimated local tax
        rate. Actual rate may vary.
      </span>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <label htmlFor="" className="w-1/3 pt-3 pb-1 md:w-2/6">
            Home Price
          </label>
          <label htmlFor="" className="w-1/3 pt-3 pb-1 md:w-2/6">
            Tax Rate
          </label>
          <label htmlFor="" className="w-1/3 pt-3 pb-1 md:w-1/5" />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex justify-between w-2/6 py-2 text-black md:w-2/6">
            <div>{formatPriceValue(homePrice)}</div>
            <div className="w-1/5 pr-9">X</div>
          </div>
          <input
            className="w-2/6 px-4 py-2 border md:w-2/6 border-beige-dark"
            type="text"
            value={`% ${taxRateValue}`}
            onChange={(event) =>
              setTaxRateValue(removeCurrencyFromValue(event.target.value))
            }
          />
          <span className="flex w-2/6 pl-2 md:pl-0 md:w-1/5">
            {`= ${formatPriceValue(calculatedValue)} / year`}
          </span>
        </div>
      </div>
    </MonthlyCostFormWrapper>
  );
};

export default PropertyTaxesForm;
