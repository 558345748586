import { useEffect, useState } from 'react';
import { usePrevious } from './usePrevious';

interface UseDebounceArguments {
  callback: () => void;
  timeout: number;
  changingValue: any;
  callOnInitial: boolean;
}

const useDebounce = ({
  callback,
  timeout,
  changingValue,
  callOnInitial,
}: UseDebounceArguments) => {
  const [initialized, setInitialized] = useState(false);

  const prevValue = usePrevious(changingValue);
  useEffect(() => {
    const id = setTimeout(() => {
      const canLoad = callOnInitial || initialized;
      canLoad && prevValue !== changingValue && callback();
      !initialized && setInitialized(true);
    }, timeout);
    return () => clearTimeout(id);
  }, [changingValue]);
};

export default useDebounce;
