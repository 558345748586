import React from 'react';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';

import FlagLabel from 'components/FlagLabel';

interface MonthlyCostFlagLabelProps {
  open: boolean;
}

const MonthlyCostFlagLabel: React.FC<MonthlyCostFlagLabelProps> = ({
  open,
}) => {
  return (
    <React.Fragment>
      <Disclosure.Button
        as="div"
        className="flex mb-[34px] cursor-pointer md:mb-10 md:mt-4 md:hidden"
      >
        <div className="relative flex flex-col justify-center w-max">
          <FlagLabel
            imageClassName={classnames({ 'transform rotate-180': open })}
            iconFlagLabel={true}
            name="Monthly cost"
          />
        </div>
      </Disclosure.Button>
      <div className="hidden mb-10 md:mb-[36px] lg:mb-[50px] md:flex">
        <div className="relative flex flex-col justify-center w-max">
          <FlagLabel
            imageClassName={classnames({ 'transform rotate-180': !open })}
            iconFlagLabel={true}
            name="Monthly cost"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthlyCostFlagLabel;
