import React, { FunctionComponent, ReactElement } from 'react';

export type TableColumn = {
  key: string;
  order?: string;
  heading?: any;
  headingMobile?: any;
  widthRatio?: number;
  cellRender?: (
    dataRecord: any,
    columnKey: string
  ) => ReactElement | string | number;
};

/**
 * data records must have distintive id
 */
export type HasId = {
  id: number | string;
  [k: string]: any;
};

const Table: FunctionComponent<{
  columns: TableColumn[];
  data: HasId[];
  rowClasses?: string;
  colClasses?: string;
  rootClassName?: string;
  whitespace?: string;
  availableCondosCoordinates?: number;
  onRowClick?: (row: HasId) => void;
  onSaleRentClick?: (type: 'rent' | 'sale') => void;
}> = ({
  columns,
  data,
  rowClasses,
  colClasses,
  whitespace = 'whitespace-nowrap',
  rootClassName = 'flex flex-row scroll',
  onRowClick,
  onSaleRentClick,
  availableCondosCoordinates,
}) => {
  return (
    <div>
      <div className={`${rootClassName}`}>
        {columns.map((column) => {
          return column.key === 'sale' || column.key === 'rent' ? (
            <a
              onClick={() => {
                if (column?.key === 'rent' || column?.key === 'sale') {
                  onSaleRentClick?.(column.key);
                  scrollTo({
                    top: availableCondosCoordinates,
                    behavior: 'smooth',
                  });
                }
              }}
              key={column.key}
              className={`text-center border-dashed-small pb-[20px] md:pb-[14px] lg:pb-[25px] pr-1 ml-1 sm:pr-6 sm:ml-6 md:pr-[30px] md:ml-[30px] lg:pr-4 lg:ml-4 xl:pr-4 xl:ml-4 last:pr-0 first:ml-0 last:border-r-0 group hover:text-gold-darker text-gold-dark cursor-pointer ${
                column.order
              } ${column.heading ? '' : 'uppercase'} ${colClasses}`}
              style={{ flexGrow: column.widthRatio || 1, flexBasis: 0 }}
            >
              <div
                className={`hidden mb-[12px] md:block text-14-19-0.3 md:text-12-16-0.3 xl:text-16-26-0.3 px-4 lg:px-0 ${whitespace}`}
              >
                {column.heading || column.key}
              </div>
              <div
                className={`mb-[5px] text-14-18-0.3 md:text-20-26-0.67 xl:text-14-26-0.3 px-4 lg:px-2 md:hidden ${whitespace}`}
              >
                {column.headingMobile}
              </div>
              {data.map((row, i) => (
                <div key={row.id} onClick={() => onRowClick && onRowClick(row)}>
                  <div
                    className={` ${
                      i > 0
                        ? 'border-beige-dark border-t '
                        : 'border-gold-dark border-t group-hover:border-gold-darker'
                    }`}
                  />
                  <div className={`my-2 ${rowClasses}`}>
                    <div>
                      {column.cellRender
                        ? column.cellRender(row, column.key)
                        : cellValue(row[column.key])}
                    </div>
                  </div>
                </div>
              ))}
            </a>
          ) : (
            <div
              key={column.key}
              className={`text-center border-dashed-small pb-[20px] md:pb-[14px] lg:pb-[25px] pr-1 ml-1 sm:pr-6 sm:ml-6 md:pr-[30px] md:ml-[30px] lg:pr-4 lg:ml-4 xl:pr-4 xl:ml-4 last:pr-0 first:ml-0 last:border-r-0 ${
                column.order
              } ${column.heading ? '' : 'uppercase'} ${colClasses}`}
              style={{ flexGrow: column.widthRatio || 1, flexBasis: 0 }}
            >
              <div
                className={`hidden mb-[12px] md:block text-14-19-0.3 md:text-12-16-0.3 xl:text-16-26-0.3 lg:px-2 ${whitespace}`}
              >
                {column.heading || column.key}
              </div>
              <div
                className={`mb-[5px] text-14-18-0.3 md:text-20-26-0.67 xl:text-14-26-0.3 whitespace-nowrap md:hidden ${whitespace}`}
              >
                {column.headingMobile}
              </div>
              {data.map((row, i) => (
                <div key={row.id} onClick={() => onRowClick && onRowClick(row)}>
                  <div
                    className={` ${
                      i > 0
                        ? 'border-beige-dark border-t '
                        : 'border-gold-dark border-t'
                    }`}
                  />
                  <div className={`my-2 ${rowClasses}`}>
                    <div>
                      {column.cellRender
                        ? column.cellRender(row, column.key)
                        : cellValue(row[column.key])}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );

  function cellValue(value: any) {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    return value;
  }
};

export { Table };
