import { ListingCollectionResponse } from 'apolloClient/types/Units';
import { LatesAction } from 'components/SavedHouses/SavedFavoritesContext';
import { getPropertyTypeByCode } from './getPropertyTypeByCode';
import {
  SearchPageBaseResponse,
  SearchPageResponse,
} from 'apolloClient/types/Searches';

export const updateListingFavoritesQuery =
  <T>(latestAction: LatesAction, fieldName: keyof T) =>
  (prevQueryResult: T): T => {
    if (!latestAction) return prevQueryResult;
    if (
      !(
        prevQueryResult?.[fieldName] as unknown as ListingCollectionResponse
      )?.data?.some(
        ({
          attributes: {
            unit: {
              data: { id },
            },
            propertyTypeCode,
          },
        }) =>
          id === latestAction.unitId &&
          getPropertyTypeByCode(propertyTypeCode) === latestAction.type
      )
    ) {
      return prevQueryResult;
    }

    const newList = (
      prevQueryResult?.[fieldName] as unknown as ListingCollectionResponse
    )?.data?.map(({ id, attributes }) =>
      Number(attributes?.unit?.data?.id) === Number(latestAction.unitId)
        ? {
            id,
            attributes: {
              ...attributes,
              unit: {
                ...attributes.unit,
                data: {
                  id: attributes.unit.data.id,
                  attributes: {
                    ...attributes.unit.data.attributes,
                    favorite:
                      latestAction.action === 'create'
                        ? {
                            data: [
                              {
                                id: latestAction.favoriteId,
                                attributes: { type: latestAction.type },
                              },
                            ],
                          }
                        : { data: [] },
                  },
                },
              },
            },
          }
        : { id, attributes }
    );

    return {
      ...prevQueryResult,
      [fieldName]: {
        ...prevQueryResult?.[fieldName],
        data:
          newList ||
          (prevQueryResult?.[
            fieldName
          ] as unknown as ListingCollectionResponse),
      },
    };
  };

export const updateSearchgFavoritesQuery =
  <T>(latestAction: LatesAction, fieldName: keyof SearchPageResponse) =>
  (prevQueryResult: SearchPageResponse): SearchPageResponse => {
    if (!latestAction) return prevQueryResult;

    const listingsData = (
      prevQueryResult[fieldName] as unknown as SearchPageBaseResponse
    )?.listings?.data;

    if (
      !listingsData?.some(
        ({ unitId, property_type_code }) =>
          unitId === latestAction.unitId &&
          getPropertyTypeByCode(property_type_code) === latestAction.type
      )
    ) {
      return prevQueryResult;
    }

    const updatedListings = listingsData.map((listing) =>
      Number(listing.unitId) === Number(latestAction.unitId)
        ? {
            ...listing,
            favoriteId:
              latestAction.action === 'create' ? latestAction.favoriteId : null,
            favoriteType:
              latestAction.action === 'create' ? latestAction.type : null,
          }
        : listing
    );

    const returnData = {
      ...prevQueryResult,
      [fieldName]: {
        ...prevQueryResult?.[fieldName],
        listings: {
          ...prevQueryResult[fieldName].listings,
          data: updatedListings,
        },
      },
    };

    return returnData;
  };
