import { StrapiMediaInfo } from '../apolloClient/types/StrapiMediaInfo';
import apiUrl from './apiUrl';

type StrapiMediaFormats = {
  small?: StrapiMediaInfo;
  thumbnail?: StrapiMediaInfo;
};

type StrapiMediaInfoContainer = StrapiMediaInfo & {
  formats?: StrapiMediaFormats;
};

type StrapiMediaSize = keyof StrapiMediaFormats;

export const getStrapiMedia = (
  info: StrapiMediaInfoContainer,
  size?: StrapiMediaSize
) => {
  const sizedInfo = size != null ? info?.formats?.[size] || info : info;
  return sizedInfo?.url?.indexOf('/') === 0
    ? new URL(sizedInfo.url, apiUrl).toString()
    : sizedInfo?.url;
};
