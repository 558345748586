import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/legacy/image';
import { Checkbox, MenuItem } from '@mui/material';

import { CbbDropdownOptions } from './CbbDropdown';
import rightArrow from '../public/images/arrow-right.svg';

export type MultiSelectProps = {
  value: string[];
  onSelectChange: ([]) => void;
  options: CbbDropdownOptions[];
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  onSelectChange,
  value,
  options,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(value);

  const selectRef = useRef(null);

  useEffect(() => {
    const onClick = ({ target }: { target: any }) => {
      if (
        target !== selectRef.current &&
        target.parentNode?.parentNode?.parentNode !== selectRef.current
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    onSelectChange(selectedItems);
  }, [selectedItems]);

  return (
    <div
      ref={selectRef}
      onClick={toggle}
      className={`custom-dropdown flex items-center no-focus relative bg-white box-border justify-between xl:mr-0 px-4 pt-[8px] pb-[9px] border cursor-pointer border-beige-dark h-[44px] w-full text-16-21-0.3`}
    >
      <div>{selectedItems.join(', ')}</div>
      <div className={`w-6 h-6 contents`}>
        <Image
          className={` transition duration-300 !pt-[2px] ${
            open ? 'transform rotate-90' : ''
          }`}
          src={rightArrow}
          alt="Toogle Dropdown"
          height={22}
          width={22}
        />
      </div>
      {open && (
        <div
          className={`absolute z-20 flex flex-col  box-border justify-between bg-white mt-0 border border-beige-dark top-[41px] left-0 w-full md:w-[298px] md:left-[-1px]`}
          onClick={(e) => e.stopPropagation()}
        >
          {options.map(({ label, value: itemValue }) => (
            <MenuItem
              key={itemValue}
              value={itemValue}
              className="w-full font-sohneBuch"
            >
              <Checkbox
                checked={selectedItems.indexOf(itemValue) > -1}
                onChange={() => {
                  let idx = selectedItems.indexOf(itemValue);
                  let updatedItems = [...selectedItems];
                  if (idx > -1) {
                    updatedItems.splice(idx, 1);
                  } else {
                    updatedItems.push(itemValue);
                  }
                  setSelectedItems(updatedItems);
                }}
              />
              {label}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
