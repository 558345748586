import React from 'react';

import { Disclosure } from '@headlessui/react';
import CommuteForm from './CommuteForm';
import CommutePinIcon from './CommutePinIcon';
import { CommuteData } from './types';
import { timeToHM } from 'lib/timeFormat';
import { CommuteParams } from './types';

type CommuteInfoItemProps = {
  commuteData: CommuteData;
  index: number;
  onHide: (index: number) => void;
  onShow: (index: number) => void;
  onDelete: (index: number) => void;
  onEditGMapDirection: (data: CommuteParams, index: number) => Promise<string>;
  mapInstance?: google.maps.Map;
};

const CommuteInfoItem: React.FC<CommuteInfoItemProps> = ({
  commuteData,
  index,
  onHide,
  onShow,
  onEditGMapDirection,
  onDelete,
  mapInstance,
}) => {
  return (
    <Disclosure>
      <div className="flex items-center justify-between gap-3 my-1.5">
        <div className="flex space-x-4">
          <div>
            <CommutePinIcon index={index} />
          </div>
          <div className="flex flex-wrap">
            <strong>
              {commuteData.result.routes[0].legs[0].duration_in_traffic?.text ||
                commuteData.result.routes[0].legs[0].duration?.text}
            </strong>
            &nbsp;to&nbsp;
            {commuteData.name ||
              commuteData.destination.formatted_address ||
              commuteData.result.routes[0].legs[0].end_address}
            &nbsp;at&nbsp;
            {timeToHM(commuteData.time)}&nbsp;
            <strong>{commuteData.transport}</strong>
          </div>
        </div>
        <div className="flex flex-col md:gap-3 md:flex-row">
          <Disclosure.Button className="text-gold-darker">
            Edit
          </Disclosure.Button>
          <button
            className="pl-1.5 text-gold-darker"
            onClick={() => (commuteData.active ? onHide(index) : onShow(index))}
          >
            {commuteData.active ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      <Disclosure.Panel className="mb-8 text-gray-500 ">
        <CommuteForm
          mapInstance={mapInstance}
          CancelButton={Disclosure.Button}
          onCalculate={(params) => onEditGMapDirection(params, index)}
          dataToEdit={commuteData}
          onDelete={() => onDelete(index)}
        />
        <div className="mt-3 border border-b-0 border-gray-light" />
      </Disclosure.Panel>
    </Disclosure>
  );
};

export default CommuteInfoItem;
