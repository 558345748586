import React from 'react';
import { FiltersValues, SetFiltersValues } from '../../types';
import {
  ANY_WATERFRONT,
  BEACH_FRONT,
  FURNISHED,
  INTERCOASTAL_VIEW,
  OCEAN_FRONT,
  OCEAN_VIEW,
  PENTHOUSE,
  PET_FRIENDLY,
  SKYLINE_VIEW,
  UNFURNISHED,
} from '../../constants';
import classnames from 'classnames';

const searchPageCheckboxes: { label: string; value: keyof FiltersValues }[] = [
  { label: 'Beachfront', value: BEACH_FRONT },
  { label: 'Oceanfront', value: OCEAN_FRONT },
  { label: 'Any waterfront', value: ANY_WATERFRONT },
  { label: 'Penthouse', value: PENTHOUSE },
  { label: 'Bay/Intercoastal view', value: INTERCOASTAL_VIEW },
  { label: 'Pet-friendly', value: PET_FRIENDLY },
  { label: 'Skyline view', value: SKYLINE_VIEW },
  { label: 'Furnished', value: FURNISHED },
  { label: 'Ocean view', value: OCEAN_VIEW },
  { label: 'Unfurnished', value: UNFURNISHED },
];

const buildingPageCheckboxes: { label: string; value: keyof FiltersValues }[] =
  [
    { label: 'Bay/Intercoastal view', value: INTERCOASTAL_VIEW },
    { label: 'Pet-friendly', value: PET_FRIENDLY },
    { label: 'Skyline view', value: SKYLINE_VIEW },
    { label: 'Furnished', value: FURNISHED },
    { label: 'Ocean view', value: OCEAN_VIEW },
    { label: 'Unfurnished', value: UNFURNISHED },
  ];

interface Props {
  values: FiltersValues;
  setValues: SetFiltersValues;
  page: 'building' | 'search';
}

const IOnlyWant: React.FC<Props> = ({ values, setValues, page }) => {
  function renderCheckboxes(
    list: { label: string; value: keyof FiltersValues }[]
  ) {
    return list.map(({ label, value }) => {
      return (
        <div
          key={value}
          className="flex items-center mt-[12px] md:mt-[8px] mb-[4px] text-14-26-0.3"
        >
          <input
            type="checkbox"
            className="mr-3 input-checkbox custom-checkbox"
            checked={!!values[value] as boolean}
            onChange={({ target }) =>
              setValues({
                ...values,
                [value]: target.checked,
              })
            }
          />
          <label className="ml-[4px] text-16-26-0.3 md:text-14-26-0.3">
            {label}
          </label>
        </div>
      );
    });
  }

  return (
    <div className="pb-6">
      <div className="md:text-14-26-0.3 pt-[4px]">I only want:</div>
      <div
        className={classnames('grid grid-cols-1', {
          'md:grid-cols-2  md:w-2/3': page === 'building',
          'md:grid-cols-3': page === 'search',
        })}
      >
        {page === 'search' && renderCheckboxes(searchPageCheckboxes)}
        {page === 'building' && renderCheckboxes(buildingPageCheckboxes)}
      </div>
    </div>
  );
};

export default IOnlyWant;
