import React from 'react';
import { Agent, MappedAgent } from '../../apolloClient/types/Agents';
import { defaultAgentImage } from '../../src/utils/agentUtils';
import Expert from '../Unit/Expert';

interface AgentsProps {
  commonAgent: MappedAgent;
  secondAgent: MappedAgent | null;
  showSecondAgent?: boolean;
  experts?: Agent[];
  containerClassName: string;
  textClassName?: string;
  containerExpert?: string;
}

const Agents: React.FC<AgentsProps> = ({
  commonAgent,
  secondAgent,
  showSecondAgent = true,
  experts,
  containerClassName = '',
  textClassName,
  containerExpert,
}) => {
  const normalizeAgent = (ag: MappedAgent) => {
    return {
      ...ag,
      company:
        ag.company ||
        experts?.find((ex) => ex.agentId?.email === ag.email)?.company,
      job: ag.job || experts?.find((ex) => ex.agentId?.email === ag.email)?.job,
    };
  };

  const renderAgent = (agent: MappedAgent) => {
    return (
      <div
        className={`${containerExpert} flex mb-[30px] mt-[6px] lg:mt-[10px]`}
      >
        <Expert
          imageUrl={agent?.image || defaultAgentImage}
          imageAlt={`expert ${agent?.firstName} ${agent?.lastName}`}
          firstName={agent?.firstName}
          lastName={agent?.lastName}
          company={agent?.company || ''}
          job={agent?.job}
          phone={agent?.phone}
          email={agent?.email}
          containerClassName={containerClassName}
          textClassName={textClassName}
        />
      </div>
    );
  };

  return (
    <div className="ml-0 lg:w-auto">
      {renderAgent(normalizeAgent(commonAgent))}
      {showSecondAgent &&
        secondAgent?.email &&
        experts &&
        !!experts?.length &&
        renderAgent(normalizeAgent(secondAgent))}
    </div>
  );
};

export default Agents;
