import React from 'react';
import Image from 'next/legacy/image';
import { styled } from '@mui/system';
import { Dialog, DialogProps, Theme, useMediaQuery } from '@mui/material';

import close from '../../public/images/close.svg';

import Navigation, { HeaderDataSet } from './Navigation';

interface StyledDialogProps extends DialogProps {
  ismobile?: string;
}

const StyledDialog = styled(Dialog)<StyledDialogProps>(({ ismobile }) => ({
  '.MuiDialog-container': {
    justifyContent: 'start',

    '.MuiPaper-root': {
      maxWidth: ismobile ? '100vw' : '50vw',
      maxHeight: 'none',
      margin: '0',
      borderRadius: '0',
    },
  },
}));

type BurgerMenuProps = {
  active: any;
  setActive: any;
  dataSet: HeaderDataSet;
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  active,
  setActive,
  dataSet,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const openBurgerMenu = () => setActive(true);
  const closeBurgerMenu = () => setActive(false);

  return (
    <React.Fragment>
      <div
        className="flex flex-col w-auto mr-[13px] sm:mr-[29px] xl:hidden"
        onClick={openBurgerMenu}
      >
        <div className="w-[28px] h-0.5 my-[2.5px] bg-black" />
        <div className="w-[28px] h-0.5 my-[2.5px] bg-black" />
        <div className="w-[28px] h-0.5 my-[2.5px] bg-black" />
      </div>
      <StyledDialog
        open={active}
        onClose={closeBurgerMenu}
        transitionDuration={0}
        ismobile={isMobile ? 'true' : ''}
        fullScreen
      >
        <div className="overflow-y-auto">
          <div className="relative md:pt-[7px]">
            <div
              className="absolute top-5 md:top-[22px] lg:top[27px] left-[29px] ml-[4px] md:ml-[20px]"
              onClick={closeBurgerMenu}
            >
              <Image src={close} alt="close" />
            </div>
            <Navigation
              active={active}
              setActive={setActive}
              dataSet={dataSet}
            />
          </div>
        </div>
      </StyledDialog>
    </React.Fragment>
  );
};

export default BurgerMenu;
