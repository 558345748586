import React, { useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';

import rightArrow from '../../../public/images/arrow-right.svg';
import { NeighborhoodsResponse } from '../../../apolloClient/types/Neighborhood';

import NeighborhoodMenuBody from './NeighborhoodMenuBody';

const MobileNeighborhoodMenu: React.FC<{
  neighborhoods?: NeighborhoodsResponse;
  fetchMoreNhds: (search: string) => void;
}> = (props) => {
  const [listNeighborhoodsOpen, setListNeighborhoodsOpen] = useState(false);

  return (
    <div className="w-full pb-3 border-b border-dashed border-gold-dark mt-[14px]">
      <div
        onClick={() => setListNeighborhoodsOpen(!listNeighborhoodsOpen)}
        className="flex pr-6 cursor-pointer hover:text-gold-hover close-neighborhood text-18-23-0.3 mt-[-4px]"
      >
        Neighborhoods
        <div className="pt-0.5 pl-2">
          <Image
            className={classnames({
              'transform rotate-90': listNeighborhoodsOpen,
            })}
            src={rightArrow}
            alt="Neighborhoods"
            height={15}
            width={12}
          />
        </div>
      </div>
      {listNeighborhoodsOpen && (
        <div className="pt-3 pb-[32px] w-80 border-gray">
          <div className="overflow-auto">
            <NeighborhoodMenuBody {...props} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNeighborhoodMenu;
