import React, { useContext, useMemo } from 'react';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from '../../lib/flattenStrapiBulkDataItems';
import GuideLink from './GuideLink';

const GuidesSection: React.FC = React.memo(function UsefulSection() {
  const { usefulGuide, building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const { isPreconstruction } = building;

  const usefulGuideData = isPreconstruction
    ? flattenStrapiDataItem(usefulGuide.data).preconstructBuildings
    : flattenStrapiDataItem(usefulGuide.data).buildings;

  const links = useMemo(() => {
    if (usefulGuideData) {
      return flattenStrapiBulkDataItems(usefulGuideData.data).map(
        ({ title, slug }) => (
          <GuideLink key={slug} title={title} slug={slug || ''} />
        )
      );
    }

    return null;
  }, [usefulGuideData]);

  if (!links) return null;

  return (
    <div className="container text-14-26-0.3 lg:pt-1 lg:pb-[20px] relative">
      <div className="lg:px-[63px] pt-[29px] pb-[51px] md:py-14 xl:pt-[60px]">
        <h2 className="uppercase text-23-29-0.3 xl:text-30-36-1 font-sohneBreitBuch">
          Some useful guides for you
        </h2>
        <p className="mt-[16px] mb-[13px] md:my-8 lg:mt-[30px] text-14-21-0.3 md:text-16-26-0.3 font-Helvetica">
          Here are some helpful documents and articles you might want to read.
        </p>
        <div className="flex flex-col font-sohneKraftig">{links}</div>
      </div>
    </div>
  );
});

export default GuidesSection;
