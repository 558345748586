import React, { useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';

import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import FlagLabel from '../../FlagLabel';
import TransitionWrapper from '../../TransitionWrapper';

import SalesHistory from './SalesHistory';
import SalesStats from './SalesStats';
import BuildingInventory from './BuildingInventory';

type BuildingStatsProps = {
  href: string;
  showButton?: string;
};

const BuildingStats: React.FC<BuildingStatsProps> = ({ href, showButton }) => {
  const { building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const { name } = building;

  return (
    <>
      <div className="xl:hidden w-fill mt-3 h-0.5 bg-beige my-6 md:mb-0" />
      <Disclosure defaultOpen={true} as="div" className="mb-6">
        {({ open }) => (
          <>
            <Disclosure.Button
              as="div"
              className="flex mb-10 cursor-pointer md:hidden"
            >
              <div className="relative flex flex-col justify-center w-max">
                <FlagLabel
                  imageClassName={classnames({ 'transform rotate-180': open })}
                  iconFlagLabel={true}
                  name={`${name} building stats`}
                />
              </div>
            </Disclosure.Button>
            <TransitionWrapper>
              <Disclosure.Panel>
                <div className="flex flex-col justify-between lg:border-b xl:border-t xl:flex-row border-beige-dark">
                  <div className="flex flex-col pt-[6px] md:pt-9 lg:pt-[26px] xl:w-2/3">
                    <div className="hidden md:flex">
                      <div className="relative flex flex-col justify-center w-max">
                        <FlagLabel
                          imageClassName={classnames({
                            'transform rotate-180': open,
                          })}
                          iconFlagLabel={true}
                          name={`${name} building stats`}
                        />
                      </div>
                    </div>
                    <BuildingInventory />
                    <SalesStats />
                  </div>
                  <SalesHistory href={href} showButton={showButton} />
                </div>
              </Disclosure.Panel>
            </TransitionWrapper>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default BuildingStats;
