import React, { Fragment } from 'react';
import Image from 'next/legacy/image';
import { Tab } from '@headlessui/react';
import { LoadScriptNext } from '@react-google-maps/api';
import { Dialog } from '@mui/material';
import { styled } from '@mui/system';
import iconClose from '../public/images/close.svg';
import { Position } from '../types';
import { Building, StrapiMediaInfo } from '../apolloClient/types';
import { Listing } from '../apolloClient/types/Units';
import { loadScriptProps } from '../constants/gapi/googleMapsConfig';

import Share from './Share';
import PopUpGallery from './PopUpGallery';
import PopUpDirections from './PopUpDirections';
import PopUpTour from './PopUpTour';
import Print from './Print';
import FloorplanPopup from './Floorplans/FloorplanPopup';

export type PopUpUnitBuildingProps = {
  activeTab: number;
  tabsRenderExclude?: {
    photos?: boolean;
    floorPlan?: boolean;
    directions?: boolean;
    tour?: boolean;
  };
  position?: Position;
  images?: StrapiMediaInfo[];
  tours?: string[] | string | null;
  toursButtonLabel?: string | null;
  floorplanButtonLabel?: string | null;
  open: boolean;
  onClose: () => void;
  renderHeader: JSX.Element;
  initialActiveRowIndex?: number;
  initialSitePlan?: boolean;
  entity: Building | Listing;
  entityType: 'listing' | 'building';
  openPopupLarge?: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      initialShowedIndex: number;
    }>
  >;
};

type PopupConfig = {
  name: string;
  title: string | ((props: PopUpUnitBuildingProps) => string);
  render: (props: PopUpUnitBuildingProps, key: any) => JSX.Element | string;
};

const popupConfig: PopupConfig[] = [
  {
    name: 'photos',
    title: 'Photos',
    render: ({ images = [], openPopupLarge }) => (
      <div className="h-screen px-2 overflow-y-auto pb-14">
        <PopUpGallery images={images} openPopupLarge={openPopupLarge} />
      </div>
    ),
  },
  {
    name: 'floorPlan',
    title: ({ floorplanButtonLabel }) => floorplanButtonLabel || 'floor plan',
    render: ({
      initialActiveRowIndex,
      initialSitePlan,
      entity,
      entityType,
    }) => (
      <div className="h-full pb-10 overflow-y-auto">
        <FloorplanPopup
          entity={entity}
          bodyType={entityType}
          initialActiveRowIndex={initialActiveRowIndex}
          initialSitePlan={initialSitePlan}
        />
      </div>
    ),
  },
  {
    name: 'directions',
    title: 'Directions',
    render: ({ position }, key) => <PopUpDirections position={position} />,
  },
  {
    name: 'tour',
    title: ({ toursButtonLabel }) => toursButtonLabel || '',
    render: ({ tours }) => <PopUpTour tours={tours} />,
  },
];

const PopUpUnitBuilding: React.FC<PopUpUnitBuildingProps> = (props) => {
  const {
    activeTab,
    tabsRenderExclude = {},
    open,
    onClose,
    renderHeader,
  } = props;

  const renderConfig = popupConfig.map((conf) => ({
    ...conf,
    skip: Boolean((tabsRenderExclude as { [key: string]: boolean })[conf.name]),
  }));

  return (
    <LoadScriptNext {...loadScriptProps} loadingElement={undefined}>
      <Dialog open={open} onClose={onClose} fullScreen>
        {renderHeader}
        <div className="container flex flex-col justify-between px-0 mt-3 lg:mt-0">
          <Tab.Group defaultIndex={activeTab}>
            <Tab.List className="outline-none">
              <div className="fixed w-full top-[113px] md:top-[57px] lg:top-[49px] z-100 bg-white container flex justify-between pl-6 md:pl-10 pt-2 lg:pt-2 xl:pt-6 md:pb-6 xl:pb-10 md:items-end">
                <div className="relative">
                  {renderConfig.map(({ title, name, skip }, index) =>
                    skip ? (
                      <Tab key={index} />
                    ) : (
                      <Tab as={Fragment} key={index}>
                        {({ selected }) => (
                          <button className="max-h-[25px] pl-2 pr-2 text-12-18-0.3 border-dashed-normal outline-none md:text-16-26-0.3 md:pr-4 md:pl-4">
                            <span
                              className={`tab-popup-unit ${
                                selected ? 'border-gold' : 'border-transparent'
                              }`}
                            >
                              {typeof title === 'function'
                                ? title(props)
                                : title}
                            </span>
                          </button>
                        )}
                      </Tab>
                    )
                  )}
                  <div className="absolute h-[27px] bg-white w-[5px] right-[-2px] top-0"></div>
                </div>
                <div className="flex items-end justify-end mb-4 md:mb-0">
                  <Print />
                  <div className="hidden mt-2 md:block">
                    <Share />
                  </div>
                  <button className="md:ml-4" onClick={onClose}>
                    <Image
                      src={iconClose}
                      alt="close popup"
                      width={15}
                      height={15}
                    />
                  </button>
                </div>
              </div>
            </Tab.List>
            <Tab.Panels>
              {renderConfig.map(({ render, skip, name }, index) =>
                skip ? (
                  <Tab.Panel className="outline-none" key={index} />
                ) : (
                  <Tab.Panel
                    key={index}
                    className="container outline-none pt-[180px] md:pt-[160px]"
                  >
                    {render(props, name)}
                  </Tab.Panel>
                )
              )}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Dialog>
    </LoadScriptNext>
  );
};

export default PopUpUnitBuilding;
