import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';

import share from '../public/images/share.svg';
import linkIcon from '../public/images/link-icon.svg';
import { ORIGIN } from 'src/constants';
import useSnackbar from './SnackBar/useSnackbar';

type shareProps = {
  visibility?: string;
  nameButton?: string;
  renderButton?: () => JSX.Element;
  copyData?: string;
};
const Share: React.FC<shareProps> = ({
  visibility = 'hidden',
  nameButton = 'Share',
  renderButton,
  copyData,
}) => {
  const { showSuccess } = useSnackbar();
  const router = useRouter();
  const [showIcon, setShowIcon] = useState(true);
  const data = copyData || `${ORIGIN}${router.asPath}`;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className="relative flex items-center uppercase cursor-pointer h-7 group">
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onClick={handleOpen}
        enterDelay={0}
        leaveTouchDelay={4000}
        title={
          <button
            className="flex w-32 px-2 m-0 text-16-26-0.3 border-0 border-gray-dark hover:text-gold-hover"
            onClick={() => {
              setOpen(false);
              navigator.clipboard.writeText(data);
              showSuccess({ message: 'Link copied' });
              setShowIcon(false);
            }}
          >
            <div className="pt-1.5">
              <Image src={linkIcon} alt="copy link" />
            </div>
            <span className="pl-1.5 pt-1 text-black">Copy Link</span>
          </button>
        }
      >
        {renderButton ? (
          renderButton()
        ) : (
          <div className="hover:bg-white">
            <div className="flex items-end gap-2 md:w-24 xl:w-[80px]">
              <Image src={share} alt="share" width={15} height={18} />
              <div
                className={`${visibility} text-black md:block hover:text-gold-hover text-14-18-0.3 md:text-16-16-0.3`}
              >
                {nameButton}
              </div>
            </div>
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default Share;
