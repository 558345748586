import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';

import useFilterPopup from '../../hooks/useFilterPopup';
import { FilterPopupProps } from '../../types';
import { ANY } from '../../constants/building/availableCondos';

const checkboxBeds = [ANY, '0', '1', '2', '3', '4', '5'];

const AnyBedsPopup: React.FC<FilterPopupProps> = ({
  onChange,
  initialValues,
  closePopup,
}) => {
  return (
    <Popover.Panel className="absolute z-50 transform -translate-x-1/2 outline-0">
      <Body
        onChange={onChange}
        initialValues={initialValues}
        closePopup={closePopup}
      />
    </Popover.Panel>
  );
};

const Body: React.FC<FilterPopupProps> = ({
  onChange,
  initialValues,
  closePopup,
}) => {
  const { setMaxValue, setMinValue, values, filterMaxValues, filterMinValues } =
    useFilterPopup({
      initialValues,
    });
  const [price, showPrice] = useState(true);
  const maxBedsRef = useRef<HTMLInputElement | any>();

  function validateAndSetMinBeds(value: any) {
    let min = ANY;
    if (!isNaN(value)) {
      if (Number(value) < 0) {
        min = '0';
      } else if (Number(value) > Number(values.max)) {
        min = values.max;
      } else {
        min = value;
      }
    }
    setMinValue(min);
  }

  function validateAndSetMaxBeds(value: any) {
    let max = ANY;
    if (!isNaN(value)) {
      if (Number(value) > Number(values.min)) {
        max = value;
      }
    }
    setMaxValue(max);
  }

  return (
    <Popover.Panel
      className="absolute z-50 transform -translate-x-1/2"
      ref={(ref: any) => ref === null && onChange(values)}
    >
      <div className="absolute z-50 mt-6 text-16-26-0.3 bg-white border -left-36 md:-left-0 border-gray">
        <div className="relative p-8 w-88 md:w-[360px]">
          <div className="absolute z-30 w-6 h-6 transform rotate-45 bg-white -translate-y-1/2 left-44 md:left-12 -top-0.1 border-t border-l border-gray" />
          <div className="flex flex-col pb-6 mb-[10px] border-b border-beige-dark">
            <div className="flex align-center">
              <input
                className="w-[137px] px-4 py-2 border h-11 border-beige-dark placeholder-black text-black"
                type="number"
                placeholder="Min beds"
                value={values.min}
                onChange={(event) => setMinValue(event.target.value)}
                onBlur={(event) => validateAndSetMinBeds(event.target.value)}
                onFocus={() => showPrice(true)}
              />
              <div className="self-center w-[8px] h-px mx-[8px] bg-black border" />
              <input
                className="w-[137px] px-4 py-2 border h-11 border-beige-dark placeholder-black text-black"
                type="number"
                ref={maxBedsRef}
                placeholder="Max beds"
                value={values.max}
                onChange={(event) => setMaxValue(event.target.value)}
                onBlur={(event) => validateAndSetMaxBeds(event.target.value)}
                onFocus={() => showPrice(false)}
              />
            </div>
          </div>
          <div className="flex space-x-7">
            <div className="flex flex-col w-1/2">
              {price && (
                <>
                  {filterMinValues(checkboxBeds).map((check, index) => (
                    <button
                      key={index}
                      className="flex items-center mt-2 xl:mt-0 text-14-26-0.3 text-gold-darker hover:text-black focus:text-black"
                      onClick={() => {
                        validateAndSetMinBeds(check);
                        maxBedsRef.current.focus();
                      }}
                    >
                      {check}
                    </button>
                  ))}
                </>
              )}
            </div>
            <div className="flex flex-col w-1/2">
              {!price && (
                <>
                  {filterMaxValues(checkboxBeds).map((check, index) => (
                    <button
                      key={index}
                      className="flex items-center mt-2 xl:mt-0 text-14-26-0.3 text-gold-darker hover:text-black focus:text-black"
                      onClick={() => {
                        setMaxValue(check);
                        showPrice(true);
                        setTimeout(closePopup, 0);
                      }}
                    >
                      {check}
                    </button>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popover.Panel>
  );
};

export default AnyBedsPopup;
