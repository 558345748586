import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import classnames from 'classnames';

import { useSavedSearch } from 'components/SavedHouses/SavedSearchContext';
import Buy from 'components/Header/Buy';
import Rent from 'components/Header/Rent';
import AboutUs from 'components/Header/AboutUs';
import imageLogo from '../../public/images/logo.svg';
import profile from '../../public/images/profile.svg';
import Neighborhoods from '../Header/Neighborhoods/Neighborhoods';
import Buildings from '../Header/Buildings';

import PopUpContactUs from '../ContactUs/PopUpContactUs';
import { useAuth } from '../Auth/AuthProvider';
import { useFavorite } from 'components/SavedHouses/SavedFavoritesContext';
import HeaderJoinOrSignButton from 'components/Auth/HeaderJoinOrSignButton';
import { NeighborhoodsResponse } from 'apolloClient/types/Neighborhood';
import { BuildingsResponse } from 'apolloClient/types';
import apiHost from 'lib/apiHost';

export type HeaderData = {
  neighborhoods: NeighborhoodsResponse;
  buildings: BuildingsResponse;
};

export type HeaderDataSet = {
  data?: HeaderData;
  fetchMoreBuildings: (params: { page?: number; search?: string }) => void;
  fetchMoreNhds: (search: string) => void;
};

type NavigationProps = {
  hidden?: string;
  isDesktop?: boolean;
  active: any;
  setActive: any;
  dataSet: HeaderDataSet;
};

const Navigation: React.FC<NavigationProps> = ({
  hidden,
  setActive,
  dataSet,
  isDesktop,
}) => {
  const { me, isLogged, logout } = useAuth();

  const { data, fetchMoreBuildings, fetchMoreNhds } = dataSet || {};
  const getBlogLink = (params: string) => {
    return `/blog/${params}`;
  };
  const { buildings, neighborhoods } = data || {};
  const { count: searchesCount } = useSavedSearch();
  const { count: favoritesCount } = useFavorite();

  type WrapperProps = {
    condition?: boolean;
    wrapper: any;
    children?: React.ReactNode;
  };
  return (
    <nav
      className={`flex w-full flex-col text-18-23-0.3 xl:text-14-18-0.3 px-[30.5px] md:pl-[48px] xl:px-0 items-center justify-end xl:flex-row space-y-4 xl:space-y-0 ${hidden}`}
    >
      <div
        className={classnames(
          { 'flex items-center ': isDesktop },
          {
            'flex w-full flex-col text-20-28-0.3 space-y-4 mb-[-24px]':
              !isDesktop,
          }
        )}
      >
        <div className="flex w-full pt-2 pr-2 text-14-18-0.3 xl:hidden mb-[12px] h-[47px]">
          <Link
            href="/"
            passHref
            onClick={() => setActive(false)}
            className="pt-[16px] md:pt-[11px] pb-3 pl-[42px] w-[215px] lg:w-60"
          >
            <Image src={imageLogo} alt="logo" priority />
          </Link>
        </div>
        {me?.isAdmin && (
          <div className="relative w-full h-auto pb-3 border-b border-dashed xl:pb-0 border-gold-dark xl:w-auto xl:border-b-0 group">
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={`${apiHost}/admin`}
              className="relative flex pr-6 cursor-pointer text-gold-hover hover:text-gold-hover close-building"
            >
              Admin
            </a>
          </div>
        )}
        <Buy />
        <Rent />
        <Neighborhoods
          fetchMoreNhds={fetchMoreNhds}
          neighborhoods={neighborhoods}
        />
        <Buildings
          fetchMoreBuildings={fetchMoreBuildings}
          buildings={buildings}
        />
        <AboutUs />
        <a
          href="/blog"
          onClick={() => setActive(false)}
          className={`
            w-full h-auto pb-3 pr-3 text-black border-b border-dashed xl:pb-0 xl:inline hover:text-gold-hover border-gold-dark xl:w-auto xl:border-b-0 
            text-18-23-0.3 xl:text-14-18-0.3
          `}
        >
          Blog
        </a>
      </div>
      <span className="hidden w-px mr-2.5 ml-2.5 h-9 bg-beige xl:inline-block" />

      <div className="w-full pl-[4px] xl:inline xl:w-auto">
        <div className="w-full mt-20 py-1.5 pr-[2px] border-t border-b border-dashed xl:inline border-gold-dark xl:w-auto xl:border-b-0 xl:border-t-0">
          {isLogged ? (
            <div className="relative w-full h-auto xl:pb-0 md:pb-0 lg:pt-[17px] group md:border-gold-dark xl:w-auto xl:border-b-0">
              <div className="cursor-pointer ">
                <div className="flex items-center">
                  <Image src={profile} alt="profile" width={30} height={30} />
                  <div className="ml-2 text-black hover:text-gold-hover">
                    {me?.fullName}
                  </div>
                </div>
              </div>
              <div className="absolute z-40 hidden bg-white border md:w-[170px] group-hover:block lg:-left-2 top-8 lg:top-[57px] border-gray text-16-34-0.3">
                <div className="absolute w-full h-[41px] bg-transparent bottom-full"></div>
                <div className="relative">
                  <div className="absolute w-3 h-3 bg-white -top-1.7 left-14 border-t border-l border-gray transform rotate-45" />
                  <div className="flex flex-col items-start px-[17px] py-[9px] text-left">
                    <div className="w-full cursor-pointer hover:text-gold-hover">
                      <a href="/saved-searches">
                        My searches ({searchesCount})
                      </a>
                    </div>
                    <div className="w-full cursor-pointer hover:text-gold-hover">
                      <a href="/myfavs">My favorites ({favoritesCount})</a>
                    </div>
                    <div className="w-full cursor-pointer hover:text-gold-hover">
                      <a href="/profile">Profile Settings</a>
                    </div>
                    <div className="w-full cursor-pointer hover:text-gold-hover">
                      <a onClick={logout}>Log out</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <HeaderJoinOrSignButton showIcon nameButton="Join / Sign in" />
          )}
        </div>
      </div>
      <div className="w-full xl:hidden mb-[6px]">
        <div className="w-full lg:w-2/3">
          <PopUpContactUs
            gtmId="Contact_Us_Start_Header"
            flagContainer="pr-10 pb-[7px] pt-[7px] md:pt-[7px] pr-[39px]"
            showName="inline-block"
            phoneNumber="inline-block"
            phoneIcon="hidden"
            flagPosition="-right-4"
          />
        </div>
      </div>
      <div className="items-start w-full pb-10 xl:hidden">
        <div className="flex justify-between w-2/3 py-4 pr-6 lg:px-2">
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.youtube.com/c/BlackbookPropertiesMiamiRealEstateLifestyle"
          >
            <img
              src="/images/youtube.svg"
              alt="youtube"
              className="hover:opacity-60"
            />
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.instagram.com/blackbookproperties/"
          >
            <img
              src="/images/instagram.svg"
              alt="instagram"
              className="hover:opacity-60"
            />
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.linkedin.com/company/1882124"
          >
            <img
              src="/images/linkedin.svg"
              alt="linkedin"
              className="w-6 h-6 hover:opacity-60"
            />
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.tiktok.com/@blackbookproperties"
          >
            <img
              src="/images/tiktok.svg"
              alt="tiktok"
              className="w-6 h-6 hover:opacity-60"
            />
          </a>
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.facebook.com/BlackbookProperties/"
          >
            <img
              src="/images/facebook.svg"
              alt="facebook"
              className="hover:opacity-60"
            />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
