import React from 'react';

import { ANY } from '../../../../constants/building/availableCondos';

import CbbDropdown from '../../../CbbDropdown';

import { SetFiltersValues } from '../../types';

interface Props {
  initialValue: string;
  setValues: SetFiltersValues;
}

const Parking: React.FC<Props> = ({ initialValue, setValues }) => {
  return (
    <div className="w-[152px] md:w-[137px]">
      <label htmlFor="" className="md:text-14-26-0.3">
        Parking
      </label>
      <div className="flex align-center leading-[21px]">
        <CbbDropdown
          showPlus
          initialSelectedOption={initialValue}
          rootClassName="w-[152px] md:w-[137px] pt-[8px] pb-[9px] pl-[16px] h-[44px] md:w-36 text-black"
          selectClassName="w-[152px] md:w-[137px] h-[44px] -left-0.1"
          nameSelected="Any"
          options={[ANY, '1', '2', '3', '4', '5']}
          onChange={(value) =>
            setValues((values) => ({
              ...values,
              parkingPlaces: value.replace(/'+'/g, ''),
            }))
          }
        />
      </div>
    </div>
  );
};

export default Parking;
