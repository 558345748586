import React from 'react';
import { BreadcrumbList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { ORIGIN } from 'src/constants';

interface BuildingAppartmentComplexSchemaProps {
  options: {
    neighborhoodId?: string;
    neighborhoodName?: string;
    buildingId?: string;
    buildingName?: string;
    unitName?: string;
    unitUrl?: string;
  };
}

const BuildingAppartmentComplexSchema: React.FC<BuildingAppartmentComplexSchemaProps> =
  ({ options }) => {
    const {
      neighborhoodId,
      neighborhoodName,
      buildingId,
      buildingName,
      unitName,
      unitUrl,
    } = options;

    const listItems: { url: string; name: string }[] = [];

    neighborhoodId &&
      neighborhoodName &&
      listItems.push({
        url: `${ORIGIN}`,
        name: neighborhoodName,
      });
    neighborhoodId &&
      neighborhoodName &&
      listItems.push({
        url: `${ORIGIN}/${neighborhoodId}`,
        name: neighborhoodName,
      });
    buildingId &&
      buildingName &&
      listItems.push({
        url: `${ORIGIN}/${neighborhoodId}/${buildingId}`,
        name: buildingName,
      });
    unitUrl && unitName && listItems.push({ url: unitUrl, name: unitName });

    const breadcrumbs: BreadcrumbList = {
      '@type': 'BreadcrumbList',
      itemListElement: listItems.map(
        ({ name, url }, index): ListItem => ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@id': url,
            name,
          },
        })
      ),
    };

    return (
      <Helmet
        script={[
          helmetJsonLdProp<BreadcrumbList>({
            '@context': 'https://schema.org',
            ...breadcrumbs,
          }),
        ]}
      />
    );
  };

export default BuildingAppartmentComplexSchema;
