import { gql } from '@apollo/client';

export const ALERTS_UNSUBSCRIBE = gql`
  mutation ($email: String!, $type: String!, $searchId: String) {
    unsubscribe(email: $email, type: $type, searchId: $searchId)
  }
`;

export const SUBSCRIBE_FOR_BLOG_ALERTS = gql`
  mutation ($email: String!, $leadId: String, $browserUUID: String) {
    subscribeUserForBlog(
      email: $email
      leadId: $leadId
      browserUUID: $browserUUID
    )
  }
`;

export const CHANGE_BLOG_SUBSCRIPTION_FREQUENCY = gql`
  mutation ($email: String!, $frequency: String!) {
    changeBlogSubscriptionFrequency(email: $email, frequency: $frequency)
  }
`;

export const SEND_CONTACT_US_FORM = gql`
  mutation (
    $Name: String!
    $Email: String!
    $Phone: String
    $BuildingName: String
    $BuildingAvgPrice: Float
    $IsPreConstruction: Boolean
    $Comments: String!
    $PageName: String
    $LeadType: String
    $BuildingAndUnit: String
    $Neighborhood: String
    $City: String
    $UnitPrice: String
    $UnitPriceInt: Int
    $UnitUrl: String
    $UnitAddress: String
    $UnitNumber: String
    $UnitZip: String
    $PpcUser: Boolean
    $RecaptchaToken: String
    $FormLoaded6tY4bPYk: String
    $PhoneNumber6tY4bPYk: String
    $agent: String
    $leadId: String
    $pageUrl: String
    $browserUUID: String
  ) {
    sendContactUsForm(
      buildingAndUnit: $BuildingAndUnit
      buildingName: $BuildingName
      buildingAvgPrice: $BuildingAvgPrice
      isPreConstruction: $IsPreConstruction
      city: $City
      comments: $Comments
      email: $Email
      leadType: $LeadType
      name: $Name
      neighborhood: $Neighborhood
      pageName: $PageName
      phone: $Phone
      ppcUser: $PpcUser
      recaptchaToken: $RecaptchaToken
      unitAddress: $UnitAddress
      unitNumber: $UnitNumber
      unitPrice: $UnitPrice
      unitPriceInt: $UnitPriceInt
      unitUrl: $UnitUrl
      unitZip: $UnitZip
      formLoaded6tY4bPYk: $FormLoaded6tY4bPYk
      phoneNumber6tY4bPYk: $PhoneNumber6tY4bPYk
      agent: $agent
      leadId: $leadId
      pageUrl: $pageUrl
      browserUUID: $browserUUID
    )
  }
`;
