import React from 'react';

import { Neighborhood } from 'apolloClient/types';
import PageBuilderContentParser from '../../PageBuilderContentParser';

const AboutNeighborhood: React.FC<{ neighborhood: Neighborhood }> = ({
  neighborhood,
}) => {
  const { name, description } = neighborhood;

  if (!description) return null;
  return (
    <>
      <div className="absolute w-screen h-[466px] md:h-[676px] lg:h-[764px] z-[-1] bg-beige-light" />
      <section className="container flex flex-col items-center mt-[100px] md:mt-0 md:pb-4 md:pt-[10px] lg:py-2 lg:pb-[29px] text-m">
        <div className="flex flex-col mx-auto mb-4 lg:mb-2">
          <h2 className="my-6 text-30-36-1 text-center uppercase lg:mt-6 lg:mb-16 md:text-39-47-1.3 font-sohneBreitHalbfett">
            Welcome to {name}
          </h2>
        </div>
        <div className="w-full neighborhood-builder">
          <PageBuilderContentParser data={description} />
        </div>
      </section>
    </>
  );
};

export default AboutNeighborhood;
