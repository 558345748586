import React, { useMemo } from 'react';

import { FiltersValues, SetFiltersValues } from '../../types';
import { useAuth } from 'components/Auth/AuthProvider';

import MultiSelect from 'components/MultiSelect';

import { ANY } from '../../../../constants/building/availableCondos';

type KeyVal = { [index: string]: keyof FiltersValues };

export enum PageType {
  building = 'building',
  search = 'search',
}

const checkboxes: KeyVal = {
  'Show Pending Listings': 'showPendingListings',
};
const searchCheckboxes: KeyVal = {
  'Accepts Bitcoin': 'bitcoin',
  'Seller Financing': 'sellerFinancing',
  'Allows Short Term Rentals': 'shortTermRent',
};

interface Props {
  values: FiltersValues;
  setValues: SetFiltersValues;
  page: PageType;
}

const adminCheckboxes: KeyVal = {
  'Rental Investments': 'rentalInvestments',
};

const SpecialTerms: React.FC<Props> = ({ values, setValues, page }) => {
  const { me } = useAuth();
  const { forSale, forRent, sold } = values;
  const checkboxesList = useMemo(() => {
    if (page === PageType.search) {
      const searchPageInputs = searchCheckboxes;
      if (me?.isAdmin) {
        Object.assign(searchPageInputs, {
          'Short Term Rentals': 'shortTermRentTerms',
          ...adminCheckboxes,
        });
      }
      return Object.assign(checkboxes, searchPageInputs);
    }
    return checkboxes;
  }, [me?.isAdmin, page]);

  const onSelectChange = (terms: string[]) => {
    setValues((values) => ({
      ...values,
      shortTermRentTerms: terms as string[],
    }));
  };

  const show = forSale || (sold && !forRent);

  return (
    <>
      {show && (
        <div className="w-full mb-6 pt-6 md:w-2/3 border-beige-dark border-t">
          <div className="md:text-14-26-0.3">Special terms:</div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            {Object.keys(checkboxesList).map((key) =>
              key === 'Short Term Rentals' && me?.isAdmin ? (
                !!values['shortTermRent'] && (
                  <div
                    key={key}
                    className="flex flex-col my-2 text-14-26-0.3 md:col-span-2"
                  >
                    <div className="morefilters w-full md:w-[298px]">
                      <label htmlFor="" className="md:text-14-26-0.3">
                        Allow Short Term Rentals At Least
                      </label>
                      <div className="flex align-center leading-[21px]">
                        <MultiSelect
                          options={[
                            { label: 'Any', value: ANY },
                            { label: '2 times/year', value: '2' },
                            { label: '3-6 times/year', value: '3-6' },
                            { label: '12 times/year', value: '12' },
                            { label: '365 times/year', value: '365' },
                          ]}
                          value={values.shortTermRentTerms}
                          onSelectChange={onSelectChange}
                        />
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div
                  key={key}
                  className="flex items-center mt-[12px] md:mt-[8px] mb-[4px] text-16-26-0.3 md:text-14-26-0.3"
                >
                  <input
                    checked={!!values[checkboxesList[key]]}
                    onChange={({ target }) =>
                      setValues((values) => ({
                        ...values,
                        [checkboxesList[key]]: target.checked,
                      }))
                    }
                    type="checkbox"
                    className="mr-3 input-checkbox custom-checkbox whitespace-nowrap"
                  />
                  <label className="whitespace-nowrap" htmlFor="">
                    {key}
                  </label>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SpecialTerms;
