import { gql } from '@apollo/client';

export const NEIGHBORHOOD_BY_SLUG = gql`
  query NeighborhoodBySlug($slug: String!) {
    findSlug(modelName: "neighborhood", slug: $slug) {
      ... on NeighborhoodEntityResponse {
        data {
          id
          attributes {
            name
            slug
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NEIGHBORHOOD_AND_BLOGS_BY_SLUG = gql`
  query NeighborhoodAndBlogsBySlug(
    $slug: String!
    $blogLimit: Int
    $neighborsLimit: Int
    $commuteTimesRightLimit: Int = 50
  ) {
    blogs(
      filters: {
        neighborhoods: {
          or: [{ slug: { ne: $slug } }, { slug: { null: true } }]
        }
      }
      pagination: { pageSize: $blogLimit }
      sort: ["publishedDate:DESC"]
    ) {
      data {
        attributes {
          slug
          title
          publishedDate
          shortDescription
          mainImage {
            data {
              attributes {
                url
                formats
              }
            }
          }
        }
      }
    }
    findSlug(modelName: "neighborhood", slug: $slug) {
      ... on NeighborhoodEntityResponse {
        data {
          id
          attributes {
            slug
            name
            description
            city {
              data {
                attributes {
                  name
                }
              }
            }
            image {
              data {
                attributes {
                  url
                  formats
                }
              }
            }
            coordinates
            neighborhoodStats {
              minSalePrice
              maxSalePrice
              saleCount
              averageSalePrice
              averageSaleSqftPrice
              averageRentalPrice
              averageRentalSqftPrice
            }
            commuteTimesLeft {
              timeFirst
              destinationFirst
              transportFirst
              timeSecond
              destinationSecond
              transportSecond
            }
            commuteTimesRight(
              pagination: { pageSize: $commuteTimesRightLimit }
            ) {
              time
              destination
            }
            mainFeaturesCommon {
              type
              description
            }
            walkScore
            walkDescription
            bikeScore
            bikeDescription
            transitScore
            transitDescription
            neighborhoodsNeighbors(pagination: { pageSize: $neighborsLimit }) {
              data {
                attributes {
                  neighborhoodStats {
                    minSalePrice
                    maxSalePrice
                    saleCount
                  }
                  name
                  slug
                  image {
                    data {
                      attributes {
                        url
                        formats
                      }
                    }
                  }
                }
              }
            }
            blogs(
              pagination: { pageSize: $blogLimit }
              sort: "publishedDate:DESC"
            ) {
              data {
                attributes {
                  mainImage {
                    data {
                      attributes {
                        url
                        formats
                      }
                    }
                  }
                  blogTags {
                    data {
                      attributes {
                        label
                      }
                    }
                  }
                  shortDescription
                  title
                  slug
                  publishedDate
                  pageContent
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NEIGHBORHOOD_PREVIEW_AND_BLOGS_BY_SLUG = gql`
  query NeighborhoodAndBlogsBySlug(
    $slug: String!
    $blogLimit: Int
    $neighborsLimit: Int
    $unsavedChangeId: ID!
  ) {
    blogs(
      filters: {
        neighborhoods: {
          or: [{ slug: { ne: $slug } }, { slug: { null: true } }]
        }
      }
      pagination: { pageSize: $blogLimit }
      sort: ["publishedDate:DESC"]
    ) {
      data {
        attributes {
          slug
          title
          publishedDate
          shortDescription
          mainImage {
            data {
              attributes {
                url
                formats
              }
            }
          }
        }
      }
    }
    findSlug(
      modelName: "neighborhood"
      slug: $slug
      publicationState: "preview"
    ) {
      ... on NeighborhoodEntityResponse {
        data {
          id
          attributes {
            slug
            name
            description
            city {
              data {
                attributes {
                  name
                }
              }
            }
            image {
              data {
                attributes {
                  url
                  formats
                }
              }
            }
            coordinates
            neighborhoodStats {
              minSalePrice
              maxSalePrice
              saleCount
              averageSalePrice
              averageSaleSqftPrice
              averageRentalPrice
              averageRentalSqftPrice
            }
            commuteTimesLeft {
              timeFirst
              destinationFirst
              transportFirst
              timeSecond
              destinationSecond
              transportSecond
            }
            commuteTimesRight {
              time
              destination
            }
            mainFeaturesCommon {
              type
              description
            }
            walkScore
            walkDescription
            bikeScore
            bikeDescription
            transitScore
            transitDescription
            neighborhoodsNeighbors(pagination: { pageSize: $neighborsLimit }) {
              data {
                attributes {
                  neighborhoodStats {
                    minSalePrice
                    maxSalePrice
                    saleCount
                  }
                  name
                  slug
                  image {
                    data {
                      attributes {
                        url
                        formats
                      }
                    }
                  }
                }
              }
            }
            blogs(
              pagination: { pageSize: $blogLimit }
              sort: "publishedDate:DESC"
            ) {
              data {
                attributes {
                  mainImage {
                    data {
                      attributes {
                        url
                        formats
                      }
                    }
                  }
                  blogTags {
                    data {
                      attributes {
                        label
                      }
                    }
                  }
                  shortDescription
                  title
                  slug
                  publishedDate
                  pageContent
                }
              }
            }
          }
        }
      }
    }
    previewButtonUnsavedChange(id: $unsavedChangeId) {
      data {
        attributes {
          data
        }
      }
    }
  }
`;

export const NEIGHBORHOOD_CONDOS = gql`
  query NeigborhoodCondos(
    $nhSlug: String
    $page: Int
    $pageSize: Int
    $sorting: [String]
  ) {
    buildings(
      filters: {
        neighborhood: { slug: { eq: $nhSlug }, updatedAt: { notNull: true } }
        buildingStats: { minSalePrice: { notNull: true } }
      }
      pagination: { page: $page, pageSize: $pageSize }
      sort: $sorting
    ) {
      data {
        id
        attributes {
          slug
          name
          mainImage {
            data {
              attributes {
                url
                formats
              }
            }
          }
          isPreconstruction
          primaryAddress
          buildingStats {
            minSalePrice
            maxSalePrice
            minSaleBeds
            maxSaleBeds
          }
          yearBuilt
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const BUILDINGS_FOR_MAP = gql`
  query BuildingsForMap($id: ID) {
    buildings(filters: { neighborhood: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          name
          primaryAddress
          lat
          lon
        }
      }
    }
  }
`;

export const NEIGHBORHOODS_COORDINATES = gql`
  query NhCoordinates {
    neighborhoods(pagination: { pageSize: 1000 }) {
      data {
        id
        attributes {
          name
          coordinates
        }
      }
    }
  }
`;

export const NEIGHBORHOOD_ITEMS_LIST = gql`
  query NhItemsList($pageSize: Int, $page: Int) {
    neighborhoods(pagination: { pageSize: $pageSize, page: $page }) {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;
