import React, { createContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import type { GetStaticProps } from 'next';

import { client } from 'apolloClient/client';
import {
  NEIGHBORHOOD_AND_BLOGS_BY_SLUG,
  NEIGHBORHOOD_ITEMS_LIST,
} from 'apolloClient/queries/neighborhood';
import { Blog as BlogType, BlogsResponse } from 'apolloClient/types/Blog';
import { ApolloResponseWithSlug } from 'types';
import {
  Neighborhood,
  NeighborhoodResponse,
} from 'apolloClient/types/Neighborhood';
import { notfoundRedirect } from 'constants/notFoundParams';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from 'lib/flattenStrapiBulkDataItems';

import {
  RequestUrl,
  StrapiBulkResponseData,
  StrapiResponse,
} from '../../apolloClient/types';

import NeighborhoodPage from '../../components/Neighborhood/NeighborhoodPage';
import { Entity, ResponseCollection } from 'apolloClient/types/common';
import { useRouter } from 'next/router';
import { DEFAULT_REVALIDATE_INTERVAL, ORIGIN } from 'src/constants';
import { Agent, AgentsResponse } from '../../apolloClient/types/Agents';
import { LOAD_AGENTS_FOR_EXPERTS_SECTION } from '../../apolloClient/queries/agents';
import { LOAD_HOME_PAGE_BLOGS } from 'apolloClient/queries/blogs';

type NeighborhoodApolloResponse = ApolloQueryResult<
  ApolloResponseWithSlug<NeighborhoodResponse> & {
    blogs: StrapiResponse<StrapiBulkResponseData<BlogType>>;
  }
>;

export interface NeighborhoodPageContextInterface {
  neighborhood: Neighborhood;
  url: RequestUrl;
  experts: Agent[];
  globalBlogs: BlogType[];
}

export const NeighborhoodPageContext =
  createContext<NeighborhoodPageContextInterface | null>(null);

export interface NeighborhoodPageProps {
  neighborhood: NeighborhoodApolloResponse;
  experts: Agent[];
  globalBlogs: BlogType[];
}

const NeighborhoodComponent: React.FC<NeighborhoodPageProps> = ({
  neighborhood,
  experts,
  globalBlogs,
}) => {
  const neighborhoodData = flattenStrapiDataItem(
    neighborhood.data.findSlug.data
  );

  const { slug } = neighborhoodData;

  const { asPath } = useRouter();

  if (!slug) return null;

  return (
    <NeighborhoodPageContext.Provider
      value={{
        neighborhood: neighborhoodData,
        url: `${ORIGIN}${asPath}`,
        experts,
        globalBlogs,
      }}
    >
      <NeighborhoodPage />
    </NeighborhoodPageContext.Provider>
  );
};

export default NeighborhoodComponent;

export const getStaticProps: GetStaticProps<
  { neighborhood?: NeighborhoodApolloResponse },
  { urlParam: string }
> = async ({ params }) => {
  const urlParam = params?.urlParam as string;

  const query = client.watchQuery<
    ApolloResponseWithSlug<NeighborhoodResponse> & {
      blogs: StrapiResponse<StrapiBulkResponseData<BlogType>>;
    }
  >({
    query: NEIGHBORHOOD_AND_BLOGS_BY_SLUG,
    variables: {
      slug: urlParam,
      blogLimit: 3,
      neighborsLimit: 3,
    },
    fetchPolicy: 'network-only',
  });

  const result = await query.result();

  const expertsResponse = await client.query<{ agents: AgentsResponse }>({
    query: LOAD_AGENTS_FOR_EXPERTS_SECTION,
  });

  const blogsResponse = await client.query<{ blogs: BlogsResponse }>({
    query: LOAD_HOME_PAGE_BLOGS,
  });

  return result?.data?.findSlug?.data?.attributes?.slug
    ? {
        props: {
          neighborhood: result,
          experts: flattenStrapiBulkDataItems(
            expertsResponse.data.agents?.data
          ),
          globalBlogs: flattenStrapiBulkDataItems(
            blogsResponse.data.blogs?.data
          ),
        },
        revalidate: DEFAULT_REVALIDATE_INTERVAL,
      }
    : notfoundRedirect;
};

export async function getStaticPaths() {
  const pageSize = 100;
  const query = client.watchQuery<{
    neighborhoods: ResponseCollection<Neighborhood>;
  }>({
    query: NEIGHBORHOOD_ITEMS_LIST,
    fetchPolicy: 'network-only',
  });
  let page = 1;
  let finished = false;
  let result: Entity<Neighborhood>[] = [];

  while (!finished) {
    const response = await query.refetch({ pageSize, page });
    result = result.concat(response.data.neighborhoods.data);
    page += 1;
    if (response.data.neighborhoods.data.length < pageSize) {
      finished = true;
    }
  }

  const paths = result
    .filter((nh) => nh.attributes.slug)
    .map((nh) => ({
      params: { urlParam: nh.attributes.slug },
    }));

  return { paths: [], fallback: 'blocking' };
}
