import React, { createContext, useEffect, useMemo, useState } from 'react';
import { GetServerSideProps } from 'next';

import {
  Building,
  EntityWithId,
  Neighborhood,
  RequestUrl,
} from 'apolloClient/types';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from 'lib/flattenStrapiBulkDataItems';
import { Blog, Blog as BlogType } from 'apolloClient/types/Blog';
import { client } from 'apolloClient/client';
import {
  getUnitPageQuery,
  GET_IS_LISTING_FAVS,
  NEARBY_CONDOS,
  NEARBY_NEIGHBOR_CONDOS,
  RequestPropertyType,
} from 'apolloClient/queries/unit';
import {
  Listing,
  ListingCollectionResponse,
  Unit,
} from 'apolloClient/types/Units';
import { Entity, ResponseCollection } from 'apolloClient/types/common';
import { CalculatedNearbyCondosParams } from 'lib/nearbyCondosParams';
import { notfoundRedirect } from 'constants/notFoundParams';
import { ORIGIN } from 'src/constants';

import { City } from '../../../../apolloClient/types/City';

import UnitPage from '../../../../components/Unit/UnitPage';
import { useRouter } from 'next/router';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth, useOnLoggedIn } from 'components/Auth/AuthProvider';
import { useFavorite } from 'components/SavedHouses/SavedFavoritesContext';
import { updateListingFavoritesQuery } from 'src/utils/updateListingFavoritesQuery';
import { FavoriteListingsResponse } from 'apolloClient/types/FavoriteListing';
import { getPropertyTypeByCode } from 'src/utils/getPropertyTypeByCode';
import { getNearbyCondosParamsUnit } from 'lib/nearbyCondosParamsUnit';
import { BuildingPageContextInterface } from 'apolloClient/types/Building';
import { Agent, AgentsResponse } from 'apolloClient/types/Agents';
import { LOAD_AGENTS_FOR_EXPERTS_SECTION } from 'apolloClient/queries/agents';

type UnitPageUnitResponse = {
  listings: ListingCollectionResponse;
  blogs?: ResponseCollection<Blog>;
};

interface UnitPageProps {
  listing: EntityWithId<Listing>;
  blogs: BlogType[];
  neighborhood: Neighborhood;
  building: Building;
  unit: EntityWithId<Unit>;
  city: City;
  url: RequestUrl;
  experts: Agent[];
}

export interface UnitPageContextInterface {
  nearbyCondos: Entity<Listing>[];
  calculatedParams: CalculatedNearbyCondosParams;
  blogs: Blog[];
  listing: EntityWithId<Listing>;
  building: Building;
  unit: EntityWithId<Unit>;
  neighborhood: Neighborhood;
  city: City;
  nearbyCondosTotal: number;
  url: RequestUrl;
  favorite?: FavoriteListingsResponse;
  experts: Agent[];
}

export const UnitPageContext = createContext<UnitPageContextInterface | null>(
  null
);

const UnitComponent: React.FC<UnitPageProps> = ({
  listing,
  blogs,
  building,
  unit,
  neighborhood,
  city,
  experts,
}) => {
  const { asPath } = useRouter();
  const { getActualAuthData } = useAuth();
  const { latestAction } = useFavorite();

  const [favorite, setFavorite] = useState(unit.favorite);

  const buildingStats = building?.buildingStats;

  const nearbyCondosParams = getNearbyCondosParamsUnit({
    minAvgCoef: 0.8,
    maxAvgCoef: 1.2,
    sqft: listing.sqft,
    price: listing.price,
  });

  const [
    fetchNearbyCondos,
    {
      data: nearbyCondosData,
      updateQuery: updateNearbyCondos,
      refetch: refetchNearbyCondos,
    },
  ] = useLazyQuery<{
    listings: ListingCollectionResponse;
  }>(NEARBY_CONDOS, {
    variables: {
      ...nearbyCondosParams,
      propertyTypeList: [listing?.propertyTypeCode],
      listingId: listing?.id,
      neighborhoodId: neighborhood?.id,
    },
  });

  const [fetchIsfavs, { data: favoritesData }] = useLazyQuery<
    {
      favoriteListings: {
        data: [
          {
            id: number;
          }
        ];
      };
    },
    {
      userId: number;
      listingId: number;
      type: string;
    }
  >(GET_IS_LISTING_FAVS);

  useEffect(() => {
    const authData = getActualAuthData();
    fetchNearbyCondos({
      variables: {
        ...nearbyCondosParams,
        propertyTypeList: [listing?.propertyTypeCode],
        listingId: listing?.id,
        neighborhoodId: neighborhood?.id,
        userId: authData.user.id || -1,
      },
    });
  }, []);

  const [
    fetchNearbyNeigborsCondos,
    {
      data: nearbyNeigborsCondos,
      updateQuery: updateNearbyNeigborsCondos,
      refetch: refetchNearbyNeigborsCondos,
    },
  ] = useLazyQuery<{
    listings: ListingCollectionResponse;
  }>(NEARBY_NEIGHBOR_CONDOS, {
    variables: {
      ...nearbyCondosParams,
      propertyTypeList: [listing?.propertyTypeCode],
      listingId: listing?.id,
      neighborhoodId: neighborhood?.id,
    },
  });

  useEffect(() => {
    if (!nearbyCondosData) return;
    if (nearbyCondosData && nearbyCondosData?.listings?.data?.length > 3)
      return;
    const authData = getActualAuthData();
    fetchNearbyNeigborsCondos({
      variables: {
        ...nearbyCondosParams,
        propertyTypeList: [listing?.propertyTypeCode],
        listingId: listing?.id,
        neighborhoodId: neighborhood?.id,
        userId: authData.user.id || -1,
      },
    });
  }, [nearbyCondosData]);

  useEffect(() => {
    if (!favoritesData) return;
    const { favoriteListings } = favoritesData;
    if (!favoriteListings.data.length) {
      return setFavorite({ data: [] });
    }
    const favorite = favoriteListings.data[0];
    setFavorite({
      data: [
        {
          ...favorite,
          attributes: {
            type: getPropertyTypeByCode(listing.propertyTypeCode),
            unit: {
              data: {
                id: unit.id,
                attributes: { ...unit },
              },
            },
          },
        },
      ],
    });
  }, [favoritesData]);

  useOnLoggedIn(() => {
    const authData = getActualAuthData();
    if (nearbyCondosData?.listings?.data?.length) {
      refetchNearbyCondos({
        userId: authData.user.id || -1,
      });
    }
    if (nearbyNeigborsCondos?.listings?.data?.length) {
      refetchNearbyNeigborsCondos({
        userId: authData.user.id || -1,
      });
    }
    fetchIsfavs({
      variables: {
        listingId: listing.id,
        userId: authData.user.id || -1,
        type: getPropertyTypeByCode(listing.propertyTypeCode),
      },
    });
  });

  useEffect(() => {
    if (latestAction) {
      if (latestAction.unitId === unit.id) {
        return setFavorite({
          data:
            latestAction.action === 'create'
              ? [
                  {
                    id: latestAction.favoriteId,
                    attributes: {
                      type: getPropertyTypeByCode(listing.propertyTypeCode),
                      unit: {
                        data: {
                          attributes: { ...unit },
                          id: unit.id,
                        },
                      },
                    },
                  },
                ]
              : [],
        });
      }
      if (
        nearbyCondosData?.listings?.data?.some(
          ({
            id,
            attributes: {
              propertyTypeCode,
              unit: {
                data: { id: unitId },
              },
            },
          }) =>
            latestAction.unitId === unitId &&
            getPropertyTypeByCode(propertyTypeCode) === latestAction.type
        )
      ) {
        updateNearbyCondos(
          updateListingFavoritesQuery(latestAction, 'listings')
        );
      }
      if (
        nearbyNeigborsCondos?.listings?.data?.some(
          ({
            id,
            attributes: {
              propertyTypeCode,
              unit: {
                data: { id: unitId },
              },
            },
          }) =>
            latestAction.unitId === unitId &&
            getPropertyTypeByCode(propertyTypeCode) === latestAction.type
        )
      ) {
        updateNearbyNeigborsCondos(
          updateListingFavoritesQuery(latestAction, 'listings')
        );
      }
    }
  }, [latestAction]);

  const { nearbyCondos, nearbyCondosTotal } = useMemo(() => {
    return {
      nearbyCondos: (nearbyCondosData?.listings?.data || [])
        .concat(nearbyNeigborsCondos?.listings?.data || [])
        .slice(0, 12),
      nearbyCondosTotal:
        Number(nearbyCondosData?.listings?.meta?.pagination?.total || 0) +
        Number(nearbyNeigborsCondos?.listings?.meta?.pagination?.total || 0),
    };
  }, [nearbyCondosData, nearbyNeigborsCondos]);

  return (
    <UnitPageContext.Provider
      value={{
        nearbyCondos,
        nearbyCondosTotal,
        listing,
        blogs,
        calculatedParams: nearbyCondosParams,
        building,
        unit,
        neighborhood,
        city,
        url: `${ORIGIN}${asPath}`,
        favorite,
        experts,
      }}
    >
      <UnitPage />
    </UnitPageContext.Provider>
  );
};

export default UnitComponent;

export const getServerSideProps: GetServerSideProps<
  UnitPageProps,
  { urlUnitParam: string; urlParam: string; urlBuildParam: string }
> = async ({ params, req }) => {
  const urlUnitParam = params?.urlUnitParam as string;
  const urlParam = params?.urlParam as string;
  const urlBuildParam = params?.urlBuildParam as string;
  const urlMatches = urlUnitParam.match(/unit-(.+)-for-(.+)/);
  if (!urlMatches) return notfoundRedirect;

  const unique = urlMatches[1];
  const propertyType = urlMatches[2];

  let typeFilter: RequestPropertyType;

  if (propertyType === 'rent') {
    typeFilter = 'rentUnit';
  } else if (propertyType === 'sale') {
    typeFilter = 'saleUnit';
  } else {
    return notfoundRedirect;
  }

  const yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);

  const {
    data: { listings, blogs },
  } = await client.query<UnitPageUnitResponse>({
    query: getUnitPageQuery(typeFilter),
    variables: {
      unique,
      buldingSlug: urlBuildParam,
      nhSlug: urlParam,
    },
  });

  if (!listings.data.length) return notfoundRedirect;

  const listing = flattenStrapiDataItem(listings.data[0]);
  const unit = flattenStrapiDataItem(listing.unit?.data);

  if (!unit.id && unit.id !== 0) return notfoundRedirect;
  const buildingByUnit = flattenStrapiDataItem(unit.building?.data);
  if (!buildingByUnit.id && buildingByUnit.id !== 0) return notfoundRedirect;
  const neighborhood = flattenStrapiDataItem(buildingByUnit.neighborhood?.data);
  if (!neighborhood.id && neighborhood.id !== 0) return notfoundRedirect;

  const globalBlogs = flattenStrapiBulkDataItems(blogs?.data);
  const buildingBlogs = flattenStrapiBulkDataItems(buildingByUnit?.blogs.data);
  const neighborhoodBlogs = flattenStrapiBulkDataItems(
    neighborhood?.blogs.data
  );

  const pageBlogs = [
    ...buildingBlogs,
    ...neighborhoodBlogs,
    ...globalBlogs,
  ].slice(0, 3);

  const urlIsCorrect =
    urlBuildParam === buildingByUnit?.slug &&
    urlParam === neighborhood?.slug &&
    unique === unit.unique;

  const expertsResponse = await client.query<{ agents: AgentsResponse }>({
    query: LOAD_AGENTS_FOR_EXPERTS_SECTION,
  });

  return urlIsCorrect
    ? {
        props: {
          blogs: pageBlogs,
          listing,
          neighborhood: neighborhood,
          unit: unit,
          building: buildingByUnit,
          city: flattenStrapiDataItem(neighborhood.city.data),
          experts: flattenStrapiBulkDataItems(
            expertsResponse.data.agents?.data
          ),
          url: req.url,
        },
      }
    : notfoundRedirect;
};
