import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';

import { BuildingsResponse } from 'apolloClient/types';

import useDebounce from '../../hooks/useDebounce';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from '../../lib/flattenStrapiBulkDataItems';
import rightArrow from '../../public/images/arrow-right.svg';
import iconClose from '../../public/images/close.svg';

const Buildings: React.FC<{
  fetchMoreBuildings: (params: { page?: number; search?: string }) => void;
  buildings?: BuildingsResponse;
}> = ({ buildings, fetchMoreBuildings }) => {
  const filteredBuildings = flattenStrapiBulkDataItems(buildings?.data);

  const [listBuildingsOpen, setListBuildingsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [scrollTop, setScrollTop] = useState(0);

  const totalBuildings = buildings?.meta?.pagination.total;

  const buildingRef = useRef(null);
  useEffect(() => {
    const onClick = ({ target }: { target: any }) => {
      if (
        target !== buildingRef.current &&
        !target.closest('.close-building') &&
        target.closest('.close-input')
      )
        setListBuildingsOpen(false);
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  useDebounce({
    callback: () => {
      fetchMoreBuildings({ search: value });
    },
    changingValue: value,
    timeout: 500,
    callOnInitial: false,
  });

  useDebounce({
    callback: () =>
      fetchMoreBuildings({
        page: (buildings?.meta?.pagination.page || 0) + 1,
        search: value,
      }),
    changingValue: scrollTop,
    timeout: 50,
    callOnInitial: false,
  });

  function onBuildingsListScroll(target: HTMLDivElement) {
    if (!totalBuildings || filteredBuildings.length >= totalBuildings) return;
    if (target.clientHeight + target.scrollTop > target.scrollHeight - 10) {
      setScrollTop(target.scrollTop);
    }
  }

  const changeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  return (
    <div className="relative w-full h-auto pb-3 border-b border-dashed xl:pb-0 border-gold-dark xl:w-auto xl:border-b-0 group">
      <div className="relative hidden pr-6 cursor-pointer hover:text-gold-hover xl:flex">
        <span className="pt-1 text-black xl:pt-0 whitespace-nowrap">
          Buildings
        </span>
      </div>
      <div className="hidden pt-3 lg:pt-[16px] top-4 xl:absolute xl:group-hover:block xl:-left-10 xl:w-[360px] z-120">
        <div className="bg-white z-120 xl:border top-4 border-gray ">
          <div className="relative">
            <div className="xl:absolute hidden xl:group-hover:block  w-3 h-3 bg-white -top-1.7 left-16 border-t border-l border-gray transform rotate-45" />
            <div className="h-3 bg-white -top-1.7 left-16" />
            <div
              className="p-4 overflow-y-auto h-96 xl:pt-[0px] xl:h-[520px]"
              onScroll={(event) => {
                onBuildingsListScroll(event.target as HTMLDivElement);
              }}
            >
              <div className="relative">
                <input
                  className="w-full p-[8px] pl-[16px] mb-[19px] mt-[2px] text-16-26-0.3 bg-white border text-black border-gray close-input"
                  type="text"
                  placeholder="Search Buildings"
                  value={value}
                  onChange={changeInputValue}
                />
                <button
                  onClick={() => setValue('')}
                  className="absolute md:top-[18px] md:right-[11px]"
                >
                  <Image
                    src={iconClose}
                    alt="close popup"
                    width={15}
                    height={15}
                  />
                </button>
              </div>
              <div className="text-16-34-0.3">
                {filteredBuildings?.map((building) => {
                  const neighborhoodData = flattenStrapiDataItem(
                    building.neighborhood?.data
                  );
                  return (
                    <div
                      key={`header-search-building-${building.slug}-${neighborhoodData.slug}`}
                      className="grid grid-cols-2"
                    >
                      <span className="block font-sohneKraftig">
                        <a
                          href={`/${neighborhoodData.slug}/${building.slug}`}
                          className="hover:text-gold-hover"
                        >
                          {building.name}
                        </a>
                      </span>
                      <a
                        href={`/${neighborhoodData.slug}`}
                        className="block hover:text-gold-hover pl-[20px]"
                      >
                        {neighborhoodData.name || ''}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setListBuildingsOpen(!listBuildingsOpen)}
        className="relative flex pr-6 cursor-pointer hover:text-gold-hover xl:hidden close-building text-18-23-0.3 mt-[-4px] !color-gold"
        ref={buildingRef}
      >
        Buildings
        <div className="pt-0.5 pl-[13px] xl:hidden">
          <Image
            className={classnames({
              'transform rotate-90': listBuildingsOpen,
            })}
            src={rightArrow}
            alt="buildings"
            height={15}
            width={12}
          />
        </div>
      </div>
      {listBuildingsOpen && (
        <div className="pt-[33px] pb-[21px] w-80">
          <div className="bg-white z-120 xl:border top-4 w-80 border-gray ">
            <div className="relative">
              <div className="xl:absolute hidden xl:group-hover:block w-3 h-3 bg-white -top-1.7 left-16 border-t border-l border-gray transform rotate-45" />
              <div
                className="relative p-2 pl-[1px] overflow-scroll z-120 h-76"
                onScroll={(event) =>
                  onBuildingsListScroll(event.target as HTMLDivElement)
                }
              >
                <input
                  className="relative w-full p-[8px] pl-[16px] mb-[20px] text-16-26-0.3 bg-white border z-120  border-gray"
                  type="text"
                  placeholder="Search Buildings"
                  value={value}
                  onChange={changeInputValue}
                />
                <button
                  onClick={() => setValue('')}
                  className="absolute top-[16px] right-[22px] md:top-[16px] md:right-[20px] z-[999]"
                >
                  <Image
                    src={iconClose}
                    alt="close popup"
                    width={15}
                    height={15}
                  />
                </button>
                <div>
                  {filteredBuildings?.map((building) => (
                    <div
                      key={building.id}
                      className="grid grid-cols-2 gap-[30px]"
                    >
                      <div className="block mr-3 break-words whitespace-normal font-sohneKraftig">
                        <a
                          className="text-17-34-0.3 xl:text-16-34-0.3"
                          href={`/${building?.neighborhood?.data?.attributes?.slug}/${building.slug}`}
                        >
                          {building.name}
                        </a>
                      </div>
                      <a
                        href={`/${building?.neighborhood?.data?.attributes?.slug}`}
                        className="block text-17-34-0.3 xl:text-16-34-0.3"
                      >
                        {building.neighborhood?.data?.attributes?.name || ''}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Buildings;
