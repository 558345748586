import React, { useEffect, useState } from 'react';

import { getNumberFromString } from '../../../../src/utils/utils';

import MonthlyCostFormWrapper from '../components/MonthlyCostFormWrapper';
import { formatPriceValue } from '../utils';

interface HOAFeesFormProps {
  onChange: (value: number) => void;
  associationFeePaidPer: string;
}

const HOAFeesForm: React.FC<HOAFeesFormProps> = ({
  onChange,
  associationFeePaidPer,
}) => {
  const [HOAFees, setHOAFees] = useState(
    getNumberFromString(associationFeePaidPer)
  );

  useEffect(() => {
    onChange(HOAFees);
  }, [HOAFees]);

  function onHOAFeesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = getNumberFromString(event.target.value);
    setHOAFees(value);
  }

  return (
    <MonthlyCostFormWrapper label="HOA Fees" value={HOAFees}>
      <div className="relative flex flex-col mb-6">
        <input
          onChange={onHOAFeesChange}
          value={formatPriceValue(HOAFees)}
          className="px-4 py-2 mt-6 border border-beige-dark"
          type="text"
        />
        <div className="absolute right-3.5 top-8 text-16-26-0.3 text-beige-dark ">
          /mo
        </div>
      </div>
      <span className="text-14-26-0.3 text-gray">
        Some properties require monthly HOA dues to cover common amenities or
        services.
      </span>
    </MonthlyCostFormWrapper>
  );
};

export default HOAFeesForm;
