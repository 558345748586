import React, { useState } from 'react';

import useFilterPopup from '../../../../hooks/useFilterPopup';

import { SetFiltersValues } from '../../types';
import {
  formatChangedPriceValue,
  normalizePriceValue,
} from '../../../Search/utils/listingsFiltersUtils';
import classnames from 'classnames';

interface PriceRangeProps {
  min: string;
  max: string;
  setValues: SetFiltersValues;
  page: 'search' | 'building';
  isBuildingPage?: boolean;
}
const PriceRange: React.FC<PriceRangeProps> = ({
  min,
  max,
  setValues,
  page,
  isBuildingPage = false,
}) => {
  const { setMaxValue, setMinValue, values } = useFilterPopup({
    onChange,
    initialValues: { min, max },
  });

  const [localMinValue, setLocalMinValue] = useState(values.min);
  const [localMaxValue, setLocalMaxValue] = useState(values.max);

  function onChange(values: { min: string; max: string }) {
    const minValue = +values.min ? values.min : 'Any';
    const maxValue = +values.max ? values.max : 'Any';
    setValues((prevValues) => ({
      ...prevValues,
      anyPrice: { min: minValue, max: maxValue },
    }));
  }

  function handleBlur() {
    onChange({ min: localMinValue, max: localMaxValue });
  }

  function renderSearchPriceRange() {
    return (
      <div className="flex align-center leading-[21px]">
        <input
          className="text-black px-4 pl-[16px] pt-[7px] pb-[7px] border w-[152px] md:w-[137px] h-[44px] border-beige-dark"
          placeholder="Min price ($)"
          value={normalizePriceValue(localMinValue)}
          onChange={({ target: { value } }) =>
            setLocalMinValue(formatChangedPriceValue(value))
          }
          onBlur={handleBlur}
        />
        <div className="self-center mt-[5px] h-px w-[10px] mx-[10px] md:w-[8px] md:mx-[8px] bg-black border" />
        <input
          className="text-black px-4 pl-[16px] pt-[7px] pb-[7px] border w-[152px] md:w-[137px] h-[44px] border-beige-dark"
          placeholder="Max price ($)"
          value={normalizePriceValue(localMaxValue)}
          onChange={({ target: { value } }) =>
            setLocalMaxValue(formatChangedPriceValue(value))
          }
          onBlur={handleBlur}
        />
      </div>
    );
  }

  function renderBuildingPriceRange() {
    return (
      <div className="flex align-center leading-[21px]">
        <input
          className={classnames(
            `px-4 pt-[6px] pb-[7px] border h-10.5 border-beige-dark text-black lg:h-[44px]`,
            {
              ' w-full md:w-80': !isBuildingPage,
              ' w-full md:w-[297px]': isBuildingPage,
            }
          )}
          placeholder="Min price"
          value={normalizePriceValue(values.min)}
          onChange={({ target: { value } }) =>
            setMinValue(formatChangedPriceValue(value))
          }
        />
        <div className="self-center md:w-[8px] px-1.5 md:px-0 mx-[9px] bg-beige border" />
        <input
          className={classnames(
            `px-4 pt-[6px] pb-[7px] border h-10.5 border-beige-dark text-black lg:h-[44px]`,
            {
              ' w-full md:w-80': !isBuildingPage,
              ' w-full md:w-[297px]': isBuildingPage,
            }
          )}
          placeholder="Max price"
          value={normalizePriceValue(values.max)}
          onChange={({ target: { value } }) =>
            setMaxValue(formatChangedPriceValue(value))
          }
        />
      </div>
    );
  }

  const ranges = {
    search: renderSearchPriceRange(),
    building: renderBuildingPriceRange(),
  };

  return (
    <div className="flex space-x-7">
      <div className="flex flex-col mb-6 md:mb-[14px]">
        <label htmlFor="" className="md:text-14-26-0.3">
          Price Range
        </label>
        {ranges[page]}
      </div>
    </div>
  );
};

export default PriceRange;
