import React, { useState, useContext, useEffect } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';

import { UnitFromQuickViewType } from 'types';
import phone from '../../public/images/phone.svg';
import ContactUsDialog from './ContactUsDialog';
import { Agent } from 'apolloClient/types/Agents';
import {
  getAgentDataFromLocalStorage,
  formatPhoneNumber,
  getLeadIdFromLocalStorage,
} from 'src/utils/utils';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from 'pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

export type PopUpContactUsProps = {
  flagPosition?: string;
  phoneIcon?: string;
  phoneNumber?: string;
  showName?: string;
  flagContainer?: string;
  contactUsName?: string;
  isRequestInfoBuilding?: boolean;
  searchQuickView?: boolean;
  isRequestInfoUnitOwner?: boolean;
  isRequestInfoBuildingOwner?: boolean;
  contactUsNotButton?: string;
  contactUsNotButtonClassName?: string;
  unitFromQuickView?: UnitFromQuickViewType;
  experts?: Agent[] | undefined;
  isMobile?: boolean;
  gtmId?: string;
};

const PopUpContactUs: React.FC<PopUpContactUsProps> = ({
  gtmId = 'gtm-contact-us',
  flagPosition = '-left-4',
  phoneIcon = 'md:hidden',
  phoneNumber = 'hidden',
  showName = 'hidden',
  flagContainer = 'pb-0.5 pt-[3px] md:pb-[8px] md:pt-[8px] pr-2 h-[33px]',
  contactUsName,
  isRequestInfoBuilding,
  isRequestInfoUnitOwner,
  isRequestInfoBuildingOwner,
  searchQuickView,
  contactUsNotButton,
  contactUsNotButtonClassName = 'text-gold-dark',
  unitFromQuickView,
  experts,
  isMobile,
}) => {
  const data = useContext(UnitPageContext) as UnitPageContextInterface;
  let unitPrice: number | undefined;

  if (data && data?.listing && data?.listing?.price) {
    unitPrice = data?.listing?.price;
  }

  const [open, setOpen] = useState(false);
  const [phoneEnding, setPhoneEnding] = useState('697-7667');
  const [areaCode, setAreaCode] = useState('305');
  const [dialogPhoneEnding, setDialogPhoneEnding] = useState('697-7667');
  const [dialogAreaCode, setDialogAreaCode] = useState('305');

  const handlePopUpClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const leadId = getLeadIdFromLocalStorage();

    if (leadId) {
      try {
        const agentsStringData = getAgentDataFromLocalStorage() || null;

        if (agentsStringData) {
          const agentsData = JSON.parse(agentsStringData);
          const leadAgentnNumber =
            agentsData?.secondAgent?.agentData?.phone ||
            agentsData?.secondAgent?.phone ||
            agentsData?.commonAgent?.agentData?.phone ||
            agentsData?.commonAgent?.phone;

          if (leadAgentnNumber) {
            const data = formatPhoneNumber(leadAgentnNumber + '');
            if (data.phoneNumber && phoneEnding !== data.phoneNumber) {
              setDialogPhoneEnding(data.phoneNumber);
            }

            if (data.areaCode && areaCode !== data.areaCode) {
              setDialogAreaCode(data.areaCode);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [open]);

  useEffect(() => {
    try {
      if (unitPrice && unitPrice > 1000000) {
        const agentsStringData = getAgentDataFromLocalStorage() || null;

        if (agentsStringData) {
          const agentsData = JSON.parse(agentsStringData);
          const higherMillionNumber =
            agentsData?.higherMillion?.agentData?.phone ||
            agentsData?.higherMillion?.phone;

          if (higherMillionNumber) {
            const data = formatPhoneNumber(higherMillionNumber + '');
            if (data.phoneNumber && phoneEnding !== data.phoneNumber) {
              setPhoneEnding(data.phoneNumber);
            }

            if (data.areaCode && areaCode !== data.areaCode) {
              setAreaCode(data.areaCode);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [unitPrice]);

  return (
    <>
      {contactUsNotButton && (
        <button
          onClick={() => setOpen(true)}
          className={contactUsNotButtonClassName}
        >
          {contactUsNotButton}
        </button>
      )}
      {contactUsName ? (
        <button
          onClick={() => setOpen(true)}
          className={classnames({
            'hover-animation px-[8px] xl:px-6 py-[11px] uppercase min-w-[140px] xl:w-72 bg-gold hover:bg-gold-hover text-16-16-0.3':
              isRequestInfoBuilding,
            'hover-animation px-[8px] xl:px-6 py-2 uppercase w-[140px] xl:w-full xl:max-w-[250px] xl:py-[11px] bg-gold hover:bg-gold-hover text-16-21-0.3':
              !isRequestInfoBuilding,
            'hover-animation !px-[8px] py-2 uppercase w-[140px] xl:!w-[140px] xl:max-w-[152px] xl:py-[11px] bg-gold hover:bg-gold-hover text-16-21-0.3 font-SohneBuch h-[44px]':
              searchQuickView,
          })}
        >
          <div className="text-16-16-0.3">{contactUsName}</div>
        </button>
      ) : (
        !contactUsNotButton && (
          <button
            id={gtmId}
            onClick={() => setOpen(true)}
            className="relative items-center flex-none outline-none cursor-pointer md:flex"
          >
            <div className="relative items-center cursor-pointer xl:ml-6">
              <div
                className={`absolute z-30 w-8 h-8 transform rotate-45 -translate-y-1/2 bg-white top-[49%] md:top-1/2 ${flagPosition}`}
              />
              <div
                className={classnames(
                  `
                  hover-animation uppercase bg-gold hover:bg-gold-hover font-sohneBreitHalbfett
                  text-16-16-0.3 md:text-14-18-0.3 lg:text-16-16-0.3
                  pl-[16px] sm:pl-[22px] md:pl-[36px] lg:pl-[37px]
                  pr-[14px] sm:pr-[14px] md:pr-[45px] lg:pr-[13px]
                  h-[33px] md:h-[35px] md:pt-[8px]
                  ${flagContainer}
                `,
                  { 'pr-[12px] pl-[22px] !pb-[30px]': isMobile }
                )}
              >
                <span
                  className={`md:inline-block font-sohneBuch text-black ${showName}`}
                >
                  Contact us
                </span>
                <span className="pr-[6px]" />
                <span
                  className={`pt-px md:inline-block text-black  ${phoneNumber}`}
                >
                  <span className="font-LucidaGrandeBold">(</span>
                  <span className="text-black font-sohneKraftig ">
                    {areaCode}
                  </span>
                  <span className="font-LucidaGrandeBold">)</span>{' '}
                  <span className="text-black font-sohneKraftig ">
                    {phoneEnding}
                  </span>
                </span>
                <span className={`pl-[26px] pr-[16px] h-[26px] ${phoneIcon}`}>
                  <Image src={phone} alt="phone" width={24} height={24} />
                </span>
              </div>
            </div>
          </button>
        )
      )}
      <ContactUsDialog
        open={open}
        onClose={handlePopUpClose}
        isRequestInfoBuilding={isRequestInfoBuilding}
        isRequestInfoBuildingOwner={isRequestInfoBuildingOwner}
        isRequestInfoUnitOwner={isRequestInfoUnitOwner}
        unitFromQuickView={unitFromQuickView}
        experts={experts}
        unitPrice={unitPrice}
        phoneEnding={phoneEnding}
        dialogPhoneEnding={dialogPhoneEnding}
        dialogAreaCode={dialogAreaCode}
        areaCode={areaCode}
      />
    </>
  );
};

export default PopUpContactUs;
