import { format } from 'date-fns';

export function translateUnitStatus(
  propertyTypeCode: string,
  statusCode: string,
  closeDate: string | null | undefined,
  isReo?: boolean,
  isShortSale?: boolean,
  isPreconstruction?: boolean
): string {
  if (isReo && statusCode !== 'CS') {
    return `Foreclosure`;
  } else if (isShortSale && statusCode !== 'CS') {
    return `Short sale`;
  } else if (isPreconstruction && statusCode !== 'CS') {
    return `Pre-construction`;
  } else if (
    (propertyTypeCode !== 'R' && statusCode === 'A') ||
    (propertyTypeCode !== 'R' && statusCode === 'AC')
  ) {
    return `For sale`;
  } else if (
    (propertyTypeCode === 'R' && statusCode === 'A') ||
    (propertyTypeCode === 'R' && statusCode === 'AC')
  ) {
    return `For rent`;
  } else if (
    statusCode === 'PS' ||
    statusCode === 'C' ||
    statusCode === 'X' ||
    statusCode === 'T' ||
    statusCode === 'W' ||
    statusCode === 'Q'
  ) {
    return `Off market`;
  } else if (statusCode === 'R') {
    return `Rented ${
      closeDate ? format(new Date(closeDate), 'MM-dd-yyyy') : ''
    }`;
  } else if (statusCode === 'CS') {
    return `Sold ${closeDate ? format(new Date(closeDate), 'MM-dd-yyyy') : ''}`;
  } else {
    return '';
  }
}
