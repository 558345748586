import iconWalkScore from '../public/images/walk-score.svg';
import iconBikeScore from '../public/images/bike-score.svg';
import iconTransitScore from '../public/images/transit-score.svg';
import iconNightLife from '../public/images/night-life.svg';
import iconDogFriendly from '../public/images/dog-friendly.svg';
import iconGyms from '../public/images/gym.svg';
import iconRestaurants from '../public/images/restaurants.svg';
import iconGroceries from '../public/images/groceries.svg';
import iconGalleries from '../public/images/galleries.svg';
import iconCoffeeShops from '../public/images/coffee-shop.svg';

export const mainFeaturesCommonDict = [
  {
    title: 'Walkability',
    type: 'Walk_score',
    image: iconWalkScore,
    altImage: 'iconWalkScore',
    walkscore: true,
  },
  {
    title: 'Bikeability',
    type: 'Bike_score',
    image: iconBikeScore,
    altImage: 'iconBikeScore',
    walkscore: true,
  },
  {
    title: 'Transit',
    type: 'Transit_score',
    image: iconTransitScore,
    altImage: 'iconTransitScore',
    walkscore: true,
  },
  {
    title: 'Night Life',
    type: 'Night_life',
    image: iconNightLife,
    altImage: 'iconNightLife',
    walkscore: false,
  },
  {
    title: 'Dog Friendly',
    type: 'Dog_Friendly',
    image: iconDogFriendly,
    altImage: 'iconDogFriendly',
    walkscore: false,
  },
  {
    title: 'Gyms',
    type: 'Gyms',
    image: iconGyms,
    altImage: 'iconGyms',
    walkscore: false,
  },
  {
    title: 'Restaurants',
    type: 'Restaurants',
    image: iconRestaurants,
    altImage: 'iconRestaurants',
    walkscore: false,
  },
  {
    title: 'Groceries',
    type: 'Groceries',
    image: iconGroceries,
    altImage: 'iconGroceries',
    walkscore: false,
  },
  {
    title: 'Galleries',
    type: 'Galleries',
    image: iconGalleries,
    altImage: 'iconGalleries',
    walkscore: false,
  },
  {
    title: 'Coffee Shops',
    type: 'Coffee_Shops',
    image: iconCoffeeShops,
    altImage: 'iconCoffeeShops',
    walkscore: false,
  },
];
