import React, { useEffect, useState } from 'react';

import { Listing, ListingFloorplan } from '../../apolloClient/types/Units';
import { ComponentBuildingFloorplan } from 'apolloClient/types/Building';
import { EntityWithId } from 'apolloClient/types';
import { isListingOffMarket } from 'lib/checkListingStatus';
import { getRowsForUnitFloorplanDetails } from '../../src/utils/listing';

import { KeyValueList } from '../KeyValueList';

interface UnitFloorplanBodyProps {
  listing: EntityWithId<Listing>;
  floorplan?: ComponentBuildingFloorplan;
}

const UnitFloorplanBody: React.FC<UnitFloorplanBodyProps> = ({
  listing,
  floorplan,
}) => {
  const [rows, setRows] = useState(
    [] as { key: string; value: string }[] | undefined
  );

  useEffect(() => {
    if (floorplan) {
      const fpRows = getRowsForUnitFloorplanDetails(
        floorplan as ListingFloorplan
      );
      setRows(fpRows);
    }
  }, [floorplan]);

  return (
    <>
      <div className="mb-10">
        <KeyValueList rows={rows || []} />
      </div>
      <p className="text-12-18-0.3 pb-20">
        Floor plan disclaimer: condoblackbook.com uses an automated floor plan
        condo matching system to match condos to the original developer floor
        plans. This plan has not been posted by or verified with the owner or
        listing broker. Floor plans are for general information and convenience
        only and cannot be relied upon for purchase or rental without
        independent third party verification. Most floor plans represent the
        original developer plans and may have since been modified.
      </p>
    </>
  );
};

export default UnitFloorplanBody;
