import React from 'react';
import Image from 'next/legacy/image';

type propsFeatureItem = {
  title: string;
  description: string;
  image: string;
  altImage: string;
  walkscore: boolean;
};

const FeatureItem: React.FC<propsFeatureItem> = ({
  title,
  description,
  image,
  altImage,
  walkscore,
}) => {
  return (
    <>
      <div className="flex my-2 md:my-8">
        <div>
          <Image width={80} height={80} src={image} alt={altImage} />
        </div>
        <div className="flex flex-col w-2/3 px-4">
          <div className="pt-3.5 font-sohneKraftig">{title}</div>
          <div className={!!walkscore ? 'text-gold-hover' : ''}>
            {description}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureItem;
