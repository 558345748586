import React, { useContext } from 'react';
import { Product, Organization } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { BuildingPageContextInterface } from 'apolloClient/types/Building';
import { BuildingPageContext } from 'pages/[urlParam]/[urlBuildParam]';

const BuildingProductSchema: React.FC = () => {
  const { building, url } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const image = building.mainImage?.data?.attributes?.url;

  const organization: Organization = {
    '@type': 'Organization',
    name: 'CONDO BLACK BOOK',
    email: 'contact@condoblackbook.com',
    telephone: '+1 (305) 697-7667',
  };

  const product: Product = {
    '@type': 'Product',
    name: building.name,
    image,
    offers: [
      {
        '@type': 'Offer',
        price: building.buildingStats?.averageSalePrice,
        availability:
          (building.buildingStats?.saleCount ?? 0) > 0 ||
          (building.buildingStats?.rentCount ?? 0) > 0
            ? 'InStock'
            : 'SoldOut',
        name: building.name,
        image,
        seller: organization,
        priceCurrency: 'USD',
        offeredBy: organization,
        itemCondition: 'NewCondition',
        url,
        priceSpecification: {
          '@type': 'PriceSpecification',
          url,
          name: building.name,
          priceCurrency: 'USD',
          price: building.buildingStats?.averageSalePrice, // @todo: add model.BuildingStats.AvgListPrice as alt price
        },
      },
    ],
    brand: organization,
    url,
  };

  return (
    <Helmet
      script={[
        helmetJsonLdProp<Product>({
          '@context': 'https://schema.org',
          ...product,
        }),
      ]}
    />
  );
};

export default BuildingProductSchema;
