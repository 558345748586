import React, { useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { FilterPopupProps } from '../../../types';
import useFilterPopup from '../../../hooks/useFilterPopup';
import { ANY } from '../../../constants/building/availableCondos';

import { formatPriceValue } from '../../Unit/MonthlyCost/utils';
import {
  formatChangedPriceValue,
  normalizePriceValue,
} from '../../Search/utils/listingsFiltersUtils';

const checkboxMinPrice = {
  sale: [
    '0',
    '200000',
    '250000',
    '300000',
    '400000',
    '500000',
    '600000',
    '800000',
    '1000000',
    '2000000',
  ],
  rent: [
    '1500',
    '2000',
    '2500',
    '3000',
    '4000',
    '4500',
    '5000',
    '6000',
    '7000',
    '10000',
  ],
};

const checkboxMaxPrice = {
  sale: [
    '400000',
    '500000',
    '600000',
    '800000',
    '1000000',
    '1200000',
    '1600000',
    '2000000',
    '4000000',
    ANY,
  ],
  rent: [
    '2000',
    '3000',
    '4000',
    '5000',
    '6000',
    '7000',
    '10000',
    '12000',
    '15000',
    ANY,
  ],
};

const AnyPricePopup: React.FC<FilterPopupProps> = ({
  onChange,
  initialValues,
  selectedPropertyType,
  closePopup,
}) => {
  return (
    <Popover.Panel className="absolute z-50 transform -translate-x-1/2 outline-0">
      <Body
        selectedPropertyType={selectedPropertyType}
        onChange={onChange}
        initialValues={initialValues}
        closePopup={closePopup}
      />
    </Popover.Panel>
  );
};

const Body: React.FC<FilterPopupProps> = ({
  onChange,
  initialValues,
  selectedPropertyType = 'sale',
  closePopup,
}) => {
  const { setMaxValue, setMinValue, values } = useFilterPopup({
    initialValues,
  });

  const [price, showPrice] = useState(true);
  const maxValueRef = useRef<HTMLInputElement | any>();

  function setMinValueAndFocusMax(check: string) {
    setMinValue(check);
    maxValueRef.current.focus();
  }
  function filterMaxValues(maxValues: string[]): string[] {
    const minValue = Number(values.min);
    const forSale = selectedPropertyType === 'sale';
    const forRent = selectedPropertyType === 'rent';

    if (minValue) {
      const roundingNumber = forSale ? 10000 : 100;
      const minPriceValue = Number(values.min);
      const startNumber = minPriceValue - (minPriceValue % roundingNumber);

      const calculatedMaxValues: string[] = [];

      for (let i = 1; i <= 9; i++) {
        if (forRent) {
          if (startNumber < 4000)
            calculatedMaxValues.push(String(startNumber + 500 * i));
          else if (startNumber < 10000)
            calculatedMaxValues.push(String(startNumber + 1000 * i));
          else if (startNumber >= 10000)
            calculatedMaxValues.push(String(startNumber + 2500 * i));
        }
        if (forSale) {
          if (startNumber < 500000)
            calculatedMaxValues.push(String(startNumber + 50000 * i));
          else if (startNumber < 1000000)
            calculatedMaxValues.push(String(startNumber + 100000 * i));
          else if (startNumber >= 1000000)
            calculatedMaxValues.push(String(startNumber + 250000 * i));
        }
      }
      calculatedMaxValues.push(ANY);
      return calculatedMaxValues;
    }
    return maxValues;
  }

  const maxValues = filterMaxValues(checkboxMaxPrice[selectedPropertyType]);

  return (
    <Popover.Panel
      className="absolute z-50 transform -translate-x-1/2 outline-0"
      ref={(ref: any) => ref === null && onChange(values)}
    >
      <div className="absolute mt-6 text-16-26-0.3 bg-white border -z-50 -left-4 md:-left-0 border-gray">
        <div className="relative p-8 w-88 md:w-[360px]">
          <div className="absolute z-30 w-6 h-6 transform rotate-45 bg-white -translate-y-1/2 left-20 lg:left-12 -top-0.1 border-t border-l border-gray" />
          <div className="flex flex-col pb-6 mb-[10px] border-b border-beige-dark">
            <div className="flex align-center">
              <input
                className="w-[137px] px-4 py-2 border h-11 border-beige-dark placeholder-black text-black"
                placeholder="Min price"
                value={normalizePriceValue(values.min)}
                onChange={({ target: { value } }) =>
                  setMinValue(formatChangedPriceValue(value))
                }
                onFocus={() => showPrice(true)}
              />
              <div className="self-center w-[8px] h-px mx-[8px] bg-black border" />
              <input
                className="w-[137px] px-4 py-2 border h-11 border-beige-dark placeholder-black text-black"
                ref={maxValueRef}
                placeholder="Max price"
                value={normalizePriceValue(values.max)}
                onChange={({ target: { value } }) =>
                  setMaxValue(formatChangedPriceValue(value))
                }
                onFocus={() => showPrice(false)}
              />
            </div>
          </div>
          <div className="flex space-x-7">
            <div className="flex flex-col w-1/2">
              {price && (
                <>
                  {checkboxMinPrice[selectedPropertyType].map(
                    (check, index) => (
                      <button
                        key={index}
                        className="flex items-center mt-2 xl:mt-0 text-14-26-0.3 text-gold-darker hover:text-black focus:text-black"
                        onClick={() => setMinValueAndFocusMax(check)}
                      >
                        {formatPriceValue(Number(check))}
                      </button>
                    )
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col w-1/2">
              {!price && (
                <>
                  {maxValues.map((check, index) => (
                    <button
                      key={index}
                      className="flex items-center mt-2 xl:mt-0 text-14-26-0.3 text-gold-darker hover:text-black focus:text-black"
                      onClick={() => {
                        setMaxValue(check);
                        setTimeout(closePopup, 0);
                      }}
                    >
                      {Number(check) ? formatPriceValue(Number(check)) : check}
                    </button>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popover.Panel>
  );
};

export default AnyPricePopup;
