import React from 'react';
import Markdown from 'markdown-to-jsx';

interface MarkdownParserProps {
  markdown: string | null | undefined;
  classNames?: string;
}

const MarkdownParser: React.FC<MarkdownParserProps> = ({
  markdown,
  classNames = 'text-14-21-0.3',
}) => {
  if (!markdown) return null;
  return (
    <div className={`markdown-parser ${classNames}`}>
      <Markdown>{markdown}</Markdown>
    </div>
  );
};

export default MarkdownParser;
