import React, { useEffect, useState } from 'react';

import MonthlyCostFormWrapper from '../components/MonthlyCostFormWrapper';
import { calculateTotalValue, formatPriceValue } from '../utils';
import { getNumberFromString } from '../../../../src/utils/utils';

const UTILITIES = 'utilities';

function getInitialValuesFromLocalStorage() {
  const initialValues = {
    gas: 0,
    waterSewer: 0,
    internet: 0,
    electric: 0,
  };

  const values = localStorage.getItem(UTILITIES);

  if (values === null) {
    localStorage.setItem(UTILITIES, JSON.stringify(initialValues));
    return initialValues;
  }

  try {
    return JSON.parse(values);
  } catch (error) {
    console.error('Data is not a JSON string:', values);
    console.error('Error parsing JSON getInitialValuesFromLocalStorage', error);

    return initialValues;
  }
}

interface UtilitiesFormProps {
  onChange: (value: number) => void;
}

const UtilitiesForm: React.FC<UtilitiesFormProps> = ({ onChange }) => {
  const [totalValue, setTotalValue] = useState(0);
  const [isInclude, setIsInclude] = useState(true);
  const [values, setValues] = useState({
    gas: 0,
    waterSewer: 0,
    internet: 0,
    electric: 0,
  });

  useEffect(() => {
    setValues(getInitialValuesFromLocalStorage());
  }, []);

  useEffect(() => {
    setTotalValue(calculateTotalValue(values));
  }, [values]);

  useEffect(() => {
    onChange(isInclude ? totalValue : 0);
  }, [totalValue, isInclude]);

  function onInputsChange(value: number, key: string) {
    const newValues = { ...values, [key]: value };
    setValues(newValues);
    localStorage.setItem(UTILITIES, JSON.stringify(newValues));
  }

  return (
    <MonthlyCostFormWrapper label="Utilities" value={totalValue}>
      <div className="flex items-center my-6 space-x-4">
        <input
          type="checkbox"
          className="mr-3 input-checkbox custom-checkbox"
          checked={isInclude}
          onChange={() => setIsInclude(!isInclude)}
        />
        <label htmlFor="">Include utilites in payment</label>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <label htmlFor="" className="w-1/2 pt-3 pb-1 md:w-2/5">
            Water / Sewer
          </label>
          <label htmlFor="" className="w-1/2 pt-3 pb-1 md:w-2/5">
            Gas
          </label>
        </div>
        <div className="relative flex justify-between">
          <input
            className="w-1/2 px-4 py-2 pt-2.5 pb-1 border md:w-2/5 border-beige-dark"
            value={formatPriceValue(values.waterSewer)}
            onChange={(event) => {
              onInputsChange(
                getNumberFromString(event.target.value),
                'waterSewer'
              );
            }}
          />
          <div className="absolute left-32 md:left-56 lg:left-24 xxl:left-44 top-2.5 text-16-26-0.3 text-beige-dark ">
            /mo
          </div>
          <input
            className="w-1/2 px-4 py-2 pt-2.5 pb-1 border md:w-2/5 border-beige-dark"
            value={formatPriceValue(values.gas)}
            onChange={(event) => {
              onInputsChange(getNumberFromString(event.target.value), 'gas');
            }}
          />
          <div className="absolute right-2.5 top-2.5 text-16-26-0.3 text-beige-dark ">
            /mo
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <label htmlFor="" className="w-1/2 pt-3 pb-1 md:w-2/5">
            Internet
          </label>
          <label htmlFor="" className="w-1/2 pt-3 pb-1 md:w-2/5">
            Electric
          </label>
        </div>
        <div className="relative flex justify-between">
          <input
            className="w-1/2 px-4 py-2 pt-3 pb-1 border md:w-2/5 border-beige-dark"
            value={formatPriceValue(values.internet)}
            onChange={(event) => {
              onInputsChange(
                getNumberFromString(event.target.value),
                'internet'
              );
            }}
          />
          <div className="absolute left-32 md:left-56 lg:left-24 xxl:left-44 top-2.5 text-16-26-0.3 text-beige-dark ">
            /mo
          </div>
          <input
            className="w-1/2 px-4 py-2 pt-3 pb-1 border md:w-2/5 border-beige-dark"
            value={formatPriceValue(values.electric)}
            onChange={(event) => {
              onInputsChange(
                getNumberFromString(event.target.value),
                'electric'
              );
            }}
          />
          <div className="absolute right-2.5 top-2.5 text-16-26-0.3 text-beige-dark ">
            /mo
          </div>
        </div>
      </div>
    </MonthlyCostFormWrapper>
  );
};

export default UtilitiesForm;
