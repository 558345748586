import React, { useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  LoadScriptNext,
  Marker,
  Polygon,
} from '@react-google-maps/api';
import { useQuery } from '@apollo/client';

import { Coordinates } from 'apolloClient/types/Neighborhood';

import { Building, BuildingsResponse } from '../../../apolloClient/types';
import {
  calculateCenter,
  getEdgeCoords,
} from '../../../src/utils/locationUtils';
import { InfoWindowData } from '../../../types';
import { BUILDINGS_FOR_MAP } from '../../../apolloClient/queries/neighborhood';
import { flattenStrapiBulkDataItems } from '../../../lib/flattenStrapiBulkDataItems';
import { loadScriptProps } from '../../../constants/gapi/googleMapsConfig';
import { ORIGIN } from '../../../src/constants';
import marker_image from '../../../public/images/map_marker.svg';

type Props = {
  id: number;
  coordinates: Coordinates;
};

const LocationGoogleMap: React.FC<Props> = ({ id, coordinates }) => {
  const { data } = useQuery<{ buildings: BuildingsResponse }>(
    BUILDINGS_FOR_MAP,
    { variables: { id } }
  );
  const buildingsByNeighborhoodId = flattenStrapiBulkDataItems(
    data?.buildings.data
  );

  const [path] = useState(coordinates);
  const [center, setCenter] = useState({
    lng: 0,
    lat: 0,
  });

  const initialInfoWindowData = {
    anchor: undefined,
    buildingName: '',
    isOpen: false,
  };

  const [infoWindowDataHover, setInfoWindowDataHover] =
    useState<InfoWindowData>(initialInfoWindowData);

  useEffect(() => {
    const coords = getEdgeCoords(path);
    const center = calculateCenter(coords);
    setCenter(center);
  }, [path]);

  const mapOptions = useMemo(
    () => ({
      zoom: 14,
      center,
    }),
    [center]
  );

  const polygonOptions = useMemo(
    () => ({
      path: path,
      fillOpacity: 0.2,
      strokeWeight: 2,
      strokeColor: '#666666',
      fillColor: '#999999',
    }),
    [path]
  );

  function closeInfoBlockHover() {
    setInfoWindowDataHover(initialInfoWindowData);
  }

  function openInfoBlockHover(
    anchor: google.maps.Marker | undefined,
    building: Building
  ) {
    setInfoWindowDataHover({ anchor, building, isOpen: true });
  }

  function getBuildingsMarkers(buildings: Building[]) {
    return buildings.reduce<Array<JSX.Element>>((acc, building) => {
      let marker: google.maps.Marker | undefined;
      const { lon, lat, name } = building;
      if (coordinates && lon && lat) {
        acc.push(
          <Marker
            icon={{
              url: ORIGIN + marker_image.src,
              size: new google.maps.Size(14, 14),
            }}
            ref={(ref) => (marker = ref?.marker)}
            key={name}
            position={{ lng: lon, lat: lat }}
            onMouseOver={() => {
              openInfoBlockHover(marker, building);
            }}
            onMouseOut={closeInfoBlockHover}
          />
        );
      }
      return acc;
    }, []);
  }

  const buildingsMarker = useMemo(() => {
    return getBuildingsMarkers(buildingsByNeighborhoodId);
  }, [buildingsByNeighborhoodId]);

  function setBounds(map: google.maps.Map) {
    const markerBounds = new window.google.maps.LatLngBounds();
    path.forEach((path) => {
      const point = new google.maps.LatLng(path.lat, path.lng);
      markerBounds.extend(point);
    });
    map.fitBounds(markerBounds);
  }

  return (
    <div className="h-full App">
      <LoadScriptNext {...loadScriptProps} loadingElement={undefined}>
        <GoogleMap
          mapContainerClassName="relative w-full h-full"
          options={mapOptions}
          onLoad={setBounds}
        >
          <Polygon options={polygonOptions} />
          {buildingsMarker}
          {infoWindowDataHover.isOpen && (
            <InfoWindow anchor={infoWindowDataHover.anchor}>
              <div>
                <div className="flex flex-col">
                  <div className="mb-1 uppercase font-sohneKraftig">
                    {infoWindowDataHover.building?.name}
                  </div>
                  <div className="placeholder-black text-black">
                    {infoWindowDataHover.building?.primaryAddress}
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScriptNext>
    </div>
  );
};

export default LocationGoogleMap;
