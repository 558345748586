import React from 'react';

import { abbreviatedPriceFormatterWithPrecision } from '../../../src/utils/utils';
import { getFilterLabel } from '../../Search/utils/listingsFiltersUtils';

import AnyPricePopup from './AnyPricePopup';
import PopoverSearchPage from './PopoverSearchPage';
import { FiltersValues, OnChangeFilters } from '../types';

interface Props {
  values: FiltersValues;
  onChangeFilters: OnChangeFilters;
  selectedPropertyType?: 'rent' | 'sale';
}

const AnyPriceFilter: React.FC<Props> = ({
  values,
  onChangeFilters,
  selectedPropertyType,
}) => {
  function priceFilterFormatter(value: string): string {
    return abbreviatedPriceFormatterWithPrecision(2)(Number(value));
  }

  return (
    <PopoverSearchPage
      buttonClassName="border xl:pt-0 xl:pl-[12px] xl:pr-[10px] border-beige-dark hidden xxl:px-2 xl:flex xl:text-14-21-0.3 md:pr-0 xxl:pr-2"
      name={getFilterLabel(values.anyPrice, 'Any Price', priceFilterFormatter)}
      render={({ close }: any) => (
        <AnyPricePopup
          closePopup={close}
          onChange={(value) => onChangeFilters({ ...values, anyPrice: value })}
          initialValues={values.anyPrice}
          selectedPropertyType={selectedPropertyType}
        />
      )}
    />
  );
};

export default AnyPriceFilter;
