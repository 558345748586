import React from 'react';
import Image from 'next/legacy/image';

import imageLogo from '../../../public/images/logo.svg';

const Logo: React.FC = () => {
  return (
    <div className="pt-[6px] md:pt-[5px] xl:pt-[8px] pr-[1px] w-44 md:w-52 lg:w-60 flex-none">
      <a href="/" className="md:pb-4 md:pt-6 p-[1px]">
        <Image src={imageLogo} alt="logo" priority width={230} height={15} />
      </a>
    </div>
  );
};

export default Logo;
