import React from 'react';

import Carousel from './Article/Carousel';
import Button from './Button';
import PageBuilderImage from './Article/PageBuilderImage';
import Editor from './Article/Editor';
import { StrapiMediaInfo } from 'apolloClient/types';

export interface StylesType {
  [key: string]: string;
}

export type PageBuilderImageType = StrapiMediaInfo;

export interface PageBuilderElement {
  type: string | { resolvedName: string };
  nodes: string[];
  props: {
    styles: StylesType;
    image: PageBuilderImageType[];
    href: string;
    name: string;
    text: string;
    caption: string;
    captionLink?: string;
    images: PageBuilderImageType[];
  };
}

interface PageBuilderDataInterface {
  [key: string]: PageBuilderElement;
}

export interface PageBuilderParserInterface {
  data: { [key: string]: PageBuilderElement } | null;
}

export const parsePageBuilderData = (data: string) => {
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Data is not a JSON string:', data);
      console.error('Error parsing JSON in parsePageBuilderData', data);

      return null;
    }
  }
  return null;
};

interface NodeType {
  node: PageBuilderElement;
  data: PageBuilderDataInterface;
}

const Node: React.FC<NodeType> = ({ node, data }) => {
  if (!node) return null;

  let typeName = '';
  if (typeof node.type === 'object') {
    typeName = node.type.resolvedName;
  } else {
    typeName = node.type;
  }

  const Children = node.nodes.map((x: string) => {
    return <Node key={x} node={data[x]} data={data} />;
  });

  const styles = node.props.styles;

  switch (typeName) {
    case 'Container':
      return (
        <div className="page-builder-container" style={styles}>
          {Children}
        </div>
      );
    case 'Carousel':
      return <Carousel {...node.props} />;
    case 'Button': {
      return (
        <Button
          classNames="page-builder-button"
          fullWidth={false}
          {...node.props}
        />
      );
    }
    case 'Image':
      return (
        <PageBuilderImage
          styles={{ ...styles, color: styles.textColor }}
          image={node.props.image[0]}
          caption={node.props.caption}
          captionLink={node.props.captionLink}
        />
      );
    case 'Editor':
      return <Editor styles={{ ...styles, maxWidth: '100%' }} text={node.props.text} />;

    default:
      return null;
  }
};

const PageBuilderContentParser: React.FC<PageBuilderParserInterface> = ({
  data,
}) => {
  if (!data) return <div>No blog content data</div>;
  return (
    <div className="flex flex-col page-builder-wrapper">
      <Node node={data.ROOT} data={data} />
    </div>
  );
};

export default PageBuilderContentParser;
