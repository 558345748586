import React, { useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Link from 'next/link';
import Tooltip from '@mui/material/Tooltip';

import {
  SEARCH_SALE_FILTERS,
  defaultSortOption,
} from '../UnitsFilters/constants';
import { getSearchLink } from '../Search/utils/listingsFiltersUtils';

import rightArrow from '../../public/images/arrow-right.svg';

const Buy: React.FC = () => {
  const [listBuildingsOpen, setListBuildingsOpen] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);

  const getBlogLink = (params: string) => {
    return `/blog/${params.replace(/^\/+/, '')}`;
  };
  const handleClose = () => {
    setOpenBuy(false);
  };
  const handleOpen = () => {
    setOpenBuy(true);
  };
  return (
    <div
      className={`
      relative w-full h-auto pb-3 xl:pb-0 xl:w-auto 
      border-b border-dashed border-gold-dark xl:border-b-0 group 
      mt-[27px] xl:mt-[0px]
    `}
    >
      <Tooltip
        TransitionProps={{ timeout: 0 }}
        open={openBuy}
        onClose={handleClose}
        onOpen={handleOpen}
        title={
          <div className="z-40 hidden text-black xl:block">
            <div className="relative bg-transparent">
              <div className="absolute hidden w-3 h-3 transform rotate-45 bg-white border-t border-l xl:block -top-1.7 left-[158px] border-gray" />
              <div className="flex">
                <div className="px-3 pb-2 pr-6 xl:w-[200px] xl:pl-[17px] text-14-26-0.3 xl:text-16-34-0.3 border-r border-gray font-sohneBuch">
                  <div className="pt-[15px] uppercase font-sohneKraftig">
                    Listing type
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover w-[215px]">
                    <a
                      href={getSearchLink(SEARCH_SALE_FILTERS)}
                      className="block"
                      rel="nofollow"
                    >
                      Condos for sale
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forSale: true,
                        newConstruction: true,
                        sortOption: defaultSortOption,
                      })}
                      className="block"
                      rel="nofollow"
                    >
                      New Construction
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forSale: true,
                        preConstruction: true,
                        sortOption: defaultSortOption,
                      })}
                      className="block"
                      rel="nofollow"
                    >
                      Pre-Construction
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forSale: true,
                        foreclosures: true,
                        sortOption: defaultSortOption,
                      })}
                      className="block"
                      rel="nofollow"
                    >
                      Foreclosures
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forSale: true,
                        newConstruction: true,
                        foreclosures: true,
                        preConstruction: true,
                        shortSales: true,
                        existingCondos: true,
                        anyPrice: { min: '600000', max: 'Any' },
                        sortOption: defaultSortOption,
                      })}
                      className="block"
                      rel="nofollow"
                    >
                      Luxury Condos
                    </a>
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forSale: true,
                        newConstruction: true,
                        foreclosures: true,
                        preConstruction: true,
                        shortSales: true,
                        existingCondos: true,
                        wfAny: true,
                        sortOption: defaultSortOption,
                      })}
                      className="block"
                      rel="nofollow"
                    >
                      Waterfront Condos
                    </a>
                  </div>
                </div>
                <div className=" font-sohneBuch px-3 pb-2 pr-6 xl:pl-[18px] xl:pr-[0px] xl:pt-[3px] xl:w-[200px] text-14-26-0.3 xl:text-16-34-0.3">
                  <div className="pt-3 uppercase font-sohneKraftig  w-[215px]">
                    Guides
                  </div>
                  <Link
                    href={getBlogLink(
                      'comprehensive-guide-to-buying-a-pre-construction-property-or-condo-in-miami'
                    )}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover text-14-26-0.3 xl:text-16-34-0.3"
                  >
                    Pre-Construction Guide
                  </Link>
                  <Link
                    href={getBlogLink(
                      'moving-to-miami-complete-guide-to-buying-a-condo'
                    )}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover"
                  >
                    Condo Buying Guide
                  </Link>
                  <Link
                    href={getBlogLink(
                      'ultimate-guide-to-buying-a-vacation-condo-or-second-home-in-miami'
                    )}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover"
                  >
                    Vacation Home Guide
                  </Link>
                  <Link
                    href={getBlogLink(
                      'get-your-miami-condo-sold-no-matter-what-the-market-odds'
                    )}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover"
                  >
                    Condo Selling Guide
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <a
          href={getSearchLink(SEARCH_SALE_FILTERS)}
          className="hidden w-full pb-3 pr-6 text-black border-b border-dashed xl:pb-0 border-gold-dark xl:border-b-0 hover:cursor-pointer hover:text-gold-hover xl:flex"
        >
          Buy
        </a>
      </Tooltip>
      <div
        onClick={() => setListBuildingsOpen(!listBuildingsOpen)}
        className="relative flex pr-6 cursor-pointer hover:text-gold-hover xl:hidden close-building text-18-23-0.3 pt-[25px] h-[46px]"
      >
        Buy
        <div className="pt-0.5 pl-[13px] xl:hidden">
          <Image
            className={classnames({
              'transform rotate-90': listBuildingsOpen,
            })}
            src={rightArrow}
            alt="buy"
            height={15}
            width={12}
          />
        </div>
      </div>
      {listBuildingsOpen && (
        <div className="pt-3 w-80">
          <div className="z-40 bg-white xl:border top-4 w-80 border-gray ">
            <div className="relative">
              <div className="xl:absolute hidden xl:group-hover:block  w-3 h-3 bg-white -top-1.7 left-16 border-t border-l border-gray transform rotate-45" />
              <div className="z-40 block text-black xl:hidden">
                <div className="relative mr-2 bg-transparent border-t border-dashed md:mr-0 border-gold-dark">
                  <div className="pb-[12px] text-16-34-0.3 border-b border-gray font-sohneBuch">
                    <div className="pt-[17px] uppercase font-sohneKraftig">
                      Listing type
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink(SEARCH_SALE_FILTERS)}
                        className="block"
                        rel="nofollow"
                      >
                        Condos for sale
                      </a>
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink({
                          forSale: true,
                          newConstruction: true,
                          sortOption: defaultSortOption,
                        })}
                        className="block"
                        rel="nofollow"
                      >
                        New Construction
                      </a>
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink({
                          forSale: true,
                          preConstruction: true,
                          sortOption: defaultSortOption,
                        })}
                        className="block"
                        rel="nofollow"
                      >
                        Pre-Construction
                      </a>
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink({
                          forSale: true,
                          foreclosures: true,
                          sortOption: defaultSortOption,
                        })}
                        className="block"
                        rel="nofollow"
                      >
                        Foreclosures
                      </a>
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink({
                          forSale: true,
                          newConstruction: true,
                          foreclosures: true,
                          preConstruction: true,
                          shortSales: true,
                          existingCondos: true,
                          anyPrice: { min: '600000', max: 'Any' },
                          sortOption: defaultSortOption,
                        })}
                        className="block"
                        rel="nofollow"
                      >
                        Luxury Condos
                      </a>
                    </div>
                    <div className="hover:cursor-pointer hover:text-gold-hover">
                      <a
                        href={getSearchLink({
                          forSale: true,
                          newConstruction: true,
                          foreclosures: true,
                          preConstruction: true,
                          shortSales: true,
                          existingCondos: true,
                          wfAny: true,
                          sortOption: defaultSortOption,
                        })}
                        className="block"
                        rel="nofollow"
                      >
                        Waterfront Condos
                      </a>
                    </div>
                  </div>
                  <div className="pb-2 text-16-34-0.3 mb-[5px]">
                    <div className="pt-3 uppercase font-sohneKraftig">
                      Guides
                    </div>
                    <Link
                      href={getBlogLink(
                        'comprehensive-guide-to-buying-a-pre-construction-property-or-condo-in-miami'
                      )}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="block hover:cursor-pointer hover:text-gold-hover"
                    >
                      Pre-Construction Guide
                    </Link>
                    <Link
                      href={getBlogLink(
                        'moving-to-miami-complete-guide-to-buying-a-condo'
                      )}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="block hover:cursor-pointer hover:text-gold-hover"
                    >
                      Condo Buying Guide
                    </Link>
                    <Link
                      href={getBlogLink(
                        'ultimate-guide-to-buying-a-vacation-condo-or-second-home-in-miami'
                      )}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="block hover:cursor-pointer hover:text-gold-hover"
                    >
                      Vacation Home Guide
                    </Link>
                    <Link
                      href={getBlogLink(
                        'get-your-miami-condo-sold-no-matter-what-the-market-odds'
                      )}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="block hover:cursor-pointer hover:text-gold-hover"
                    >
                      Condo Selling Guide
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Buy;
