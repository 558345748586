import React, { useState } from 'react';
import useFilterPopup from '../../../../hooks/useFilterPopup';
import { SetFiltersValues } from '../../types';

interface Props {
  min: string;
  max: string;
  setValues: SetFiltersValues;
}

const SquareFeet: React.FC<Props> = ({ min, max, setValues }) => {
  const { values } = useFilterPopup({
    onChange,
    initialValues: { min, max },
  });

  const [localMinValue, setLocalMinValue] = useState(values.min);
  const [localMaxValue, setLocalMaxValue] = useState(values.max);

  function onChange(newValues: { min: string; max: string }) {
    setValues((prevValues) => ({ ...prevValues, sqft: newValues }));
  }

  const handleBlur = () => {
    onChange({ min: localMinValue, max: localMaxValue });
  };

  return (
    <div className="md:w-1/2">
      <label htmlFor="" className="md:text-14-26-0.3">
        Square Feet
      </label>
      <div className="flex mt-0 align-center leading-[21px]">
        <input
          className="w-[152px] md:w-[137px] pt-[6px] pb-[7px] pl-[16px] h-[44px] px-4 py-2 border border-beige-dark text-black"
          type="number"
          placeholder="Min"
          value={localMinValue}
          onChange={({ target }) => setLocalMinValue(target.value)}
          onBlur={handleBlur}
        />
        <div className="self-center mt-[5px] w-[10px] mx-[10px] md:px-0 md:w-[8px] md:mx-[8px] bg-beige border" />
        <input
          className="w-[152px] md:w-[137px] pt-[6px] pb-[7px] pl-[16px] h-[44px] px-4 py-2 border border-beige-dark text-black"
          type="number"
          placeholder="Max"
          value={localMaxValue}
          onChange={({ target }) => setLocalMaxValue(target.value)}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default SquareFeet;
