import React from 'react';

import { ANY } from '../../../../constants/building/availableCondos';
import useFilterPopup from '../../../../hooks/useFilterPopup';

import CbbDropdown from '../../../CbbDropdown';

import { SetFiltersValues } from '../../types';

interface Props {
  min: string;
  max: string;
  setValues: SetFiltersValues;
}

const BedroomRange: React.FC<Props> = ({ min, max, setValues }) => {
  const { setMaxValue, setMinValue, values, filterMaxValues, filterMinValues } =
    useFilterPopup({
      onChange,
      initialValues: { min, max },
    });

  function onChange(values: { min: string; max: string }) {
    setValues((prevValues) => ({ ...prevValues, anyBeds: values }));
  }

  return (
    <div className="flex flex-col mb-6 md:mb-0">
      <label htmlFor="" className="mt-[6px] md:mt-0 md:text-14-26-0.3">
        Bedroom Range
      </label>
      <div className="relative flex align-center leading-[21px]">
        <CbbDropdown
          rootClassName="w-[152px] md:w-[137px] pb-[8px] pt-[8px] pl-[16px] text-black h-[44px]"
          selectClassName="w-[152px] md:w-[137px] -left-0.1"
          nameSelected="Any"
          onChange={setMinValue}
          options={filterMinValues([ANY, '0', '1', '2', '3', '4', '5'])}
          initialSelectedOption={values.min}
        />
        <div className="self-center w-[10px] md:px-0 mx-[10px] md:w-[8px] md:mx-[8px] bg-beige border" />
        <CbbDropdown
          rootClassName="w-[152px] md:w-[137px] pb-[8px] pt-[8px] pl-[16px] text-black"
          selectClassName="-left-0.1 w-[152px] md:w-[137px]"
          nameSelected="Any"
          onChange={setMaxValue}
          options={filterMaxValues([ANY, '0', '1', '2', '3', '4', '5'])}
          initialSelectedOption={values.max}
        />
      </div>
    </div>
  );
};

export default BedroomRange;
