import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { Dialog } from '@headlessui/react';
import classnames from 'classnames';
import { styled } from '@mui/system';
import arrowButton from '../public/images/arrow-button.svg';

import iconClose from '../public/images/close.svg';
import topicsArrow from '../public/images/arrow-topics.svg';

type Props = {
  name?: string;
  isBlogButton?: boolean;
  buttonIsLoading: boolean | undefined;
  popupOpen: boolean;
  buttonWidthFull?: boolean;
  closePopup: () => void;
};

const PopupDiv = styled('div')({
  zIndex: 9999,
});

const PopUpThanks: React.FC<Props> = (props: Props) => {
  const {
    popupOpen,
    closePopup,
    buttonIsLoading,
    isBlogButton,
    buttonWidthFull = false,
  } = props;

  return (
    <>
      <button
        className={`flex justify-around self-end mt-[20px] w-[143px] md:w-32 py-[14px] md:mt-[10px] lg:mt-10 md:mb-[32px] lg:mb-[29px] uppercase  ${classnames(
          {
            '!w-full !justify-between !md:justify-around': buttonWidthFull,
            'bg-gold-hover hover:bg-gold-hover': buttonIsLoading,
            'bg-gold hover:bg-gold-hover': !buttonIsLoading,
            'h-[48px] md:!w-[306px] md:h-[63px] flex items-center justify-between uppercase bg-gold hover:bg-gold-hover font-sohneBreitHalbfett text-14-26-0.3 lg:text-16-26-0.3 max-w-full md:max-w-full py-[11.5px] md:py-[13.5px] lg:!pt-[21px] lg:pb-[21px] pl-6 lg:pl-6 pr-[15px] lg:pr-[21px] mt-[8px] md:my-4 md:ml-[4px] ':
              props?.isBlogButton,
          }
        )}`}
      >
        <div className="text-16-16-0.3">
          {buttonIsLoading ? 'Sending' : props?.name ? props.name : 'Send'}
        </div>
        {isBlogButton && !buttonIsLoading && (
          <div className="w-6 lg:pl-[3px] lg:w-auto flex align-center">
            <Image
              src={arrowButton}
              alt="arrow-button"
              height={21}
              width={29}
            />
          </div>
        )}
      </button>
      <Dialog
        open={popupOpen}
        onClose={() => closePopup()}
        className="flex justify-center w-screen h-screen "
      >
        <PopupDiv>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </PopupDiv>
        <PopupDiv
          className={`fixed top-0 ${props?.isBlogButton ? 'top-9' : ''}`}
        >
          <div className="w-screen h-screen p-10 bg-white border border-gray md:h-full md:w-full">
            <div className="flex flex-row items-end justify-end">
              <button className="outline-none" onClick={() => closePopup()}>
                <Image
                  src={iconClose}
                  alt="close popup"
                  width={25}
                  height={25}
                />
              </button>
            </div>
            <div className="flex justify-center">
              <div className="flex items-center justify-center border-8 rounded-full h-52 w-52 border-gold">
                <Image
                  src={topicsArrow}
                  alt="close popup"
                  width={100}
                  height={100}
                />
              </div>
            </div>
            <div className="py-6 text-47-54-1.25 text-center uppercase font-sohneBreitHalbfett">
              Thank you
            </div>
            <div className="py-2 text-20-28-0.3 text-center font-sohneBreitHalbfett">
              Your request has been succesfully sent.
            </div>
            <div className="py-2 text-20-28-0.3 text-center font-sohneBreitHalbfett">
              We will contact you very soon!
            </div>
            <div className="flex justify-center md:w-112">
              <button
                type="submit"
                onClick={() => closePopup()}
                className="self-end w-1/3 h-10 mt-6 text-12-18-0.3 uppercase border justify-self-center hover-animation bg-gold hover:bg-gold-hover md:text-16-26-0.3 border-gold hover:border-gold-hover"
              >
                Okay
              </button>
            </div>
          </div>
        </PopupDiv>
      </Dialog>
    </>
  );
};

export default PopUpThanks;
