import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Helmet } from 'react-helmet';
import Script from 'next/script';

type GoogleAnalyticsProps = {
  pageGroup: string;
};

export const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({
  pageGroup,
}) => {
  const router = useRouter();

  useEffect(() => {
    const handlePageView = (url: any) => {
      window.gtag('config', 'G-X7BM6WN2N9', {
        page_path: url,
        content_group: pageGroup,
      });
      // window.gtag('event', 'contact_us_body', {
      //   event_category: 'contact_us_body',
      //   event_label: 'Success',
      //   value: 120,
      // });
      // window.gtag('event', 'contact_us_header', {
      //   event_category: 'contact_us_header',
      //   event_label: 'Success',
      //   value: 120,
      // });
      // window.gtag('event', 'click_phone', {
      //   event_category: 'click_phone',
      //   event_label: 'Success',
      //   value: 120,
      // });
      // window.gtag('event', 'registration_successful', {
      //   event_category: 'registration_successful',
      //   event_label: 'Success',
      //   value: 120,
      // });
    };

    const handleRouteChange = (url: any) => {
      handlePageView(url);
    };

    // Track initial page view
    handlePageView(router.asPath);

    // Track subsequent route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <Helmet>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-X7BM6WN2N9"
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-X7BM6WN2N9', {
            'content_group': '${pageGroup}'
          });
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
