import React from 'react';
import FlagLabel from '../components/FlagLabel';
import FeatureItem from '../components/FeatureItem';
import LineBetweenSections from './LineBetweenSections';

import {
  StrapiMainFeature,
  MainFeatureToDisplay,
} from 'apolloClient/types/Neighborhood';
import { mainFeaturesCommonDict } from 'constants/mainFeatures';

type MainFeatureProps = {
  nbhFeaturesWalkscore: StrapiMainFeature[];
  nbhFeaturesCommon: StrapiMainFeature[];
};

const serializeMainFeatures = (
  mainFeaturesCommonDict: MainFeatureToDisplay[],
  inputFeatures: StrapiMainFeature[]
): MainFeatureToDisplay[] => {
  return mainFeaturesCommonDict
    .map((feature) => {
      const foundFeature = inputFeatures.find(
        (it) => !!it.description && it.type === feature.type
      );
      if (foundFeature) {
        return { ...feature, description: foundFeature.description };
      }
      return null;
    })
    .filter((feature) => feature !== null) as MainFeatureToDisplay[];
};

const MainFeatures: React.FC<MainFeatureProps> = ({
  nbhFeaturesWalkscore,
  nbhFeaturesCommon,
}) => {
  const features = [...nbhFeaturesWalkscore, ...nbhFeaturesCommon];
  function renderFeatureItem() {
    const allFeatures: MainFeatureToDisplay[] = [];
    const serializedFeatures = serializeMainFeatures(
      mainFeaturesCommonDict,
      features
    );

    for (let i = 0; i < features.length; i++) {
      for (let j = 0; j < serializedFeatures.length; j++) {
        if (
          serializedFeatures[j].type === features[i].type &&
          !allFeatures.includes(serializedFeatures[j])
        ) {
          allFeatures[i] = serializedFeatures[j];
          break;
        }
      }
    }

    return serializeMainFeatures(allFeatures, features).map(
      ({ type, title, description, image, altImage, walkscore }) =>
        type && (
          <FeatureItem
            key={title}
            title={title}
            description={description || ''}
            image={image}
            altImage={altImage}
            walkscore={walkscore}
          />
        )
    );
  }
  return (
    <>
      <LineBetweenSections hideLine={'container'} />
      <section className="container pt-3 md:py-4 lg:py-2 text-m">
        <div className="flex">
          <div className="relative flex flex-col justify-center mb-10 w-max">
            <FlagLabel name="Main features" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {renderFeatureItem()}
        </div>
      </section>
    </>
  );
};

export default MainFeatures;
