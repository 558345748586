import React, { useContext, useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

import TransitionWrapper from '../../TransitionWrapper';

import MonthlyCostFlagLabel from './components/MonthlyCostFlagLabel';
import CalculatedValue from './components/CalculatedValue';
import PrincipalInterestForm from './forms/PrincipalInterestForm';
import HomeInsuranceForm from './forms/HomeInsuranceForm';
import UtilitiesForm from './forms/UtilitiesForm';
import PropertyTaxesForm from './forms/PropertyTaxesForm';
import HOAFeesForm from './forms/HOAFeesForm';
import { calculateTotalValue } from './utils';
import ErrorBoundary from 'components/ErrorBoundary';
import LineBetweenSections from 'components/LineBetweenSections';

const MonthlyCostContainer: React.FC = () => {
  const { listing } = useContext(UnitPageContext) as UnitPageContextInterface;
  const { price, taxAmount, hoaFees, maintenanceFee } = listing;

  const [calculatedValue, setCalculatedValue] = useState(0);
  const [principalInterestForm, setPrincipalInterestForm] = useState(0);
  const [homeInsuranceForm, setHomeInsuranceForm] = useState(0);
  const [utilitiesForm, setUtilitiesForm] = useState(0);
  const [propertyTaxesForm, setPropertyTaxesForm] = useState(0);
  const [hoaFeesForm, setHOAFeesForm] = useState(0);

  useEffect(() => {
    setCalculatedValue(
      calculateTotalValue({
        principalInterestForm,
        homeInsuranceForm,
        utilitiesForm,
        propertyTaxesForm,
        hoaFeesForm,
      })
    );
  }, [
    principalInterestForm,
    homeInsuranceForm,
    utilitiesForm,
    propertyTaxesForm,
    hoaFeesForm,
  ]);

  if (
    price === null ||
    taxAmount === null ||
    (hoaFees === null && maintenanceFee === null)
  )
    return null;

  return (
    <ErrorBoundary>
      <section className="container pt-[22.5px] md:pt-[32px] lg:pt-2 text-m">
        <Disclosure defaultOpen={true} as="div" className="mb-6">
          {({ open }) => (
            <>
              <MonthlyCostFlagLabel open={open} />
              <TransitionWrapper>
                <Disclosure.Panel>
                  <div className="flex flex-col lg:flex-row lg:justify-between">
                    <CalculatedValue calculatedValue={calculatedValue} />
                    <div className="flex flex-col lg:w-[532px]">
                      <PrincipalInterestForm
                        onChange={setPrincipalInterestForm}
                        initialHomePrice={String(price)}
                      />
                      <HomeInsuranceForm onChange={setHomeInsuranceForm} />
                      <UtilitiesForm onChange={setUtilitiesForm} />
                      <PropertyTaxesForm
                        onChange={setPropertyTaxesForm}
                        homePrice={price}
                        taxAmount={taxAmount}
                      />
                      <HOAFeesForm
                        onChange={setHOAFeesForm}
                        associationFeePaidPer={String(
                          hoaFees || maintenanceFee
                        )}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </TransitionWrapper>
            </>
          )}
        </Disclosure>
      </section>
      <LineBetweenSections hideLine={'container !pb-0'} />
    </ErrorBoundary>
  );
};

export default MonthlyCostContainer;
