import React from 'react';
import Image from 'next/legacy/image';
import { Theme, useMediaQuery } from '@mui/material';

import Button from '../components/Button';
import arrowDown from '../public/images/arrow-down.svg';
import classnames from 'classnames';

type RelatedArticlesProps = {
  showButtonMore?: string;
  name?: string;
  buttonName?: string;
  children?: React.ReactNode;
  isHomePage?: boolean;
  flexGap?: string;
  classNameWidth?: string;
};

const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  showButtonMore,
  name = 'Related articles',
  children,
  buttonName = 'Read more',
  isHomePage,
  flexGap,
  classNameWidth,
}) => {
  const lessLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  return (
    <section
      className={classnames(
        'md:mb-[95px] lg:mb-[109px] pt-[17px] md:pt-[36px] lg:pt-[57px]',
        {
          'container-xl md:!pl-[45px] pt-[11px] md:mb-[95px] lg:mb-[122px]':
            isHomePage,
          container: !isHomePage,
        }
      )}
    >
      <div className="flex flex-col md:mb-4">
        <div className="flex ">
          <div className="items-center justify-center hidden w-10 md:flex lg:w-16 md:ml-[2px] lg:ml-[8px] md:mr-[3px]">
            <div className="w-10 lg:w-16 h-0.5 bg-black" />
          </div>
          <div className="text-24-32-0.3  uppercase lg:text-30-36-1 md:mx-4 font-sohneBreitBuch">
            <button className="flex w-full uppercase ml-[2px]">
              {/* <div className="block md:hidden mr-[7px]">
                <Image src={arrowDown} alt="show more" />
              </div> */}
              <h2 className="pt-1 text-left text-23-29-0.77 xl:text-30-47-1">
                {name}
              </h2>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`grid grid-cols-1 gap-10 md:gap-[30px] lg:gap-[40px] mt-[29px] md:mt-[22px] lg:mt-[30px] lg:ml-[2px] mb-4 md:grid-cols-3 ${flexGap}`}
      >
        {children}
      </div>
      <div
        className={`flex items-center justify-center mx-auto mt-[76px] md:mt-[59px] lg:mt-[74px] md:w-[323px] lg:w-[398px] lg:px-4 ${showButtonMore}`}
      >
        <Button
          name={buttonName}
          href={'/blog'}
          nofollow={true}
          styles={{
            height: `${lessLg ? '48px' : '63px'}`,
          }}
          classNames="py-[15px] md:pb-0"
          classNameWidth={classNameWidth}
        />
      </div>
    </section>
  );
};

export default RelatedArticles;
