import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import Image from 'next/legacy/image';

import arrowLeft from 'public/images/arrow-left.svg';
import FlagLabel from 'components/FlagLabel';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

import MarkdownParser from '../MarkdownParser';

const UnitDescription: React.FC = () => {
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const {
    building,
    listing: { unitNumber, remarks, unit },
  } = useContext(UnitPageContext) as UnitPageContextInterface;
  return (
    <div className="flex flex-col pt-3 mx-0 md:pt-[39px] lg:pt-[56px] md:w-1/2 lg:w-7/12 md:mr-[10px] lg:mr-0 md:mb-[10px] lg:mb-0">
      <div className="relative flex flex-col justify-center mb-10 w-max">
        <FlagLabel name="About" />
      </div>
      <h2 className="mb-10 text-18-23-0.3 md:text-21-28-0.3 font-sohneKraftig">
        {building.name} #{unitNumber || unit?.data?.attributes?.unique}
      </h2>
      {remarks && (
        <>
          <div
            className={classnames(
              'relative text-16-21-0.3 md:leading-6 md:pr-1 lg:16-26-0.3 pb-[56px]',
              {
                'max-h-112': !descriptionOpen,
                'overflow-hidden': !descriptionOpen,
              }
            )}
          >
            <MarkdownParser
              markdown={remarks}
              classNames="text-14-21-0.3 lg:text-16-26-0.3"
            />
            {remarks.length > 1040 && (
              <div
                className={classnames('absolute bottom-0 h-10 w-full', {
                  hidden: descriptionOpen,
                })}
                style={{
                  background: 'linear-gradient(rgba(255,255,255,0),#fff)',
                }}
              />
            )}
          </div>
          {remarks.length > 1040 && (
            <button
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
            >
              <div className="w-5 h-4">
                <Image
                  className={classnames({
                    'transform -rotate-90': descriptionOpen,
                  })}
                  src={arrowLeft}
                  alt={`${descriptionOpen ? 'Hide' : 'Read more'}`}
                  width={15}
                />
              </div>
              <span className="whitespace-nowrap">
                {`${descriptionOpen ? 'Hide' : 'Read more'}`}
              </span>
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default UnitDescription;
