import React, { ReactNode } from 'react';
import Image from 'next/legacy/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Dialog } from '@mui/material';
import { styled } from '@mui/system';

import Share from 'components/Share';
import BlurredBgImage from './BlurredBgImage';

import unitLeftArrow from '../../public/images/unit_left_arrow.svg';
import unitRightArrow from '../../public/images/unit_right_arrow.svg';
import arrowLeft from '../../public/images/arrow-left.svg';
import iconClose from '../../public/images/close.svg';
import { StrapiMediaInfo } from '../../apolloClient/types';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: hidden;
  }
`;

type PopUpViewLargePhotoProps = {
  open: boolean;
  onClose: () => void;
  images?: StrapiMediaInfo[];
  actionHandle?: () => void;
  renderHeader: JSX.Element;
  initialActiveIndex?: number;
};

const PopUpViewLargePhoto: React.FC<PopUpViewLargePhotoProps> = ({
  open,
  onClose,
  images,
  actionHandle,
  renderHeader,
  initialActiveIndex,
}) => {
  const ViewLargePrevArrow = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className="transform slick-arrow slick-prev slick-prev-view-large !left-[25px] !top-[45%] !translate-y-[-50%] md:!top-[200px] xl:!top-[269px]"
    >
      <Image src={unitLeftArrow} alt="previous" width="28" height="48" />
    </button>
  );

  const ViewLargeNextArrow = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className="transform slick-arrow slick-next slick-next-view-large !top-[45%] !translate-y-[-50%] md:!top-[200px] xl:!top-[269px]"
    >
      <Image src={unitRightArrow} alt="next" width="28" height="48" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialActiveIndex,
    prevArrow: <ViewLargePrevArrow />,
    nextArrow: <ViewLargeNextArrow />,
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullScreen>
      <section className="">
        {renderHeader}
        <div className="container pt-[110px] md:pt-[50px]">
          <div className="flex justify-between">
            <button
              onClick={actionHandle}
              className="flex items-center mt-4 mb-4 uppercase border-b-2 cursor-pointer border-gold md:mt-8 md:mb-10 lg:my-6 w-max"
            >
              <div className="w-5 h-4">
                <Image src={arrowLeft} alt="view all photos" width={15} />
              </div>
              <span className="whitespace-nowrap">View all photos</span>
            </button>
            <div className="flex items-center">
              <div className="">
                <Share />
              </div>
              <button className="mt-1.5 ml-4" onClick={onClose}>
                <Image src={iconClose} alt="close" width={25} height={25} />
              </button>
            </div>
          </div>
          <div className="relative mb-28 popup-view-large">
            <Slider {...settings}>
              {images?.reduce<Array<ReactNode>>(
                (acc, { hash, name, url, formats }) => {
                  const imageUrl = formats?.large?.url || url;
                  const blurredMainImageUrl =
                    formats?.thumbnail?.url ||
                    formats?.small?.url ||
                    formats?.medium?.url ||
                    imageUrl;
                  acc.push(
                    <div key={hash}>
                      <div className="relative flex mx-auto lg:w-3/4 h-60 md:h-102 xl:h-135">
                        <BlurredBgImage
                          src={imageUrl}
                          alt={name}
                          blurDataURL={blurredMainImageUrl}
                        />
                      </div>
                    </div>
                  );

                  return acc;
                },
                []
              )}
            </Slider>
          </div>
        </div>
      </section>
    </StyledDialog>
  );
};

export default PopUpViewLargePhoto;
