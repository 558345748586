import { gql } from '@apollo/client';

export const SEARCH_LISTINGS_COUNT = gql`
  query SearchListingsCount($filters: FiltersValues, $buildingId: ID) {
    getListingsCountData(filters: $filters, buildingId: $buildingId)
  }
`;

export const SEARCH_LISTINGS = gql`
  query SearchListings(
    $filters: ListingFiltersInput
    $sortOption: [String]
    $pageSize: Int
    $page: Int
    $userId: ID
  ) {
    listings(
      filters: $filters
      sort: $sortOption
      pagination: { pageSize: $pageSize, page: $page }
    ) {
      data {
        id
        attributes {
          yearBuilt
          isShortSale
          isReo
          propertyTypeCode
          statusCode
          closeDate
          sqft
          priceSqft
          bedsTotal
          bathsFull
          bathsHalf
          price
          unitNumber
          hasFloorplan
          images {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          virtualTourUrl
          address {
            zipCode
            streetName
            streetNumber
            streetType
            streetDirection
          }
          unit {
            data {
              id
              attributes {
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                latestRentListing {
                  data {
                    attributes {
                      closeDate
                      soldPrice
                    }
                  }
                }
                building {
                  data {
                    attributes {
                      lat
                      lon
                      primaryAddress
                      name
                      slug
                      isPreconstruction
                      neighborhood {
                        data {
                          id
                          attributes {
                            slug
                            name
                            city {
                              data {
                                id
                                attributes {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                unique
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const SEARCH_BUILDINGS = gql`
  query SearchBuildings($filters: ListingFiltersInput) {
    buildings(
      filters: {
        units: { listings: $filters }
        publishedAt: { notNull: true }
        lat: { notNull: true }
        lon: { notNull: true }
        neighborhood: { publishedAt: { notNull: true } }
      }
      pagination: { pageSize: 1000 }
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          slug
          name
          primaryAddress
          lat
          lon
        }
      }
    }
  }
`;

export const SEARCH_DATA = gql`
  query SearchPageData(
    $filters: FiltersValues
    $pageSize: Int
    $page: Int
    $userId: ID
    $buildingId: ID
    $loadBuildings: Boolean
  ) {
    getSearchPageData(
      filters: $filters
      userId: $userId
      buildingId: $buildingId
      loadBuildings: $loadBuildings
      pagination: { pageSize: $pageSize, page: $page }
    ) {
      listings {
        data {
          id
          year_built
          is_short_sale
          is_reo
          property_type_code
          status_code
          close_date
          sqft
          price_sqft
          beds_total
          baths_full
          baths_half
          price
          unit_number
          virtual_tour_url
          zip_code
          street_name
          street_number
          street_type
          street_direction
          unitId
          unitUnique
          lat
          lon
          primary_address
          buildingName
          buildingSlug
          is_preconstruction
          neighborhoodId
          neighborhoodSlug
          neighborhoodName
          cityId
          cityName
          favoriteId
          favoriteType
          floorplanId
          lastRentListingCloseDate
          lastRentListingSoldPrice
          images {
            id
            url
            formats
          }
        }
        meta {
          pagination {
            total
            page
            pageSize
            pageCount
          }
        }
      }
      buildings {
        id
        slug
        name
        primaryAddress
        lat
        lon
        listingscount
      }
    }
  }
`;

export const SEARCH_FLOORPLANS_BY_LISTING_ID = gql`
  query GetFloorplanByListingId($id: Int) {
    getFloorplanByListingId(id: $id) {
      id
      apartmentNumbers
      area
      exteriorArea
      bedBath
      image {
        data {
          attributes {
            url
          }
        }
      }
      floors {
        id
        from
        to
      }
      model
    }
  }
`;

export const SEARCH_FLOORPLANS = gql`
  query SearchFloorplans($ids: [ID]) {
    buildings(
      filters: { floorplans: { listings: { id: { in: $ids } } } }
      pagination: { pageSize: 1000 }
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          floorplans(filters: { listings: { id: { in: $ids } } }) {
            image {
              data {
                attributes {
                  url
                  previewUrl
                  provider
                }
              }
            }
            listings(filters: { id: { in: $ids } }) {
              data {
                id
              }
            }
            exteriorArea
            id
          }
        }
      }
    }
  }
`;

export const GET_LISTING_TYPES_COUNT = gql`
  query ListingTypesCount(
    $saleFilter: ListingFiltersInput
    $newConstructionFilter: ListingFiltersInput
    $preConstructionFilter: ListingFiltersInput
    $foreclosuresFilter: ListingFiltersInput
    $shortSalesFilter: ListingFiltersInput
    $existingCondosFilter: ListingFiltersInput
    $rentFilter: ListingFiltersInput
    $soldFilter: ListingFiltersInput
  ) {
    forSale: listings(filters: $saleFilter) {
      ...Count
    }
    newConstruction: listings(filters: $newConstructionFilter) {
      ...Count
    }
    preConstruction: listings(filters: $preConstructionFilter) {
      ...Count
    }
    foreclosures: listings(filters: $foreclosuresFilter) {
      ...Count
    }
    shortSales: listings(filters: $shortSalesFilter) {
      ...Count
    }
    existingCondos: listings(filters: $existingCondosFilter) {
      ...Count
    }
    forRent: listings(filters: $rentFilter) {
      ...Count
    }
    sold: listings(filters: $soldFilter) {
      ...Count
    }
  }

  fragment Count on ListingEntityResponseCollection {
    meta {
      pagination {
        total
      }
    }
  }
`;

export const LISTINGS_BY_BUILDING_SLUG = gql`
  query ($slug: String, $limit: Int, $date: DateTime) {
    listings(
      filters: {
        unit: { building: { slug: { eq: $slug } } }
        statusCode: { eq: "CS" }
        closeDate: { gte: $date }
      }
      pagination: { pageSize: $limit }
    ) {
      data {
        id
        attributes {
          propertyTypeCode
          unitNumber
          bedsTotal
          bathsFull
          bathsHalf
          sqft
          price
          soldPrice
          closeDate
          unit {
            data {
              attributes {
                unique
                building {
                  data {
                    attributes {
                      primaryAddress
                      slug
                      neighborhood {
                        data {
                          attributes {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LISTINGS_FOR_MAP_MARKER = gql`
  query getListingsForMapMarker(
    $filters: ListingFiltersInput!
    $page: Int = 0
    $limit: Int = 1000
  ) {
    listings(filters: $filters, pagination: { pageSize: $limit, page: $page }) {
      data {
        attributes {
          unitNumber
          mlsListingId
          price
          sqft
          bedsTotal
          bathsHalf
          bathsFull
          propertyTypeCode
          images {
            data {
              attributes {
                url
              }
            }
          }
          unit {
            data {
              id
              attributes {
                unique
                building {
                  data {
                    id
                    attributes {
                      slug
                      neighborhood {
                        data {
                          id
                          attributes {
                            slug
                            city {
                              data {
                                id
                                attributes {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageCount
        }
      }
    }
  }
`;
