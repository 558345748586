import React from 'react';
import NeighborhoodAccordion from './NeighborhoodAccordion';
import { Neighborhood } from 'apolloClient/types';

interface BasicAccordionProps {
  countyName: string;
  cities: {
    cityName: string;
    neighborhoods: Neighborhood[];
  }[];
}

const BasicAccordion: React.FC<BasicAccordionProps> = ({
  countyName,
  cities,
}) => {
  return <NeighborhoodAccordion countyName={countyName} cities={cities} />;
};

export default BasicAccordion;
