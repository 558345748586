import {
  MappedAgent,
  AgentFUB,
  CustomAgentFUB,
} from '../../apolloClient/types/Agents';

export const defaultAgentImage =
  'https://media.architecturaldigest.com/photos/5e679e7d9f1ae10008cf0e1e/16:9/w_2560%2Cc_limit/GettyImages-1203653538.jpg';

export function mapAgent(agent: AgentFUB | null): MappedAgent | null {
  if (!agent) return null;
  const { email, name, picture, phone, job, company } = agent;
  const [firstName, lastName] = name?.split(' ') || [];

  return {
    firstName,
    lastName,
    email,
    job,
    phone,
    company,
    image: picture?.original || defaultAgentImage,
  };
}

export function mapCustomAgent(
  agent: CustomAgentFUB | undefined
): MappedAgent | null {
  if (!agent) return null;
  const { email, name, picture, phone, job, company, agentData } = agent;
  const [firstName, lastName] = name?.split(' ') || [];

  return {
    firstName,
    lastName,
    email,
    job: job || agentData?.job,
    phone: agentData?.phone || phone,
    company: company || agentData?.company,
    image: picture?.original || defaultAgentImage,
  };
}
