import React, { Fragment, useContext, useState } from 'react';
import { Tab, Disclosure } from '@headlessui/react';
import Image from 'next/legacy/image';
import { format } from 'date-fns';

import arrowLeft from 'public/images/arrow-left.svg';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';

import FlagLabel from '../../components/FlagLabel';
import TransitionWrapper from '../../components/TransitionWrapper';
import {
  centenaryNumberFormatter,
  getStatusText,
  numberFormatter,
  percentFormatter,
  priceFormatter,
} from '../../src/utils/utils';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { HistoryItem } from '../../apolloClient/types/Units';

import { Table } from '../Table';
import LineBetweenSections from 'components/LineBetweenSections';

const PriceHistory: React.FC = () => {
  const { listing } = useContext(UnitPageContext) as UnitPageContextInterface;
  const history = listing.unit.data.attributes.history;
  const [showMore, setShowMore] = useState(history.length > 3);
  const [showLess, setShowLess] = useState(false);

  const toggleButton = () => {
    setShowMore(!showMore);
    setShowLess(!showLess);
  };

  function getDataForTable(history: HistoryItem[]) {
    const data = history.map((history) => {
      const firstPrice = history.oldPrice;
      const lastPrice = history.newPrice;
      const delta = +centenaryNumberFormatter(
        ((lastPrice - firstPrice) * 100.0) / firstPrice
      );
      return {
        id: history.id,
        date:
          format(new Date(history.changedDate), 'yyyy-MM-dd') ||
          NO_DATA_PLACEHOLDER,
        event: history,
        statusCode: history.newStatusCode || NO_DATA_PLACEHOLDER,
        previousStatusCode: history.oldStatusCode || NO_DATA_PLACEHOLDER,
        price: history.newPrice || NO_DATA_PLACEHOLDER,
        delta: delta || NO_DATA_PLACEHOLDER,
        pricePerFt:
          listing.sqft > 0 && history.newPrice > 0
            ? priceFormatter(history.newPrice / listing.sqft)
            : NO_DATA_PLACEHOLDER,
        source:
          history.source === 'Hook'
            ? 'MLS'
            : history.source === 'Attom'
            ? 'Public records'
            : NO_DATA_PLACEHOLDER,
      };
    });

    if (showMore) {
      return data.slice(0, 3);
    }

    return data;
  }

  return (
    <>
      {history.length > 0 && (
        <>
          <section className="container pt-6 pb-8 md:pt-[33px] text-m">
            <Disclosure defaultOpen={true} as="div" className="mb-6">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    as="div"
                    className="flex cursor-pointer md:hidden"
                  >
                    <div className="relative flex flex-col justify-center w-max">
                      <FlagLabel
                        imageClassName={`${open ? '' : 'transform rotate-180'}`}
                        iconFlagLabel={true}
                        name="Price history"
                      />
                    </div>
                  </Disclosure.Button>
                  <div className="hidden md:flex">
                    <div className="relative flex flex-col justify-center w-max">
                      <FlagLabel
                        imageClassName={`${open ? '' : 'transform rotate-180'}`}
                        iconFlagLabel={true}
                        name="Price history"
                      />
                    </div>
                  </div>
                  <TransitionWrapper>
                    <Disclosure.Panel>
                      <Tab.Group>
                        <Tab.List>
                          {/*@
                          TODO:show public tax
                          <div className="flex">
                          */}
                          <div className="hidden">
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  className={`outline-none flex pb-2 pt-2.5 px-6 uppercase justify-center text-center border-gold border my-10 relative ${
                                    selected ? 'bg-white' : 'bg-gold'
                                  }`}
                                >
                                  <div
                                    className={
                                      selected
                                        ? 'absolute w-3 h-3 bg-white -bottom-1.7 bg-write border-r border-b z-10 border-gold transform rotate-45'
                                        : 'hidden'
                                    }
                                  />
                                  <span className="pt-0.5">Price history</span>
                                </button>
                              )}
                            </Tab>
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  className={`outline-none flex pb-2 pt-2.5 px-6 uppercase justify-center text-center border-gold border my-10 relative ${
                                    selected ? 'bg-white' : 'bg-gold'
                                  }`}
                                >
                                  <div
                                    className={
                                      selected
                                        ? 'absolute w-3 h-3 bg-white -bottom-1.7 bg-write border-r border-b z-10 border-gold transform rotate-45'
                                        : 'hidden'
                                    }
                                  />
                                  <span className="pt-0.5">Public tax</span>
                                </button>
                              )}
                            </Tab>
                          </div>
                        </Tab.List>
                        <Tab.Panels>
                          <Tab.Panel>
                            <div className="mt-[44px]">
                              <Table
                                rowClasses="font-sohneKraftig text-14-18-0.3 md:text-15-17-0.3 xl:text-21-28-0.3 whitespace-nowrap my-[18px] mx-[4px]"
                                columns={[
                                  {
                                    key: 'date',
                                    headingMobile: 'DATE',
                                  },
                                  {
                                    key: 'event',
                                    headingMobile: 'EVENT',
                                    cellRender: (data) => {
                                      const statusText = getStatusText(
                                        data,
                                        listing
                                      );
                                      const isLongStatus = statusText
                                        .toLowerCase()
                                        .includes('status change');

                                      return (
                                        <div
                                          className={`w-full xl:w-80 ${
                                            !!isLongStatus &&
                                            'whitespace-normal capitalize min-w-[230px] mt-[-10px] xl:mt-[-17px] mb-[-8px] xl:mb-[-11px]'
                                          }`}
                                        >
                                          {statusText || NO_DATA_PLACEHOLDER}
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    key: 'price',
                                    headingMobile: 'PRICE',
                                    widthRatio: 2,
                                    cellRender: (data) => (
                                      <div className="flex justify-center w-full gap-[10px] whitespace-nowrap">
                                        <div className="md:w-auto" />
                                        <div className="flex justify-center w-3/5 px-2 md:px-0 md:w-auto">
                                          {priceFormatter(data.price)}
                                        </div>
                                        <div className="flex w-auto">
                                          {data.delta > 0 && (
                                            <div className="text-azure text-md">
                                              {`+${numberFormatter(
                                                data.delta
                                              )} %`}
                                            </div>
                                          )}
                                          {data.delta < 0 && (
                                            <div className="text-red text-md">
                                              {`${numberFormatter(
                                                data.delta
                                              )} %`}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ),
                                  },
                                  {
                                    key: 'pricePerFt',
                                    heading: 'PRICE / SQ FT',
                                    headingMobile: '$ / SQ FT',
                                  },
                                  {
                                    key: 'source',
                                    headingMobile: 'SOURCE',
                                  },
                                ]}
                                data={getDataForTable(history)}
                              />
                            </div>
                            {showMore && (
                              <button
                                onClick={toggleButton}
                                className="min-w-[57px] flex items-center mt-4 text-16-26-0.3 uppercase border-b-[3px] cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
                              >
                                <div className="w-[8px] h-[14px] mr-[7px]">
                                  <Image
                                    src={arrowLeft}
                                    alt="Show more"
                                    width={8}
                                    height={14}
                                  />
                                </div>
                                <span className="pt-[6px] whitespace-nowrap">
                                  Show more
                                </span>
                              </button>
                            )}
                            {showLess && (
                              <button
                                onClick={() => toggleButton()}
                                className="min-w-[57px] flex items-center mt-4 text-16-26-0.3 uppercase border-b-[3px] cursor-pointer border-gold-dark md:mt-8 md:mb-10 lg:my-6 w-max"
                              >
                                <div className="w-[8px] h-[14px] mr-[7px] transform -rotate-90">
                                  <Image
                                    src={arrowLeft}
                                    alt="Show less"
                                    width={8}
                                    height={14}
                                  />
                                </div>
                                <span className="pt-[2px] pl-[4px] whitespace-nowrap">
                                  Show less
                                </span>
                              </button>
                            )}
                          </Tab.Panel>
                          <Tab.Panel>
                            <Table
                              rowClasses="font-sohneKraftig text-16-26-0.3"
                              columns={[
                                {
                                  key: 'year',
                                  headingMobile: 'YEAR',
                                  cellRender: (data) => data.date,
                                },
                                {
                                  key: 'propertyTaxes',
                                  heading: 'PROPERTY TAXES',
                                  headingMobile: 'TAXES',
                                  cellRender: (data) => (
                                    <div>
                                      <span>{numberFormatter(data.price)}</span>
                                      <span className="ml-2 text-red">
                                        {percentFormatter(
                                          data.priceChange / 100
                                        )}
                                      </span>
                                    </div>
                                  ),
                                },
                                {
                                  key: 'taxAssessment',
                                  heading: 'TAX ASSESSMENT',
                                  headingMobile: 'ASSESSMENT',
                                  cellRender: (data) => (
                                    <div>
                                      <span>{numberFormatter(data.price)}</span>
                                      <span className="ml-2 text-red">
                                        {percentFormatter(
                                          data.priceChange / 100
                                        )}
                                      </span>
                                    </div>
                                  ),
                                },
                              ]}
                              data={[
                                {
                                  id: 1,
                                  date: '10/26/2020',
                                  event: 'Price Change',
                                  price: 775000,
                                  priceChange: -1.3,
                                },
                                {
                                  id: 2,
                                  date: '10/26/2020',
                                  event: 'Price Change',
                                  price: 775000,
                                  priceChange: -1.3,
                                },
                                {
                                  id: 3,
                                  date: '10/26/2020',
                                  event: 'Price Change',
                                  price: 775000,
                                  priceChange: -1.3,
                                },
                              ]}
                            />
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </Disclosure.Panel>
                  </TransitionWrapper>
                </>
              )}
            </Disclosure>
          </section>
          <LineBetweenSections hideLine={'container'} />
        </>
      )}
    </>
  );
};

export default PriceHistory;
