import React from 'react';

import { getFilterLabel } from '../../Search/utils/listingsFiltersUtils';

import AnyBedsPopup from '../../Search/AnyBedsPopup';

import { FiltersValues, OnChangeFilters } from '../types';
import PopoverSearchPage from './PopoverSearchPage';

interface Props {
  values: FiltersValues;
  onChangeFilters: OnChangeFilters;
}

const AnyBedsFilter: React.FC<Props> = ({ values, onChangeFilters }) => {
  return (
    <PopoverSearchPage
      buttonClassName="border xl:pt-0 xl:pl-[12px] xl:pr-[10px] border-beige-dark hidden xxl:px-2 xl:flex xl:text-14-21-0.3 md:pr-0 xxl:pr-2"
      name={getFilterLabel(values.anyBeds, 'Any') + ' Beds'}
      render={({ close }: any) => (
        <AnyBedsPopup
          closePopup={close}
          onChange={(value) => onChangeFilters({ ...values, anyBeds: value })}
          initialValues={values.anyBeds}
        />
      )}
    />
  );
};

export default AnyBedsFilter;
