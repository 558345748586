import {
  Building,
  BuildingFloorplans,
  StrapiBulkResponseData,
} from '../../apolloClient/types';
import { ORIGIN } from 'src/constants';
import { flattenStrapiBulkDataItems } from '../../lib/flattenStrapiBulkDataItems';
import { NO_DATA_PLACEHOLDER } from '../../constants/labels';
import { Listing } from '../../apolloClient/types/Units';
import { isNil } from 'lodash';
import { getATTFromLocalStorage } from './utils';

type getBuildingFloorplansArguments = BuildingFloorplans[] | undefined;

export type NormalizedBuildingFloorplan = {
  [key: string]: number | string;
  aptNo: string;
  model: string;
  bedBath: string;
  url: string;
  floors: any;
  interiorExt: string;
};

export function getBuildingFloorplans(
  floorplansData: getBuildingFloorplansArguments
): NormalizedBuildingFloorplan[] {
  const filterFloorplan = floorplansData !== undefined ? floorplansData : [];
  return filterFloorplan.map((floorplan: any) => {
    return {
      aptNo:
        floorplan.apartmentNumbers !== null
          ? floorplan.apartmentNumbers
          : NO_DATA_PLACEHOLDER,
      model: floorplan.model || NO_DATA_PLACEHOLDER,
      bedBath: floorplan.bedBath || NO_DATA_PLACEHOLDER,
      url: floorplan.image?.data?.attributes?.url || '',
      floors:
        floorplan.floors
          .filter((floor: any) => floor.from > 0)
          .map((floor: any) =>
            floor.from === floor.to
              ? floor.from
              : `${floor.from > 0 ? floor.from : ''} ${
                  floor.from > 0 && floor.to > 0 ? '-' : ''
                } ${floor.to > 0 ? `${floor.to}` : ''}`
          )
          .join(', ') || NO_DATA_PLACEHOLDER,
      interiorExt:
        (floorplan.area || NO_DATA_PLACEHOLDER) +
        ' + ' +
        (floorplan.exteriorArea || NO_DATA_PLACEHOLDER),
    };
  });
}

export function getContactFormContextData(
  building: undefined | Building,
  listing: undefined | Listing,
  buildingPageUrl: undefined | String,
  unitPageUrl: undefined | String,
  pageUrl: undefined | String
) {
  return {
    BuildingName: building?.name ?? '',
    //TODO: when we will have a logic for metatags (title) - make using title metatag OR specific pageTitle definition for current CBB page type (refer to legacy site)
    PageName: document.title ?? '',
    LeadType:
      listing?.propertyTypeCode !== undefined
        ? listing?.propertyTypeCode === 'R'
          ? 'RENT'
          : 'SALE'
        : '',
    BuildingAndUnit: building?.name
      ? `${building?.name}${
          !isNil(listing?.unitNumber) ? ` #${listing?.unitNumber}` : ''
        }`
      : '',
    Neighborhood: building?.neighborhood?.data?.attributes?.name ?? '',
    City:
      building?.neighborhood?.data?.attributes?.city?.data?.attributes?.name ??
      '',
    UnitPrice: listing?.price?.toString() ?? '',
    UnitPriceInt: listing?.price ?? null,
    UnitUrl: unitPageUrl ?? buildingPageUrl ?? '', //TODO: on unit page - get current page URL
    UnitAddress:
      (listing
        ? `${listing.address.streetNumber} ${
            listing.address.streetDirection != null
              ? `${listing.address.streetDirection} `
              : ''
          }${listing.address.streetName} # ${listing.unitNumber}`
        : building?.primaryAddress) ?? '', //TODO: building page and unit page: make backend logic for getting address; for building page - use {Address.StreetNumber} {Address.StreetName}; for unit - take its address*/
    UnitNumber: listing?.unitNumber ?? '',
    UnitZip: listing?.address?.zipCode ?? '',
    PpcUser: getATTFromLocalStorage() ? true : false, //TODO: when PPC logic will be ready - take from localStorage.getItem("ppc_user") (refer to legacy site)
    BuildingAvgPrice: building?.buildingStats?.averageSalePrice,
    IsPreConstruction: building?.isPreconstruction,
    pageUrl: ORIGIN && pageUrl ? `${ORIGIN}${pageUrl}` : ORIGIN,
  };
}
