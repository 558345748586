import React, { useContext, useMemo } from 'react';

import FlagLabel from 'components/FlagLabel';
import { KeyValueList } from 'components/KeyValueList';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import {
  abbreviatedPriceFormatterWithPrecision,
  priceFormatter,
} from 'src/utils/utils';

import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import {
  filterConveniencesLabels,
  filterAssociationLabels,
  getAmenitiesData,
  WATERFRONT,
  filterMaintenanceLabels,
  filterStyleLabels,
} from './amenitiesLabels';
import renderAmenitiesRows from './RenderAmenitiesRows';

const BuildingAmenities: React.FC = () => {
  const { building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const amenities = building.amenity;
  const scores = building.walkscore;
  const stats = building.buildingStats;
  const details = useMemo(() => {
    if (!amenities) return;
    let averageSaleSqftPrice = stats?.averageSaleSqftPrice
      ? stats?.averageSaleSqftPrice
      : `$${NO_DATA_PLACEHOLDER}`;
    let conveniences = amenities?.convenience
      ? filterConveniencesLabels(amenities.convenience)
      : NO_DATA_PLACEHOLDER;
    let associations = amenities?.association
      ? filterAssociationLabels(amenities.association)
      : NO_DATA_PLACEHOLDER;
    let maintenances = amenities?.maintenance
      ? filterMaintenanceLabels(amenities.maintenance)
      : NO_DATA_PLACEHOLDER;
    let styles = amenities?.style
      ? filterStyleLabels(amenities.style)
      : NO_DATA_PLACEHOLDER;
    const transitScore = scores?.transit
      ? `${scores.transit} (${scores.transitDescription})`
      : NO_DATA_PLACEHOLDER;
    const bikeScore = scores?.bike
      ? `${scores.bike} (${scores.bikeDescription})`
      : NO_DATA_PLACEHOLDER;
    return [
      {
        key: 'Monthly Avg Association Fee / Sq Ft',
        value: building?.buildingStats?.averageAssociationFeeSqftPrice
          ? abbreviatedPriceFormatterWithPrecision()(
              building.buildingStats.averageAssociationFeeSqftPrice
            )
          : NO_DATA_PLACEHOLDER,
      },
      {
        key: 'Avg Price / Sq Ft',
        value:
          +averageSaleSqftPrice > 0
            ? priceFormatter(+averageSaleSqftPrice)
            : `$${NO_DATA_PLACEHOLDER}`,
      },
      {
        key: 'Unit Conveniences',
        value: conveniences,
      },
      {
        key: 'Maintenance Includes',
        value: maintenances || NO_DATA_PLACEHOLDER,
      },
      {
        key: 'Type of Association',
        value: associations || NO_DATA_PLACEHOLDER,
      },
      {
        key: 'Style ',
        value: styles || NO_DATA_PLACEHOLDER,
      },
      {
        key: 'Transit Score ®',
        value: transitScore ? (
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="text-gold-dark hover:text-gold-hover"
            href="https://www.walkscore.com/how-it-works/"
          >
            {transitScore}
          </a>
        ) : (
          NO_DATA_PLACEHOLDER
        ),
      },
      {
        key: 'Bike Score ®',
        value: bikeScore ? (
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="text-gold-dark hover:text-gold-hover"
            href="https://www.walkscore.com/how-it-works/"
          >
            {bikeScore}
          </a>
        ) : (
          NO_DATA_PLACEHOLDER
        ),
      },
    ];
  }, [amenities?.convenience, scores]);

  const Amenities = useMemo(() => {
    const data = getAmenitiesData(amenities)
      ?.map((_) => {
        return {
          ..._,
          items: _.name === WATERFRONT && !_.items.length ? 'No' : _.items,
        };
      })
      .filter((_) => Boolean(_.items.length));
    return data ? renderAmenitiesRows(data) : null;
  }, []);

  if (!amenities) return null;
  return (
    <>
      <div className="flex flex-col md:border-b lg:flex-row text-m border-beige-dark">
        <div className="lg:w-1/2">
          <div className="flex md:pt-[30px]">
            <div className="relative flex flex-col justify-center mb-[37px] lg:mb-[37px] w-max">
              <FlagLabel name="Building Amenities" />
            </div>
          </div>
          <div className="flex flex-col mb-10">
            <div>{Amenities}</div>
          </div>
        </div>
        <div className="px-0 text-m lg:hidden">
          <div className="w-fill h-0.5 bg-beige" />
        </div>
        <div className="border-l-0 lg:w-1/2 lg:pl-[38px] border-beige-dark lg:border-l pb-[51px]">
          <div className="flex pt-6 md:pt-[30px]">
            <div className="relative flex flex-col justify-center mb-6 w-max md:mb-[37px]">
              <FlagLabel name="Additional details" />
            </div>
          </div>
          <div>{details && <KeyValueList fixedRatio rows={details} />}</div>
        </div>
      </div>
    </>
  );
};

export default BuildingAmenities;
