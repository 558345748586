import React, { useState } from 'react';
import Image from 'next/legacy/image';

import enlarge from '../../public/images/enlarge.svg';
import { Listing } from '../../apolloClient/types/Units';
import { Position } from '../../types';
import { EntityWithId, StrapiMediaInfo } from '../../apolloClient/types';
import { flattenStrapiDataItem } from '../../lib/flattenStrapiBulkDataItems';

import FlagLabel from '../FlagLabel';
import { KeyValueList } from '../KeyValueList';
import PopUpUnitBuilding from '../PopUpUnitBuilding';

import UnitPopupHeader from './UnitPopupHeader';
import { getRowsForUnitFloorplanDetails } from '../../src/utils/listing';
import PopUpContactUs from '../ContactUs/PopUpContactUs';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  getMediumImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { defaultImage } from 'src/constants/constants';

interface FloorplanSectionProps {
  entity: Listing;
  positionOnTheMap: Position | undefined;
  images: EntityWithId<StrapiMediaInfo>[];
}

const UnitFloorplanSection: React.FC<FloorplanSectionProps> = ({
  entity,
  positionOnTheMap,
  images,
}) => {
  const [isFloorplanDialogOpen, setFloorplanDialogOpen] = useState(false);
  const floorplan = entity?.floorplan;

  const floorplanImage = flattenStrapiDataItem(floorplan?.image?.data)?.url;
  const imagesExists = Boolean(images && images.length > 0);
  const floorPlanExists = Boolean(floorplan);
  const videoExists = Boolean(entity.virtualTourUrl);

  function openFloorplanDialog() {
    setFloorplanDialogOpen(true);
  }

  function closeFloorplanDialog() {
    setFloorplanDialogOpen(false);
  }

  return (
    <ErrorBoundary>
      <section
        id="FloorPlan"
        className="container pt-[7.5px] md:pt-[20px] pb-[68px] md:pb-[36px] lg:pb-[57px] lg:pt-2 text-m"
      >
        <div
          id="FloorPlan"
          className="absolute -top-20 md:-top-28 lg:-top-24"
        />
        <div className="flex">
          <div className="relative flex flex-col justify-center mb-[26px] md:mb-[44px] w-max">
            <FlagLabel name="The floorplan" />
          </div>
        </div>
        {floorPlanExists ? (
          <div className="flex flex-col lg:flex-row">
            <div className="relative flex justify-center min-h-full p-2 border-2 lg:w-2/3 border-gold md:px-8 md:pt-16 md:pb-[74px] lg:mr-8 lg:mr-[40px]">
              <div className="relative h-52 md:h-[386px] lg:mr-8 lg:h-96">
                <img
                  className="object-cover w-full h-full min-h-full mx-auto"
                  src={
                    floorplan.image?.data?.attributes
                      ? getMediumImageUrlFromImage(
                          floorplan.image?.data?.attributes
                        )
                      : defaultImage
                  }
                  alt="floorplan"
                />
              </div>
              <button
                onClick={openFloorplanDialog}
                className="absolute right-0 flex items-center px-2 uppercase border-2 hover:bg-gold hover-animation -bottom-14 md:bottom-4 lg:bottom-[18px] md:right-4 lg:right-[20px] border-gold"
              >
                <Image src={enlarge} alt="enlarge" className="w-4 mr-1" />
                <span className="pt-1 pl-2 whitespace-nowrap">Enlarge</span>
              </button>
              <PopUpUnitBuilding
                entityType="listing"
                entity={entity}
                open={isFloorplanDialogOpen}
                onClose={closeFloorplanDialog}
                activeTab={1}
                images={images}
                position={positionOnTheMap}
                tabsRenderExclude={{
                  photos: true,
                  floorPlan: !floorPlanExists,
                  tour: true,
                  directions: true,
                }}
                renderHeader={<UnitPopupHeader />}
                initialSitePlan={true}
              />
            </div>
            <div className="flex flex-col mt-16 md:mt-[34px] md:flex-row lg:flex-col lg:w-1/3 lg:mt-0">
              <div className="mb-[20px] mr-5 md:mr-[31px] md:mb-10 md:w-1/2 lg:w-full lg:mr-0">
                <KeyValueList
                  fixedRatio
                  rowsClassName="md:grid-cols-5"
                  colLeftClassName="font-sohneKraftig py-[10.5px] md:py-[11px] lg:py-[4px] text-16-26-0.3 md:!col-span-3 lg:!col-span-2"
                  colRightClassName="py-[10.5px] md:py-[11px] lg:py-[4px] text-16-26-0.3 md:!col-span-2 lg:!col-span-3"
                  rows={getRowsForUnitFloorplanDetails(floorplan) || []}
                />
              </div>
              <p className="text-12-18-0.3 md:mt-[36px] lg:mt-0 md:w-1/2 lg:w-full">
                Floor plan disclaimer: condoblackbook.com uses an automated
                floor plan condo matching system to match condos to the original
                developer floor plans. This plan has not been posted by or
                verified with the owner or listing broker. Floor plans are for
                general information and convenience only and cannot be relied
                upon for purchase or rental without independent third party
                verification. Most floor plans represent the original developer
                plans and may have since been modified.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center text-center">
            <div className="mb-2 font-sohneKraftig">Bummer!</div>
            <div className="mb-4 text-14-26-0.3">
              We didn’t find an exact floor plan match for this unit, but we can
              likely get it for you.
            </div>
            <div className="flex justify-center">
              <PopUpContactUs
                isRequestInfoBuilding={true}
                contactUsName={'Request floor plan'}
              />
            </div>
          </div>
        )}
      </section>
    </ErrorBoundary>
  );
};

export default UnitFloorplanSection;
