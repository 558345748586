import React, { useMemo, useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import unitRightArrowBig from '../../public/images/unit_right_arrow.svg';
import unitLeftArrowBig from '../../public/images/unit_left_arrow.svg';
import { Agent, MappedAgent } from '../../apolloClient/types/Agents';

import FlagLabel from '../FlagLabel';
import Button from '../Button';
import { mapAgent } from '../../src/utils/agentUtils';
import ContactUsDialog from 'components/ContactUs/ContactUsDialog';

type Props = {
  flagLabelTextSize?: string;
  isHomePage?: boolean;
  experts: Agent[];
};

const Experts: React.FC<Props> = ({
  isHomePage,
  flagLabelTextSize,
  experts,
}) => {
  const [open, setOpen] = useState(false);
  const handlePopUpClose = () => {
    setOpen(false);
  };

  function ExpertsPrevArrow({ currentSlide, slideCount, ...props }: any) {
    return (
      <button
        {...props}
        className={classnames(
          'z-50 transform slick-arrow slick-prev slick-prev-experts !w-[15px] md:!w-[31px] md:!h-[50px]',
          {
            '!top-[58px]  md:!top-[99px] xl:!top-[116px] !left-[-20px] xl:!left-[-28px]':
              !isHomePage,
            '!top-[57px]  md:!top-[95px] lg:!top-[94px] xl:!top-[141px] !left-[-26px] md:!left-[-20px] lg:!left-[-31px] xl:!left-[-24px]':
              isHomePage,
          }
        )}
      >
        <Image src={unitLeftArrowBig} alt="previous" width="31" height="50" />
      </button>
    );
  }

  function ExpertsNextArrow({ currentSlide, slideCount, ...props }: any) {
    return (
      <button
        {...props}
        className={classnames(
          'z-50 transform slick-arrow slick-next slick-next-experts !w-[15px] md:!w-[31px] md:!h-[50px] md:!top-[99px] md:!right-[-28px]',
          {
            '!top-[58px]  md:!top-[99px] xl:!top-[116px] !right-[6px] !md:right-[0px]':
              !isHomePage,
            '!top-[57px]  md:!top-[95px] lg:!top-[94px] xl:!top-[141px] !right-[-5px] md:!right-[-19px] lg:!right-[-43px] xl:!right-[-36px]':
              isHomePage,
          }
        )}
      >
        <Image src={unitRightArrowBig} alt="next" width="31" height="50" />
      </button>
    );
  }

  const mappedExperts: MappedAgent[] = useMemo(() => {
    return experts.reduce<MappedAgent[]>((acc, _) => {
      const mappedAgent = mapAgent(_.agentId);
      mappedAgent && acc.push(mappedAgent);
      return acc;
    }, []);
  }, [experts]);
  const imagesCarousel = Boolean(mappedExperts && mappedExperts.length <= 4);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <ExpertsPrevArrow />,
    nextArrow: <ExpertsNextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function renderAgents() {
    return mappedExperts.map((expert, index) => {
      return (
        <div
          key={index}
          className={classnames(
            'group md:pt-[3px] xl:pt-[21px] md:px-[15px] xl:pl-[13px] xl:pr-0 !w-1/4',
            {
              'xl:px-0 ': isHomePage,
            }
          )}
        >
          <div
            className={classnames('relative', {
              'h-[120px] md:h-[184px] w-[143px] md:w-[146px] ml-[2px]':
                !isHomePage,
              'h-[120px] md:h-[184px] xl:h-[240px] w-[143px] md:w-[146px] xl:w-[190px]':
                isHomePage,
            })}
          >
            <Image
              layout="fill"
              className="object-cover object-top "
              src={expert.image}
              blurDataURL={expert.image}
              placeholder="blur"
              loading="eager"
              alt={`expert ${expert.firstName} ${expert.lastName}`}
            />
          </div>
          <div
            className={classnames(
              'h-[62px] pt-4 md:pt-[24px] w-[148px] md:w-[146px] md:mb-[9px] text-14-18-0.3 uppercase group-hover:text-gold-hover',
              {
                'xl:h-20 xl:w-[190px] xl:pb-4 xl:pt-[41px] xl:text-16-21-0.3':
                  isHomePage,
              }
            )}
          >
            <p className="font-sohneBreitHalbfett">{expert.firstName}</p>
            <p className="font-sohneBreitHalbfett">{expert.lastName}</p>
          </div>
          <div className="h-[40px] w-[148px] text-14-26-0.3 xl:pt-[7px] xl:h-20 xl:text-16-21-0.3 group-hover:text-gold-hover">
            {experts[index].job}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      <section
        className={classnames(
          'pt-3 md:pt-[0px] pb-[31px] md:pb-0 md:mb-[54px] xl:mb-[78px] text-m',
          {
            'container-xl !px-[31px] xl:!px-[41px] md:mb-[78px] xl:mb-[116px]':
              isHomePage,
            container: !isHomePage,
          }
        )}
      >
        <div className="flex flex-col xl:flex-row">
          <div
            className={classnames(
              'flex flex-col xl:w-1/3 md:flex-row xl:flex-col xl:mb-0 mb-[53px]',
              {
                'mb-[39px] md:mb-[60px]': isHomePage,
                'xl:w-[460px]': !isHomePage,
              }
            )}
          >
            <div
              className={classnames(
                'relative flex flex-col justify-center md:w-1/2 md:mt-[13px] xl:w-auto md:mb-10 xl:mb-0',
                { 'md:ml-[13px]': isHomePage }
              )}
            >
              <div className="relative flex flex-col justify-center w-max">
                <FlagLabel name="the experts" textSize={flagLabelTextSize} />
              </div>
            </div>
            <div
              className={classnames(
                'text-14-21-0.3 md:w-1/2 xl:w-auto xl:text-16-26-0.3'
              )}
            >
              <div
                className={classnames(
                  'mt-[22px] md:mt-[27px] xl:pr-10 xl:mt-8',
                  {
                    'md:pl-[9px xl:pr-[55px]': isHomePage,
                    'xl:pr-[0px] max-w-[418px]': !isHomePage,
                  }
                )}
              >
                Our condo expert agents live and breathe Miami condos and South
                Florida area condos every day. We never stop learning, so that
                we can guide you to the Miami or South Florida condo that suits
                you best. Browser our site full of exclusive insider info at
                your leisure, and reach out anytime. We are here to help.
              </div>
            </div>
          </div>
          <div
            className={classnames(
              'relative mb-[9px] w-full xl:w-2/3 md:mb-[35px] xl:mb-[62px] md:max-w-[706px] mx-auto',
              {
                'max-w-[313px] lg:max-w-[657px] xl:max-w-[802px]': isHomePage,
                'max-w-[335px]': !isHomePage,
              }
            )}
          >
            <div
              className={classnames(
                'relative experts-homepage pl-[7px] md:pl-[3px] mr-[-19px] md:mr-[-6px] md:ml-[-17px]',
                { 'pl-[3px] md:pl-[11px] xl:pl-0': isHomePage }
              )}
            >
              {mappedExperts.length > 4 ? (
                <Slider {...settings}>{renderAgents()}</Slider>
              ) : (
                <div className="flex">{renderAgents()}</div>
              )}
            </div>
          </div>
        </div>
        <div
          className={classnames(
            'flex items-center justify-center mx-auto md:w-[324px] xl:w-[445px] xl:pl-6',
            { 'xl:pl-[100px] xl:pr-[15px]': !isHomePage }
          )}
        >
          {!isHomePage ? (
            <Button
              classNames="h-[48px] py-[15px] md:pb-0"
              name="contact us"
              onClick={() => setOpen(true)}
              href="#experts"
            />
          ) : (
            <Button
              classNames="h-[48px] py-[15px] md:pb-0"
              name="Work with us"
              href="https://joinblackbookproperties.com/"
            />
          )}
        </div>
        <ContactUsDialog open={open} onClose={handlePopUpClose} />
      </section>
    </>
  );
};

export default Experts;
