import React, { useContext } from 'react';

import Share from 'components/Share';
import SaveToFavoritesButton from 'components/SavedHouses/SaveToFavoritesButton';
import ErrorBoundary from 'components/ErrorBoundary';
import { getPropertyTypeByCode } from 'src/utils/getPropertyTypeByCode';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

const NameSection: React.FC = () => {
  const { building, listing, favorite, unit, city } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;
  const { unitNumber, propertyTypeCode } = listing;
  const { id } = unit;
  const { primaryAddress } = building;
  const { streetDirection, streetName, streetNumber, streetType, zipCode } =
    listing.address;
  const streetTypeToDisplay = !!streetType
    ? ''
    : streetType === 'Unknown'
    ? ''
    : streetType;
  const listingAddress = `${streetNumber} ${
    streetDirection || ''
  } ${streetName} ${streetTypeToDisplay}, ${city.name}, FL ${zipCode}`;
  return (
    <ErrorBoundary>
      <section
        id={`${propertyTypeCode === 'R' ? 'for-rent-ga4' : 'for-sale-ga4'}`}
        className="container md:mb-[16px] text-m md:pb-[2px] md:pt-[12px] lg:py-2 lg:mb-0 lg:pb-0"
      >
        <div className="relative flex flex-col-reverse justify-between md:flex-row">
          <div>
            <h1 className="px-px py-1 pt-[10px] md:pt-0 mb-0 lg:pt-0 text-23-29-0.77 lg:text-24-32-0.3 uppercase font-sohneBreitBuch">
              {building.name} #{unitNumber || unit?.unique}
            </h1>
            <p className="px-px pt-[4px] text-14-21-0.3 md:text-14-26-0.3 md:mb-0 w-[95%] lg:w-[100%]">
              {primaryAddress || listingAddress}
            </p>
          </div>
          <div className="relative flex pt-px mb-2 space-x-3 text-16-26-0.3 md:absolute md:right-[-19px] lg:right-0 md:top-[-60px] lg:-top-2 lg:relative md:mb-0">
            <div className="mt-0.5 md:w-full md:mt-[14px] lg:ml-0 lg:mt-2">
              <SaveToFavoritesButton
                visibility="block"
                userFavorites={favorite}
                unitId={id}
                type={getPropertyTypeByCode(propertyTypeCode)}
                full
              />
            </div>
            <div className="mt-0.5 md:w-full md:mt-[14px] lg:mt-2">
              <Share visibility="block" />
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default NameSection;
