import React from 'react';
import Image from 'next/legacy/image';

import whiteHeart from '../../public/images/white_heart.svg';
import { priceFormatter } from '../../src/utils/utils';
import { defaultImage } from 'src/constants/constants';

type NeighborhoodProps = {
  imageUrl?: string;
  blurredImageUrl?: string;
  imageAlt: string;
  nameNeighbor?: string;
  minSalePrice: number;
  maxSalePrice: number;
  description?: string;
  href: string;
};

const NeighborhoodBlock: React.FC<NeighborhoodProps> = ({
  imageUrl,
  blurredImageUrl,
  imageAlt,
  nameNeighbor,
  minSalePrice,
  maxSalePrice,
  description,
  href,
}) => {
  return (
    <a href={href}>
      <div className="mt-4 md:mb-4 group image-wrapper">
        <div className="relative border-transparent">
          <div className="relative h-[234px] w-[334px] md:w-[205px] lg:w-full xl:w-[366px] md:h-[144px] lg:h-[256px]">
            <Image
              className="w-full h-full"
              src={imageUrl || defaultImage}
              alt={imageAlt}
              blurDataURL={blurredImageUrl || defaultImage}
              placeholder="blur"
              loading="eager"
              objectFit="cover"
              layout="fill"
            />
          </div>
        </div>
        <h3 className="mt-[19px] text-18-26-0.3 lg:text-16-26-0.3 font-sohneKraftig group-hover:text-gold-hover lg:mr-10">
          {nameNeighbor}
        </h3>
        <p className="text-20-28-0.3 lg:text-28-28-0.3 mt-[5px] group-hover:text-gold-hover text-bold font-sohneKraftig">
          {priceFormatter(minSalePrice)}-{priceFormatter(maxSalePrice)}
        </p>
        <p className="mt-[12px] text-14-26-0.3 md:text-16-26-0.3 group-hover:text-gold-hover">
          {description}
        </p>
      </div>
    </a>
  );
};

export default NeighborhoodBlock;
