import React from 'react';
import NeighborhoodAccordion from './NeighborhoodAccordion';
import { Neighborhood } from 'apolloClient/types';

interface ResultAccordionProps {
  countyName: string;
  cities: {
    cityName: string;
    neighborhoods: Neighborhood[];
  }[];
}

const ResultAccordion: React.FC<ResultAccordionProps> = ({
  countyName,
  cities,
}) => {
  return (
    <NeighborhoodAccordion
      countyName={countyName}
      cities={cities}
      defaultExpanded
    />
  );
};

export default ResultAccordion;
