import { useLazyQuery } from '@apollo/client';

import { FiltersValues, PropertyType } from '../components/UnitsFilters/types';
import { compareDefaultValues } from '../components/Search/utils/listingsFiltersUtils';
import { defaultFilterValues } from 'components/UnitsFilters/constants';
import { SEARCH_LISTINGS_COUNT } from '../apolloClient/queries/searchListings';

export const useSearchCount = () => {
  const [fetchCount, { data: countData, loading }] = useLazyQuery<
    {
      getListingsCountData: number;
    },
    { filters: { [index: string]: any }; buildingId: number }
  >(SEARCH_LISTINGS_COUNT);

  function getCountFromData(
    data: { getListingsCountData: number } | undefined
  ): number {
    return data?.getListingsCountData || 0;
  }

  function loadListingsCount(
    values: FiltersValues,
    buildingId?: number,
    propertyType?: PropertyType
  ) {
    fetchCount({
      fetchPolicy: 'no-cache',
      variables: {
        /**
         * @todo add propertyType as in useSearch ??
         */
        filters: compareDefaultValues(values, defaultFilterValues),
        buildingId: buildingId || -1,
      },
    });
  }

  return {
    count: getCountFromData(countData),
    loadListingsCount,
    loading,
  };
};
