import React from 'react';

import NeighborhoodMenuBody from './NeighborhoodMenuBody';
import { NeighborhoodsResponse } from '../../../apolloClient/types/Neighborhood';

const DesktopNeighborhoodMenu: React.FC<{
  neighborhoods?: NeighborhoodsResponse;
  fetchMoreNhds: (search: string) => void;
}> = (props) => {
  return (
    <div className="relative group">
      <div className="pr-6 cursor-pointer hover:text-gold-hover">
        <span className="text-black whitespace-nowrap">Neighborhoods</span>
      </div>
      <div className="hidden pt-[16px] top-4 w-[406px] absolute  group-hover:block -left-10 z-120">
        <div className="bg-white border top-4 border-gray ">
          <div className="relative">
            <div className="absolute group-hover:block w-3 h-3 bg-white -top-1.7 left-[80px] border-t border-l border-gray transform rotate-45" />
            <div className="py-3 xl:pt-[0px] overflow-y-auto h-[700px]">
              <NeighborhoodMenuBody {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNeighborhoodMenu;
