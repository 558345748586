import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { priceFormatter } from 'src/utils/utils';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import { defaultImage } from 'src/constants/constants';

type CondoProps = {
  href: string;
  imageUrl?: string;
  blurredImageUrl?: string;
  imageAlt?: string;
  name?: string;
  buildingStatus?: boolean;
  minSalePrice: number;
  maxSalePrice: number;
  address?: string;
  yearBuilt?: number;
  minSaleBeds?: number;
  maxSaleBeds?: number;
};

const Condo: React.FC<CondoProps> = ({
  href,
  imageUrl,
  imageAlt,
  name,
  minSalePrice,
  maxSalePrice,
  address,
  yearBuilt,
  minSaleBeds,
  maxSaleBeds,
  buildingStatus,
  blurredImageUrl,
}) => {
  return (
    <Link
      href={href}
      passHref
      className="mt-4 md:mb-4 group text-ellipsis image-wrapper w-full"
    >
      <div className="relative border-transparent">
        <div className="relative md:w-[323px] lg:w-[366px] h-[192px] md:h-[226px] lg:h-[256px]">
          <Image
            className="object-cover w-full h-full"
            src={imageUrl || defaultImage}
            alt={imageAlt}
            blurDataURL={blurredImageUrl || defaultImage}
            placeholder="blur"
            loading="eager"
            layout="fill"
          />
          {buildingStatus && (
            <div className="absolute bottom-0 flex items-center justify-center w-full md:w-[271px] lg:w-[239px] md:h-[40px] px-3 py-1 text-12-18-0.3 md:text-16-21-0.53 md:bottom-auto md:uppercase md:top-3 xl:top-6 bg-gold font-sohneBreitHalbfett">
              pre-construction
            </div>
          )}
        </div>
      </div>
      <h3 className="mt-4 text-20-26-0.3 uppercase text-ellipsis border-b ellipsis-small font-sohneKraftig group-hover:text-gold-hover border-beige-dark">
        {name}
      </h3>
      <div className="text-14-26-0.3 md:text-16-26-0.3 text-ellipsis group-hover:text-gold-hover">
        <p className="mt-[6px] font-sohneBuch max-h-10 ellipsis-small">
          {address}
        </p>
      </div>
      <p className="text-24-28-0.3 md:text-28-28-0.3 mt-[18px] group-hover:text-gold-hover text-bold font-sohneKraftig">
        {priceFormatter(minSalePrice)} - {priceFormatter(maxSalePrice)}
      </p>
      <div className="flex flex-col mt-[5px] group-hover:text-gold-hover font-sohneKraftig text-14-26-0.3 md:text-16-26-0.3">
        <p>
          {`${
            minSaleBeds === maxSaleBeds
              ? `${maxSaleBeds}`
              : `${minSaleBeds}-${maxSaleBeds}`
          }`}{' '}
          beds | Year built:{' '}
          {yearBuilt && yearBuilt > 0 ? yearBuilt : NO_DATA_PLACEHOLDER}
        </p>
      </div>
    </Link>
  );
};

export default Condo;
