import { useEffect, useState } from 'react';

const useDeviceSize = () => {
  const [height, setHeight] = useState(0);
  const handleWindowResize = () => {
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return [height];
};

export default useDeviceSize;
