import { gql } from '@apollo/client';

import { BUILDING_AMENITIES_STRING } from './building';

export type RequestPropertyType = 'saleUnit' | 'rentUnit';

const UNITS_AMENITIES_STRING = `{
  pets {
    allowed
    large
  }
  waterfront {
    any
    bay
    beach
    bridge
    canal
    intracoastal
    lagoon
    lake
    mangrove
    navigable
    ocean
    pond
    ripRap
    river
    seawall
    other
  }
  waterAccess {
    boatHoistsDavits
    boatlift
    boatlock
    communityBoatDock
    communityBoatRamp
    deededDock
    dockAvailable
    privateDock
    restrictedSalt
    unrestrictedSalt
    canalWidth121Plus
    canalWidth1To80
    canalWidth81To120
    intersectingCanals
    navigable
    noFixedBridges
    ocean
    fixedBridges
    lake
  }
  view {
    any
    none
    bay
    canal
    city
    clubArea
    directOcean
    ocean
    garden
    golf
    intracoastal
    lagoon
    lake
    poolArea
    river
    skyline
    tennis
    water
    other
  }
  sport {
    basketball
    bikeStorage
    bikeJogPath
    billiard
    exerciseRoom
    fishingPier
    golf
    pool
    shuffleboard
    tennis
  }
  general {
    bar
    bbqPicnicArea
    boatDock
    businessCenter
    cabana
    childPlayArea
    club
    commonLaundry
    communityRoom
    courtesyBus
    elevator
    exteriorLighting
    extraStorage
    heatedPool
    hobbyRoom
    kitchenFacilities
    library
    marina
    privateBeachPavilion
    privatePool
    puttingGreen
    spaHotTub
    trashChute
    vehicleWashArea
    sauna
  }
  convenience {
    centralCooling
    centralHeat
    dishwasher
    electricCooling
    electricHeat
    furnished
    partiallyFurnished
    unfurnished
    walkInClosets
    washerDryer
  }
  association {
    condo
    homeowners
    none
    other
    voluntary
    allowed
  }
  maintenance {
    landscapingAndLawn
    recreationFacilities
    security
    buildingExterior
    commonArea
    insurance
    outside
    poolService
    roofRepairs
    trashRemoval
    airConditioning
    all
    cableTv
    electric
    elevator
    fidelityBond
    golf
    hotWater
    manager
    parking
    laundryFacilities
    legalAndAccounting
    sewer
    water
    other
    pestControlInterior
    reserveFund
    masterAntenna
    none
    internetAndWiFi
  }
  style {
    anchoredCenter
    commercial
    professional
    restaurant
    shoppingCenter
    retail
    freeStanding
    warehouse
    apartment
    apartmentsAnnual
    manufacturedAndMobileHome
    coOp
    condo
    duplex
    efficiency
    house
    lease
    mobile
    multiFamily
    other
    residential
    townhouse
    villa
    apartmentsOffSeason
    apartmentsSeasonal
    coOp1To4Stories
    dockominium
    coOp5PlusStories
    hotel
    officeAndRetail
    industrialAndManufacturing
    officeAndWarehouse
    office
    storeAndWarehouse
    industrial
    condo1To4Stories
    condo5PlusStories
    condoHotel
    timeshare
    condoAndCoOpAnnual
    condoAndCoOpOffSeason
    condoAndCoOpSeasonal
    condoAndTimeshare
    fourplex
    triplex
    duplexAndTriplexAndQuadruplexAnnual
    condoAndCoOpAndAnnual
    condoAndCoOpAndOffSeason
    condoAndCoOpAndSeasonal
    duplexAndTriplexAndQuadruplexOffSeason
    duplexAndTriplexAndQuadruplexSeasonal
    efficiencyAndStandartAndHotelRoomAnnual
    efficiencyAndStandartAndHotelRoomOffSeason
    efficiencyAndStandartAndHotelRoomSeasonal
    hotelAndMotel
    lounge
    recreationFacility
    singleFamilyAnnual
    townhouseAndVillaAnnual
    medicalOffice
    noPoolAndNoWater
    pool
    quadruplex
    residentialAnnual
    residentialOffSeason
    residentialSeasonal
    singleFamilyOffSeason
    singleFamilySeasonal
    townhouseCondo
    townhouseFeeSimple
    townhouseAndVillaOffSeason
    townhouseAndVillaSeasonal
    unimprovedAgriculturalAndRecreationAndMobileHome
    villaCondo
    villaFeeSimple
    waterfrontAndNoOceanAccess
    waterfrontAndOceanAccess
    waterfrontAndPoolAndNoOceanAccess
    waterfrontAndPoolAndOceanAccess
  }
  parking {
    oneAssigned
    covered
    deeded
    guest
    underBuilding
    valet
    places
    garagePlaces
  }
}`;

export const getUnitPageQuery = (type: RequestPropertyType) => gql`
  query UnitPageQuery(
    $unique: String
    $buldingSlug: String
    $nhSlug: String
  ) {
    listings(
      filters: {
        ${type}: { id: { notNull: true } }
        unit: {
          unique: { eq: $unique }
          building: {
            slug: { eq: $buldingSlug }
            neighborhood: { slug: { eq: $nhSlug } }
          }
        }
      }
      pagination: { pageSize: 1 }
    ) {
      data {
        id
        attributes {
          ...listingData
          floorplan {
            id
            model
            apartmentNumbers
            bedBath
            floors {
              id
              from
              to
            }
            area
            exteriorArea
            image {
              data {
                attributes {
                  name
                  url
                }
              }
            }
          }
          unit {
            data {
              id
              attributes {
                unique
                building {
                  data {
                    id
                    attributes {
                      walkscore
                      floorsCount
                      primaryAddress
                      lat
                      lon
                      slug
                      name
                      amenity ${BUILDING_AMENITIES_STRING}
                      buildingStats {
                        maxSalePrice
                        minSalePrice
                        minSaleSqft
                        maxSaleSqft
                        averageAssociationFeeSqftPrice
                      }
                      isPreconstruction
                      neighborhood {
                        data {
                          id
                          attributes {
                            slug
                            name
                            shortDescription
                            city {
                              data {
                                id
                                attributes {
                                  name
                                }
                              }
                            }
                            blogs(pagination: { pageSize: 3 }) {
                              data {
                                ...blogs
                              }
                            }
                          }
                        }
                      }
                      blogs(pagination: { pageSize: 3 }) {
                        data {
                          ...blogs
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    blogs(pagination: { pageSize: 3 }) {
      data {
        ...blogs
      }
    }
  }

  fragment blogs on BlogEntity {
    id
    attributes {
      title
      slug
      pageContent
      publishedDate
      mainImage {
        data {
          attributes {
            url
          }
        }
      }
      shortDescription
      blogTags {
        data {
          attributes {
            label
          }
        }
      }
    }
  }

  fragment listingData on Listing {
    previousPrice
    maintenanceFee
    hoaFees
    associationFeePaidPer
    daysOnMarket
    taxAmount
    virtualTourUrl
    sqft
    listOfficeMlsId
    sqftLivingArea
    bedsTotal
    bathsFull
    bathsHalf
    price
    mlsListingId
    mlsOfficeName
    address {
      zipCode
      streetName
      streetNumber
      streetType
      streetDirection
    }
    unitNumber
    closeDate
    statusCode
    propertyTypeCode
    yearBuilt
    isShortSale
    isReo
    createdAt
    remarks
    taxes
    taxYear
    equipmentAppliances
    housingOlderPersonsAct
    interiorFeatures
    yearBuiltDescription
    style
    securityInformation
    unitDesign
    heatingDescription
    coolingDescription
    bedroomDescription
    garageSpaces
    maintenanceIncludes
    taxInformation
    isPropertyDetached
    typeOfAssociation
    masterBathroomDescription
    parcelNumber
    isMembershipPurchaseRequired
    approvalInformation
    termsConsidered
    parkingDescription
    parkingRestrictions
    unitView
    waterfrontDescription
    isWaterfrontProperty
    waterAccess
    windowsTreatment
    frontExposure
    floorDescription
    exteriorFeatures
    unitFloorLocation
    furnishedInfoList
    images(pagination: { pageSize: 200 }) {
      data {
        attributes {
          url
          name
          hash
          formats
        }
      }
    }
    amenity ${UNITS_AMENITIES_STRING}
    unit {
      data {
        id
        attributes {
          history {
            id
            display
            oldStatusCode
            newStatusCode
            oldPrice
            newPrice
            sqft
            changedDate
            source
          }
        }
      }
    }
    history {
      id
      display
      oldStatusCode
      newStatusCode
      oldPrice
      newPrice
      sqft
      changedDate
      source
    }
  }
`;

export const GET_IS_LISTING_FAVS = gql`
  query GetListingFavs($userId: ID!, $listingId: ID!, $type: String!) {
    favoriteListings(
      filters: {
        unit: { listings: { id: { eq: $listingId } } }
        author: { id: { eq: $userId } }
        type: { eq: $type }
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const NEARBY_CONDOS = gql`
  query UnitPageNeqrby(
    $maxPrice: Long
    $minPrice: Long
    $minArea: Long
    $maxArea: Long
    $propertyTypeList: [String]
    $listingId: ID
    $neighborhoodId: ID
    $userId: ID
  ) {
    listings(
      filters: {
        or: [
          { saleUnit: { id: { notNull: true } } }
          { rentUnit: { id: { notNull: true } } }
        ]
        id: { ne: $listingId }
        statusCode: { eq: "A" }
        price: { between: [$minPrice, $maxPrice] }
        sqft: { between: [$minArea, $maxArea] }
        unit: {
          building: {
            publishedAt: { notNull: true }
            neighborhood: {
              id: { eq: $neighborhoodId }
              publishedAt: { notNull: true }
            }
          }
        }
        propertyTypeCode: { in: $propertyTypeList }
      }
      sort: ["isRecommended:DESC", "createdAt:DESC"]
      pagination: { pageSize: 12 }
    ) {
      data {
        id
        attributes {
          unitNumber
          statusCode
          propertyTypeCode
          sqft
          closeDate
          price
          previousPrice
          bedsTotal
          bathsFull
          images {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          unit {
            data {
              id
              attributes {
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                unique
                unitNumber
                building {
                  data {
                    attributes {
                      primaryAddress
                      slug
                      name
                      neighborhood {
                        data {
                          attributes {
                            slug
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const NEARBY_NEIGHBOR_CONDOS = gql`
  query UnitPageNeqrbyNeighborsSimilar(
    $maxPrice: Long
    $minPrice: Long
    $minArea: Long
    $maxArea: Long
    $propertyTypeList: [String]
    $neighborhoodId: ID
    $listingId: ID
    $userId: ID
  ) {
    listings(
      filters: {
        or: [
          { saleUnit: { id: { notNull: true } } }
          { rentUnit: { id: { notNull: true } } }
        ]
        id: { ne: $listingId }
        statusCode: { eq: "A" }
        price: { between: [$minPrice, $maxPrice] }
        sqft: { between: [$minArea, $maxArea] }
        unit: {
          building: {
            neighborhood: {
              neighborhoods: {
                id: { eq: $neighborhoodId }
                publishedAt: { notNull: true }
              }
            }
          }
        }
        propertyTypeCode: { in: $propertyTypeList }
      }
      sort: ["isRecommended:DESC", "createdAt:DESC"]
      pagination: { pageSize: 12 }
    ) {
      data {
        id
        attributes {
          unitNumber
          statusCode
          propertyTypeCode
          sqft
          closeDate
          price
          bedsTotal
          bathsFull
          images {
            data {
              id
              attributes {
                url
              }
            }
          }
          unit {
            data {
              id
              attributes {
                unique
                unitNumber
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                building {
                  data {
                    attributes {
                      primaryAddress
                      slug
                      name
                      neighborhood {
                        data {
                          attributes {
                            slug
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
