import React from 'react';

type LineBetweenSectionsProps = {
  hideLine?: string;
};

const LineBetweenSections: React.FC<LineBetweenSectionsProps> = ({
  hideLine,
}) => {
  return (
    <div className={`pt-[17px] pb-[15px] w-full lg:py-6 ${hideLine}`}>
      <div className="border-b w-fill border-beige-dark bg-beige-dark" />
    </div>
  );
};

export default LineBetweenSections;
