import React from 'react';
import { RealEstateAgent } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { ORIGIN } from 'src/constants';

const RealEstateAgentSchema: React.FC = () => {
  const organization: RealEstateAgent = {
    '@type': 'RealEstateAgent',
    name: 'Condo Black Book',
    email: 'contact@CondoBlackBook.com',
    telephone: '(305) 697-7667',
    url: ORIGIN,
    openingHours: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-17:00',
    priceRange: '$',
    description:
      'Miami Beach and Miami&#39;s ultra-local condo neighborhood resource, with real-time sale and rental availability,  pics, condo search, floor plans, recent sales and news updates.',
    image: `${ORIGIN}/images/logo.svg`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: '1900 N BAYSHORE DR #1A',
      addressLocality: 'Miami',
      addressRegion: 'FL',
      addressCountry: 'United States',
      postalCode: '33132',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '25.794339',
      longitude: '-80.18705',
    },
  };

  return (
    <Helmet
      script={[
        helmetJsonLdProp<RealEstateAgent>({
          '@context': 'https://schema.org',
          ...organization,
        }),
      ]}
    />
  );
};

export default RealEstateAgentSchema;
