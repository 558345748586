import React from 'react';
import Image from 'next/legacy/image';

const BlurredBgImage: React.FC<{
  src: string;
  blurDataURL: string;
  alt: string;
}> = ({ src, blurDataURL, alt }) => {
  return (
    <div className="container-with-blur">
      <div className="image-wrapper-with-blur">
        <Image
          className="z-10"
          src={src}
          layout="fill"
          objectFit="contain"
          // blurDataURL={blurDataURL}
          alt={alt}
          priority={true}
        />
      </div>
      <Image
        className="blur"
        src={blurDataURL}
        alt="blur"
        layout="fill"
        objectFit="cover"
        priority={true}
      />
    </div>
  );
};

export default BlurredBgImage;
