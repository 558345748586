import React from 'react';
import { styled, Theme } from '@mui/system';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  useMediaQuery,
} from '@mui/material';
import Image from 'next/legacy/image';
import { Neighborhood } from 'apolloClient/types';
import rightArrow from '../../../public/images/arrow-right-neighborhood-menu.svg';

interface StyledAccordionSummaryProps extends AccordionSummaryProps {
  ismobile?: string;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: `1px solid #D2CFCA`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: StyledAccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<Image src={rightArrow} alt="expand" height={15} width={12} />}
  />
))(({ ismobile }) => ({
  height: ismobile ? '37px' : '37px',
  paddingTop: ismobile ? '0' : '0',
  paddingLeft: ismobile ? '0px' : '16px',
  minHeight: '0px',
  display: 'flex',
  justifyContent: ismobile ? 'flex-start' : 'space-between',
  alightItems: 'center',
  '& .MuiAccordionSummary-content': {
    flexGrow: '0',
    marginRight: '0.5rem',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const AccordionSummaryCity = styled((props: StyledAccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<Image src={rightArrow} alt="expand" height={15} width={12} />}
  />
))(({ ismobile }) => ({
  height: ismobile ? '43px' : '37px',
  paddingLeft: '0px',
  marginLeft: ismobile ? '0px' : '18px',
  paddingTop: '0px',
  minHeight: '0px',
  display: 'flex',
  justifyContent: ismobile ? 'flex-start' : 'space-between',
  alightItems: 'center',
  '& .MuiAccordionSummary-content': {
    flexGrow: '0',
    marginRight: '0.5rem',
    height: '100%',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
}));

interface CountyAccordionProps {
  countyName: string;
  cities: {
    cityName: string;
    neighborhoods: Neighborhood[];
  }[];
  defaultExpanded?: boolean;
}

const NeighborhoodAccordion: React.FC<CountyAccordionProps> = ({
  countyName,
  cities,
  defaultExpanded = false,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <>
      <Accordion
        key={countyName}
        defaultExpanded={countyName === 'Miami-Dade' ? true : defaultExpanded}
      >
        <AccordionSummary ismobile={isMobile ? 'true' : ''}>
          <div className="uppercase text-gold-darker hover:text-gold-hover flex items-center">
            {countyName}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={(theme: Theme) => ({
            paddingLeft: theme.spacing(2),
          })}
        >
          {cities.map(({ cityName, neighborhoods }) => {
            const isSingleNeighborhood =
              cityName === neighborhoods[0]?.name && neighborhoods.length <= 1;

            return isSingleNeighborhood ? (
              <div key={cityName}>
                <a
                  href={`/${neighborhoods[0].slug}`}
                  className="block hover:text-gold-hover text-16-44-0.3 border-b border-beige-dark xl:ml-[18px]"
                >
                  {neighborhoods[0]?.name}
                </a>
              </div>
            ) : (
              <Accordion key={cityName} defaultExpanded={defaultExpanded}>
                <AccordionSummaryCity ismobile={isMobile ? 'true' : ''}>
                  <span className="text-gold-darker hover:text-gold-hover text-16-44-0.3">
                    {cityName}
                  </span>
                </AccordionSummaryCity>
                <AccordionDetails>
                  {neighborhoods.map((neighborhood) => (
                    <div key={`header-search-nh-${neighborhood.slug}`}>
                      <a
                        href={`/${neighborhood.slug}`}
                        className="block hover:text-gold-hover text-16-34-0.3 xl:ml-[18px]"
                      >
                        {neighborhood.name}
                      </a>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default NeighborhoodAccordion;
