import React from 'react';
import Image from 'next/legacy/image';
import { defaultAgentImage } from 'src/utils/agentUtils';

type ExpertProps = {
  imageUrl: string;
  imageAlt: string;
  firstName: string;
  lastName: string;
  company: string;
  job?: string;
  email?: string;
  phone?: string;
  textClassName?: string;
  expertNameFonts?: string;
  containerClassName?: string;
};

const Expert: React.FC<ExpertProps> = ({
  imageUrl,
  imageAlt,
  firstName,
  lastName,
  company,
  job,
  email,
  phone,
  textClassName,
  expertNameFonts,
  containerClassName,
}) => {
  return (
    <>
      <div
        className={`${containerClassName} relative w-[84px] h-[84px] md:mt-0 mr-[15px] md:mr-6 text-16-26-0.3 lg:mr-10 lg:w-28 lg:h-28`}
      >
        <Image
          className="object-cover w-full h-full"
          src={imageUrl}
          alt={imageAlt}
          loading="eager"
          layout="fill"
          blurDataURL={imageUrl}
          placeholder="blur"
          priority
        />
      </div>
      <div className={`flex flex-col ${textClassName}`}>
        <div
          className={`mb-[12px] md:mb-2 uppercase font-sohneBreitHalbfett text-14-18-0.3 ${expertNameFonts}`}
        >
          {firstName} {lastName}
        </div>
        <span className="text-14-21-0.3 md:text-13-21-0.3 lg:text-16-21-0.3">
          {job}
        </span>
        <span className="text-14-21-0.3 md:text-13-21-0.3 lg:text-16-21-0.3">
          {company}
        </span>
        <span className="text-14-21-0.3 md:text-13-21-0.3 lg:text-16-21-0.3">
          {email}
        </span>
        <span className="text-14-21-0.3 md:text-13-21-0.3 lg:text-16-21-0.3">
          {phone}
        </span>
      </div>
    </>
  );
};

export default Expert;
