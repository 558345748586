import { Box } from '@mui/system';
import { StrapiBulkResponseData, StrapiResponse } from 'apolloClient/types';
import { BlogLabel } from 'apolloClient/types/Blog';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import React from 'react';
import { createTagLink } from './createBlogTagLink';

const TagLinks: React.FC<{
  tags?: StrapiResponse<StrapiBulkResponseData<BlogLabel>>;
}> = ({ tags }) => {
  const router = useRouter();

  return (
    <Box className="ellipsis-1 pt-[12px] md:pt-[26px] pb-[5px] md:pb-[14px] h-[33px] md:h-[44px] lg:h-[54px] lg:pt-[22px] lg:pb-[5px]">
      {tags?.data?.map((label, index: any) => {
        return (
          /**
           * @todo styled component
           */
          <Typography
            className="uppercase text-gold-darker !text-14-21-0.3 md:!text-16-26-0.3 !font-sohneKraftig h-[22px]"
            marginRight={1}
            display="inline"
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              fontFamily: 'Sohne Kraftig,sans-serif;',
            }}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              router.push(createTagLink(label.attributes.slug));
            }}
          >
            {label.attributes.label}
          </Typography>
        );
      })}
    </Box>
  );
};

export default TagLinks;
