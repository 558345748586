import React, { useContext, useMemo } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import {
  abbreviatedPriceFormatterWithPrecision,
  decimalNumberFormatterWithoutZero,
  priceFormatter,
} from 'src/utils/utils';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import arrowLeft from 'public/images/arrow-left.svg';
import questionMark from 'public/images/question_mark.svg';
import FlagLabel from 'components/FlagLabel';
import PopUpContactUs from 'components/ContactUs/PopUpContactUs';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';

import { KeyValueList } from '../KeyValueList';

import BuildingDescription from './BuildingDescription';

const priceWithPercisionFormatter = abbreviatedPriceFormatterWithPrecision();

const getDesigner = (entry: string[], key: string) => {
  if (entry.length > 0)
    return entry?.join
      ? [
          {
            key,
            value: entry.join(', '),
          },
        ]
      : [];
  return [];
};

type AboutSectionProps = {
  href: string;
};

const AboutSection: React.FC<AboutSectionProps> = ({ href }) => {
  const { building, neighborhood } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const {
    name,
    amenity,
    architects,
    developers,
    interiorDesigners,
    landscapeArchitects,
    walkscore,
  } = building;
  let avgB = building?.buildingStats?.averageAssociationFeeSqftPrice || 0;
  let avgN =
    building?.neighborhood?.data?.attributes?.neighborhoodStats
      ?.averageAssociationFeeSqftPrice || 0;
  let priceRange;
  let ratio = avgN / avgB;
  let googleRank = building?.googleRank;
  let yelpRank = building?.yelpRank;

  if (!ratio) {
    priceRange = 0;
  } else if (ratio <= 0.5) {
    priceRange = 4;
  } else if (ratio > 0.5 && ratio <= 0.66) {
    priceRange = 4.25;
  } else if (ratio <= 0.75 && ratio > 0.66) {
    priceRange = 4.5;
  } else if (ratio > 0.75 && ratio <= 0.83) {
    priceRange = 4.75;
  } else {
    priceRange = 5;
  }
  let cbbRank;

  if (googleRank && yelpRank) {
    if (
      (googleRank > 2.5 && yelpRank > 2.5) ||
      (googleRank <= 2.5 && yelpRank <= 2.5)
    ) {
      cbbRank = (googleRank + yelpRank + priceRange) / 3;
    } else if (googleRank > 2.5) {
      cbbRank = (googleRank + priceRange) / 2;
    } else if (yelpRank > 2.5) {
      cbbRank = (yelpRank + priceRange) / 2;
    }
  } else if ((googleRank && googleRank > 2.5) || (yelpRank && yelpRank > 2.5)) {
    cbbRank = ((googleRank || 0) + (yelpRank || 0) + priceRange) / 2;
  } else {
    cbbRank = priceRange;
  }

  const { walk, walkDescription } = walkscore || {};
  const { pets } = amenity || {};
  const petsString = useMemo(() => {
    if (!pets) return '';
    const { allowedForRent, allowedForSale, largeForRent, largeForSale } = pets;
    return `
      ${allowedForSale || allowedForRent ? 'Pets OK for' : ' '}
      ${allowedForSale ? 'Owners' : ''}
      ${allowedForSale && allowedForRent ? '&' : ''}
      ${allowedForRent ? 'Renters' : ''}
      ${
        (allowedForSale || allowedForRent) && (largeForSale || largeForRent)
          ? ', '
          : ' '
      }
      ${largeForSale || largeForRent ? 'Large Pets Allowed for' : ' '}
      ${largeForSale ? 'Owners' : ''}
      ${largeForSale && largeForRent ? '&' : ''}
      ${largeForRent ? 'Renters' : ''}
      ${
        allowedForSale || allowedForRent || largeForSale || largeForRent
          ? ' '
          : '-'
      }
    `;
  }, [pets]);

  const details = [
    ...getDesigner(
      architects.map(({ name }) => name),
      'Architect'
    ),
    ...getDesigner(
      developers.map(({ name }) => name),
      'Developer'
    ),
    ...getDesigner(
      interiorDesigners.map(({ name }) => name),
      'Interior Designer'
    ),
    ...getDesigner(
      landscapeArchitects.map(({ name }) => name),
      'Landscape Designer'
    ),
    ...(neighborhood
      ? [
          {
            key: 'Neighborhood',
            value: (
              <Link
                href={`/${neighborhood.slug}`}
                passHref
                className="underline text-gold-dark hover:text-gold-hover"
              >
                {neighborhood?.name}
              </Link>
            ),
          },
        ]
      : []),
    {
      key: 'Avg Price / Sq Ft',
      value: building?.buildingStats?.averageSaleSqftPrice
        ? priceFormatter(building.buildingStats.averageSaleSqftPrice)
        : NO_DATA_PLACEHOLDER,
    },
    {
      key: 'Avg Assoc Fee',
      value: building?.buildingStats?.averageAssociationFeeSqftPrice
        ? `${priceWithPercisionFormatter(
            building.buildingStats.averageAssociationFeeSqftPrice
          )} / sq ft / month`
        : NO_DATA_PLACEHOLDER,
    },
    {
      key: 'Walk Score ®',
      value: walk ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="text-gold-dark hover:text-gold-hover"
          href="https://www.walkscore.com/how-it-works/"
        >
          {walk} ({walkDescription})
        </a>
      ) : (
        NO_DATA_PLACEHOLDER
      ),
    },
    ...(cbbRank
      ? [
          {
            key: 'CBB Rank',
            value: (
              <p className="relative inline-block group">
                <p className="flex items-center">
                  {`${decimalNumberFormatterWithoutZero(cbbRank)}/5`}
                  <Image
                    src={questionMark}
                    alt="help popup"
                    className="cursor-pointer !pt-[2px]"
                    width={20}
                    height={20}
                  />
                </p>
                <p className="absolute z-30 hidden p-4 m-0 border bottom-8 -left-20 w-52 group-hover:block align-center bg-gold-background border-beige-dark">
                  <p className="text-14-26-0.3 font-sohneBuch">
                    CBB Rank is based on an internal ranking of buildings based
                    on location and level of luxury combined with the average of
                    user reviews from popular review sites.
                  </p>
                </p>
              </p>
            ),
          },
        ]
      : []),
    ...(pets ? [{ key: 'Pets', value: petsString }] : []),
  ];

  return (
    <>
      <div
        id="About"
        className="absolute scroll-smooth -top-28 md:-top-24 lg:-top-20"
      />
      <div className="flex flex-col justify-between border-t md:border-b sm:flex-row border-beige-dark">
        <BuildingDescription />
        <div className="block md:hidden w-fill mt-[51px] md:mt-12 h-0.5 bg-beige" />
        <div className="flex flex-col pt-[22px] mx-0 md:pt-[33px] md:w-1/2 lg:w-5/12 md:border-l md:border-beige-dark">
          <div className="relative flex flex-col justify-center mb-[24px] md:mb-[37px] md:ml-8 w-max">
            <FlagLabel name="Building key details" />
          </div>
          <div className="mt-0 xl:mb-[42px] sm:m-8 md:mt-0">
            <KeyValueList rows={details} />
          </div>
          <div className="border-t sm:pl-8 border-beige-dark lg:pb-[42px]">
            <h2 className="mt-[35px] md:mt-[50px] mb-[33px] md:mb-[30px] text-23-28-0.77 md:text-24-32-0.3 uppercase border-b-8 lg:text-26-31-0.3 border-gold font-Helvetica font-bold">
              Do you own in {name}?
            </h2>
            <p className="text-14-21-0.3 mb-[30px] mt-[2px] lg:text-16-26-0.3">
              Our condo sales experts and premier marketing team can help you
              sell your place for top dollar.
            </p>
            <div className="flex mb-[40px] lg:mb-[36px] xl:w-80">
              <PopUpContactUs
                isRequestInfoBuildingOwner={true}
                contactUsName={'Contact us'}
              />
            </div>
            <a
              href={href}
              className="flex items-center mb-8 uppercase border-b-[3px] cursor-pointer scroll-smooth border-gold-dark md:mb-10 lg:mb-6 w-max"
            >
              <div className="w-5 h-4">
                <Image src={arrowLeft} alt="See building stats" width={15} />
              </div>
              <span className="pt-1 whitespace-nowrap text-16-26-0.3">
                See building stats
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="block md:hidden w-fill mt-0 h-0.5 bg-beige" />
    </>
  );
};

export default AboutSection;
