import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import React, { useContext } from 'react';

import { Table } from '../../components/Table';
import {
  centenaryNumberFormatter,
  priceFormatter,
  sqftFormatter,
} from '../../src/utils/utils';
import { isListingOffMarket } from 'lib/checkListingStatus';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import ErrorBoundary from 'components/ErrorBoundary';

const TableUnit: React.FC = () => {
  const { listing } = useContext(UnitPageContext) as UnitPageContextInterface;
  const {
    statusCode,
    bathsFull,
    bathsHalf,
    price,
    bedsTotal,
    sqft,
    yearBuilt,
    previousPrice,
  } = listing;

  const labelPrice = (() => {
    if (statusCode === 'CS') {
      return `SOLD`;
    } else if (statusCode === 'R') {
      return `RENTED`;
    } else {
      return `LIST`;
    }
  })();

  function renderBathrooms(): any {
    return (
      (bathsHalf && bathsHalf > 0 ? bathsFull + 0.5 : bathsFull) ||
      NO_DATA_PLACEHOLDER
    );
  }

  function renderPriceDelta() {
    const delta = +centenaryNumberFormatter(
      ((price - previousPrice) * 100.0) / previousPrice
    );

    if (
      statusCode === 'CS' ||
      statusCode === 'R' ||
      isListingOffMarket(statusCode) ||
      !previousPrice ||
      !delta ||
      delta > 200
    )
      return null;

    if (delta > 0)
      return (
        <div
          title={`Was ${priceFormatter(previousPrice)}`}
          className="text-12-18-0.3 font-extrabold md:text-16-26-0.3 text-azure font-sohneHalbfett"
        >
          Price Increased: +{delta}%
        </div>
      );
    if (delta < 0)
      return (
        <div
          title={`Was ${priceFormatter(previousPrice)}`}
          className="text-12-18-0.3 font-extrabold md:text-16-26-0.3 text-red font-sohneHalbfett"
        >
          Price Reduced: {delta}%
        </div>
      );
  }

  function renderSalePrice(): any {
    return statusCode === 'A' ||
      statusCode === 'AC' ||
      statusCode === 'PS' ||
      statusCode === 'T'
      ? price
      : renderPriceEstimate();
  }

  function renderPriceEstimate() {
    return (
      <a
        href="#condo-value-estimates"
        className="text-12-18-0.3 md:text-18-28-0.3"
      >
        <div className="md:h-10 px-1 py-1 text-12-18-0.3 w-18 md:px-3 md:text-18-28-0.3 bg-gold-dark">
          Price estimate
        </div>
      </a>
    );
  }

  return (
    <ErrorBoundary>
      <div
        id="RentOrSale"
        className="absolute top-[-120px] md:top-[-130px] xl:top-[-80px]"
      />
      <section className="container mt-[20px] md:mt-[6px] md:px-12 md:pt-5 md:pb-2 lg:py-2 text-m">
        <Table
          rootClassName="no-scroll flex flex-row flex-nowrap "
          rowClasses="font-sohneHalbfett text-14-26-0.3 md:text-20-28-0.3 lg:text-30-36-1 md:mt-4 md:tracking-widest font-bold"
          colClasses="!pb-0 md:pb-[32px] lg:pb-[3px] mb-[13px] md:mb-4 "
          columns={[
            {
              key: 'price',
              heading: `${labelPrice} PRICE`,
              headingMobile: `${labelPrice} PRICE`,
              widthRatio: 2,
              cellRender: (row) => (
                <div>
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-center md:flex-row">
                      <div className="flex justify-center">
                        <div className="pt-px md:pt-0.7 font-sohneHalbfett">
                          {renderSalePrice() > 0
                            ? !isListingOffMarket(statusCode)
                              ? priceFormatter(renderSalePrice())
                              : `$ ${NO_DATA_PLACEHOLDER}`
                            : renderPriceEstimate()}
                        </div>
                      </div>
                    </div>
                    {renderPriceDelta()}
                  </div>
                </div>
              ),
            },
            {
              key: 'bedrooms',
              headingMobile: 'BEDS',
            },
            {
              key: 'bathrooms',
              headingMobile: 'BATHS',
            },
            {
              key: 'area',
              heading: 'AREA sq ft',
              headingMobile: 'SQ FT',
              cellRender: (row) => {
                return (
                  <div>
                    <div>
                      {row.area ? sqftFormatter(row.area) : NO_DATA_PLACEHOLDER}
                    </div>
                  </div>
                );
              },
            },
            {
              key: 'year',
              heading: 'YEAR BUILT',
              headingMobile: 'BUILT',
            },
          ]}
          data={[
            {
              id: 1,
              salePrice: renderSalePrice(),
              priceDelta: renderPriceDelta(),
              bedrooms: bedsTotal || NO_DATA_PLACEHOLDER,
              bathrooms: renderBathrooms(),
              area: sqft,
              year: yearBuilt || NO_DATA_PLACEHOLDER,
            },
          ]}
        />
      </section>
    </ErrorBoundary>
  );
};

export default TableUnit;
