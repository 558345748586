import React from 'react';
import { Coordinates } from 'apolloClient/types/Neighborhood';

import FlagLabel from '../../components/FlagLabel';
import LineBetweenSections from 'components/LineBetweenSections';

import LocationGoogleMap from './LocationGoogleMap/LocationGoogleMap';

interface Props {
  neighborhoodId: number;
  coordinates: Coordinates;
}
const Location: React.FC<Props> = ({ neighborhoodId, coordinates }) => {
  return (
    <>
      <LineBetweenSections hideLine={'container'} />
      <section className="container pt-3 md:pt-[22px] lg:pt-3 pb-6 text-m lg:mx-auto">
        <div className="flex lg:max-w-[1180px] lg:mx-auto">
          <div className="relative flex flex-col justify-center mb-10 w-max">
            <FlagLabel name="Location" textSize="text-16-21-0.53 bg-beige" />
          </div>
        </div>
        <div className="relative w-full h-80 md:h-[396px] lg:h-[553px] max-w-[1180px] mx-auto">
          <LocationGoogleMap coordinates={coordinates} id={neighborhoodId} />
        </div>
      </section>
    </>
  );
};

export default Location;
