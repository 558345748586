import { NO_DATA_PLACEHOLDER } from '../constants/labels';

type TKeyGetter<T> = (value: T) => number;
type Formatter = (value: number) => string;

export const getRange = <T>(
  row: T,
  minProp: TKeyGetter<T>,
  maxProp: TKeyGetter<T>,
  formatter: Formatter
) =>
  minProp(row) && maxProp(row)
    ? `${formatter(minProp(row))}-${formatter(maxProp(row))}`
    : minProp(row)
    ? `${formatter(minProp(row))}+`
    : NO_DATA_PLACEHOLDER;
