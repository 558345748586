import { EntityWithId, StrapiMediaInfo } from 'apolloClient/types';
import Image from 'next/legacy/image';
import React from 'react';

import { PageBuilderImageType } from '../PageBuilderContentParser';

interface PageBuilderImageTypes {
  styles: React.CSSProperties;
  image: PageBuilderImageType;
  caption?: string;
  captionLink?: string;
}

type ImageAdditionalProps = {
  src: string;
  blurDataURL: string;
  width: number;
  height: number;
};

function getImageProps(image: StrapiMediaInfo): ImageAdditionalProps {
  const mediumImage = image.formats?.medium;
  const blurDataURL = image.formats?.thumbnail?.url || image.url;

  if (mediumImage?.url) {
    return {
      src: mediumImage.url,
      width: mediumImage.width,
      height: mediumImage.height,
      blurDataURL,
    };
  }

  return {
    src: image.url,
    width: image.width,
    height: image.height,
    blurDataURL,
  };
}

const PageBuilderImage: React.FC<PageBuilderImageTypes> = ({
  styles,
  image,
  caption,
  captionLink,
}) => {
  if (!image) return null;
  return (
    <div className="page-builder-image" style={styles}>
      <Image
        className="object-cover object-center"
        alt="image"
        {...getImageProps(image)}
        layout="responsive"
        placeholder="blur"
        loading="eager"
        priority
      />
      {caption && (
        <div
          className="caption-wrapper"
          style={{
            justifyContent: styles.justifyContent,
            alignItems: styles.alignItems,
          }}
        >
          {!!captionLink ? (
            <a
              target="_blank"
              href={captionLink}
              className="caption"
              style={{
                fontSize: styles.fontSize,
                fontWeight: styles.fontWeight,
                color: styles.color,
              }}
              rel="noopener noreferrer"
            >
              {caption}
            </a>
          ) : (
            <p
              className="caption"
              style={{
                fontSize: styles.fontSize,
                fontWeight: styles.fontWeight,
                color: styles.color,
              }}
            >
              {caption}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PageBuilderImage;
