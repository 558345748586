import React, { FunctionComponent, ReactElement } from 'react';
import { getHrefForUnitLink } from './Search/utils/listingsFiltersUtils';

export type TableColumn = {
  key: string;
  order?: string;
  heading?: string;
  headingMobile?: string;
  widthRatio?: number;
  cellRender?: (
    dataRecord: any,
    columnKey: string
  ) => ReactElement | string | number;
};

/**
 * data records must have distintive id
 */
export type HasId = {
  id: number | string;
  [k: string]: any;
};

const TableWithLink: FunctionComponent<{
  columns: TableColumn[];
  data: HasId[];
  rowClasses?: string;
  rootClassName?: string;
  whitespace?: string;
  unitId?: string;
}> = ({
  columns,
  data,
  rowClasses,
  whitespace = 'whitespace-nowrap',
  rootClassName = 'flex flex-row scroll',
}) => {
  return (
    <div>
      <div className={`${rootClassName}`}>
        {columns.map((column) => (
          <div
            key={column.key}
            className={`pb-2 mb-4 text-center border-r border-dashed  border-gray pr-1 ml-1 md:pr-4 md:ml-4 last:pr-0 first:ml-0 last:border-r-0 ${
              column.order
            } ${column.heading ? '' : 'uppercase'}`}
            style={{ flexGrow: column.widthRatio || 1, flexBasis: 0 }}
          >
            <div className={`hidden mb-2 lg:block ${whitespace}`}>
              {column.heading || column.key}
            </div>
            <div
              className={`mb-2 text-14-18-0.3 md:text-14-26-0.3 px-2 lg:hidden ${whitespace}`}
            >
              {column.headingMobile}
            </div>
            <div>
              {data.map((row, i) => (
                <React.Fragment key={row.id}>
                  <div className="hover:text-gold-hover whitespace-nowrap">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getHrefForUnitLink(row.listing)}
                      className="z-50 hover:bg-red"
                    >
                      <div
                        className={`hover:text-beige ${
                          i > 0
                            ? 'border-beige-dark border-t '
                            : 'border-gold-dark border-t'
                        }`}
                      />
                      <div className={`my-2 ${rowClasses}`}>
                        <div className={whitespace}>
                          {column.cellRender
                            ? column.cellRender(row, column.key)
                            : cellValue(row[column.key])}
                        </div>
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  function cellValue(value: any) {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    return value;
  }
};

export default TableWithLink;
