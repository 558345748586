import React, { useState, useEffect, useMemo, useContext } from 'react';

import { Position } from 'types';
import {
  cacheDirections,
  getCachedDirections,
} from 'lib/localStorageController';

import { BuildingPageContext } from '../pages/[urlParam]/[urlBuildParam]';

import Commute, { CommuteParams, CommuteData } from './Commute';
import UnitBuildingLocation from './UnitBuildingLocation';

const PopUpDirections: React.FC<{
  position?: Position;
}> = React.memo(
  function PopUpDirectionsMemoized({ position }) {
    const { building } = useContext(BuildingPageContext) || {};
    const { id } = building || {};

    const [directions, setDirections] = useState<CommuteData[]>([]);
    const [mapInstance, setMapInstance] = useState<google.maps.Map>();

    useEffect(() => {
      const cached = getCachedDirections({ building: Number(id) });
      const setCached = async () => {
        if (cached) {
          const processedCachedDirections = await Promise.all(
            cached.map(
              (item, index) =>
                getDirections(item, index, true) as Promise<
                  CommuteData | string
                >
            )
          );
          const validCachedDirections: CommuteData[] =
            processedCachedDirections.filter(
              (direction) => typeof direction !== 'string'
            ) as CommuteData[];
          cacheDirections({
            building: Number(id),
            directions: validCachedDirections,
          });
          setDirections(validCachedDirections);
        }
      };
      setCached();
    }, [id]);
    const travelModeMap = useMemo(
      () => ({
        Car: google.maps.TravelMode.DRIVING,
        Walking: google.maps.TravelMode.WALKING,
        Bicycle: google.maps.TravelMode.BICYCLING,
        Transit: google.maps.TravelMode.TRANSIT,
      }),
      []
    );
    const directionService = useMemo(
      () => new google.maps.DirectionsService(),
      []
    );
    const onHide = (index: number) => {
      directions[index] = { ...directions[index], active: false };
      setDirections([...directions]);
    };
    const onShow = (index: number) => {
      directions[index] = { ...directions[index], active: true };
      setDirections([...directions]);
    };
    const onDelete = (index: number) => {
      directions.splice(index, 1);
      cacheDirections({ building: Number(id), directions });
      setDirections([...directions]);
    };
    const getDirections: (
      params: CommuteParams,
      index: number,
      noCache?: boolean
    ) => Promise<string | CommuteData> = (
      { destination, time, transport, name },
      index,
      noCache
    ) =>
      new Promise((rs) => {
        if (position && destination.geometry?.location) {
          if (time < new Date()) {
            time.setDate(time.getDate() + 1);
          }
          directionService.route(
            {
              origin: { lng: position.lng, lat: position.lat },
              destination: destination.geometry?.location,
              travelMode: travelModeMap[transport],
              drivingOptions: { departureTime: time },
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                const direction = {
                  destination,
                  result: result as google.maps.DirectionsResult,
                  time,
                  transport,
                  active: true,
                  name,
                };
                if (noCache) {
                  return rs(direction);
                }
                if (index === -1) {
                  const res = [...directions, direction];
                  rs(status);
                  cacheDirections({ building: Number(id), directions: res });
                  return setDirections(res);
                }
                directions[index] = direction;
                const res = [...directions];
                cacheDirections({ building: Number(id), directions: res });
                setDirections(res);
              }
              rs(status);
            }
          );
        } else {
          rs('');
        }
      });

    return (
      <div className="flex flex-col w-full px-2 overflow-y-auto xl:h-screen xl:pb-20 xl:flex-row lg:pb-40">
        <div className="relative flex justify-center h-60 md:h-96 xl:h-full xl:w-1/2">
          <div className="w-full h-60 md:h-96 xl:h-full">
            <UnitBuildingLocation
              onMapLoaded={setMapInstance}
              map={mapInstance}
              position={position}
              directions={directions}
            />
          </div>
        </div>
        <Commute
          mapInstance={mapInstance}
          onAddGMapDirection={(params) =>
            getDirections(params, -1) as Promise<string>
          }
          onHide={onHide}
          onShow={onShow}
          commuteData={directions}
          onEditGMapDirection={getDirections as () => Promise<string>}
          onDelete={onDelete}
        />
      </div>
    );
  },
  (prev, next) =>
    prev.position?.lat === next.position?.lat &&
    prev.position?.lng === next.position?.lng
);

export default PopUpDirections;
