import React, { useEffect } from 'react';

type ZoomWrapperProps = {
  zoomIn: any;
  zoomOut: any;
  resetTransform: any;
  initialActiveRowIndex: any;
};

const ZoomWrapper: React.FC<ZoomWrapperProps> = ({
  zoomIn,
  zoomOut,
  resetTransform,
  initialActiveRowIndex,
}) => {
  useEffect(() => {
    resetTransform();
  }, [initialActiveRowIndex]);

  return (
    <div className="relative flex justify-center mt-2">
      <button className="px-2 border border-gold-dark" onClick={() => zoomIn()}>
        +
      </button>
      <button
        className="px-2 border border-gold-dark"
        onClick={() => zoomOut()}
      >
        -
      </button>
      <button
        className="px-2 border border-gold-dark"
        onClick={() => resetTransform()}
      >
        x
      </button>
    </div>
  );
};

export default ZoomWrapper;
