export type CalculatedNearbyCondosParams = {
  minPrice: number;
  maxPrice: number;
  minArea: number;
  maxArea: number;
  unused?: boolean;
};

export const getNearbyCondosParamsUnit = ({
  minAvgCoef,
  maxAvgCoef,
  sqft,
  price,
}: {
  minAvgCoef: number;
  maxAvgCoef: number;
  sqft: number;
  price: number;
}): CalculatedNearbyCondosParams => {
  const minPrice = Math.floor((price / sqft) * sqft * minAvgCoef) || 0;
  const maxPrice =
    Math.ceil((price / sqft) * sqft * maxAvgCoef) || 100000000000;
  const minArea = Math.floor(sqft * minAvgCoef) || 0;
  const maxArea = Math.ceil(sqft * maxAvgCoef) || 100000000000;

  return { maxArea, maxPrice, minArea, minPrice };
};
