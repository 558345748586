import { gql } from '@apollo/client';

export const BUILDING_AMENITIES_STRING = `{
  pets {
    allowedForRent
    largeForRent
    allowedForSale
    largeForSale
  }
  waterfront {
    any
    bay
    beach
    bridge
    canal
    intracoastal
    lagoon
    lake
    mangrove
    navigable
    ocean
    pond
    ripRap
    river
    seawall
    other
  }
  waterAccess {
    boatHoistsDavits
    boatlift
    boatlock
    communityBoatDock
    communityBoatRamp
    deededDock
    dockAvailable
    privateDock
    restrictedSalt
    unrestrictedSalt
    canalWidth121Plus
    canalWidth1To80
    canalWidth81To120
    intersectingCanals
    navigable
    noFixedBridges
    ocean
    fixedBridges
    lake
  }
  view {
    any
    none
    bay
    canal
    city
    clubArea
    directOcean
    ocean
    garden
    golf
    intracoastal
    lagoon
    lake
    poolArea
    river
    skyline
    tennis
    water
    other
  }
  sport {
    basketball
    bikeStorage
    bikeJogPath
    billiard
    exerciseRoom
    fishingPier
    golf
    pool
    shuffleboard
    tennis
  }
  general {
    bar
    bbqPicnicArea
    boatDock
    businessCenter
    cabana
    childPlayArea
    club
    commonLaundry
    communityRoom
    courtesyBus
    elevator
    exteriorLighting
    extraStorage
    heatedPool
    hobbyRoom
    kitchenFacilities
    library
    marina
    privateBeachPavilion
    privatePool
    puttingGreen
    spaHotTub
    trashChute
    vehicleWashArea
    sauna
  }
  convenience {
    centralCooling
    centralHeat
    dishwasher
    electricCooling
    electricHeat
    furnished
    partiallyFurnished
    unfurnished
    walkInClosets
    washerDryer
  }
  association {
    condo
    homeowners
    none
    other
    voluntary
    allowed
  }
  maintenance {
    landscapingAndLawn
    recreationFacilities
    security
    buildingExterior
    commonArea
    insurance
    outside
    poolService
    roofRepairs
    trashRemoval
    airConditioning
    all
    cableTv
    electric
    elevator
    fidelityBond
    golf
    hotWater
    manager
    parking
    laundryFacilities
    legalAndAccounting
    sewer
    water
    other
    pestControlInterior
    reserveFund
    masterAntenna
    none
    internetAndWiFi
  }
  style {
    anchoredCenter
    commercial
    professional
    restaurant
    shoppingCenter
    retail
    freeStanding
    warehouse
    apartment
    apartmentsAnnual
    manufacturedAndMobileHome
    coOp
    condo
    duplex
    efficiency
    house
    lease
    mobile
    multiFamily
    other
    residential
    townhouse
    villa
    apartmentsOffSeason
    apartmentsSeasonal
    coOp1To4Stories
    dockominium
    coOp5PlusStories
    hotel
    officeAndRetail
    industrialAndManufacturing
    officeAndWarehouse
    office
    storeAndWarehouse
    industrial
    condo1To4Stories
    condo5PlusStories
    condoHotel
    timeshare
    condoAndCoOpAnnual
    condoAndCoOpOffSeason
    condoAndCoOpSeasonal
    condoAndTimeshare
    fourplex
    triplex
    duplexAndTriplexAndQuadruplexAnnual
    condoAndCoOpAndAnnual
    condoAndCoOpAndOffSeason
    condoAndCoOpAndSeasonal
    duplexAndTriplexAndQuadruplexOffSeason
    duplexAndTriplexAndQuadruplexSeasonal
    efficiencyAndStandartAndHotelRoomAnnual
    efficiencyAndStandartAndHotelRoomOffSeason
    efficiencyAndStandartAndHotelRoomSeasonal
    hotelAndMotel
    lounge
    recreationFacility
    singleFamilyAnnual
    townhouseAndVillaAnnual
    medicalOffice
    noPoolAndNoWater
    pool
    quadruplex
    residentialAnnual
    residentialOffSeason
    residentialSeasonal
    singleFamilyOffSeason
    singleFamilySeasonal
    townhouseCondo
    townhouseFeeSimple
    townhouseAndVillaOffSeason
    townhouseAndVillaSeasonal
    unimprovedAgriculturalAndRecreationAndMobileHome
    villaCondo
    villaFeeSimple
    waterfrontAndNoOceanAccess
    waterfrontAndOceanAccess
    waterfrontAndPoolAndNoOceanAccess
    waterfrontAndPoolAndOceanAccess
  }
  parking {
    oneAssigned
    covered
    deeded
    guest
    underBuilding
    valet
  }
}`;

export const BUILDING_AND_BLOGS_BY_SLUG = gql`
  query ($slug: String!, $nhSlug: String!) {
    saleCount: listings(
      filters: {
        saleUnit: { id: { notNull: true } }
        unit: {
          building: {
            slug: { eq: $slug }
            neighborhood: { slug: { eq: $nhSlug } }
          }
        }
        statusCode: { eq: "A" }
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    rentCount: listings(
      filters: {
        rentUnit: { id: { notNull: true } }
        unit: {
          building: {
            slug: { eq: $slug }
            neighborhood: { slug: { eq: $nhSlug } }
          }
        }
        statusCode: { eq: "A" }
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    blogs(pagination: { pageSize: 3 }, sort: "publishedDate:DESC") {
      data {
        attributes {
          title
          slug
          publishedDate
          shortDescription
          mainImage {
            data {
              attributes {
                url
                formats
              }
            }
          }
          blogTags {
            data {
              attributes {
                label
              }
            }
          }
        }
      }
    }
    buildings(
      filters: { slug: { eq: $slug }, neighborhood: { slug: { eq: $nhSlug } } }
    ) {
      data {
        id
        attributes {
          name
          slug
          primaryAddress
          mapAddress
          isPreconstruction
          yearBuilt
          videoUrl
          floorsCount
          unitsCount
          description
          maintenanceIncludes
          typeOfAssociation
          style
          googleRank
          yelpRank
          developers {
            name
          }
          architects {
            name
          }
          interiorDesigners {
            name
          }
          landscapeArchitects {
            name
          }
          floorPlanImage {
            data {
              attributes {
                url
                formats
              }
            }
          }
          walkscore
          floorplans(pagination: { pageSize: 200 }) {
            apartmentNumbers
            area
            exteriorArea
            bedBath
            image {
              data {
                attributes {
                  url
                  formats
                }
              }
            }
            floors {
              from
              to
            }
            model
          }
          lat
          lon
          blogs(pagination: { pageSize: 3 }, sort: "publishedDate:DESC") {
            data {
              attributes {
                title
                slug
                publishedDate
                shortDescription
                mainImage {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
                blogTags {
                  data {
                    attributes {
                      label
                    }
                  }
                }
              }
            }
          }
          neighborhood {
            data {
              id
              attributes {
                name
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                slug
                shortDescription
                neighborhoodStats {
                  averageAssociationFeeSqftPrice
                }
                city {
                  data {
                    id
                    attributes {
                      name,
                      slug
                    }
                  }
                }
                blogs(pagination: { pageSize: 3 }, sort: "publishedDate:DESC") {
                  data {
                    attributes {
                      title
                      slug
                      shortDescription
                      blogTags {
                        data {
                          attributes {
                            label
                          }
                        }
                      }
                      mainImage {
                        data {
                          attributes {
                            url
                            formats
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          buildingStats {
            saleCount
            rentCount
            maxSalePrice
            minSalePrice
            minSaleSqftPrice
            maxSaleSqftPrice
            unitsCount
            salePercent
            rentPercent
            unitsPercent
            averageSalePrice
            averageSaleSqftPrice
            averageSaleSqftPriceSixMonths
            averageSalePriceSixMonths
            minSaleBeds
            averageSaleDaysOnMarketSixMonths
            maxSaleBeds
            minSaleSqft
            maxSaleSqft
            saleHistory
            averageAssociationFeeSqftPrice
          }
          amenity ${BUILDING_AMENITIES_STRING}
          mainImage {
            data {
              attributes {
                url
              }
            }
          }
          images(pagination: { pageSize: 200 }) {
            data {
              attributes {
                url
                formats
                name
                hash
              }
            }
          }
          moreInfo {
            header
            url
          }
        }
      }
    }
  }
`;

export const NEARBY_CONDOS = gql`
  query NearbyCondos(
    $maxPrice: Long!
    $minPrice: Long!
    $minArea: Long!
    $maxArea: Long!
    $propertyTypeList: [String]!
    $buildingId: ID!
    $neighborhoodId: ID!
    $userId: ID
  ) {
    listings(
      filters: {
        or: [
          { saleUnit: { id: { notNull: true } } }
          { rentUnit: { id: { notNull: true } } }
        ]
        statusCode: { eq: "A" }
        price: { between: [$minPrice, $maxPrice] }
        sqft: { between: [$minArea, $maxArea] }
        unit: {
          building: {
            publishedAt: { notNull: true }
            id: { ne: $buildingId }
            neighborhood: {
              id: { eq: $neighborhoodId }
              publishedAt: { notNull: true }
            }
          }
        }
        propertyTypeCode: { in: $propertyTypeList }
      }
      sort: ["isRecommended:DESC", "createdAt:DESC"]
      pagination: { pageSize: 12 }
    ) {
      data {
        id
        attributes {
          images {
            data {
              attributes {
                url
              }
            }
          }
          unitNumber
          statusCode
          propertyTypeCode
          sqft
          closeDate
          price
          bedsTotal
          bathsFull
          unit {
            data {
              id
              attributes {
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                unique
                unitNumber
                building {
                  data {
                    attributes {
                      primaryAddress
                      slug
                      name
                      neighborhood {
                        data {
                          attributes {
                            slug
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NEARBY_CONDOS_WITHOUT_RANGES = gql`
  query NearbyCondosWithoutRanges(
    $propertyTypeList: [String]
    $buildingId: ID
    $neighborhoodId: ID
    $userId: ID
  ) {
    listings(
      filters: {
        or: [
          { saleUnit: { id: { notNull: true } } }
          { rentUnit: { id: { notNull: true } } }
        ]
        statusCode: { eq: "A" }
        unit: {
          building: {
            publishedAt: { notNull: true }
            id: { ne: $buildingId }
            neighborhood: {
              id: { eq: $neighborhoodId }
              publishedAt: { notNull: true }
            }
          }
        }
        propertyTypeCode: { in: $propertyTypeList }
      }
      sort: ["isRecommended:DESC", "createdAt:DESC"]
      pagination: { pageSize: 12 }
    ) {
      data {
        id
        attributes {
          images {
            data {
              attributes {
                url
              }
            }
          }
          unitNumber
          statusCode
          propertyTypeCode
          sqft
          closeDate
          price
          bedsTotal
          bathsFull
          unit {
            data {
              id
              attributes {
                favorite(filters: { author: { id: { eq: $userId } } }) {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                unique
                unitNumber
                building {
                  data {
                    attributes {
                      primaryAddress
                      slug
                      name
                      neighborhood {
                        data {
                          attributes {
                            slug
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BUILDING_ITEMS_LIST = gql`
  query BuildingItemsList($pageSize: Int, $page: Int) {
    buildings(
      pagination: { pageSize: $pageSize, page: $page }
      filters: { neighborhood: { publishedAt: { notNull: true } } }
    ) {
      data {
        attributes {
          slug
          neighborhood {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
    }
  }
`;
