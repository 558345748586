import React, { useEffect, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import useDeviceSize from '../../../hooks/useDeviceSize';
import PopUpContactUs from '../../ContactUs/PopUpContactUs';

import { DefaultHeaderBody, ToggleHeader } from './Header';
import classNames from 'classnames';

const getDimensions = (ele: HTMLElement | null) => {
  let { height } = ele?.getBoundingClientRect() || { height: 0 };
  const offsetTop = ele?.offsetTop || 0;
  const offsetBottom = offsetTop + height;
  height += 60;
  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

interface HeaderWithLinksProps {
  links: {
    id: string;
    name: string;
    nameMobile: string;
    section: string;
    ref: React.MutableRefObject<null>;
  }[];
  headerRef: React.MutableRefObject<null>;
  isNeighborhoodPage?: boolean;
}

const HeaderUnitPage: React.FC<HeaderWithLinksProps> = ({
  links,
  headerRef,
  isNeighborhoodPage,
}) => {
  const [visibleSection, setVisibleSection] = useState<string | null>(
    'RentOrSale'
  );

  const SectionScrollRef = useRef<any>(null);

  const deviceSize = useDeviceSize();
  const halfDeviceSize = deviceSize[0] / 7;
  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = links.find(({ section, ref }) => {
        const ele: any = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return (
            scrollPosition + halfDeviceSize > offsetTop &&
            scrollPosition + halfDeviceSize < offsetBottom
          );
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(null);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleSection]);
  function renderLinks() {
    const elementsWidth: any = [];

    return links.map((sectionRef, index) => (
      <a
        ref={(ref) => {
          elementsWidth[index] = ref?.clientWidth;
          if (ref && visibleSection === sectionRef.section) {
            const width = elementsWidth
              .slice(0, index)
              .reduce((acc: number, width: number) => (acc += width), 0);
            SectionScrollRef.current?.scrollTo(width, 0);
          }
        }}
        href={`#${sectionRef.id}`}
        key={index}
        className="mr-1 pr-1 md:pr-3 md:mr-3 lg:pr-3 lg:mr-3 border-r from-black mt-4 my-3.5 md:my-5 lg:mb-3.5 lg:mt-[17px] xl:my-[10px] cursor-none text-12-18-0.3 md:text-16-26-0.3 lg:text-16-26-0.3 whitespace-pre-wrap  border-gold-dark  last:border-r-0 "
      >
        <div
          className={`flex cursor-none whitespace-nowrap ${
            visibleSection === sectionRef.section
              ? `selected border-b border-gold-dark`
              : ``
          }`}
        >
          <div className="hidden md:inline-block">{sectionRef.name}</div>
          <div className="md:hidden">{sectionRef.nameMobile}</div>
        </div>
      </a>
    ));
  }

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  return (
    <ToggleHeader
      heightClass="h-13"
      toggle={!isMobile}
      initialHeader={
        <div className="w-full">
          <DefaultHeaderBody />
          <div className="w-full border-t border-beige-dark" />
          <div
            ref={SectionScrollRef}
            className={classNames(
              'container flex text-14-26-0.3 cursor-auto cursor-none',
              { 'hidden md:flex': isNeighborhoodPage }
            )}
          >
            {renderLinks()}
          </div>
        </div>
      }
      scrolledHeader={
        <div className="container flex justify-center pr-0 text-14-26-0.3 bg-white cursor-auto lg:justify-between cursor-none h-13 md:h-auto">
          <div
            ref={SectionScrollRef}
            className="flex text-14-26-0.3 cursor-auto cursor-none"
          >
            {renderLinks()}
          </div>
          <PopUpContactUs />
        </div>
      }
    />
  );
};

export default HeaderUnitPage;
