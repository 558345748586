import Image from 'next/legacy/image';
import React from 'react';

import { useAuth } from './AuthProvider';
import profile from '../../public/images/profile.svg';

type HeaderJoinOrSignButtonProps = {
  nameButton?: string;
  showIcon: boolean;
};

const HeaderJoinOrSignButton: React.FC<HeaderJoinOrSignButtonProps> = ({
  nameButton,
  showIcon,
}) => {
  const {
    authData: {
      user: { id },
    },
    openHeaderPopup,
  } = useAuth();
  return (
    <>
      {id === null && (
        <button
          id="Join_Sign_in_Start"
          onClick={() => openHeaderPopup()}
          className="join-class relative w-full h-auto pt-[13px] xl:pt-[22px] mb-[22px] border-dashed xl:w-auto"
        >
          <div className="cursor-pointer join-class ">
            <div className="join-class flex items-center ml-[-2px]">
              {showIcon && (
                <Image
                  className="join-class"
                  src={profile}
                  alt="profile"
                  width={26}
                  height={26}
                />
              )}
              <div
                id="gtm-join"
                className="h-full ml-2 text-black join-class hover:text-gold-hover "
              >
                {nameButton}
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  );
};

export default HeaderJoinOrSignButton;
