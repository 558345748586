import React, { useContext } from 'react';
import { getRange } from 'lib/getRange';
import { priceFormatter, sqftFormatter } from 'src/utils/utils';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';

import PopUpContactUs from '../ContactUs/PopUpContactUs';

const BuildingPopupHeader: React.FC = () => {
  const {
    building: { floorsCount, name, unitsCount, buildingStats },
  } = useContext(BuildingPageContext) as BuildingPageContextInterface;

  if (!buildingStats) return <div>No building stats</div>;

  return (
    <div className="fixed w-full text-14-26-0.3 bg-white border-b cursor-auto z-60 border-beige-dark">
      <div className="container flex items-start justify-between px-0 py-4 pl-8 md:py-2 md:items-center h-28 md:pl-14 md:h-14 xl:h-12">
        <div className="flex flex-col w-2/3 h-full xl:gap-6 xl:items-center xl:flex-row md:w-3/5 lg:w-1/2">
          <div className="relative flex items-center w-auto h-full border-dashed xl:border-r border-gray group">
            <span className="relative inline pr-6 mb-2 text-20-28-0.3 leading-5 md:mb-2 xl:mb-0 self-align-center hover:text-gold-hover font-sohneKraftig">
              {name}
            </span>
          </div>
          <div className="relative flex flex-wrap w-2/3 h-auto leading-5 md:w-auto group">
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {getRange(
                buildingStats,
                (val) => val?.minSalePrice,
                (val) => val?.maxSalePrice,
                priceFormatter
              )}
            </span>
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {getRange(
                buildingStats,
                (val) => val?.minSaleSqft,
                (val) => val?.maxSaleSqft,
                sqftFormatter
              )}{' '}
              sqft
            </span>
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {unitsCount || NO_DATA_PLACEHOLDER} units
            </span>
            <span className="relative pr-3 lg:pr-6 md:pb-4 lg:pb-0 hover:text-gold-hover">
              {floorsCount || NO_DATA_PLACEHOLDER} stories
            </span>
          </div>
        </div>
        <PopUpContactUs />
      </div>
    </div>
  );
};

export default BuildingPopupHeader;
