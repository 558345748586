import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Image from 'next/legacy/image';
import { Tab } from '@headlessui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classnames from 'classnames';
import { LoadScriptNext } from '@react-google-maps/api';
import { Dialog, DialogProps, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

import iconClose from '../public/images/close.svg';
import arrowButton from '../public/images/arrow-button.svg';
import unitLeftArrow from '../public/images/unit_left_arrow.svg';
import unitRightArrow from '../public/images/unit_right_arrow.svg';
import { Table } from '../components/Table';
import { priceFormatter, sqftFormatter } from '../src/utils/utils';
import whiteQuickView from 'public/images/white_quick_view.svg';
import whiteQuickViewHover from 'public/images/white_quick_view_hover.svg';
import whiteFloorplan from 'public/images/white_floorplan.svg';
import whiteFloorplanHover from 'public/images/white_floorplan_hover.svg';
import whiteLocation from 'public/images/white_location.svg';
import whiteLocationHover from 'public/images/white_location_hover.svg';

import { EntityWithId } from '../apolloClient/types';
import { translateUnitStatus } from '../lib/translateUnitStatus';
import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from '../lib/flattenStrapiBulkDataItems';
import { Listing } from '../apolloClient/types/Units';
import { ORIGIN } from '../src/constants';
import { getHrefForUnitLink } from './Search/utils/listingsFiltersUtils';
import { loadScriptProps } from '../constants/gapi/googleMapsConfig';
import { getPropertyTypeByCode } from '../src/utils/getPropertyTypeByCode';

import Share from './Share';
import SaveToFavoritesButton from './SavedHouses/SaveToFavoritesButton';
import PopUpDirections from './PopUpDirections';
import FloorplanPopup from './Floorplans/FloorplanPopup';
import PopUpContactUs from './ContactUs/PopUpContactUs';
import {
  getMediumImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { defaultImage } from 'src/constants/constants';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import { useAuth } from './Auth/AuthProvider';

interface StyledDialogProps extends DialogProps {
  isdesktop?: string;
}

const StyledDialog = styled(Dialog)<StyledDialogProps>(({ isdesktop }) => ({
  '.MuiDialog-container': {
    minHeight: '900px!important',
    height: isdesktop ? '900px!important' : '100vh',

    '.MuiPaper-root': {
      minHeight: isdesktop ? '900px!important' : '100vh',
      height: '900px!important',
      padding: '0',
      borderRadius: '0',
      width: '1022px',
      margin: '0',
    },
  },
}));

type TabsCollection = {
  photos: {
    tab: JSX.Element;
    panel: JSX.Element;
    button: JSX.Element;
  };
  floorplan: {
    tab: JSX.Element;
    panel: JSX.Element;
    button: JSX.Element;
  };
  direction: {
    tab: JSX.Element;
    panel: JSX.Element;
    button: JSX.Element;
  };
  tour: {
    tab: JSX.Element;
    panel: JSX.Element;
    button: null;
  };
};
export type tabsCollectionKeys = keyof TabsCollection;

type PopUpSearchQuickViewProps = {
  listing: Listing;
  activeUnitIndex: number;
  tabsForRender: tabsCollectionKeys[];
  isPPC?: boolean;
  isIconPPC?: boolean;
  items: EntityWithId<Listing>[];
};

function SearchQuickViewPrevArrow({ slideCount, currentSlide, ...props }: any) {
  return (
    <button
      {...props}
      className="z-50 transform -translate-y-1/2 slick-arrow slick-prev slick-prev-search-quick-view !left-[25px]"
    >
      <Image src={unitLeftArrow} alt="previous unit" width="28" height="48" />
    </button>
  );
}
function SearchQuickViewNextArrow({ slideCount, currentSlide, ...props }: any) {
  return (
    <button
      {...props}
      className="z-50 transform -translate-y-1/2 slick-arrow slick-next slick-next-search-quick-view"
    >
      <Image src={unitRightArrow} alt="next unit" width="28" height="48" />
    </button>
  );
}

const PopUpSearchQuickView: React.FC<PopUpSearchQuickViewProps> = ({
  isIconPPC,
  isPPC,
  listing,
  items,
  activeUnitIndex,
  tabsForRender,
}) => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(activeUnitIndex);
  const [activeTab, setActiveTab] = useState(0);
  const [tForRender, setTForRender] = useState(tabsForRender);
  const { me } = useAuth();

  useEffect(() => {
    if (!listing.hasFloorplan) {
      setTForRender((list) => list.filter((item) => item !== 'floorplan'));
    } else if (
      listing.hasFloorplan &&
      !tForRender.find((it) => it === 'floorplan')
    ) {
      setTForRender((list) => [...list, 'floorplan']);
    }
  }, [activeIndex, me?.id]);

  const item = items[activeIndex];

  const dataStreetDirection =
    item?.address?.streetDirection !== undefined
      ? Object.assign([], item?.address?.streetDirection)
      : [];
  const streetDirection = dataStreetDirection
    .filter((x: any) => x === x.toUpperCase())
    .join('')
    .replace('_', '');
  const streetType = item?.address?.streetType?.replace('Unknown', '');
  const unit = flattenStrapiDataItem(item?.unit?.data);
  const unitBuilding = flattenStrapiDataItem(unit?.building?.data);
  const href = getHrefForUnitLink(item);

  const handleNextListing = () => {
    const isLastItem = activeIndex === items.length - 1;

    if (isLastItem) {
      setActiveIndex(0);
      return;
    }

    setActiveIndex(activeIndex + 1);
  };

  const handlePrevListing = () => {
    const isFirstItem = activeIndex === 0;

    if (isFirstItem) {
      setActiveIndex(items.length - 1);
      return;
    }

    setActiveIndex(activeIndex - 1);
  };

  const MemoSlider = useCallback(() => {
    const images = flattenStrapiBulkDataItems(item.images.data);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SearchQuickViewPrevArrow />,
      nextArrow: <SearchQuickViewNextArrow />,
    };

    return (
      <React.Fragment>
        {images.length > 1 ? (
          <div className="relative w-full h-screen-image">
            <Slider {...settings}>
              {images.map((image, index) => {
                return (
                  <div key={index}>
                    <div className="relative h-screen-image">
                      <Image
                        className="object-cover object-left w-full h-full"
                        src={getMediumImageUrlFromImage(image)}
                        blurDataURL={getThumbnailImageUrlFromImage(image)}
                        placeholder="blur"
                        loading="eager"
                        layout="fill"
                        alt={image.name}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : (
          <div className="relative w-full h-screen-image">
            <Image
              className="object-contain object-center w-full h-full"
              src={getMediumImageUrlFromImage(images[0]) || defaultImage}
              blurDataURL={
                getThumbnailImageUrlFromImage(images[0]) || defaultImage
              }
              placeholder="blur"
              loading="eager"
              layout="fill"
              alt="default image"
            />
          </div>
        )}
      </React.Fragment>
    );
  }, [activeIndex]);

  const components: TabsCollection = {
    photos: {
      tab: (
        <Tab as={Fragment}>
          {({ selected }) => (
            <button className="pr-2 text-12-18-0.3 border-r border-dashed border-gray last:border-0 md:pr-4 lg:pr-[10px] md:text-16-26-0.3">
              <span
                className={`tab-popup-unit text-12-15-0.3 md:text-16-21-0.3 ${
                  selected ? 'border-gold' : 'border-transparent'
                }`}
              >
                Photos
              </span>
            </button>
          )}
        </Tab>
      ),
      panel: (
        <Tab.Panel className="lg:px-8 pt-[8px] md:pt-[18px] xl:pl-[34px] outline-none">
          <div className="flex flex-col">
            <div className="relative flex justify-center w-full min-h-full lg:w-[951px]">
              <MemoSlider />
            </div>
            <section className="px-[4px] md:px-[29px] lg:px-0 py-[10px] lg:py-1.5 mt-2 margin-none">
              <Table
                colClasses="!pb-0"
                whitespace="text-12-16-0.3 md:text-14-19-0.3"
                rootClassName="flex flex-row flex-nowrap overflow-auto text-14-26-0.3"
                rowClasses="font-sohneHalbfett text-14-26-0.3 md:text-20-28-0.3 lg:text-30-36-1 mt-4 tracking-widest font-bold"
                columns={[
                  {
                    key: 'price',
                    headingMobile: 'PRICE',
                    widthRatio: 2,
                    cellRender: (row) => (
                      <div>
                        <div className="flex flex-col">
                          <div className="flex flex-col justify-center md:flex-row">
                            <div className="flex justify-center">
                              <div className="pt-px md:pt-0.7 font-bold text-14-18--0.15 md:text-20-28-0.3 lg:text-30-36-1">
                                {row.price > 0
                                  ? priceFormatter(row.price)
                                  : NO_DATA_PLACEHOLDER}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'bedrooms',
                    headingMobile: 'BEDS',
                  },
                  {
                    key: 'bathrooms',
                    headingMobile: 'BATHS',
                  },
                  {
                    key: 'area',
                    heading: 'AREA sq ft',
                    headingMobile: 'SQ FT',
                    cellRender: (row) => (
                      <div>
                        <div>
                          {row.area
                            ? sqftFormatter(row.area)
                            : NO_DATA_PLACEHOLDER}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'year',
                    heading: 'YEAR BUILT',
                    headingMobile: 'BUILT',
                  },
                ]}
                data={[
                  {
                    id: 1,
                    price: item.price,
                    bedrooms: item.bedsTotal || NO_DATA_PLACEHOLDER,
                    bathrooms: item.bathsFull || NO_DATA_PLACEHOLDER,
                    area: item.sqft,
                    year: item.yearBuilt || NO_DATA_PLACEHOLDER,
                  },
                ]}
              />
            </section>
            <div className="flex justify-between px-[20px] md:px-[45px] lg:px-0 mt-2 md:mt-[45px] lg:mt-[20px] text-12-18-0.3 md:text-16-26-0.3">
              <div className="h-10 md:w-[160px] lg:w-[175px]">
                <button
                  className={classnames(
                    'flex items-center justify-between h-[44px] uppercase bg-white md:px-4 lg:px-[20px] md:border md:w-[160px] lg:w-[175px] border-gold text-14-26-0.3'
                  )}
                  onClick={handlePrevListing}
                >
                  <Image
                    src={arrowButton}
                    className="transform rotate-180 w-[28px] md:w-[19px] h-[21px] md:h-[14px]"
                    alt="previous item"
                  />
                  <div className="hidden opacity-0 md:block md:opacity-100">
                    Prev Listing
                  </div>
                </button>
              </div>
              <a
                href={href}
                className="h-[44px] px-[29px] flex items-center text-14-26-0.3 uppercase border md:text-16-26-0.3 md:leading-8 md:px-12 bg-gold border-gold"
              >
                View full details
              </a>
              <div className="h-10 md:w-[160px] lg:w-[175px]">
                <button
                  className={classnames(
                    'flex items-center justify-between h-[44px] uppercase bg-white md:px-4 md:border md:w-[160px] lg:w-[175px] border-gold text-14-26-0.3'
                  )}
                  onClick={handleNextListing}
                >
                  <div className="hidden opacity-0 md:block md:opacity-100">
                    Next Listing
                  </div>
                  <Image
                    src={arrowButton}
                    alt="next item"
                    className="w-[28px] md:w-[19px] h-[21px] md:h-[14px]"
                  />
                </button>
              </div>
            </div>
          </div>
        </Tab.Panel>
      ),
      button: (
        <>
          {(me?.id || (!isPPC && !isIconPPC)) && (
            <button
              onClick={() => setOpen(true)}
              className="block cursor-pointer group"
            >
              <>
                <div className="group-hover:hidden">
                  <Image
                    src={whiteQuickView}
                    alt="quick view"
                    height={18}
                    width={30}
                  />
                </div>
                <div className="hidden group-hover:block">
                  <Image
                    src={whiteQuickViewHover}
                    alt="quick view"
                    height={18}
                    width={30}
                  />
                </div>
              </>
            </button>
          )}
        </>
      ),
    },
    floorplan: {
      tab: (
        <Tab as={Fragment}>
          {({ selected }) => (
            <button className="px-2 text-12-18-0.3 md:px-4 lg:px-[20px] md:text-16-26-0.3 border-r border-dashed border-gray last:border-0">
              <span
                className={`tab-popup-unit text-12-15-0.3 md:text-16-21-0.3 ${
                  selected ? 'border-gold' : 'border-transparent'
                }`}
              >
                Floor plan
              </span>
            </button>
          )}
        </Tab>
      ),
      panel: (
        <Tab.Panel className="px-8 pt-[8px] lg:pl-[34px]">
          <div className="flex flex-col px-0 overflow-y-auto xl:w-180 lg:flex-row h-135">
            <FloorplanPopup entity={item} bodyType="listing" />
          </div>
        </Tab.Panel>
      ),
      button: (
        <button
          onClick={() => setOpen(true)}
          className="block cursor-pointer group"
        >
          <div className="group-hover:hidden">
            <Image
              src={whiteFloorplan}
              alt="floor plans"
              height={21}
              width={21}
            />
          </div>
          <div className="hidden group-hover:block">
            <Image
              src={whiteFloorplanHover}
              alt="floor plans"
              height={21}
              width={21}
            />
          </div>
        </button>
      ),
    },
    direction: {
      tab: (
        <Tab as={Fragment}>
          {({ selected }) => (
            <button className="px-2 text-12-18-0.3 border-r border-dashed border-gray last:border-0 md:px-4 lg:px-[20px] md:text-16-26-0.3">
              <span
                className={`tab - popup - unit text - 12 - 15 - 0.3 md: text - 16 - 21 - 0.3 ${
                  selected ? 'border-gold' : 'border-transparent'
                } `}
              >
                Directions
              </span>
            </button>
          )}
        </Tab>
      ),
      panel: (
        <Tab.Panel className="px-8 pt-[8px] xl:pl-[34px] pb-[60px] md:pb-0">
          <div className="flex flex-col px-0 lg:flex-row">
            <div className="relative flex justify-center w-full min-h-full">
              <div className="w-full overflow-hidden md:h-quickview">
                <LoadScriptNext {...loadScriptProps} loadingElement={undefined}>
                  <PopUpDirections
                    position={
                      unitBuilding.lon && unitBuilding.lat
                        ? {
                            lng: unitBuilding.lon,
                            lat: unitBuilding.lat,
                          }
                        : undefined
                    }
                  />
                </LoadScriptNext>
              </div>
            </div>
          </div>
        </Tab.Panel>
      ),
      button: (
        <button
          onClick={() => setOpen(true)}
          className="block cursor-pointer group"
        >
          <div className="group-hover:hidden">
            <Image
              src={whiteLocation}
              alt="map location"
              height={25}
              width={20}
            />
          </div>
          <div className="hidden group-hover:block">
            <Image
              src={whiteLocationHover}
              alt="map location"
              height={25}
              width={20}
            />
          </div>
        </button>
      ),
    },
    tour: {
      tab: (
        <Tab as={Fragment}>
          {({ selected }) => (
            <button className="px-2 text-12-18-0.3 border-r border-dashed border-gray last:border-0 md:px-4 lg:px-[20px] md:text-16-26-0.3">
              <span
                className={`tab - popup - unit text - 12 - 15 - 0.3 md: text - 16 - 21 - 0.3 ${
                  selected ? 'border-gold' : 'border-transparent'
                } `}
              >
                Tour
              </span>
            </button>
          )}
        </Tab>
      ),
      panel: (
        <Tab.Panel className="px-8 pt-[8px] xl:pl-[34px]">
          <div className="flex flex-col px-0 xl:w-180 lg:flex-row">
            <div className="relative flex justify-center w-full min-h-full">
              <div className="w-full h-quickview">
                <iframe
                  className="w-full h-80 md:h-full"
                  src={item.virtualTourUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </Tab.Panel>
      ),
      button: null,
    },
  };

  const createButton = (button: JSX.Element | null, index: number) => {
    if (!button) {
      return null;
    }

    const handleClick = () => setActiveTab(index);

    return (
      <div key={index} onClick={handleClick}>
        {button}
      </div>
    );
  };

  const createTab = (tab: JSX.Element | null, index: number) => {
    if (!tab) {
      return null;
    }

    return React.cloneElement(tab, { key: index });
  };

  const createPanel = (panel: JSX.Element | null, index: number) => {
    if (!panel) {
      return null;
    }

    return React.cloneElement(panel, { key: index });
  };

  const { buttons, panels, tabs } = useMemo(() => {
    const { buttons, tabs, panels } = tForRender.reduce<{
      buttons: Array<JSX.Element | null>;
      tabs: Array<JSX.Element | null>;
      panels: Array<JSX.Element | null>;
    }>(
      (acc, red, index) => {
        const { tab, button, panel } = components[red];

        acc.buttons.push(createButton(button, index));
        acc.tabs.push(createTab(tab, index));
        acc.panels.push(createPanel(panel, index));

        return acc;
      },
      { buttons: [], tabs: [], panels: [] }
    );

    return { buttons, panels, tabs };
  }, [tForRender, activeIndex]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const cityAddress =
    item?.unit?.data?.attributes?.building?.data?.attributes?.neighborhood?.data
      ?.attributes?.city?.data?.attributes?.name;
  const countyAddress =
    item?.unit?.data?.attributes?.building?.data?.attributes?.neighborhood?.data
      ?.attributes?.city?.data?.attributes?.county?.data?.attributes?.name;
  const displayedAddress =
    item?.unit?.data?.attributes?.building?.data?.attributes?.primaryAddress ||
    `${item?.address?.streetNumber} ${streetDirection}
      ${item?.address?.streetName} ${streetType},
      ${!!cityAddress ? cityAddress : countyAddress},
      FL ${item?.address?.zipCode} `;

  return (
    <>
      {buttons}
      <StyledDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setActiveIndex(activeUnitIndex);
        }}
        maxWidth="xl"
        fullScreen={isMobile}
        isdesktop={isDesktop ? 'true' : ''}
      >
        <div className="flex flex-col justify-between px-0 sm:w-full md:w-full">
          <div>
            <div className="flex w-[95%] md:w-full">
              <div className="flex flex-col w-full pl-[20px] md:mt-[16px] lg:mt-0 md:pl-[44px] md:px-[34px] lg:pl-[34px] lg:flex-row lg:h-[54px] lg:items-center lg:pt-[4px]">
                <span className="relative px-0 uppercase pt-[10px] md:pt-0 lg:pl-[16px] lg:pr-[32px] text-gold-hover md:hidden text-14-18-0.3">
                  {translateUnitStatus(
                    item.propertyTypeCode,
                    item.statusCode,
                    item.closeDate
                  )}
                </span>
                <span className="relative text-21-26-0.3 md:text-21-21-0.3 hover:text-gold-hover pt-[10px] md:pt-0.5 font-sohneKraftig w-[80%] md:w-full lg:w-max lg:max-w-[360px] lg:pt-0 lg:pb-[3px]">
                  {unitBuilding.name || 'No name'} #
                  {Number(item?.unitNumber) ||
                    item?.unit?.data?.attributes?.unique}
                </span>
                <div className="flex flex-col lg:justify-center lg:h-full md:flex-row">
                  <span className="relative lg:flex items-center px-0 pt-1 md:pt-[10px] lg:pt-0 uppercase lg:pl-[16px] lg:pr-[28px] text-gold-darker hidden md:block text-16-21-0.3">
                    {translateUnitStatus(
                      item.propertyTypeCode,
                      item.statusCode,
                      item.closeDate
                    )}
                  </span>
                  <span className="lg:flex items-center pt-[6px] md:pl-6 md:pt-[10px] lg:pt-0 lg:pl-[28px] border-dashed lg:border-l text-16-21-0.3 lg:align-items pb-[8px] md:pb-0">
                    {displayedAddress}
                  </span>
                </div>
              </div>
              <button
                className="absolute right-[20px] md:right-[32px] lg:right-[20px] top-[15px] md:top-[18px] lg:top-[6px] md:relative self-center md:self-start lg:self-center w-1/12 lg:w-[46px] ml-4 lg:ml-0 pt-[6px] md:pt-[3px] pr-[6px] lg:pr-0"
                onClick={() => {
                  setOpen(false);
                  setActiveIndex(activeUnitIndex);
                }}
              >
                <Image
                  src={iconClose}
                  alt="close popup"
                  className="!w-[21px] md:!min-w-[21px] md:!w-[26px] !h-[21px] md:!h-[26px] md:!min-h-[26px]"
                  width={26}
                  height={26}
                />
              </button>
            </div>
            <div className="rectangle-copy md:mt-[6px] lg:mt-0"></div>
          </div>
          <Tab.Group defaultIndex={activeTab}>
            <Tab.List>
              <div className="flex flex-col-reverse gap-[10px] lg:gap-[20px] pl-[20px] pr-[29px] md:pl-[40px] md:pr-[44px] lg:px-8 pt-[16px] pb-[12px] md:py-2 lg:justify-between lg:items-center lg:flex-row xl:pl-[34px] xl:!pb-0 xl:!pt-[10px] xl:pr-[32px] padding-none">
                <div>{tabs}</div>
                <div className="flex items-center md:mt-[13px] lg:mt-0 mb-[20px] md:mb-2 space-x-2 justify-between md:flex-row-reverse xl:justify-end lg:mb-0 lg:flex-row xl:gap-[10px] margin-none">
                  <PopUpContactUs
                    contactUsName={'Request info'}
                    unitFromQuickView={unit}
                    searchQuickView
                  />
                  <div className="flex">
                    <SaveToFavoritesButton
                      visibility="block text-16-21-0.3 pr-[20px]"
                      unitId={unit.id}
                      userFavorites={unit.favorite}
                      type={getPropertyTypeByCode(item.propertyTypeCode)}
                      full
                    />
                    <Share
                      copyData={`${ORIGIN}${href} `}
                      visibility="block text-16-21-0.3"
                    />
                  </div>
                </div>
              </div>
            </Tab.List>
            <Tab.Panels>{panels}</Tab.Panels>
          </Tab.Group>
        </div>
      </StyledDialog>
    </>
  );
};

export default PopUpSearchQuickView;
