import React, { useContext } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import { Table } from '../../components/Table';
import { priceFormatter, sqftFormatter } from '../../src/utils/utils';
import { NO_DATA_PLACEHOLDER } from '../../constants/labels';
import { getRange } from '../../lib/getRange';
import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';

interface TableSectionProps {
  setAvailableCondosPropertyType: (type: 'rent' | 'sale') => void;
  availableCondosCoordinates?: number;
}

const TableSection: React.FC<TableSectionProps> = ({
  setAvailableCondosPropertyType,
  availableCondosCoordinates,
}) => {
  const { building, saleCount, rentCount } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const { floorsCount, unitsCount, yearBuilt, buildingStats } = building;

  const { minSaleSqft, maxSaleSqft, maxSalePrice, minSalePrice } =
    buildingStats || {};

  const lessLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <section className="container mt-[17px] md:mt-0 md:px-12 md:pt-5 md:pb-2 lg:py-2 text-m lg:pt-[8px]">
      <div>
        <Table
          whitespace="!px-0 whitespace-nowrap"
          availableCondosCoordinates={availableCondosCoordinates}
          onSaleRentClick={setAvailableCondosPropertyType}
          rootClassName={`no-scroll grid ${
            building.isPreconstruction
              ? 'grid-cols-4 lg:grid-cols-6'
              : 'grid-cols-10 lg:grid-cols-7 grid-table'
          }`}
          rowClasses="font-sohneHalbfett mt-[12px] md:mt-[20px] text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1"
          columns={
            building.isPreconstruction
              ? [
                  {
                    key: 'sale',
                    heading: (
                      <span className="uppercase text-gold-dark group-hover:underline group-hover:text-gold-darker">
                        For Sale
                      </span>
                    ),
                    headingMobile: (
                      <span className="uppercase text-gold-dark">Sale</span>
                    ),
                    cellRender: () => (
                      <a className="text-14-18--0.15 md:text-20-26-0.67 xl:text-30-38-1 group-hover:underline text-gold-dark group-hover:text-gold-darker ">
                        {saleCount || 0}
                      </a>
                    ),
                  },
                  {
                    key: 'price',
                    heading: 'LIST PRICE',
                    headingMobile: 'PRICE',
                    order:
                      'xl:order-none order-last col-span-2 lg:col-span-1 mt-[10px] lg:mt-0 lg:!bg-left lg:pl-[16px] lg:!pr-0 xl:!bg-right xl:pl-0 xl:!pr-[16px]',
                    cellRender: (row) => (
                      <div className="flex justify-center text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1">
                        <div className="font-sohneHalbfett">
                          {`${priceFormatter(Number(minSalePrice))}+`}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'area',
                    heading: 'SQ FT AREA',
                    headingMobile: 'SQ FT',
                    order: `xl:order-none order-last col-span-2 mt-[10px] lg:col-span-1 mt-[10px] lg:mt-0 ${
                      lessLg && '!bg-none'
                    } lg:!border-dashed-small lg:!bg-left lg:pl-[16px] lg:!pr-0 xl:!bg-right xl:pl-0 xl:!pr-[16px]`,
                    cellRender: (row) => (
                      <div className="text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1">
                        <div>
                          {Number(minSaleSqft) > 0 &&
                          minSaleSqft === maxSaleSqft
                            ? `${sqftFormatter(Number(minSaleSqft))}`
                            : getRange(
                                row,
                                (val) => val?.minSaleSqft,
                                (val) => val?.maxSaleSqft,
                                sqftFormatter
                              )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'units',
                    heading: 'NO. OF UNITS',
                    headingMobile: 'UNITS',
                  },
                  {
                    key: 'stories',
                    heading: 'NO. OF STORIES',
                    headingMobile: 'STORIES',
                  },
                  {
                    key: 'year',
                    heading: 'YEAR BUILT ',
                    headingMobile: 'BUILT ',
                    // widthRtio: 2,
                  },
                ]
              : [
                  {
                    key: 'sale',
                    heading: (
                      <span className="uppercase text-gold-dark group-hover:underline group-hover:text-gold-darker">
                        For Sale
                      </span>
                    ),
                    headingMobile: (
                      <span className="uppercase text-gold-dark">Sale</span>
                    ),
                    cellRender: () => (
                      <a className="text-14-18--0.15 md:text-20-26-0.67 xl:text-30-38-1 group-hover:underline text-gold-dark group-hover:text-gold-darker">
                        {saleCount || 0}
                      </a>
                    ),
                    order: `col-span-2 lg:col-span-1 md:w-auto`,
                  },
                  {
                    key: 'rent',
                    heading: (
                      <span className="uppercase text-gold-dark group-hover:underline group-hover:text-gold-darker">
                        For Rent
                      </span>
                    ),
                    headingMobile: (
                      <span className="uppercase text-gold-dark">Rent</span>
                    ),
                    cellRender: () => (
                      <a className="text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1 group-hover:underline text-gold-dark group-hover:text-gold-darker">
                        {rentCount || 0}
                      </a>
                    ),
                    order: `col-span-2 lg:col-span-1 md:w-auto`,
                  },
                  {
                    key: 'price',
                    heading: 'LIST PRICE',
                    headingMobile: 'PRICE',
                    order:
                      'xl:order-none order-last col-start-2 col-end-6 lg:col-span-1 mt-[10px] lg:mt-0 lg:!bg-left lg:pl-[16px] lg:!pr-0 xl:!bg-right xl:pl-0 xl:!pr-[16px]',
                    cellRender: (row) => (
                      <div className="flex justify-center text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1">
                        <div className="font-sohneHalbfett">
                          {`${priceFormatter(Number(minSalePrice))}+`}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'area',
                    heading: 'SQ FT AREA',
                    headingMobile: 'SQ FT',
                    order: `xl:order-none order-last col-start-6 col-end-10  lg:col-span-1 mt-[10px] lg:mt-0 lg:!bg-left lg:pl-[16px] lg:!pr-0 xl:!bg-right xl:pl-0 xl:!pr-[16px] ${
                      lessLg && '!bg-none'
                    }`,
                    cellRender: (row) => (
                      <div className="text-14-18--0.15 md:text-20-26-0.67 xl:text-30-36-1">
                        <div>
                          {Number(minSaleSqft) > 0 &&
                          minSaleSqft === maxSaleSqft
                            ? `${sqftFormatter(Number(minSaleSqft))}`
                            : getRange(
                                row,
                                (val) => val?.minSaleSqft,
                                (val) => val?.maxSaleSqft,
                                sqftFormatter
                              )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: 'units',
                    heading: 'NO. OF UNITS',
                    headingMobile: 'UNITS',
                    order: `col-span-2 lg:col-span-1 md:w-auto`,
                  },
                  {
                    key: 'stories',
                    heading: 'NO. OF STORIES',
                    headingMobile: 'STORIES',
                    order: `col-span-2 lg:col-span-1 md:w-auto`,
                  },
                  {
                    key: 'year',
                    heading: 'YEAR BUILT ',
                    headingMobile: 'BUILT ',
                    order: `col-span-2 lg:col-span-1 pl-1 sm:pl-[24px] md:pl-0 md:w-auto`,
                  },
                ]
          }
          data={[
            {
              id: 1,
              rent: rentCount || 0,
              sale: saleCount || 0,
              maxSalePrice,
              minSalePrice,
              units: unitsCount || NO_DATA_PLACEHOLDER,
              stories: floorsCount || NO_DATA_PLACEHOLDER,
              minSaleSqft,
              maxSaleSqft,
              year: yearBuilt || NO_DATA_PLACEHOLDER,
            },
          ]}
        />
      </div>
    </section>
  );
};

export default TableSection;
