import React from 'react';
import { sortOptions } from '../constants';
import CbbDropdown from '../../CbbDropdown';

interface Props {
  selectedName?: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  rootClassName: string;
  selectClassName?: string;
}

const UnitsSorting: React.FC<Props> = ({
  selectedName,
  onChange,
  rootClassName,
  selectClassName = 'w-[226px] -left-0.1 mt-0 text-14-26-0.3',
}) => {
  return (
    <CbbDropdown
      rootClassName={rootClassName}
      nameSelected={selectedName || sortOptions.RECOMMENDED}
      selectClassName={selectClassName}
      textBeforeSelect="Sort by: "
      onChange={onChange}
      options={[
        sortOptions.RECOMMENDED,
        sortOptions.PRICE_HIGHEST_FIRST,
        sortOptions.PRICE_LOWEST_FIRST,
        sortOptions.SQUARE_FEET,
        sortOptions.BEDROOMS,
        sortOptions.RECENT_CHANGES,
      ]}
    />
  );
};

export default UnitsSorting;
