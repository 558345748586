export function formatPriceValue(value: number): string {
  const dividedNumberIntoDischarges = divideNumberIntoDischarges(value);
  return `$ ${dividedNumberIntoDischarges}`;
}

export function divideNumberIntoDischarges(value: number): string {
  return value
    .toLocaleString()
    .split('')
    .map((_) => {
      return _.replace(/\s/g, '') ? _ : ',';
    })
    .join('');
}

export function removeCurrencyFromValue(value: string): string {
  return value.slice(2, value.length);
}

export function calculateTotalValue(values: { [index: string]: number }) {
  let calculatedValue = 0;
  Object.keys(values).forEach((key) => {
    calculatedValue += values[key];
  });
  return calculatedValue;
}
