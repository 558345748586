import React, { useContext } from 'react';

import { NO_DATA_PLACEHOLDER } from 'constants/labels';

import { priceFormatter } from '../../../src/utils/utils';

import { Table } from '../../Table';
import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

const SalesStats: React.FC = () => {
  const {
    building: { buildingStats: buildingStat },
  } = useContext(BuildingPageContext) as BuildingPageContextInterface;

  return (
    <>
      <div className="flex flex-col items-center mt-[29px] md:mt-[40px] md:items-start md:mb-10 lg:mt-[56px]">
        <h2 className="text-18-23-0.3 md:text-21-28-0.3 uppercase font-sohneKraftig">
          Sales stats
        </h2>
        <p className="text-14-21-0.3 md:text-16-36-0.3 pt-[4px] md:pt-0 lg:pt-[2px]">
          Key averages for the building
        </p>
      </div>
      <Table
        rootClassName="grid grid-cols-2 mt-[54px] md:my-6 md:mr-12"
        rowClasses="font-sohneHalbfett text-16-26-0.3 md:text-20-28-0.3 xl:text-30-36-1 mt-4"
        columns={[
          {
            key: 'averageListPrice',
            heading: 'AVG LIST PRICE',
            headingMobile: 'AVG LIST PRICE',
            cellRender: (row) => (
              <div className="flex justify-center">
                <p className="pt-px md:pt-0.7 font-sohneHalbfett text-14-18-0.47 md:text-30-38-1">
                  {row.averageListPrice
                    ? priceFormatter(row.averageListPrice)
                    : NO_DATA_PLACEHOLDER}
                </p>
              </div>
            ),
          },
          {
            key: 'averageListPriceSgFt',
            heading: 'AVG LIST PRICE / SQ FT',
            headingMobile: 'AVG LIST PRICE / SQ FT',
            cellRender: (row) => (
              <div className="flex justify-center">
                <p className="pt-px md:pt-0.7 font-sohneHalbfett text-14-18-0.47 md:text-30-38-1">
                  {row.averageListPriceSgFt
                    ? priceFormatter(row.averageListPriceSgFt)
                    : NO_DATA_PLACEHOLDER}
                </p>
              </div>
            ),
          },
        ]}
        data={[
          {
            id: 1,
            averageListPrice: buildingStat?.averageSalePrice || 0,
            averageListPriceSgFt: buildingStat?.averageSaleSqftPrice || 0,
          },
        ]}
      />
      <Table
        rootClassName="grid grid-cols-2 mb-6 md:mr-12"
        rowClasses="font-sohneHalbfett text-16-26-0.3 md:text-20-28-0.3 xl:text-30-36-1 mt-4"
        columns={[
          {
            key: 'averageSalePrice',
            heading: 'AVG SALE PRICE',
            headingMobile: 'AVG SALE PRICE',
            cellRender: (row) => (
              <div className="flex flex-col items-center">
                <div className="flex">
                  <p className="pt-px md:pt-0.7 font-sohneHalbfett text-14-18-0.47 md:text-30-38-1">
                    {`${
                      row.averageSalePrice
                        ? priceFormatter(row.averageSalePrice)
                        : NO_DATA_PLACEHOLDER
                    }*`}
                  </p>
                </div>
                <p className="text-12-21-0.3 font-sohneBuch text-gray pt-[6px] md:pt-0">
                  *past six months
                </p>
              </div>
            ),
          },
          {
            key: 'averageSalePriceSqft',
            heading: 'AVG SALE PRICE/ SQ FT',
            headingMobile: 'AVG SALE PRICE/ SQ FT',
            cellRender: (row) => (
              <div className="flex flex-col items-center">
                <div className="flex">
                  <p className="pt-px md:pt-0.7 font-sohneHalbfett text-14-18-0.47 md:text-30-38-1">
                    {`${
                      row.averageSalePriceSqft
                        ? priceFormatter(row.averageSalePriceSqft)
                        : NO_DATA_PLACEHOLDER
                    }*`}
                  </p>
                </div>
                <p className="text-12-21-0.3 font-sohneBuch text-gray pt-[6px] md:pt-0">
                  *past six months
                </p>
              </div>
            ),
          },
        ]}
        data={[
          {
            id: 1,
            averageSalePrice: buildingStat?.averageSalePriceSixMonths || 0,
            averageSalePriceSqft:
              buildingStat?.averageSaleSqftPriceSixMonths || 0,
          },
        ]}
      />
      <div className="flex flex-col items-center justify-center mt-10 text-center xl:mb-14">
        <div className="px-3 md:px-6 border-l border-r border-dashed w-[70%] md:w-1/2 border-gray sm:border-0 ">
          <p className="text-59-57-1.57 md:text-76-76-2.03 xl:text-91-91-2.43 text-gold-dark font-sohneBreitHalbfett">
            {buildingStat?.averageSaleDaysOnMarketSixMonths
              ? priceFormatter(buildingStat?.averageSaleDaysOnMarketSixMonths)
              : NO_DATA_PLACEHOLDER}
            *
          </p>
          <p className="16-36-0.3 pt-[8px]">Avg Days on Market</p>
          <p className="text-12-21-0.3 text-gray pt-[16px] md:pt-[4px]">
            *past six months
          </p>
        </div>
      </div>
    </>
  );
};

export default SalesStats;
