import React, { useContext } from 'react';
import { ApartmentComplex } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { BuildingPageContextInterface } from 'apolloClient/types/Building';
import { BuildingPageContext } from 'pages/[urlParam]/[urlBuildParam]';

const BuildingAppartmentComplexSchema: React.FC = () => {
  const { building, url } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const image = building.mainImage?.data?.attributes?.url;

  const appartment: ApartmentComplex = {
    '@type': 'ApartmentComplex',
    address: building.primaryAddress,
    image,
    name: building.name,
    url,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: building.lat,
      longitude: building.lon,
      address: building.primaryAddress,
      url,
      image,
      name: building.name,
    },
  };

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ApartmentComplex>({
          '@context': 'https://schema.org',
          ...appartment,
        }),
      ]}
    />
  );
};

export default BuildingAppartmentComplexSchema;
