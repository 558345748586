export function getEdgeCoords(points: { lng: number; lat: number }[]) {
  let north: number = 0;
  let south: number = 0;
  let east: number = 0;
  let west: number = 0;

  points.forEach(({ lng, lat }, index) => {
    if (index === 0) {
      north = lat + 0.005;
      south = lat - 0.005;
      east = lng + 0.005;
      west = lng - 0.005;
    } else {
      north = lat > north ? lat : north;
      south = lat < south ? lat : south;
      east = lng > east ? lng : east;
      west = lng > west ? west : lng;
    }
  });

  return { west, east, north, south };
}

type Coords = {
  west: number;
  east: number;
  north: number;
  south: number;
};
export function calculateCenter(coords: Coords) {
  return {
    lng: (coords.west + coords.east) / 2,
    lat: (coords.north + coords.south) / 2,
  };
}
