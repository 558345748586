/* eslint-disable react/no-unknown-property */
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';

import ErrorBoundary from './ErrorBoundary';
import BreadcrumbsSchema from './Schema/BreadcrumbsSchema';
import classnames from 'classnames';

type BreadcrumbsProps = {
  neighborhoodPage?: boolean;
  buildingPage?: boolean;
  unitPage?: boolean;
  neighborhoodName?: string;
  neighborhoodId?: string;
  buildingId?: string;
  buildingName?: string;
  namePage?: string;
  unitName?: string;
  unitUrl?: string;
  blogPage?: string;
  articlePage?: string;
  preConstructionPage?: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  neighborhoodPage,
  buildingPage,
  unitPage,
  neighborhoodName,
  neighborhoodId,
  buildingId,
  buildingName,
  namePage,
  unitName,
  unitUrl,
  blogPage,
  articlePage,
  preConstructionPage = false,
}) => {
  return (
    <ErrorBoundary>
      <div
        className={classnames(`container text-14-26-0.3 breadcrumbs`, {
          ' md:pt-4 lg:pt-[9px]': !preConstructionPage,
        })}
      >
        <ul
          className={classnames(
            `px-px pt-[20px] pb-[10px] md:py-2 lg:pt-[40px]`,
            {
              ' lg:pt-[10px]': unitPage,
              ' lg:pt-[27px]': buildingPage,
              ' lg:pt-[37px]': articlePage,
              ' lg:!pt-[7px] lg:pb-0': namePage === 'Blog' && !articlePage,
              ' lg:pt-[8px]': namePage === 'Saved Houses',
            }
          )}
        >
          <li className="inline">
            <Link href={`/`} passHref className="text-gold-darker">
              <span>Home</span>
            </Link>
          </li>
          {(neighborhoodPage || buildingPage || unitPage) && (
            <>
              <BreadcrumbsSchema
                options={{
                  neighborhoodId,
                  neighborhoodName,
                  buildingId,
                  buildingName,
                  unitName,
                  unitUrl,
                }}
              />
              <li className="inline">
                <span className="mx-1 text-gold-darker">/</span>
                <Link
                  href={`/${neighborhoodId}`}
                  passHref
                  className={
                    neighborhoodPage ? 'cursor-default' : 'text-gold-darker'
                  }
                >
                  <span>{neighborhoodName}</span>
                </Link>
              </li>
            </>
          )}
          {namePage && (
            <li className="inline">
              <span className="mx-1">/</span>
              <span className="mx-1">{namePage}</span>
            </li>
          )}
          {(buildingPage || unitPage) && (
            <li className="inline">
              <span className={buildingPage ? 'mx-1' : 'mx-1 text-gold-darker'}>
                /
              </span>
              <Link
                href={`/${neighborhoodId}/${buildingId}`}
                passHref
                className={buildingPage ? 'cursor-default' : 'text-gold-darker'}
              >
                <span>{buildingName}</span>
              </Link>
            </li>
          )}
          {unitPage && (
            <li className="inline">
              <span className="mx-1">/</span>
              <a
                className={unitPage ? 'cursor-default' : 'text-gold-darker'}
                href="#"
              >
                <span>#{unitName}</span>
              </a>
            </li>
          )}
          {blogPage && articlePage && (
            <>
              <li className="inline">
                <span className="mx-1 text-gold-darker">/</span>
                <a
                  className={
                    articlePage ? 'text-gold-darker' : 'cursor-default'
                  }
                  href="/blog"
                >
                  <span>{blogPage}</span>
                </a>
              </li>
              <li className="inline">
                <span className="mx-1">/</span>
                <a
                  className={
                    articlePage ? 'cursor-default' : 'text-gold-darker'
                  }
                  href="#"
                >
                  <span>{articlePage}</span>
                </a>
              </li>
            </>
          )}
          {blogPage && !articlePage && (
            <li className="inline">
              <span className="mx-1">/</span>
              <a
                className={blogPage ? 'cursor-default' : 'text-gold-darker'}
                href="#"
              >
                <span>{blogPage}</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </ErrorBoundary>
  );
};

export default Breadcrumbs;
