import React, { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { format, toDate } from 'date-fns';
import { useLazyQuery } from '@apollo/client';

import { NO_DATA_PLACEHOLDER } from 'constants/labels';

import TableWithLink from '../components/TableWithLink';
import arrowDown from '../public/images/arrow-down.svg';
import arrowLeft from '../public/images/arrow-left.svg';
import { priceFormatter } from '../src/utils/utils';
import { LISTINGS_BY_BUILDING_SLUG } from '../apolloClient/queries/searchListings';
import { ListingCollectionResponse } from '../apolloClient/types/Units';
import { flattenStrapiBulkDataItems } from '../lib/flattenStrapiBulkDataItems';
import LineBetweenSections from './LineBetweenSections';

function getSixMonthAgoDate() {
  const today = new Date();
  const sixMonthAgo = toDate(today.setMonth(today.getMonth() - 6));
  const sixMonthAgoParam = format(new Date(sixMonthAgo), 'yyyy-MM-dd');
  return sixMonthAgoParam;
}

type RecentSalesProps = {
  name: string;
  urlBuildParam: string;
  showLine?: boolean;
};

const RecentSales: React.FC<RecentSalesProps> = ({
  urlBuildParam,
  name,
  showLine = false,
}) => {
  const [loadListingsByBuildingSlug, { data }] = useLazyQuery<
    {
      listings: ListingCollectionResponse;
    },
    { slug?: string; date?: string; limit?: number }
  >(LISTINGS_BY_BUILDING_SLUG, {
    variables: {
      slug: urlBuildParam,
      date: new Date(getSixMonthAgoDate()).toISOString(),
    },
  });
  const listingsByBuilding = flattenStrapiBulkDataItems(data?.listings.data);

  const [showButton, setShowButton] = useState(true);
  const [countShowFloorplans, setCountShowFloorplans] = useState(6);

  useEffect(() => {
    loadListingsByBuildingSlug({ variables: { limit: 6 } });
  }, []);

  const showLessThanFiveUnits = () => {
    setShowButton(true);
    setCountShowFloorplans(6);
    loadListingsByBuildingSlug({
      variables: { limit: countShowFloorplans - 1 },
    });
  };
  const showMoreThanFiveUnits = () => {
    setShowButton(false);
    setCountShowFloorplans(50);
    loadListingsByBuildingSlug({ variables: { limit: 50 } });
  };

  return (
    <>
      {listingsByBuilding.length > 0 && (
        <>
          <section className="py-6 lg:pt-10 text-m">
            <div className="justify-center text-24-32-0.3  uppercase lg:text-30-36-1 font-sohneBreitBuch md:font-sohneKraftig">
              <div className="flex mb-10">
                <div className="text-24-32-0.3  uppercase lg:text-30-36-1 md:mr-4 font-sohneBreitBuch">
                  <button className="flex">
                    <span className="place-self-start text-18-23-0.3 md:text-27-28-0.3 font-sohneKraftig">
                      {name} Recent Sales
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <TableWithLink
              rowClasses={`font-sohneKraftig text-13-17--0.14 md:text-21-28-0.3`}
              rootClassName={`flex flex-row scroll text-13-17--0.14 md:text-21-28-0.3`}
              columns={[
                {
                  key: 'unit',
                  headingMobile: 'UNIT',
                },
                {
                  key: 'bedBath',
                  heading: 'BED/BATH',
                  headingMobile: 'BED/BATH',
                },
                {
                  key: 'sqft',
                  heading: 'SQ FT',
                  headingMobile: 'SQ FT',
                },
                {
                  key: 'salePrice',
                  heading: 'SALE PRICE',
                  headingMobile: 'SALE PRICE',
                  cellRender: (data) => (
                    <div>
                      <span>
                        {data.price > 0
                          ? priceFormatter(data.price)
                          : data.price}
                      </span>
                    </div>
                  ),
                },
                {
                  key: 'pricePerFt',
                  heading: 'PRICE / SQ FT',
                  headingMobile: 'PRICE / SQ FT',
                  cellRender: (data) => (
                    <div>
                      <span>
                        {data.price / data.sqft > 0
                          ? priceFormatter(data.price / data.sqft)
                          : 'N/A'}
                      </span>
                    </div>
                  ),
                },
                {
                  key: 'date',
                  heading: 'SALE DATE',
                  headingMobile: 'SALE DATE',
                },
              ]}
              data={listingsByBuilding
                .slice(0, countShowFloorplans - 1)
                .map((listing) => ({
                  id: listing.id,
                  unit: listing.unitNumber || NO_DATA_PLACEHOLDER,
                  bedBath: `${listing.bedsTotal || 0} / ${
                    (listing.bathsFull || 0) + (listing.bathsHalf || 0) ||
                    NO_DATA_PLACEHOLDER
                  } `,
                  sqft: listing.sqft || NO_DATA_PLACEHOLDER,
                  price: listing.soldPrice || listing.price,
                  pricePerFt:
                    listing.price / listing.sqft || NO_DATA_PLACEHOLDER,
                  date:
                    format(new Date(listing.closeDate), 'yyyy-MM-dd') ||
                    NO_DATA_PLACEHOLDER,
                  listing: listing,
                }))}
            />
            {listingsByBuilding.length > 5 && showButton && (
              <button
                onClick={() => showMoreThanFiveUnits()}
                className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold md:mt-8 md:mb-10 lg:my-6 w-max"
              >
                <div className="w-5 h-4">
                  <Image src={arrowLeft} alt="show more" width={15} />
                </div>
                <span className="whitespace-nowrap">Show more</span>
              </button>
            )}
            {!showButton && (
              <button
                onClick={() => showLessThanFiveUnits()}
                className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold md:mt-8 md:mb-10 lg:my-6 w-max"
              >
                <div className="w-5 h-4 transform -rotate-90">
                  <Image src={arrowLeft} alt="show less" width={15} />
                </div>
                <span className="whitespace-nowrap">Show less</span>
              </button>
            )}
          </section>
          <LineBetweenSections />
        </>
      )}
    </>
  );
};

export default RecentSales;
