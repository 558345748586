import React, { useEffect } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';

import arrowButton from '../public/images/arrow-button.svg';

import { StylesType } from './PageBuilderContentParser';

type ButtonWithScrollProps = {
  name: string;
  href?: string;
  styles?: StylesType;
  fullWidth?: boolean;
  classNames?: string;
  fontClassName?: string;
  classNameWidth?: string;
  nofollow?: boolean;
};

const ButtonWithScroll: React.FC<ButtonWithScrollProps> = ({
  name,
  classNameWidth = 'max-w-[323px] md:max-w-full',
  styles = {},
  href = '#',
  fullWidth = true,
  classNames = 'mt-[3px]',
  nofollow = false,
  fontClassName = 'font-sohneBreitHalbfett',
}) => {
  function getNormalizedStyles(styles: StylesType) {
    let buttonStyles: StylesType = {};
    let linkStyles: StylesType = {};

    styles &&
      Object.keys(styles).forEach((styleName) => {
        if (
          styleName === 'margin' ||
          styleName === 'width' ||
          styleName === 'padding'
        ) {
          linkStyles[styleName] = styles[styleName];
        } else {
          buttonStyles[styleName] = styles[styleName];
        }
      });

    return { buttonStyles, linkStyles };
  }

  const { buttonStyles, linkStyles } = getNormalizedStyles(styles);

  return (
    <p
      title={name}
      style={linkStyles}
      className={classnames(`custom-button hover-animation ${classNames}`, {
        'w-full': fullWidth,
      })}
    >
      <button
        onClick={() =>
          document.getElementById('request-info')?.scrollIntoView({
            behavior: 'smooth',
          })
        }
        style={buttonStyles}
        className={`${classNameWidth}
            flex items-center justify-between uppercase bg-gold hover:bg-gold-hover font-sohneBreitHalbfett 
            text-14-26-0.3 lg:text-16-26-0.3
            py-[13.5px] lg:!pt-[21px] lg:pb-[21px]
            pl-6 lg:pl-6
            pr-[15px] lg:pr-[21px]
            mt-[8px] md:my-4
            ml-[4px]
          `}
      >
        <div className="flex items-center h-[21px] mr-[10px]">
          <div
            className={`${fontClassName} text-14-18-0.47 lg:text-16-16-0.53`}
          >
            {name}
          </div>
        </div>
        <div className="w-6 lg:pl-[3px] lg:w-auto flex align-center">
          <Image src={arrowButton} alt="arrow-button" height={21} width={29} />
        </div>
      </button>
    </p>
  );
};

export default ButtonWithScroll;
