import React, { useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Link from 'next/link';
import Tooltip from '@mui/material/Tooltip';

import {
  SEARCH_RENT_FILTERS,
  defaultSortOption,
} from '../UnitsFilters/constants';
import { getSearchLink } from '../Search/utils/listingsFiltersUtils';

import rightArrow from '../../public/images/arrow-right.svg';

const Rent: React.FC = () => {
  const [listBuildingsOpen, setListBuildingsOpen] = useState(false);
  const [openRent, setOpenRent] = useState(false);
  const handleCloseRent = () => {
    setOpenRent(false);
  };
  const handleOpenRent = () => {
    setOpenRent(true);
  };

  return (
    <div className="relative w-full h-auto pb-3 border-b border-dashed xl:pb-0 border-gold-dark xl:w-auto xl:border-b-0 group">
      <Tooltip
        sx={{
          '.  MuiTooltip-tooltip': {
            maxWidth: 'none',
            width: '450px',
          },
        }}
        TransitionProps={{ timeout: 0 }}
        open={openRent}
        onClose={handleCloseRent}
        onOpen={handleOpenRent}
        title={
          <div className="z-40 hidden text-black xl:block">
            <div className="relative bg-transparent">
              <div className="absolute hidden w-3 h-3 transform rotate-45 bg-white border-t border-l xl:block -top-1.7 left-[158px] border-gray" />
              <div className="grid grid-flow-col auto-cols-max">
                <div className="px-3 pb-2 pr-6 xl:pl-[17px] text-14-26-0.3 xl:text-16-34-0.3 border-r border-gray font-sohneBuch">
                  <div className="pt-[15px] uppercase font-sohneKraftig">
                    Listing type
                  </div>
                  <div className="hover:cursor-pointer hover:text-gold-hover">
                    <a href={getSearchLink(SEARCH_RENT_FILTERS)} rel="nofollow">
                      Condo Listings for Rent
                    </a>
                  </div>
                  <div className="pb-1 mb-1 hover:cursor-pointer hover:text-gold-hover">
                    <a
                      href={getSearchLink({
                        forRent: true,
                        petFriendly: true,
                        sortOption: defaultSortOption,
                      })}
                      rel="nofollow"
                    >
                      Pet-friendly Rentals
                    </a>
                  </div>
                </div>
                <div className="font-sohneBuch px-3 pb-2 pr-6 xl:pl-[18px] xl:pr-[0px] xl:pt-[3px] xl:w-[200px] text-14-26-0.3 xl:text-16-34-0.3">
                  <div className="pt-3 uppercase font-sohneKraftig  w-[215px]">
                    Guides
                  </div>
                  <Link
                    href="/rent-vs-buy-calculator"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover text-14-26-0.3 xl:text-16-34-0.3"
                  >
                    Rent vs Buy Calculator
                  </Link>
                  <Link
                    href="/blog/32-questions-to-ask-when-renting-an-apartment-the-ultimate-checklist"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="block hover:cursor-pointer hover:text-gold-hover text-14-26-0.3 xl:text-16-34-0.3"
                  >
                    Guide for Renting
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <a
          href={getSearchLink(SEARCH_RENT_FILTERS)}
          className="hidden w-full pb-3 pr-6 text-black border-b border-dashed xl:flex xl:pb-0 border-gold-dark xl:border-b-0 hover:cursor-pointer hover:text-gold-hover"
        >
          Rent
        </a>
      </Tooltip>
      <div
        onClick={() => setListBuildingsOpen(!listBuildingsOpen)}
        className="relative flex pr-6 cursor-pointer hover:text-gold-hover xl:hidden close-building text-18-23-0.3 mt-[-3px]"
      >
        Rent
        <div className="pt-[3px] pl-[13px] xl:hidden">
          <Image
            className={classnames({
              'transform rotate-90': listBuildingsOpen,
            })}
            src={rightArrow}
            alt="rent"
            height={15}
            width={12}
          />
        </div>
      </div>
      {listBuildingsOpen && (
        <div className="pt-[14px] mb-[11px] w-80">
          <div className="z-40 bg-white xl:border top-4 w-80 border-gray ">
            <div className="relative">
              <div className="xl:absolute hidden xl:group-hover:block  w-3 h-3 bg-white -top-1.7 left-16 border-t border-l border-gray transform rotate-45" />
              <div>
                <div className="relative bg-transparent">
                  <div className="absolute hidden w-3 h-3 transform rotate-45 bg-white border-t border-l xl:block -top-1.7 left-10 border-gray" />
                  <div className="flex">
                    <div className="pb-2 pr-6 text-16-34-0.3 font-sohneBuch">
                      <div className="pt-3 hover:cursor-pointer hover:text-gold-hover">
                        <a
                          href={getSearchLink(SEARCH_RENT_FILTERS)}
                          rel="nofollow"
                        >
                          Condo Listings for Rent
                        </a>
                      </div>
                      <div className="hover:cursor-pointer hover:text-gold-hover">
                        <a
                          href={getSearchLink({
                            forRent: true,
                            petFriendly: true,
                            sortOption: defaultSortOption,
                          })}
                          rel="nofollow"
                        >
                          Pet-friendly Rentals
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rent;
