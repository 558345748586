import { BuildingStats } from 'apolloClient/types';

export type CalculatedNearbyCondosParams = {
  minPrice: number;
  maxPrice: number;
  minArea: number;
  maxArea: number;
  unused?: boolean;
};

export const getNearbyCondosParams = ({
  minAvgCoef,
  maxAvgCoef,
  buildingStats,
}: {
  minAvgCoef: number;
  maxAvgCoef: number;
  buildingStats: BuildingStats | null;
}): CalculatedNearbyCondosParams => {
  const avgArea =
    (Number(buildingStats?.minSaleSqft) + Number(buildingStats?.maxSaleSqft)) /
    2;
  const avgPrice =
    (Number(buildingStats?.minSalePrice) +
      Number(buildingStats?.maxSalePrice)) /
    2;
  const minPrice = Math.floor(avgPrice * minAvgCoef) || 0;
  const maxPrice = Math.ceil(avgPrice * maxAvgCoef) || 100000000000;
  const minArea = Math.floor(avgArea * minAvgCoef) || 0;
  const maxArea = Math.ceil(avgArea * maxAvgCoef) || 100000000000;

  return { maxArea, maxPrice, minArea, minPrice };
};
