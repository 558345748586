import React, { useEffect, useState } from 'react';

import { getNumberFromString } from '../../../../src/utils/utils';

import CbbDropdown from '../../../CbbDropdown';

import MonthlyCostFormWrapper from '../components/MonthlyCostFormWrapper';
import { formatPriceValue, removeCurrencyFromValue } from '../utils';
import { GET_PMMS } from 'apolloClient/queries/pmms';
import { useLazyQuery } from '@apollo/client';

const THIRTY_YEAR_FIXED: string = '30-year fixed';
const FIFTEEN_YEAR_FIXED: string = '15-year fixed';
const ARM: string = '5/1 ARM';
const interestRateDefaults = {
  [THIRTY_YEAR_FIXED]: '2.78',
  [FIFTEEN_YEAR_FIXED]: '2.12',
  [ARM]: '2.49',
};
const loanProgramValues = {
  [THIRTY_YEAR_FIXED]: 360,
  [FIFTEEN_YEAR_FIXED]: 180,
  [ARM]: 360,
};

interface PrincipalInterestFormProps {
  initialHomePrice: string;
  onChange: (value: number) => void;
}

interface ValuesInterface {
  downPaymentPercent: number;
  homePrice: number;
  downPayment: number;
  loanProgram: string;
  interestRate: string;
  interestRatePercents: Record<string, string>;
}

const PrincipalInterestForm: React.FC<PrincipalInterestFormProps> = ({
  initialHomePrice,
  onChange,
}) => {
  const [valuesIsInitialized, setValuesIsInitialized] = useState(false);
  const [calculatedMainValue, setCalculatedMainValue] = useState(0);
  const [values, setValues] = useState<ValuesInterface>({
    downPaymentPercent: 0,
    homePrice: 0,
    downPayment: 0,
    loanProgram: '',
    interestRate: '',
    interestRatePercents: interestRateDefaults,
  });
  const [getPmmsRequest] = useLazyQuery(GET_PMMS);

  useEffect(() => {
    initializeValues();
  }, []);

  useEffect(() => {
    valuesIsInitialized && setCalculatedMainValue(calculateMainValue(values));
  }, [values, valuesIsInitialized]);

  useEffect(() => {
    onChange(calculatedMainValue);
  }, [calculatedMainValue]);

  async function initializeValues() {
    const downPaymentPercentValue =
      Number(localStorage.getItem('downPaymentPercent')) || 20;
    const homePriceValue = getNumberFromString(initialHomePrice);

    const {
      data: { pmms: { data: { attributes: pmmsData = null } = {} } = {} } = {},
    } = (await getPmmsRequest()) || {};

    const interestRatePercents = {
      [THIRTY_YEAR_FIXED]:
        pmmsData.thirtyYearsRate || interestRateDefaults[THIRTY_YEAR_FIXED],
      [FIFTEEN_YEAR_FIXED]:
        pmmsData.fifteenYearsRate || interestRateDefaults[FIFTEEN_YEAR_FIXED],
      [ARM]: pmmsData.oneToFiveYearsRate || interestRateDefaults[ARM],
    };

    setValues({
      downPaymentPercent: downPaymentPercentValue,
      homePrice: homePriceValue,
      downPayment: calculateDownPaymentValue(
        homePriceValue,
        downPaymentPercentValue
      ),
      loanProgram: THIRTY_YEAR_FIXED,
      interestRate: interestRatePercents[THIRTY_YEAR_FIXED],
      interestRatePercents: interestRatePercents,
    });

    setValuesIsInitialized(true);
  }

  function calculateMainValue(values: ValuesInterface): number {
    const { homePrice, interestRate, loanProgram, downPayment } = values;
    const P = homePrice - downPayment;
    const r = Number(interestRate) / 100 / 12;
    const n = loanProgramValues[loanProgram];
    // M = P * [r * (1 + r) ^ n / ((1 + r) ^ n - 1)]
    const M = P * ((r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1));
    return Math.round(M);
  }

  function calculateDownPaymentValue(
    homePrice: number,
    downPaymentPercent: number
  ): number {
    return Number((homePrice * (downPaymentPercent / 100)).toFixed());
  }

  function onHomePriceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setValues((values) => ({
      ...values,
      homePrice: getNumberFromString(value),
      downPayment: calculateDownPaymentValue(
        getNumberFromString(value),
        values.downPaymentPercent
      ),
    }));
  }

  function onDownPaymentChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = getNumberFromString(event.target.value);
    setValues((values) => ({
      ...values,
      downPayment: value,
      downPaymentPercent: Number(((value / values.homePrice) * 100).toFixed(1)),
    }));
  }

  function onDownPaymentPercentChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const value = getNumberFromString(event.target.value);
    setValues((values) => ({
      ...values,
      downPaymentPercent: value,
      downPayment: calculateDownPaymentValue(values.homePrice, value),
    }));
  }

  function onLoanProgramChange(value: string) {
    setValues((values) => ({
      ...values,
      interestRate: values.interestRatePercents[value],
      loanProgram: value,
    }));
  }

  function onInterestRateChange(event: React.ChangeEvent<HTMLInputElement>) {
    const withoutCurrency = removeCurrencyFromValue(event.target.value);
    setValues({ ...values, interestRate: withoutCurrency });
  }

  function saveDownPaymentPercentToLocalStorage(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    localStorage.setItem(
      'downPaymentPercent',
      String(getNumberFromString(event.target.value))
    );
  }

  return (
    <MonthlyCostFormWrapper
      label="Principal Interest"
      value={calculatedMainValue}
    >
      <div className="flex flex-col">
        <label htmlFor="" className="pt-3 pb-1">
          Home Price
        </label>
        <input
          className="px-4 py-2 border border-beige-dark"
          type="text"
          placeholder={initialHomePrice}
          value={formatPriceValue(values.homePrice)}
          onChange={onHomePriceChange}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="" className="pt-3 pb-1">
          Down Payment
        </label>
        <div className="flex justify-between">
          <input
            className="w-3/5 px-4 py-2 border md:w-1/2 border-beige-dark"
            type="text"
            placeholder="200,000"
            value={formatPriceValue(values.downPayment)}
            onChange={onDownPaymentChange}
          />
          <input
            className="w-2/5 px-4 py-2 border border-beige-dark"
            type="text"
            value={`% ${values.downPaymentPercent}`}
            onChange={onDownPaymentPercentChange}
            onBlur={saveDownPaymentPercentToLocalStorage}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <label htmlFor="" className="w-1/2 pt-3 pb-1">
            Loan Program
          </label>
          <label htmlFor="" className="w-2/5 pt-3 pb-1">
            Interest Rate
          </label>
        </div>
        <div className="flex justify-between">
          <CbbDropdown
            rootClassName="w-3/5 md:w-[266px] px-4 py-2 border md:w-1/2 border-beige-dark"
            selectClassName="w-full md:w-[266px] -left-0.1"
            onChange={onLoanProgramChange}
            options={[THIRTY_YEAR_FIXED, FIFTEEN_YEAR_FIXED]}
            nameSelected={values.loanProgram}
          />
          <input
            className="w-2/5 px-4 py-2 border border-beige-dark"
            type="text"
            onChange={onInterestRateChange}
            value={`% ${values.interestRate}`}
          />
        </div>
      </div>
    </MonthlyCostFormWrapper>
  );
};

export default PrincipalInterestForm;
