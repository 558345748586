import { FiltersValues } from 'components/UnitsFilters/types';

export const initialFilters = {
  and: [
    {
      or: [
        { saleUnit: { id: { notNull: true } } },
        { rentUnit: { id: { notNull: true } } },
      ],
    },
    {
      unit: {
        building: {
          neighborhood: { publishedAt: { notNull: true } },
          publishedAt: { notNull: true },
        },
      },
    },
  ],
};

export const initialFiltersStatuses = {
  or: [
    {
      statusCode: { eq: 'CS' },
    },
    {
      statusCode: { eq: 'A' },
    },
    {
      statusCode: { eq: 'AC' },
      propertyTypeCode: { ne: 'R' },
    },
    {
      statusCode: { eq: 'PS' },
      propertyTypeCode: { ne: 'R' },
    },
  ],
};

export const initialFiltersEmptyListingsType = {
  statusCode: { eq: 'A' },
};

export const isListingTypesEmpty = (values: FiltersValues | undefined) => {
  return (
    values &&
    !(
      values.preConstruction ||
      values.newConstruction ||
      values.forRent ||
      values.forSale ||
      values.foreclosures ||
      values.shortSales ||
      values.existingCondos ||
      values.sold
    )
  );
};
