import React, { useState } from 'react';
import Image from 'next/legacy/image';
import arrowLeft from 'public/images/arrow-left.svg';
import arrowBottom from 'public/images/arrow-down.svg';

import CommuteForm from './CommuteForm';
import CommuteInfoItem from './CommuteInfoItem';
import { CommuteParams } from './types';
import { CommuteData } from '.';
import { MAX_COMMUTE_TIMES } from 'constants/building/commute';

type CommutePeops = {
  onAddGMapDirection: (res: CommuteParams) => Promise<string>;
  onShow: (index: number) => void;
  onHide: (index: number) => void;
  onDelete: (index: number) => void;
  onEditGMapDirection: (data: CommuteParams, index: number) => Promise<string>;
  commuteData: CommuteData[];
  mapInstance?: google.maps.Map;
};

const Commute: React.FC<CommutePeops> = ({
  onAddGMapDirection,
  onHide,
  onShow,
  onEditGMapDirection,
  onDelete,
  commuteData,
  mapInstance,
}) => {
  const [isAddOpen, setAddOpen] = useState(false);

  return (
    <div className="flex flex-col mt-3 mb-20 xl:mb-0 xl:w-1/2 xl:mt-0 xl:overflow-y-auto">
      <div className="uppercase border-b md:mt-4 xl:pr-8 xl:ml-8 border-beige-dark">
        Commute Times
      </div>
      <div className="flex flex-col w-full py-3 xl:py-8 xl:pl-8">
        {commuteData.length > 0 && (
          <div className="flex flex-col px-4 py-1 border border-beige-dark bg-beige">
            {commuteData.map((commuteInfoItemData, index) => (
              <CommuteInfoItem
                key={
                  commuteInfoItemData.destination.formatted_address +
                  commuteInfoItemData.transport +
                  commuteInfoItemData.time.getTime()
                }
                commuteData={commuteInfoItemData}
                index={index}
                onHide={onHide}
                onShow={onShow}
                onEditGMapDirection={onEditGMapDirection}
                onDelete={onDelete}
              />
            ))}
          </div>
        )}
        {commuteData.length < MAX_COMMUTE_TIMES && (
          <div
            onClick={() => setAddOpen(!isAddOpen)}
            className="flex items-center mt-4 mb-8 uppercase border-b-2 cursor-pointer scroll-smooth border-gold-dark md:mb-10 xl:mb-6 w-max"
          >
            <div className="w-5 h-[21px]">
              <Image
                src={isAddOpen ? arrowBottom : arrowLeft}
                alt="Add Commute Time"
                width={15}
              />
            </div>
            <span className="whitespace-nowrap">ADD COMMUTE TIME</span>
          </div>
        )}
        {isAddOpen && commuteData.length < MAX_COMMUTE_TIMES && (
          <CommuteForm
            mapInstance={mapInstance}
            CancelButton={({ children, className }) => (
              <button className={className} onClick={() => setAddOpen(false)}>
                {children}
              </button>
            )}
            onCalculate={async (params) => {
              if (commuteData.length < MAX_COMMUTE_TIMES) {
                const res = await onAddGMapDirection(params);
                if (res === google.maps.DirectionsStatus.OK)
                  setAddOpen(!isAddOpen);
                return res;
              }
              return '';
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Commute;
