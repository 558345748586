import React, { useEffect, useState } from 'react';

import MonthlyCostFormWrapper from '../components/MonthlyCostFormWrapper';
import { formatPriceValue } from '../utils';
import { getNumberFromString } from '../../../../src/utils/utils';

interface HomeInsuranceFormProps {
  onChange: (value: number) => void;
}

const HomeInsuranceForm: React.FC<HomeInsuranceFormProps> = ({ onChange }) => {
  const [homeInsuranceValue, setHomeInsuranceValue] = useState(83);

  useEffect(() => {
    const initial = localStorage && localStorage.getItem('homeInsuranceValue');
    initial && setHomeInsuranceValue(Number(initial));
  }, []);

  useEffect(() => {
    onChange(homeInsuranceValue);
    localStorage.setItem('homeInsuranceValue', String(homeInsuranceValue));
  }, [homeInsuranceValue]);

  function onHomeInsuranceChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = getNumberFromString(event.target.value);
    setHomeInsuranceValue(value);
  }

  return (
    <MonthlyCostFormWrapper label="Home Insurance" value={homeInsuranceValue}>
      <div className="relative flex flex-col mb-6">
        <input
          onChange={onHomeInsuranceChange}
          className="px-4 py-2 mt-6 border border-beige-dark"
          type="text"
          value={formatPriceValue(homeInsuranceValue)}
        />
        <div className="absolute right-3.5 top-8 text-16-26-0.3 text-beige-dark ">
          /mo
        </div>
      </div>
      <span className="text-14-26-0.3 text-gray">
        Stunning Carbonell Condo in Brickell rises 40 stories and has nine
        apartments per floor with four passenger elevators and one service
        elevator. Some of it’s features are high speed
      </span>
    </MonthlyCostFormWrapper>
  );
};

export default HomeInsuranceForm;
