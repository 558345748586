import React from 'react';
import Image from 'next/legacy/image';

import iconThumbs from '../../../public/images/icon_thumbs.svg';
import iconList from '../../../public/images/icon_list.svg';
import iconThumbsClicked from '../../../public/images/icon_thumbs_clicked.svg';
import iconListClicked from '../../../public/images/icon_list_clicked.svg';

import { FiltersValues, SetFiltersValues } from '../../UnitsFilters/types';
import AnyPriceFilter from '../../UnitsFilters/components/AnyPriceFilter';
import AnyBedsFilter from '../../UnitsFilters/components/AnyBedsFilter';
import UnitsSorting from '../../UnitsFilters/components/UnitsSorting';
import BuildingPageMoreFilters from '../BuildingPageMoreFilters';

interface Props {
  renderCards: boolean;
  setRenderCards: (isTableMode: boolean) => void;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
  setFiltersValues: SetFiltersValues;
  filtersValues: FiltersValues;
  buildingId: number;
  selectedPropertyType: 'rent' | 'sale';
}

const AvailableCondoFilter: React.FC<Props> = ({
  renderCards,
  setRenderCards,
  selectedPropertyType,
  filtersValues,
  setFiltersValues,
  setSortOption,
  buildingId,
}) => {
  return (
    <div className="flex flex-col justify-between mb-12 lg:flex-row">
      <div className="flex flex-col md:flex-row">
        <div className="flex">
          <button className="mr-2" onClick={() => setRenderCards(true)}>
            <Image
              src={renderCards ? iconThumbsClicked : iconThumbs}
              alt="icon-thumbs"
              height={44}
              width={44}
            />
          </button>
          <button className="mr-2" onClick={() => setRenderCards(false)}>
            <Image
              src={renderCards ? iconList : iconListClicked}
              alt="icon-list"
              height={44}
              width={44}
            />
          </button>
        </div>
        <div className="flex items-start">
          <AnyPriceFilter
            selectedPropertyType={selectedPropertyType}
            values={filtersValues}
            onChangeFilters={setFiltersValues}
          />
          <AnyBedsFilter
            values={filtersValues}
            onChangeFilters={setFiltersValues}
          />
        </div>
        <BuildingPageMoreFilters
          buildingId={buildingId}
          values={filtersValues}
          setValues={setFiltersValues}
          propertyType={selectedPropertyType}
        />
      </div>
      <UnitsSorting
        onChange={setSortOption}
        rootClassName={
          'w-72 mt-4 lg:mt-0 py-2.5 lg:pt-[7px] lg:pb-[9px] mb-2 text-14-26-0.3 pl-[16px]'
        }
        selectClassName="w-72 h-[44px] -left-0.1"
      />
    </div>
  );
};

export default AvailableCondoFilter;
