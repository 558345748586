import React, { useContext, useEffect, useState } from 'react';

import {
  defaultFilterValues,
  sortOptions,
} from 'components/UnitsFilters/constants';
import { FiltersValues, PropertyType } from 'components/UnitsFilters/types';

import { useSearch } from '../../../hooks/useSearch';
import { BuildingPageContext } from '../../../pages/[urlParam]/[urlBuildParam]';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import AvailableCondoFilter from './AvailableCondoFilter';
import AvailableCondosBody from './AvailableCondosBody';
import { useRouter } from 'next/router';
import { cloneDeep } from 'lodash';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

const RENT = 'rent';
const SALE = 'sale';

interface Props {
  initialPropertyType: PropertyType;
  isPPC?: boolean;
  isIconPPC?: boolean;
}

const AvailableCondos: React.FC<Props> = ({
  initialPropertyType,
  isPPC,
  isIconPPC,
}) => {
  const router = useRouter();

  const { rentCount, saleCount, building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const { name, id } = building;

  const [selectedPropertyType, setSelectedPropertyType] =
    useState<PropertyType>(initialPropertyType);
  const [filtersValues, setFiltersValues] = useState<FiltersValues>(
    getFiltersValues(cloneDeep(defaultFilterValues))
  );
  const [sortOption, setSortOption] = useState(sortOptions.RECOMMENDED);
  const [renderCards, setRenderCards] = useState(true);

  const {
    listings,
    refetchQueryResults,
    pagination,
    paginate,
    listingsLoading,
  } = useSearch({
    buildingId: id,
    pageSize: 6,
  });

  useEffect(() => {
    console.log('filtersValues :>> ', filtersValues);
    refetchQueryResults({
      values: getFiltersValues(filtersValues),
      sortOption,
      propertyType: selectedPropertyType,
    });
  }, [sortOption, filtersValues, selectedPropertyType]);

  useEffect(() => {
    setSelectedPropertyType(initialPropertyType);
  }, [initialPropertyType]);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    !!listings.length && setShowFilters(true);
  }, [listings.length]);

  function getFiltersValues(filters: FiltersValues) {
    const values = { ...filters };
    if (selectedPropertyType === 'sale') {
      values.forSale = true;
      values.preConstruction = true;
      values.newConstruction = true;
      values.foreclosures = true;
      values.shortSales = true;
      values.existingCondos = true;
    }
    if (selectedPropertyType === 'rent') {
      // @TODO: do not add the sale values as initial, so we don't need to check it as false here
      values.forRent = true;
      values.forSale = false;
      values.preConstruction = false;
      values.newConstruction = false;
      values.foreclosures = false;
      values.shortSales = false;
      values.existingCondos = false;
    }
    return values;
  }

  function onPaginate(page: number) {
    paginate(page, sortOption);
    router.push('#AvailableCondos');
  }

  const Preloader = () => (
    <>
      {new Array(4).fill(null).map((_, i) => (
        <Box pt={3} key={i}>
          {new Array(6).fill(null).map((__, j) => (
            <Typography key={j} variant="h3">
              <Skeleton />
            </Typography>
          ))}
          <Divider sx={{ marginTop: 3 }} />
        </Box>
      ))}
    </>
  );

  return (
    <>
      <h2 className="mb-[29px] text-18-23-0.3 md:text-27-36-0.3 font-sohneKraftig mt-[44px]">
        Available Condos at {name}
      </h2>
      <div className="flex">
        <button
          className={`outline-none flex pb-[9px] pt-[7px] px-6 uppercase justify-center text-center border-gold border my-10 relative ${
            selectedPropertyType === SALE ? 'bg-gold' : 'bg-white'
          }`}
          onClick={() => setSelectedPropertyType(SALE)}
        >
          <div
            className={
              selectedPropertyType === SALE
                ? 'absolute w-3 h-3 bg-gold -bottom-1.7 bg-write border-r border-b border-gold transform rotate-45'
                : 'hidden'
            }
          />
          <span className="text-14-17-0.3 md:text-16-19-0.3 w-28 md:w-[179px]">{`Sale(${saleCount})`}</span>
        </button>
        <button
          className={`outline-none flex pb-[9px] pt-[7px] px-6 uppercase justify-center text-center border-gold border my-10 relative ${
            selectedPropertyType === RENT ? 'bg-gold' : 'bg-white'
          }`}
          onClick={() => setSelectedPropertyType(RENT)}
        >
          <div
            className={
              selectedPropertyType === RENT
                ? 'absolute w-3 h-3 bg-gold -bottom-1.7 bg-write border-r border-b border-gold transform rotate-45'
                : 'hidden'
            }
          />
          <span className="text-14-17-0.3 md:text-16-19-0.3 w-28 md:w-[179px]">{`Rent(${rentCount})`}</span>
        </button>
      </div>
      {showFilters ? (
        <AvailableCondoFilter
          renderCards={renderCards}
          setRenderCards={setRenderCards}
          selectedPropertyType={selectedPropertyType}
          filtersValues={filtersValues}
          setSortOption={setSortOption}
          setFiltersValues={setFiltersValues}
          buildingId={building.id}
        />
      ) : null}
      {!listings.length && !showFilters ? (
        <div className="mb-12 text-center">
          <span>There are currently no units listed for sale in the MLS</span>
        </div>
      ) : !listings.length && showFilters ? (
        <div className="mb-12 text-center">
          <p>No listings match your criteria.</p>
          <p>Please remove one or more filters and try again.</p>
        </div>
      ) : null}
      {listingsLoading && <Preloader />}
      {/* 
        @todo refactor this
      */}
      <AvailableCondosBody
        isIconPPC={isIconPPC}
        isPPC={isPPC}
        selectedPropertyType={selectedPropertyType}
        units={listings}
        sortOption={sortOption}
        renderCards={renderCards}
        pagination={pagination}
        setCurrentPage={onPaginate}
      />
    </>
  );
};

export default AvailableCondos;
