import React, { useContext, useState } from 'react';
import Image from 'next/legacy/image';

import { Position } from 'types';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';

import enlarge from '../../public/images/enlarge.svg';
import { StrapiMediaInfo } from '../../apolloClient/types';
import PopUpContactUs from '../ContactUs/PopUpContactUs';
import PopUpUnitBuilding from '../../components/PopUpUnitBuilding';
import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { getBuildingFloorplans } from '../../src/utils/buildingUtils';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';

import { TableFloorplans } from '../Floorplans/TableFloorplans';
import FlagLabel from '../FlagLabel';

import BuildingPopupHeader from './BuildingPopupHeader';
import { defaultImage } from 'src/constants/constants';
import {
  getMediumImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';

const tableFloorplansColumns = [
  {
    key: 'aptNo',
    heading: 'Apt#',
  },
  {
    key: 'model',
    heading: 'Model',
    widthRatio: 2,
  },
  {
    key: 'bedBath',
    heading: 'Bed/Baths',
  },
  {
    key: 'floors',
    heading: 'Floors',
  },
  {
    key: 'interiorExt',
    heading: 'Interior + Ext (Sq Ft)',
    widthRatio: 2,
  },
];

type BuildingFloorplansSectionProps = {
  images?: StrapiMediaInfo[];
  position?: Position;
  floorplans: any;
};

const BuildingFloorplansSection: React.FC<BuildingFloorplansSectionProps> = ({
  images,
  position,
  floorplans,
}) => {
  const { building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const { name, videoUrl } = building;
  const floorplansData = getBuildingFloorplans(floorplans);
  const [isFloorplanDialogOpen, setFloorplanDialogOpen] = useState(false);
  const [showDirection, setShowDirection] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>(
    undefined
  );

  const imagesExists = Boolean(images && images.length > 0);
  const floorPlanExists = Boolean(floorplans && floorplans.length > 0);
  const videoExists = Boolean(videoUrl);

  function openFloorplanDialog(rowIndex?: number) {
    setFloorplanDialogOpen(true);
    setActiveRowIndex(rowIndex);
  }

  function closeFloorplanDialog() {
    setFloorplanDialogOpen(false);
    setActiveRowIndex(undefined);
  }

  return (
    <>
      <div className="flex">
        <div className="relative flex flex-col justify-center mt-3 mb-10 w-max">
          <FlagLabel name={`${name} floorplans`} />
        </div>
      </div>
      {floorplans.length > 0 ? (
        <div className="flex flex-col xl:flex-row xl:justify-between">
          <div className="relative py-4 px-8 border border-gold-dark xl:w-[50%] h-96 md:h-112 xl:h-[639px]">
            <div className="relative w-full h-full px-[20px]">
              <Image
                className="object-contain w-full h-full"
                src={
                  getMediumImageUrlFromImage(
                    flattenStrapiDataItem(building.floorPlanImage.data)
                  ) || defaultImage
                }
                blurDataURL={
                  getThumbnailImageUrlFromImage(
                    flattenStrapiDataItem(building.floorPlanImage.data)
                  ) || defaultImage
                }
                layout="fill"
                placeholder="blur"
                loading="eager"
                priority
                alt={`${building.name} floorplans`}
              />
              <button
                onClick={() => openFloorplanDialog()}
                className="absolute flex items-center h-7 pl-[10px] pr-[6px] uppercase bg-white border-2 hover-animation hover:bg-gold right-[-32px] top-96 md:top-96 xl:top-[565px] md:bottom-4 md:right-4 xl:right-14 border-gold w-[115px]"
              >
                <Image
                  src={enlarge}
                  alt="enlarge"
                  className="w-[16px] h-[16px] mr-1"
                />
                <span className="pt-1 pl-2 whitespace-nowrap text-14-18-0.3">
                  Enlarge
                </span>
              </button>
              <PopUpUnitBuilding
                entityType="building"
                entity={building}
                open={isFloorplanDialogOpen}
                onClose={closeFloorplanDialog}
                activeTab={1}
                images={images}
                position={position}
                tabsRenderExclude={{
                  photos: !showDirection,
                  floorPlan: !floorPlanExists,
                  tour: !showDirection,
                  directions: !showDirection,
                }}
                renderHeader={<BuildingPopupHeader />}
                initialActiveRowIndex={activeRowIndex}
                initialSitePlan={activeRowIndex === undefined}
              />
            </div>
          </div>
          <div className="mt-20 md:mt-8 xl:mt-0 xl:ml-8 xl:w-7/12">
            <TableFloorplans
              columns={tableFloorplansColumns}
              data={floorplansData}
              openFloorplanDialog={openFloorplanDialog}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center text-center">
          <div className="mb-2 font-sohneKraftig">Bummer!</div>
          <div className="mb-4 text-14-26-0.3">
            We didn’t find an exact floor plan match for this building, but we
            can likely get it for you.
          </div>
          <div className="flex justify-center">
            <PopUpContactUs
              isRequestInfoBuilding={true}
              contactUsName={'Request floor plan'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BuildingFloorplansSection;
