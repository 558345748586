import { CommuteData, CommuteParams } from 'components/Commute';

type CachedGeometry = {
  location: google.maps.LatLngLiteral | undefined;
};

type CachedDestination = {
  geometry: CachedGeometry;
  formatted_address?: string;
};

interface CachedDirectionsTime extends Omit<CommuteParams, 'time'> {
  time: number;
}

interface CachedDirections extends Omit<CachedDirectionsTime, 'destination'> {
  destination: CachedDestination;
}

type BuildingCachedDirections = {
  building: number;
  directions: CachedDirections[];
};

export function storageRead<T>(field: string): T | null {
  const raw = window?.localStorage.getItem(field);
  if (raw) {
    try {
      const res = JSON.parse(raw);
      return res;
    } catch (error) {
      console.error('Error parsing JSON storageRead', error);
      return null;
    }
  }
  return null;
}

export const storageWrite = (field: string, value: any) => {
  window?.localStorage.setItem(field, JSON.stringify(value));
};

export const cacheDirections = ({
  directions,
  building,
}: {
  directions: CommuteData[];
  building: number;
}) => {
  let cached: BuildingCachedDirections[] | null = storageRead('directions');
  const cachedIndex = cached?.findIndex((item) => item.building === building);
  const convertedDirections: CachedDirections[] =
    directions.map<CachedDirections>((direction) => ({
      active: direction.active,
      time: direction.time.getTime(),
      name: direction.name,
      transport: direction.transport,
      destination: {
        formatted_address: direction.destination.formatted_address,
        geometry: {
          location: direction.destination.geometry?.location?.toJSON(),
        },
      },
    }));
  if (cached) {
    if ((cachedIndex || cachedIndex === 0) && cachedIndex !== -1) {
      cached[cachedIndex] = {
        building,
        directions: convertedDirections,
      };
    } else {
      cached.push({
        building,
        directions: convertedDirections,
      });
    }
  } else {
    cached = [{ building, directions: convertedDirections }];
  }

  storageWrite('directions', cached);
};

export const getCachedDirections = ({
  building,
}: {
  building: number;
}): CommuteParams[] | null | undefined => {
  const cached: BuildingCachedDirections[] | null = storageRead('directions');
  if (cached) {
    return cached
      .find((item) => item.building === building)
      ?.directions.map((direction) => ({
        ...direction,
        time: new Date(direction.time),
        destination: {
          formatted_address: direction.destination.formatted_address,
          geometry: {
            ...direction.destination.geometry,
            location: new google.maps.LatLng({
              lat: direction.destination.geometry?.location?.lat || 0,
              lng: direction.destination.geometry?.location?.lng || 0,
            }),
          },
        },
      }));
  }
  return null;
};
