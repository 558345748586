import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo } from 'react';

import { LOAD_USER_AGENTS } from 'apolloClient/queries/agents';
import { MappedAgent, LoadUserAgentsResponse } from 'apolloClient/types/Agents';

import {
  getAgentIdFromLocalStorage,
  getLeadIdFromLocalStorage,
} from '../../src/utils/utils';
import { UnitPageContext } from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { mapCustomAgent } from '../../src/utils/agentUtils';
import { useAuth } from '../Auth/AuthProvider';
import Agents from './Agents';

interface AgentsContainerProps {
  showSecondAgent?: boolean;
  experts?: any[];
  containerClassName?: string;
  textClassName?: string;
  containerExpert?: string;
  onDefaultAgent?: (agent: MappedAgent) => void;
}

const AgentsContainer: React.FC<AgentsContainerProps> = ({
  showSecondAgent,
  experts,
  containerClassName = '',
  textClassName,
  containerExpert,
  onDefaultAgent,
}) => {
  const [loadUserAgents, { data: agentsData, loading: agentsLoading }] =
    useLazyQuery<{
      getCustomUserAgents: LoadUserAgentsResponse;
    }>(LOAD_USER_AGENTS);

  const { listing } = useContext(UnitPageContext) || {};

  const { isLogged, me } = useAuth();

  useEffect(() => {
    const agentFromStorage = getAgentIdFromLocalStorage();
    const leadId = getLeadIdFromLocalStorage();
    loadUserAgents({
      variables: {
        leadId: leadId || undefined,
        agentNick: agentFromStorage?.toLowerCase() || undefined,
        listingPrice: listing?.price || undefined,
        isLogged,
        isForStorage: false,
      },
    });
  }, [me]);

  const agents = useMemo(() => {
    let allAgentsData = agentsData?.getCustomUserAgents;

    if (allAgentsData?.isAssignedAgentExists && onDefaultAgent) {
      return {
        commonAgent: mapCustomAgent(allAgentsData?.secondAgent),
        secondAgent: mapCustomAgent(allAgentsData?.commonAgent),
      };
    }

    return {
      commonAgent: mapCustomAgent(allAgentsData?.commonAgent),
      secondAgent: mapCustomAgent(allAgentsData?.secondAgent),
    };
  }, [agentsData, agentsLoading]);

  useEffect(() => {
    if (agents.commonAgent) {
      onDefaultAgent && onDefaultAgent(agents.commonAgent);
    }
  }, [agents]);

  const filtersExperts = experts?.filter(
    (obj) =>
      obj?.agentId?.email === agents?.commonAgent?.email ||
      obj?.agentId?.email === agents?.secondAgent?.email
  );

  return agents.commonAgent ? (
    <>
      <Agents
        commonAgent={agents.commonAgent}
        secondAgent={agents.secondAgent}
        showSecondAgent={showSecondAgent}
        experts={filtersExperts}
        containerClassName={containerClassName}
        textClassName={textClassName}
        containerExpert={containerExpert}
      />
    </>
  ) : null;
};

export default AgentsContainer;
