import React, { useContext } from 'react';
import Image from 'next/legacy/image';

import { Listing } from 'apolloClient/types/Units';
import { Pagination } from 'apolloClient/types/common';
import { PropertyType } from 'components/UnitsFilters/types';
import { BuildingPageContext } from 'pages/[urlParam]/[urlBuildParam]';

import arrowButton from '../../../public/images/arrow-button.svg';
import { EntityWithId } from '../../../apolloClient/types';
import { createSearchUrlByType } from '../../Search/utils/listingsFiltersUtils';
import { BuildingPageContextInterface } from '../../../apolloClient/types/Building';

import SearchResults from '../../Search/CondosSearchResult/CondosSearchResult';
import PopUpContactUs from '../../ContactUs/PopUpContactUs';

interface Props {
  renderCards: boolean;
  units: EntityWithId<Listing>[];
  setCurrentPage: (val: number, sortOption?: string) => void;
  sortOption: string;
  pagination?: Pagination;
  selectedPropertyType: PropertyType;
  isPPC?: boolean;
  isIconPPC?: boolean;
}

const AvailableCondosBody: React.FC<Props> = ({
  renderCards,
  units,
  pagination,
  setCurrentPage,
  selectedPropertyType,
  isPPC,
  isIconPPC,
}) => {
  const { building } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;
  const { slug } = building;

  return (
    <React.Fragment>
      {units.length === 0 ? (
        <div className="flex flex-col items-center justify-center md:justify-start gap-[15px] md:gap-0 md:flex-row">
          <PopUpContactUs contactUsName={'Contact us'} />
          {/* <div className="ml-1 w-[70%] md:w-full text-center lg:text-left lg:pl-[139px]"> */}
          <div className="ml-1 w-[70%] md:w-auto text-center">
            for price list and members-only developer specials
          </div>
        </div>
      ) : (
        /**
         * @todo refactor
         */
        <SearchResults
          isIconPPC={isIconPPC}
          isPPC={isPPC}
          gridResults={'grid-cols-1 md:grid-cols-2 xl:grid-cols-3'}
          imageClassName={'h-40 xl:h-[256px]'}
          items={units}
          resultDisplaying={renderCards ? 'cards' : 'table'}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
        />
      )}
      <div className="relative">
        <div className="right-0 flex justify-center mt-8 md:mt-0 xl:absolute -top-14">
          <a
            rel="nofollow"
            href={createSearchUrlByType(selectedPropertyType, {
              buildings: [slug],
            })}
            className=""
          >
            <button className="hover-animation flex items-center justify-between py-4 pl-6 pr-3 text-14-26-0.3 uppercase w-72 bg-gold md:my-4 lg:pl-6 lg:pr-6 md:py-[12px] md:hover:bg-gold-hover font-sohneBreitHalbfett lg:text-16-26-0.3">
              <div className="text-14-14-0.3 font-sohneBreitHalbfett">
                Advanced search
              </div>
              <Image
                src={arrowButton}
                alt="Advanced search"
                width={19}
                height={14}
              />
            </button>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AvailableCondosBody;
