import React from 'react';
import { Disclosure } from '@headlessui/react';
import Image from 'next/legacy/image';

import arrowTopics from '../../../../public/images/arrow-topics.svg';

import TransitionWrapper from '../../../TransitionWrapper';
import classnames from 'classnames';

interface MonthlyCostFormWrapperProps {
  label: string;
  value: number;
  children?: React.ReactNode;
}

const MonthlyCostFormWrapper: React.FC<MonthlyCostFormWrapperProps> = ({
  value,
  label,
  children,
}) => {
  return (
    <React.Fragment>
      <Disclosure as="div" className="mb-6">
        {({ open }) => (
          <React.Fragment>
            <Disclosure.Button
              as="div"
              className="flex justify-between py-2 border-b cursor-pointer border-gold-dark text-16-21-0.3"
            >
              <span className="font-sohneKraftig">{label}</span>
              <div className="flex space-x-6">
                <span className="font-sohneKraftig">{`$${value}/mo`}</span>
                <span>
                  <Image
                    className={classnames({ 'transform rotate-180': open })}
                    src={arrowTopics}
                    alt={label}
                  />
                </span>
              </div>
            </Disclosure.Button>
            <TransitionWrapper>
              <Disclosure.Panel>{children}</Disclosure.Panel>
            </TransitionWrapper>
          </React.Fragment>
        )}
      </Disclosure>
    </React.Fragment>
  );
};

export default MonthlyCostFormWrapper;
