import Image from 'next/legacy/image';
import React from 'react';

import questionMark from 'public/images/question_mark.svg';

const TooltipInfo: React.FC<{ text: string }> = ({ text }) => (
  <div className="relative inline-block group">
    <div className="flex px-2 cursor-pointer">
      <Image src={questionMark} alt="help popup" width={20} height={26} />
    </div>
    <div className="absolute z-30 hidden p-4 m-0 border bottom-8 -left-20 w-52 group-hover:block align-center bg-gold-background border-beige-dark">
      <p className="text-14-26-0.3 font-sohneBuch">{text}</p>
    </div>
  </div>
);

export default TooltipInfo;
