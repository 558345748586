export const getVideoUrl = (url: string) => {
  const inputUrl = new URL(url);

  if (
    inputUrl.hostname.includes('youtube.com') &&
    inputUrl.pathname === '/watch'
  ) {
    const videoId = inputUrl.searchParams.get('v');
    const startTime = inputUrl.searchParams.get('t');

    const embedUrl = new URL(`https://www.youtube.com/embed/${videoId}`);
    if (startTime) {
      embedUrl.searchParams.set('start', startTime);
    }

    return embedUrl;
  } else if (inputUrl.hostname === 'youtu.be') {
    const videoId = inputUrl.pathname.slice(1);
    const startTime = inputUrl.searchParams.get('t');

    const embedUrl = new URL(`https://www.youtube.com/embed/${videoId}`);
    if (startTime) {
      embedUrl.searchParams.set('start', startTime);
    }

    return embedUrl;
  } else if (inputUrl.hostname.includes('dropbox')) {
    return new URL(url.replace('dl=0', 'raw=1'));
  } else {
    return inputUrl;
  }
};
