import { EntityWithId } from 'apolloClient/types';
import { BlogWithoutId } from 'apolloClient/types/Blog';

export const getThreeUniquePageBlogs = (
  blogs: EntityWithId<BlogWithoutId>[]
): EntityWithId<BlogWithoutId>[] => {
  const uniquePageBlogsMap = new Map();
  blogs.forEach((blog) => uniquePageBlogsMap.set(blog.title, blog));
  const uniquePageBlogs = Array.from(uniquePageBlogsMap).map(
    ([_, value]) => value
  );
  return uniquePageBlogs.slice(0, 3);
};
