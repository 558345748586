import { SearchPageListingDataResponse } from 'apolloClient/types/Searches';
import {
  Listing,
  ListingResponse,
  UnitResponse,
} from 'apolloClient/types/Units';
import { Address, StrapiMediaInfo } from 'apolloClient/types';
import { NeighborhoodWithoutId } from 'apolloClient/types/Neighborhood';
import { BuildingWithoutId } from 'apolloClient/types/Building';
import {
  Entity,
  Response,
  ResponseCollection,
} from 'apolloClient/types/common';
import { FavoriteListingsResponse } from 'apolloClient/types/FavoriteListing';

/**
 * That`s a temporary solution, to resolve the task faster.
 * In the future we need to remove it and kepp the response data without transformers
 */
export const transformSearchPageListings = (
  listing: SearchPageListingDataResponse
): Entity<Listing> => {
  return {
    id: listing.id,
    attributes: {
      yearBuilt: parseInt(listing.year_built),
      isShortSale: listing.is_short_sale,
      isReo: listing.is_reo,
      propertyTypeCode: listing.property_type_code,
      statusCode: listing.status_code,
      closeDate: listing.close_date ? listing.close_date : '',
      sqft: +listing.sqft || 0,
      priceSqft: listing.price_sqft,
      bedsTotal: listing.beds_total,
      bathsFull: listing.baths_full,
      bathsHalf: listing.baths_half,
      price: parseInt(listing.price),
      unitNumber: listing.unit_number,
      hasFloorplan: !!listing.floorplanId,
      images: {
        data: listing.images.map((image) => {
          return {
            attributes: {
              url: image.url,
              formats: image.formats,
            },
            id: image.id,
          };
        }),
      } as ResponseCollection<StrapiMediaInfo>,
      virtualTourUrl: listing.virtual_tour_url,
      address: {
        zipCode: listing.zip_code,
        streetName: listing.street_name,
        streetNumber: listing.street_number,
        streetType: listing.street_type,
        streetDirection: listing.street_direction,
      } as Address,
      unit: {
        data: {
          id: listing.unitId,
          attributes: {
            favorite: {
              data: listing.favoriteId
                ? [
                    {
                      id: listing.favoriteId,
                      attributes: { type: listing.favoriteType },
                    },
                  ]
                : [],
            } as FavoriteListingsResponse,
            latestRentListing: {
              data: {
                attributes:
                  listing.lastRentListingCloseDate ||
                  listing.lastRentListingSoldPrice
                    ? {
                        closeDate: listing.lastRentListingCloseDate,
                        soldPrice: listing.lastRentListingSoldPrice,
                      }
                    : null,
              },
            } as ListingResponse,
            building: {
              data: {
                attributes: {
                  lat: listing.lat,
                  lon: listing.lon,
                  primaryAddress: listing.primary_address,
                  name: listing.buildingName,
                  slug: listing.buildingSlug,
                  isPreconstruction: listing.is_preconstruction,
                  neighborhood: {
                    data: {
                      id: listing.neighborhoodId,
                      attributes: {
                        slug: listing.neighborhoodSlug,
                        name: listing.neighborhoodName,
                        city: {
                          data: {
                            id: listing.cityId,
                            attributes: {
                              name: listing.cityName,
                            },
                          },
                        },
                      } as NeighborhoodWithoutId,
                    },
                  },
                } as BuildingWithoutId,
              } as Entity<BuildingWithoutId>,
            } as Response<BuildingWithoutId>,
            unique: listing.unitUnique,
          },
        },
      } as UnitResponse,
    },
  } as Entity<Listing>;
};
