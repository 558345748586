import React, { useContext, useMemo, useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';

import { normilizeUnitDescription, priceFormatter } from 'src/utils/utils';
import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import { isListingOffMarket } from 'lib/checkListingStatus';

import FlagLabel from '../../components/FlagLabel';
import { KeyValueList } from '../../components/KeyValueList';
import arrowLeft from '../../public/images/arrow-left.svg';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  filterAssociationLabels,
  filterConvenienceInteriorFeaturesLabels,
  filterConveniencesEquipmentAppliancesLabels,
  filterConveniencesHeatingCoolingLabels,
  filterMaintenanceLabels,
  filterStyleLabels,
} from 'components/Building/BuildingAmenities/amenitiesLabels';

const AdditionalUnitDetails: React.FC = () => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const { building, listing } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;
  const scores = building.walkscore;
  const {
    housingOlderPersonsAct,
    equipmentAppliances,
    interiorFeatures,
    yearBuiltDescription,
    associationFeePaidPer,
    rentalPaymentIncludes,
    securityInformation,
    unitDesign,
    heatingDescription,
    coolingDescription,
    bedroomDescription,
    garageSpaces,
    isPropertyDetached,
    masterBathroomDescription,
    taxAmount,
    parcelNumber,
    taxYear,
    taxInformation,
    approvalInformation,
    termsConsidered,
    isMembershipPurchaseRequired,
    propertyTypeCode,
    amenity,
    statusCode,
  } = listing;
  let convenienceEquipmentAppliances = amenity?.convenience
    ? filterConveniencesEquipmentAppliancesLabels(amenity.convenience)
    : NO_DATA_PLACEHOLDER;
  let convenienceInteriorFeatures = amenity?.convenience
    ? filterConvenienceInteriorFeaturesLabels(amenity.convenience)
    : NO_DATA_PLACEHOLDER;
  let convenienceHeatingCooling = amenity?.convenience
    ? filterConveniencesHeatingCoolingLabels(amenity.convenience)
    : NO_DATA_PLACEHOLDER;
  let associations = amenity?.association
    ? filterAssociationLabels(amenity.association)
    : NO_DATA_PLACEHOLDER;
  let places = amenity?.parking?.places;
  let maintenances = amenity?.maintenance
    ? filterMaintenanceLabels(amenity.maintenance)
    : NO_DATA_PLACEHOLDER;
  let styles = amenity?.style
    ? filterStyleLabels(amenity.style)
    : NO_DATA_PLACEHOLDER;

  const detailsLeftCol = useMemo(() => {
    return [
      ...(equipmentAppliances
        ? [
            {
              key: 'Equipment / Appliances',
              value:
                normilizeUnitDescription(listing?.equipmentAppliances) ||
                NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(housingOlderPersonsAct
        ? [
            {
              key: 'Housing Older Persons Act',
              value: housingOlderPersonsAct,
            },
          ]
        : []),
      ...(interiorFeatures
        ? [
            {
              key: 'Interior Features',
              value:
                normilizeUnitDescription(listing?.interiorFeatures) ||
                NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(yearBuiltDescription
        ? [
            {
              key: 'Year Built Description',
              value: yearBuiltDescription,
            },
          ]
        : []),
      ...(styles
        ? [
            {
              key: 'Style',
              value: styles || NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(associationFeePaidPer
        ? [
            {
              key: 'Assoc Fee Paid Per',
              value: normilizeUnitDescription(associationFeePaidPer),
            },
          ]
        : []),
      ...(maintenances
        ? [
            {
              key: 'Maintenance Includes',
              value: maintenances || NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(propertyTypeCode === 'R'
        ? [
            {
              key: 'Rental Payment Includes',
              value: rentalPaymentIncludes
                ? normilizeUnitDescription(rentalPaymentIncludes)
                : NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(securityInformation
        ? [
            {
              key: 'Security Information',
              value: normilizeUnitDescription(securityInformation),
            },
          ]
        : []),
      ...(unitDesign
        ? [
            {
              key: 'Unit Design',
              value: normilizeUnitDescription(unitDesign),
            },
          ]
        : []),
      ...(heatingDescription || coolingDescription
        ? [
            {
              key: 'Heating/Cooling',
              value: convenienceHeatingCooling || NO_DATA_PLACEHOLDER,
            },
          ]
        : [
            {
              key: 'Heating/Cooling',
              value: '-',
            },
          ]),
    ];
  }, []);

  const detailsRightCol = useMemo(() => {
    const transitScore = scores?.transit
      ? `${scores.transit} (${scores.transitDescription})`
      : NO_DATA_PLACEHOLDER;

    const bikeScore = scores?.bike
      ? `${scores.bike} (${scores.bikeDescription})`
      : NO_DATA_PLACEHOLDER;

    return [
      ...(bedroomDescription
        ? [
            {
              key: 'Bedroom Description',
              value: normilizeUnitDescription(bedroomDescription),
            },
          ]
        : []),
      ...(garageSpaces > 0
        ? [
            {
              key: 'Garage Places',
              value: garageSpaces,
            },
          ]
        : []),
      ...(places
        ? [
            {
              key: 'Places',
              value: +places > 1 ? '2 Or More Spaces' : '1 Space',
            },
          ]
        : []),
      {
        key: 'Property Detached',
        value: isPropertyDetached ? 'Yes' : 'No',
      },
      ...(associations
        ? [
            {
              key: 'Type of Association',
              value: associations || NO_DATA_PLACEHOLDER,
            },
          ]
        : []),
      ...(masterBathroomDescription
        ? [
            {
              key: 'Master Bathroom Description',
              value: normilizeUnitDescription(masterBathroomDescription),
            },
          ]
        : []),
      {
        key: 'Tax Amount',
        value:
          +taxAmount > 0
            ? `${priceFormatter(+taxAmount)}/y`
            : NO_DATA_PLACEHOLDER,
      },
      {
        key: 'Tax Record',
        value: parcelNumber ? (
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="break-words text-gold-dark hover:text-gold-hover"
            href={`https://www8.miamidade.gov/apps/pa/propertysearch/#/?folio=${parcelNumber}`}
          >
            {parcelNumber} (external link to county website)
          </a>
        ) : (
          NO_DATA_PLACEHOLDER
        ),
      },
      ...(taxYear > 0
        ? [
            {
              key: 'Tax Year',
              value: taxYear,
            },
          ]
        : []),
      ...(taxInformation
        ? [
            {
              key: 'Tax Information',
              value: normilizeUnitDescription(taxInformation),
            },
          ]
        : []),
      ...(approvalInformation
        ? [
            {
              key: 'Approval Information',
              value: normilizeUnitDescription(approvalInformation),
            },
          ]
        : []),
      ...(termsConsidered
        ? [
            {
              key: 'Terms Considered',
              value: normilizeUnitDescription(termsConsidered),
            },
          ]
        : []),
      ...(termsConsidered
        ? [
            {
              key: 'Membership Purchase Required',
              value: isMembershipPurchaseRequired ? 'Yes' : 'No',
            },
          ]
        : []),
      {
        key: 'Transit Score ®',
        value: transitScore ? (
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="text-gold-dark hover:text-gold-hover"
            href="https://www.walkscore.com/how-it-works/"
          >
            {transitScore}
          </a>
        ) : (
          NO_DATA_PLACEHOLDER
        ),
      },
      {
        key: 'Bike Score ®',
        value: bikeScore ? (
          <a
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="text-gold-dark hover:text-gold-hover"
            href="https://www.walkscore.com/how-it-works/"
          >
            {bikeScore}
          </a>
        ) : (
          NO_DATA_PLACEHOLDER
        ),
      },
    ];
  }, [scores]);
  const neighborhoodName = building?.neighborhood?.data?.attributes?.name;
  const cityName =
    building?.neighborhood?.data?.attributes?.city?.data?.attributes?.name;

  return (
    <ErrorBoundary>
      <section className="container pt-[7.5px] md:pb-2 md:pt-[4px] md:mt-8 lg:mt-[47px] lg:pb-2 lg:pt-0 text-m">
        <div className="flex">
          <div className="relative flex flex-col justify-center w-max">
            <FlagLabel name="Additional Unit Details" />
          </div>
        </div>
        <div
          className={classnames('relative grid mt-10 lg:grid-cols-2 gap-x-16', {
            'max-h-[300px]': !detailsOpen,
            'overflow-hidden': !detailsOpen,
          })}
        >
          <KeyValueList
            rowsClassName="lg:last:border-b-0"
            rows={detailsLeftCol}
          />
          <KeyValueList rows={detailsRightCol} />
          <div
            className={classnames('absolute bottom-0 h-10 w-full', {
              hidden: detailsOpen,
            })}
            style={{
              background: 'linear-gradient(rgba(255,255,255,0),#fff)',
            }}
          />
        </div>
        <button
          onClick={() => setDetailsOpen(!detailsOpen)}
          className="flex items-center mt-4 uppercase border-b-2 cursor-pointer border-gold-dark md:mt-[63px] md:mb-10 lg:my-6 w-max"
        >
          <div className="w-5 h-4">
            <Image
              className={classnames({
                'transform -rotate-90': detailsOpen,
              })}
              src={arrowLeft}
              alt={`${detailsOpen ? 'Hide' : 'Read more'}`}
              width={15}
            />
          </div>
          <span className="whitespace-nowrap">
            {`${detailsOpen ? 'Hide' : 'Read more'}`}
          </span>
        </button>
        <p className="mt-[34px] md:mt-6 mb-3 text-10-16-0.3 md:text-12-21-0.3">
          <a href="/">CondoBlackBook.com</a> and Blackbook Properties are not
          responsible for the accuracy of the {building.name} condos in{' '}
          {neighborhoodName} {neighborhoodName !== cityName ? cityName : ''}{' '}
          information listed above. The data relating to real estate for{' '}
          {!isListingOffMarket(statusCode)
            ? listing.propertyTypeCode === 'R'
              ? 'rent'
              : 'sale'
            : ''}{' '}
          on this web page comes in part from the Internet Data Exchange Program
          and the South East Florida Regional MLS and is provided here for
          consumers personal, non-commercial use, It may not be used for any
          purpose other than to identify prospective Miami Beach area condos
          consumers may be interested in purchasing. Data provided is deemed
          reliable but not guaranteed. Listing provided by{' '}
          {listing?.mlsOfficeName}.
        </p>
      </section>
    </ErrorBoundary>
  );
};

export default AdditionalUnitDetails;
