import React from 'react';
import { Popover } from '@headlessui/react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import whiteThreeDots from '../../../public/images/three_dots.svg';
import whiteThreeDotsHover from '../../../public/images/three_dots_hover.svg';
import { Listing } from '../../../apolloClient/types/Units';
import { Building } from '../../../apolloClient/types';
import { priceFormatter } from '../../../src/utils/utils';
import {
  getQueryStringFromAsPath,
  getValuesFromQuery,
  setQueryParams,
} from '../utils/searchUtils';
import { FiltersValues } from '../../UnitsFilters/types';
import {
  defaultFilterValues,
  defaultFilterValuesWithoutListingTypes,
} from '../../UnitsFilters/constants';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import { useAuth } from 'components/Auth/AuthProvider';

interface Props {
  showThreeDots: boolean;
  listing: Listing;
  building: Building;
  setFiltersValues?: (values: FiltersValues) => void;
  unitStatus?: string;
}

function translateUnitStatusToFilter(status: string = '') {
  if (status.includes('Rented')) {
    return {};
  }

  if (status.includes('Sold')) {
    return { sold: true };
  }

  switch (status) {
    case 'Foreclosure':
      return { foreclosures: true };
    case 'Short sale':
      return { shortSales: true };
    case 'Pre-construction':
      return { preConstruction: true };
    case 'For sale':
      return {
        forSale: true,
        preConstruction: true,
        newConstruction: true,
        foreclosures: true,
        shortSales: true,
        existingCondos: true,
      };
    case 'For rent':
      return { forRent: true };
    case 'Off market':
      return {};
    default:
      return {};
  }
}

const ThreeDots: React.FC<Props> = ({
  showThreeDots,
  listing,
  building,
  setFiltersValues,
  unitStatus,
}) => {
  const router = useRouter();

  const { me } = useAuth();

  const { isAdmin } = me || {};

  const { unit } = listing;
  const { slug } = building;

  const { latestRentListing } = flattenStrapiDataItem(unit?.data);

  const { soldPrice, closeDate } = flattenStrapiDataItem(
    latestRentListing?.data
  );

  const getValues = (slug?: string, status?: string) =>
    getValuesFromQuery(getQueryStringFromAsPath(router.asPath), {
      ...(slug ? { buildings: [slug] } : {}),
      ...(status ? translateUnitStatusToFilter(status) : {}),
    });

  async function onSimilarInBuildingClick() {
    const values: FiltersValues = await getValues(slug, unitStatus);
    if (setFiltersValues) {
      setFiltersValues(values);
    } else {
      setQueryParams(values, defaultFilterValues, router);
    }
  }

  async function showAllUnitsByBuilding() {
    const valuesFromQuery: FiltersValues = await getValues(slug);
    const normalizedValues = {
      ...valuesFromQuery,
      ...defaultFilterValuesWithoutListingTypes,
    };
    if (setFiltersValues) {
      setFiltersValues(normalizedValues);
    } else {
      setQueryParams(normalizedValues, defaultFilterValues, router);
    }
  }
  if (!showThreeDots) return null;
  return (
    <Popover as="div" className="relative cursor-pointer group">
      {({ open }) => (
        <>
          <Popover.Button>
            {isAdmin && (
              <>
                <div className="group-hover:hidden">
                  <Image
                    src={whiteThreeDots}
                    alt="more actions"
                    height={20}
                    width={5}
                  />
                </div>
                <div className="hidden group-hover:block">
                  <Image
                    src={whiteThreeDotsHover}
                    alt="more actions"
                    height={25}
                    width={25}
                  />
                </div>
              </>
            )}
          </Popover.Button>
          <Popover.Panel className="absolute z-20 transform -translate-x-1/2 ">
            {({ close }) => (
              <div className="absolute z-50 mt-3 text-black bg-white border -left-36 border-gray">
                <div className="relative text-14-26-0.3 w-45">
                  <div className="absolute z-30 w-3 h-3 transform rotate-45 bg-white -translate-y-1/2 right-5  -top-0.1 border-t border-l border-gray" />
                  <div className="flex flex-col justify-start p-3 font-Helvetica">
                    <span
                      className="font-medium mb-1.5"
                      onClick={() => {
                        onSimilarInBuildingClick();
                        close();
                      }}
                    >
                      Similar in building
                    </span>
                    <span
                      className="font-medium"
                      onClick={() => {
                        showAllUnitsByBuilding();
                        close();
                      }}
                    >
                      All in building
                    </span>
                  </div>
                  {isAdmin && closeDate && soldPrice && (
                    <div className="p-3 border-t border-gray">
                      <div className="mb-1.5 text-gray font-HelveticaOblique flex flex-col justify-start">
                        <span>Rented date</span>
                        <span>
                          {new Date(closeDate).toLocaleDateString('en-US')}
                        </span>
                      </div>
                      <div className="flex flex-col justify-start text-gray font-HelveticaOblique">
                        <span>Rented price</span>
                        <span>{priceFormatter(soldPrice)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default ThreeDots;
