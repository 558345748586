import React from 'react';
import Image from 'next/legacy/image';
import { AmenitiesCategoriesNames } from 'apolloClient/types/Amenities';

export interface AmenityToRender {
  name: AmenitiesCategoriesNames;
  label: string;
  items: string;
  icon?: any;
}

const renderAmenitiesRows = (labels: (AmenityToRender | null)[] | null) =>
  labels
    ? labels.map((category) => {
        if (!category) return null;
        const { items, label, name, icon } = category;
        return (
          <div key={name} className="flex">
            <div className="flex w-1/2 h-12 md:w-1/3">
              <div className="w-8 mt-1.5">
                <Image src={icon} alt={label} />
              </div>
              <div className="pb-1 mt-1.5 ml-4 font-sohneKraftig text-14-26-0.3">
                {label}
              </div>
            </div>
            <div className="flex w-1/2 ml-6 border-l md:w-2/3 border-beige-dark ">
              <div className="pb-1 mt-1.5 mb-2 ml-8">{items}</div>
            </div>
          </div>
        );
      })
    : null;

export default renderAmenitiesRows;
