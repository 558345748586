import React, { useContext, useMemo, useRef, useState } from 'react';
import Image from 'next/legacy/image';

import {
  flattenStrapiBulkDataItems,
  flattenStrapiDataItem,
} from '../../lib/flattenStrapiBulkDataItems';
import {
  NeighborhoodPageContext,
  NeighborhoodPageContextInterface,
} from '../../pages/[urlParam]';
import { getStrapiMedia } from '../../lib/getStrapiMedia';
import ErrorBoundary from '../ErrorBoundary';
import Breadcrumbs from '../Breadcrumbs';
import Share from '../Share';
import TableNeighborhood from './TableNeighborhood';
import Button from '../Button';
import LineBetweenSections from '../LineBetweenSections';
import { Metadata } from '../Metadata';
import RelatedArticles from '../RelatedArticles';
import HeaderWithLinks from '../layouts/Header/HeaderWithLinks';
import Articles from '../Articles';
import Location from './Location';
import AboutNeighborhood from './AboutNeighborhoodSection/AboutNeighborhood';
import NeighborhoodCondos from './NeighborhoodCondos';
import NearbyNeigbourhoods from './NearbyNeighborhoods';
import { createSearchUrlByType } from 'components/Search/utils/listingsFiltersUtils';
import Experts from 'components/Experts/Experts';
import CommuteTimes from './CommuteTimes';
import MainFeatures from 'components/MainFeatures';

import { Blog } from 'apolloClient/types/Blog';
import { Coordinates } from 'apolloClient/types/Neighborhood';
import {
  getMediumImageUrlFromImage,
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { GoogleAnalytics } from 'src/ga/grouping';
import { getThreeUniquePageBlogs } from 'src/utils/blogs';

const NeighborhoodPage: React.FC = () => {
  const { neighborhood, url, experts, globalBlogs } = useContext(
    NeighborhoodPageContext
  ) as NeighborhoodPageContextInterface;
  const {
    id,
    name,
    image,
    slug,
    neighborhoodStats,
    blogs: blogsNeighborhood,
    neighborhoodsNeighbors,
    coordinates,
    city,
    commuteTimesLeft,
    commuteTimesRight,
    mainFeaturesCommon,
    walkScore,
    walkDescription,
    bikeScore,
    bikeDescription,
    transitScore,
    transitDescription,
  } = neighborhood;

  const mainFeaturesWalkscore = [
    {
      type: 'Walk_score',
      description:
        walkDescription && walkScore
          ? `${walkScore} (${walkDescription})`
          : null,
    },
    {
      type: 'Bike_score',
      description:
        bikeDescription && bikeScore
          ? `${bikeScore} (${bikeDescription})`
          : null,
    },
    {
      type: 'Transit_score',
      description:
        transitDescription && transitScore
          ? `${transitScore} (${transitDescription})`
          : null,
    },
  ];

  const cityName = city?.data?.attributes?.name;
  const title = `${name} Condos for Sale and Rent in ${cityName} | CondoBlackBook`;
  const description = `Search all ${name} condos in ${cityName}. Get real-time sale and rental availability with easy-to-use search, plus exclusive local insights, recent sales and latest news.`;

  const headerRef = useRef(null);
  const RentAndSaleRef = useRef<HTMLParagraphElement>(null);
  const MainFeaturesRef = useRef<HTMLParagraphElement>(null);
  const CommuteTimesRef = useRef<HTMLParagraphElement>(null);
  const LocationRef = useRef<HTMLParagraphElement>(null);
  const WelcomeToRef = useRef<HTMLParagraphElement>(null);
  const CondosRef = useRef<HTMLParagraphElement>(null);
  const ArticlesRef = useRef<HTMLParagraphElement>(null);
  const NeighborhoodsRef = useRef<HTMLParagraphElement>(null);

  const arrayBuildingBlogs = [
    ...flattenStrapiBulkDataItems(blogsNeighborhood?.data),
    ...globalBlogs,
  ];
  const threeUniquePageBlogs = getThreeUniquePageBlogs(arrayBuildingBlogs);

  const linksForHeader = useMemo(
    () => [
      {
        id: 'rentAndSale',
        name: 'For Sale & Rent',
        nameMobile: 'For Sale & Rent',
        section: 'rentAndSale',
        ref: RentAndSaleRef,
      },
      {
        id: 'mainFeatures',
        name: 'Highlights',
        nameMobile: 'Highlights',
        section: 'mainFeatures',
        ref: MainFeaturesRef,
      },
      // {
      //   id: 'mainFeatures',
      //   name: `${name} Highlights`,
      //   nameMobile: `${name} Highlights`,
      //   section: 'mainFeatures',
      //   ref: MainFeaturesRef,
      // },
      {
        id: 'location',
        name: 'Location',
        nameMobile: 'Location',
        section: 'location',
        ref: LocationRef,
      },
      {
        id: 'welcomeTo',
        name: 'Welcome',
        nameMobile: 'Welcome',
        section: 'welcomeTo',
        ref: WelcomeToRef,
      },
      // {
      //   id: 'welcomeTo',
      //   name: `Welcome to ${name}`,
      //   nameMobile: `Welcome to ${
      //     name.length > 5 ? `${name.slice(0, 4)}...` : name
      //   }`,
      //   section: 'welcomeTo',
      //   ref: WelcomeToRef,
      // },
      {
        id: 'gettingAround',
        name: 'Getting Around',
        nameMobile: 'Getting Around',
        section: 'Commute times',
        ref: CommuteTimesRef,
      },
      {
        id: 'condos',
        name: 'Condos',
        nameMobile: 'Condos',
        section: 'condos',
        ref: CondosRef,
      },
      // {
      //   id: 'condos',
      //   name: `${name} Condos`,
      //   nameMobile: `${name} Condos`,
      //   section: 'condos',
      //   ref: CondosRef,
      // },
      // delete CBBRR-2677
      // {
      //   id: 'articles',
      //   name: `Related Articles`,
      //   nameMobile: `Related Articles`,
      //   section: 'articles',
      //   ref: ArticlesRef,
      // },
      // {
      //   id: 'neighborhoods',
      //   name: `Nearby Neighborhoods`,
      //   nameMobile: `Nearby Neighborhoods`,
      //   section: 'neighborhoods',
      //   ref: NeighborhoodsRef,
      // },
    ],
    []
  );

  const showMainFeatures =
    !!mainFeaturesCommon?.length ||
    mainFeaturesWalkscore.some((el) => el.description);
  const showCommuteTimes =
    !!commuteTimesLeft?.timeFirst ||
    !!commuteTimesLeft?.timeSecond ||
    !!commuteTimesRight?.length;

  const linksToRender = () => {
    let skipArr: string[] = [];
    !showMainFeatures && skipArr.push('mainFeatures');
    !showCommuteTimes && skipArr.push('gettingAround');
    !neighborhood.description && skipArr.push('welcomeTo');
    !isBuildings && skipArr.push('condos');
    return linksForHeader.filter((el) => !skipArr.includes(el.id));
  };

  const [isBuildings, setIsBuildings] = useState(false);
  const callbackBuildings = (value: any) => {
    setIsBuildings(value);
  };

  return (
    <>
      <Metadata
        {...{
          title,
          image: getStrapiMedia(image?.data?.attributes, 'small'),
          description,
          url,
        }}
      />
      <GoogleAnalytics pageGroup={'Neighborhood'} />
      <HeaderWithLinks
        links={linksToRender()}
        headerRef={headerRef}
        neighborhoodName={name}
        isNeighborhoodPage
      />
      {/* <Header /> */}
      <ErrorBoundary>
        <div className="pt-0 md:pt-[90px] lg:pt-[60px] xl:pt-[17px]">
          <Breadcrumbs
            neighborhoodPage={true}
            neighborhoodName={name}
            neighborhoodId={slug}
          />
        </div>
      </ErrorBoundary>
      <section
        id="neighborhood-ga4"
        className="container mb-6 text-m md:py-4 lg:py-0"
      >
        <div className="relative flex flex-col-reverse justify-between lg:flex-row">
          <div>
            <h1 className="px-px py-1 pt-[10px] lg:pt-0 text-23-29-0.77 lg:text-30-47-1 uppercase font-sohneBreitBuch">
              Miami's {name} Neighborhood
            </h1>
          </div>
          <div className="relative flex pt-px lg:pt-[6px] mb-2 space-x-2 text-16-26-0.3 md:absolute md:right-0 md:-top-14 lg:-top-2 lg:relative md:mb-0">
            <div className="mt-0.5 md:w-full md:mt-2">
              <Share visibility="block" />
            </div>
          </div>
        </div>
      </section>
      <section className="relative">
        {image?.data?.attributes?.url && (
          <div className="container mt-[21px] md:mt-0 md:pb-2 lg:py-2">
            <div className="relative h-[220px] md:h-[345px] xl:h-145">
              <Image
                layout="fill"
                objectFit="cover"
                src={getMediumImageUrlFromImage(
                  flattenStrapiDataItem(image?.data)
                )}
                blurDataURL={getThumbnailImageUrlFromImage(
                  flattenStrapiDataItem(image?.data)
                )}
                placeholder="blur"
                loading="eager"
                className="object-center w-full h-full"
                alt={`${name} neighborhood`}
                priority
              />
            </div>
          </div>
        )}
      </section>
      <div className="relative md:pb-[69px]" ref={RentAndSaleRef}>
        <ErrorBoundary>
          <div className="relative">
            <div
              id="rentAndSale"
              ref={RentAndSaleRef}
              className="absolute h-1 -top-40 md:-top-102 xl:-top-80"
            />
            <TableNeighborhood stats={neighborhoodStats} />
          </div>
        </ErrorBoundary>
        <div className="container pb-3 mt-[43px] md:mt-0 md:px-12 md:pt-5 md:pb-2 lg:pt-0 text-m">
          <div className="flex flex-col items-center justify-center mx-auto space-y-[21px] md:flex-row md:flex-wrap lg:flex-nowrap lg:space-y-0 lg:space-x-8 xl:space-x-[43px] lg:w-1/2">
            <Button
              classNames="h-[48px] py-[15px] lg:pt-0 lg:!mt-[-23px] md:pb-0"
              name={`${name} Condos for sale`}
              href={createSearchUrlByType('sale', { neighborhoods: [slug] })}
              classNameWidth="lg:pl-[19px]"
              fontClassName="font-sohneBreitHalbfett md:pb-[1.5px] lg:pb-[1px]"
            />
            <Button
              classNames="h-[48px] py-[15px] lg:pt-0 lg:!mt-[-23px] md:pb-0"
              fontClassName="font-sohneBreitHalbfett md:pb-[1.5px] lg:pb-[1px]"
              name={`${name} Condos for rent`}
              href={createSearchUrlByType('rent', { neighborhoods: [slug] })}
              classNameWidth="lg:pl-[19px]"
            />
          </div>
        </div>
      </div>
      {/* We will work on this section in phase 2 */}
      {showMainFeatures && (
        <section className="relative" ref={MainFeaturesRef}>
          <div
            id="mainFeatures"
            className="absolute -top-24 md:-top-44 lg:-top-72 xl:-top-80"
          />
          <MainFeatures
            nbhFeaturesWalkscore={mainFeaturesWalkscore}
            nbhFeaturesCommon={mainFeaturesCommon || []}
          />
        </section>
      )}
      <section className="relative" ref={LocationRef}>
        <div id="location" className="absolute -top-24 md:-top-60 lg:-top-28" />
        <ErrorBoundary>
          <Location
            coordinates={(coordinates as Coordinates) || []}
            neighborhoodId={id}
          />
        </ErrorBoundary>
      </section>
      <section className="relative" ref={WelcomeToRef}>
        <div
          id="welcomeTo"
          className="absolute -top-24 md:-top-32 lg:-top-28"
        />
        <ErrorBoundary>
          <AboutNeighborhood neighborhood={neighborhood} />
        </ErrorBoundary>
      </section>
      {showCommuteTimes && (
        <section className="relative" ref={CommuteTimesRef}>
          <div
            id="gettingAround"
            className="absolute -top-24 md:-top-96 lg:-top-72"
          />
          <CommuteTimes
            commuteTimesLeft={commuteTimesLeft}
            commuteTimesRight={commuteTimesRight}
          />
        </section>
      )}
      <LineBetweenSections hideLine={'container'} />
      <section className="relative" ref={CondosRef}>
        <div id="condos" className="absolute -top-24 md:-top-32 lg:-top-28" />
        <ErrorBoundary>
          <NeighborhoodCondos
            callbackBuildings={callbackBuildings}
            neigborhood={neighborhood}
          />
        </ErrorBoundary>
      </section>
      <section className="relative">
        <Experts
          experts={experts}
          flagLabelTextSize={'text-16-21-0.53 bg-beige'}
        />
      </section>
      <LineBetweenSections hideLine={'container'} />
      <section className="relative" ref={ArticlesRef}>
        <div id="articles" className="absolute -top-24 md:-top-32 lg:-top-28" />
        <ErrorBoundary>
          <RelatedArticles name="Related articles" showButtonMore="mb-[67px]">
            {threeUniquePageBlogs?.map((blog: Blog, index) => {
              return (
                <Articles
                  href={`/blog/${blog.slug}`}
                  key={index}
                  topics={blog?.blogTags}
                  {...(blog?.mainImage?.data?.attributes
                    ? {
                        imageUrl: getSmallImageUrlFromImage(
                          blog.mainImage.data.attributes
                        ),
                        blurredImageUrl: getThumbnailImageUrlFromImage(
                          blog.mainImage.data.attributes
                        ),
                      }
                    : {})}
                  imageAlt={blog.title}
                  title={blog.title}
                  description={blog.shortDescription || ''}
                />
              );
            })}
          </RelatedArticles>
        </ErrorBoundary>
      </section>
      <section className="relative" ref={NeighborhoodsRef}>
        <div
          id="neighborhoods"
          className="absolute -top-24 md:-top-32 lg:-top-28"
        />
        <ErrorBoundary>
          <NearbyNeigbourhoods neighborhoods={neighborhoodsNeighbors?.data} />
        </ErrorBoundary>
      </section>
    </>
  );
};

export default NeighborhoodPage;
