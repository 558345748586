import React from 'react';

export const markerColorsMap = [
  '#EA8864',
  '#64EA93',
  '#8464EA',
  '#EA64B5',
  '#F84343',
  '#1ACBD7',
];

type CommutePinIconProps = {
  index: number;
};

const CommutePinIcon: React.FC<CommutePinIconProps> = ({ index }) => (
  <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1">
    <style>
      {`
        .label {
          font-size: 12px;
          fill: white;
        }
      `}
    </style>
    <title>70B7EBB4-A3B7-46D2-8B1D-416BA159F6E3</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Driving"
        transform="translate(-760.000000, -231.000000)"
        fill={markerColorsMap[index]}
      >
        <g id="Commute-pin" transform="translate(761.000000, 232.000000)">
          <g id="white_location" stroke="#B064EA" strokeWidth="0.2">
            <path
              d="M10.0000576,24 C9.29596682,23.9939999 8.62728593,23.7040128 8.15858537,23.2 C5.11728456,20 0.000987886017,13.912064 0.000987886017,9.888 C-0.036507092,7.303936 0.994650133,4.812032 2.86950569,2.95808 C4.74430792,1.1040768 7.30853566,0.040192 10,0 C12.6914728,0.04 15.2557538,1.104 17.1305028,2.95808 C19.005305,4.8120832 20.0363502,7.304192 19.9990206,9.888 C19.9990206,13.888 14.8827239,19.960064 11.8414231,23.2079355 C11.3706427,23.7089275 10.7019618,23.9959291 9.99995089,24 L10.0000576,24 Z M10.0000576,1.6 C7.75131877,1.64199936 5.6119559,2.5380096 4.05077615,4.091008 C2.48842318,5.6450048 1.63226741,7.729024 1.66767726,9.888128 C1.66767726,12.400128 4.55912614,16.976256 9.40024989,22.136192 C9.55752508,22.2921938 9.77415977,22.3811922 10.0001909,22.3811922 C10.226222,22.3811922 10.4428673,22.2921912 10.6001319,22.136192 C15.4412556,16.976256 18.3327045,12.400256 18.3327045,9.888128 C18.368117,7.7291264 17.5119586,5.645184 15.9496056,4.091008 C14.3882925,2.5380096 12.248903,1.6420096 10.0003242,1.6 L10.0000576,1.6 Z"
              id="Fill-1"
            ></path>
          </g>
          <polygon
            id="Path-2"
            points="5.80447355 1.68892524 1.25462972 6.51253844 1 12.4550509 5.80447355 20.6842915 9.76910107 24 12.3890579 21.661462 17.4340151 14.6102437 19 10.4594275 18.0775847 5.64040691 13.4167657 1.68892524 9.50881612 1"
          ></polygon>
        </g>
      </g>
    </g>
    <text x="8.5" y="16" className="label">
      {index + 1}
    </text>
  </svg>
);

export default CommutePinIcon;
