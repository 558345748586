import React, { useCallback, useEffect, useState } from 'react';
import { styled, Theme, useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import PopUpContactUs from '../../ContactUs/PopUpContactUs';

import Navigation, { HeaderData } from '../Navigation';

import { useHeader } from './HeaderProvider';
import Logo from './Logo';
import BurgerMenu from '../BurgerMenu';
import { useRouter } from 'next/router';
import classnames from 'classnames';

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
  box-shadow: none;
`;

const StyledToolbar = styled(Toolbar)`
  min-height: 0 !important;
  padding: 0 !important;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #d2cfca;
`;

interface HeaderProps {
  children: React.ReactNode;
  heightClass?: string;
}

export const HeaderWrapper: React.FC<HeaderProps> = ({
  children,
  heightClass,
}) => {
  return (
    <StyledAppBar position="sticky">
      <div className={`w-full ${heightClass}`}>
        <StyledToolbar>{children}</StyledToolbar>
      </div>
    </StyledAppBar>
  );
};

export const DefaultHeaderBody: React.FC = () => {
  const { asPath, query } = useRouter();
  const [menuActive, setMenuActive] = useState(false);
  const { fetchMoreBuildings, fetchMoreNhds, data } = useHeader();
  const isHomePage = asPath === '/';
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  const DesktopMenu = useCallback(
    ({ data }: { data: HeaderData | undefined }) => (
      <React.Fragment>
        <div className="flex items-center justify-between w-full lg:min-w-min">
          <Logo />
          <Navigation
            isDesktop
            hidden={'hidden xl:flex'}
            active={menuActive}
            setActive={setMenuActive}
            dataSet={{
              fetchMoreBuildings,
              fetchMoreNhds,
              data,
            }}
          />
          <PopUpContactUs />
        </div>
      </React.Fragment>
    ),
    [menuActive]
  );

  const MobileMenu = useCallback(
    ({ data }: { data: HeaderData | undefined }) => (
      <React.Fragment>
        <div className="flex items-center">
          <BurgerMenu
            active={menuActive}
            setActive={setMenuActive}
            dataSet={{
              fetchMoreBuildings,
              fetchMoreNhds,
              data,
            }}
          />
          <Logo />
        </div>
        <PopUpContactUs isMobile={true} />
      </React.Fragment>
    ),
    [menuActive]
  );

  return (
    <div
      className={classnames(
        `
          flex max-w-full w-full items-center justify-between px-0 
          pl-[30px] md:pl-[45px] lg:pl-[50px] 
          h-[61px] md:h-16 lg:h-13 xl:h-[49px],
        `,
        {
          'container-header': isHomePage,
          container: !isHomePage,
        }
      )}
    >
      {isDesktop ? <DesktopMenu data={data} /> : <MobileMenu data={data} />}
    </div>
  );
};

interface ToggleHeaderProps {
  scrolledHeader: React.ReactNode;
  initialHeader: React.ReactNode;
  heightClass?: string;
  toggle?: boolean;
}
export const ToggleHeader: React.FC<ToggleHeaderProps> = ({
  scrolledHeader,
  initialHeader,
  heightClass,
  toggle = true,
}) => {
  const [inTop, setInTop] = React.useState(true);

  useEffect(() => {
    const handleScroll = () => setInTop(window.scrollY <= 50);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderWrapper heightClass={heightClass}>
      {toggle && !inTop ? scrolledHeader : initialHeader}
    </HeaderWrapper>
  );
};

const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <DefaultHeaderBody />
    </HeaderWrapper>
  );
};

export default Header;
