import React, { ReactNode } from 'react';
import FlagLabel from '../../components/FlagLabel';
import { KeyValue, KeyValueList } from '../../components/KeyValueList';
import {
  CommuteTimesLeft,
  CommuteTimesRight,
} from 'apolloClient/types/Neighborhood';
import LineBetweenSections from 'components/LineBetweenSections';

type CommuteTimesProps = {
  commuteTimesLeft?: CommuteTimesLeft;
  commuteTimesRight?: CommuteTimesRight[];
};

type CommuteTimeProps = {
  time?: string;
  destination?: string;
  transport?: string;
};

const getCommuteKeyValue = (
  inputArray: CommuteTimesRight[] | undefined
): KeyValue<ReactNode>[] => {
  let commuteKeyVal = undefined;
  if (inputArray) {
    commuteKeyVal = inputArray.map((obj) => ({
      key: obj.destination,
      value: obj.time,
    }));
  }
  return commuteKeyVal as KeyValue<ReactNode>[];
};

const CommuteTime: React.FC<CommuteTimeProps> = ({
  time,
  destination,
  transport,
}) => {
  return (
    <div className="flex items-center flex-col w-1/2 border-r border-dashed last:border-r-0 xl:h-32 border-gray px-[10px] md:px-[20px] xl:px-[30px] gap-[10px]">
      <div className="text-50-57-1.33 md:text-70-57-1.87 text-gold-dark font-sohneBreitHalbfett">
        {time}M
      </div>
      <div className="flex flex-col my-4 text-center">
        <div>{destination}</div>
        <div>by {transport}</div>
      </div>
    </div>
  );
};

const CommuteTimes: React.FC<CommuteTimesProps> = ({
  commuteTimesLeft,
  commuteTimesRight,
}) => {
  return (
    <>
      <LineBetweenSections hideLine={'container'} />
      <section className="container py-3 mb-6 md:py-4 xl:py-2 text-m">
        <div className="flex">
          <div className="relative flex flex-col justify-center mb-10 w-max">
            <FlagLabel name="Commute times" />
          </div>
        </div>
        <div className="flex flex-col justify-between xl:flex-row">
          <div className="flex mt-4 mb-6 md:mr-10 xl:w-5/12">
            {commuteTimesLeft?.timeFirst &&
              commuteTimesLeft?.destinationFirst &&
              commuteTimesLeft?.transportFirst && (
                <CommuteTime
                  time={commuteTimesLeft?.timeFirst}
                  destination={commuteTimesLeft?.destinationFirst}
                  transport={commuteTimesLeft?.transportFirst}
                />
              )}
            {commuteTimesLeft?.timeSecond &&
              commuteTimesLeft?.destinationSecond &&
              commuteTimesLeft?.transportSecond && (
                <CommuteTime
                  time={commuteTimesLeft?.timeSecond}
                  destination={commuteTimesLeft?.destinationSecond}
                  transport={commuteTimesLeft?.transportSecond}
                />
              )}
          </div>
          <div className="flex flex-col lg:ml-10 xl:w-7/12 xl:flex-row">
            <div className="w-full">
              <KeyValueList
                colLeftClassName="font-sohneKraftig py-[6px] md:py-[12px] text-14-22-0.3 md:text-16-23-0.3"
                colRightClassName="py-[6px] md:py-[12px] text-14-22-0.3 md:text-16-26-0.3"
                rows={getCommuteKeyValue(commuteTimesRight)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommuteTimes;
