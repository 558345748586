import { useCallback, useRef } from 'react';

export const useDebounced = <T>(
  callback: (arg: T) => void,
  timeout = 300,
  deps: any[]
) => {
  const timerRef = useRef<NodeJS.Timeout>();
  return useCallback((arg: T) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      callback(arg);
    }, timeout);
  }, deps);
};
