import React, { useState } from 'react';

import TooltipInfo from './TooltipInfo';
import { SetFiltersValues } from '../../types';

interface Props {
  value: string;
  setValues: SetFiltersValues;
  label?: string;
  fieldName?: string;
  placeholder?: string;
  info?: string;
}

const Keywords: React.FC<Props> = ({
  setValues,
  value,
  label = 'Keywords',
  fieldName = 'keyWords',
  placeholder = 'pool, patio, etc.',
  info,
}) => {
  const [localValue, setLocalValue] = useState(value);

  const handleBlur = () => {
    setValues((values) => ({ ...values, [fieldName]: localValue }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = e.target.value.replace(/['\\;]/g, '');
    setLocalValue(sanitizedValue);
  };

  return (
    <div className="">
      <div className="mb-0 flex items-center md:text-14-26-0.3">
        {label}
        {info && <TooltipInfo text={info} />}
      </div>
      <div className="md:pr-3.5 md:w-[298px] leading-[21px]">
        <input
          className="w-full px-4 h-[44px] pt-[6px] pb-[7px] mr-2 border md:w-[298px] border-beige-dark text-black"
          type="text"
          placeholder={placeholder}
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default Keywords;
