import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image from 'next/legacy/image';

import unitLeftArrow from '../../public/images/unit_left_arrow.svg';
import unitRightArrow from '../../public/images/unit_right_arrow.svg';
import { PageBuilderImageType } from 'components/PageBuilderContentParser';

type CarouselTypes = {
  images: PageBuilderImageType[];
  styles: object;
};
const Carousel: React.FC<CarouselTypes> = ({ images, styles }) => {
  function ArticleCarouselPrevArrow({ onClick, ...props }: any) {
    return (
      <button
        {...props}
        onClick={onClick}
        className="z-50 transform -translate-y-1/2 slick-arrow slick-prev slick-prev-article-carousel"
      >
        <Image src={unitLeftArrow} alt="previous item" width="28" height="48" />
      </button>
    );
  }
  function ArticleCarouselNextArrow({ onClick, ...props }: any) {
    return (
      <button
        {...props}
        onClick={onClick}
        className="z-50 transform -translate-y-1/2 slick-arrow slick-next slick-next-article-carousel"
      >
        <Image src={unitRightArrow} alt="next item" width="28" height="48" />
      </button>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArticleCarouselPrevArrow />,
    nextArrow: <ArticleCarouselNextArrow />,
  };
  if (!images || !images.length) return null;
  return (
    <div className="mb-20 page-builder-carousel" style={styles}>
      <div className="relative h-full">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <div className="relative h-full">
                <img
                  className="object-cover object-center w-full min-h-full"
                  src={image.url}
                  alt="image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default Carousel;
